// // EditReceipt.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditReceipt = ({ receipt, fetchReceipts, handleClose }) => {
//   const [regNo, setRegNo] = useState('');
//   const [course, setCourse] = useState('');
//   const [totalFees, setTotalFees] = useState('');
//   const [currentDue, setCurrentDue] = useState('');

//   useEffect(() => {
//     if (receipt) {
//       setRegNo(receipt.regNo);
//       setCourse(receipt.course);
//       setTotalFees(receipt.totalFees);
//       setCurrentDue(receipt.currentDue);
//     }
//   }, [receipt]);

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     axios.put(`http://localhost:8081/api/receipts/${receipt._id}`, {
//       regNo,
//       course,
//       totalFees,
//       currentDue
//     })
//     .then(response => {
//       console.log('Receipt updated successfully:', response.data);
//       fetchReceipts(); // Refresh receipts after update
//       handleClose(); // Close modal after submission
//     })
//     .catch(error => {
//       console.error('Error updating receipt:', error);
//     });
//   };

//   return (
//     <div className="modal-content">
//       <div className="modal-header">
//         <h5 className="modal-title">Edit Receipt</h5>
//         <button type="button" className="close" onClick={handleClose}>
//           <span>&times;</span>
//         </button>
//       </div>
//       <div className="modal-body">
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="regNo">Registration Number</label>
//             <input type="text" className="form-control" id="regNo" value={regNo} onChange={(e) => setRegNo(e.target.value)} required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="course">Course</label>
//             <input type="text" className="form-control" id="course" value={course} onChange={(e) => setCourse(e.target.value)} required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="totalFees">Total Fees</label>
//             <input type="text" className="form-control" id="totalFees" value={totalFees} onChange={(e) => setTotalFees(e.target.value)} required />
//           </div>
//           <div className="form-group">
//             <label htmlFor="currentDue">Current Due</label>
//             <input type="text" className="form-control" id="currentDue" value={currentDue} onChange={(e) => setCurrentDue(e.target.value)} required />
//           </div>
//           <button type="submit" className="btn btn-primary">Save Changes</button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default EditReceipt;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditReceipt = ({ receipt, fetchReceipts, handleClose }) => {
//   const [regNo, setRegNo] = useState('');
//   const [course, setCourse] = useState('');
//   const [totalFees, setTotalFees] = useState('');
//   const [currentDue, setCurrentDue] = useState('');

//   useEffect(() => {
//     if (receipt) {
//       setRegNo(receipt.regNo);
//       setCourse(receipt.course);
//       setTotalFees(receipt.totalFees);
//       setCurrentDue(receipt.currentDue);
//     }
//   }, [receipt]);

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     axios.put(`/api/receipts/${receipt._id}`, {
//       regNo,
//       course,
//       totalFees,
//       currentDue
//     })
//     .then(response => {
//       console.log('Receipt updated successfully:', response.data);
//       fetchReceipts(); // Refresh receipts after update
//       handleClose(); // Close modal after submission
//     })
//     .catch(error => {
//       console.error('Error updating receipt:', error);
//     });
//   };

//   return (
//     <div className="modal-content">
//       <div className="modal-header">
//         <h5 className="modal-title">Edit Receipt</h5>
//         <button type="button" className="close" onClick={handleClose}>
//           <span>&times;</span>
//         </button>
//       </div>
//       <div className="modal-body">
//         <form onSubmit={handleSubmit}>
//           <div className="form-group">
//             <label htmlFor="regNo">Registration Number</label>
//             <input type="text" className="form-control" id="regNo" value={regNo} onChange={(e) => setRegNo(e.target.value)} required style={{color:"black"}} />
//           </div>
//           <div className="form-group">
//             <label htmlFor="course">Course</label>
//             <input type="text" className="form-control" id="course" value={course} onChange={(e) => setCourse(e.target.value)} required  style={{color:"black"}}/>
//           </div>
//           <div className="form-group">
//             <label htmlFor="totalFees">Total Fees</label>
//             <input type="text" className="form-control" id="totalFees" value={totalFees} onChange={(e) => setTotalFees(e.target.value)} required style={{color:"black"}}/>
//           </div>
//           <div className="form-group">
//             <label htmlFor="currentDue">Current Due</label>
//             <input type="text" className="form-control" id="currentDue" value={currentDue} onChange={(e) => setCurrentDue(e.target.value)} required style={{color:"black"}} />
//           </div>
//           <button type="submit" className="btn btn-primary">Save Changes</button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default EditReceipt;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditReceipt = ({ receipt, fetchReceipts, handleClose }) => {
  const [regNo, setRegNo] = useState('');
  const [course, setCourse] = useState('');
  const [totalFees, setTotalFees] = useState('');
  const [totalReceived, setTotalReceived] = useState(''); // Added this line
  const [currentDue, setCurrentDue] = useState('');

  useEffect(() => {
    if (receipt) {
      setRegNo(receipt.regNo);
      setCourse(receipt.course);
      setTotalFees(receipt.totalFees);
      setTotalReceived(receipt.totalReceived); // Added this line
      setCurrentDue(receipt.currentDue);
    }
  }, [receipt]);

  // Update currentDue whenever totalFees or totalReceived changes
  useEffect(() => {
    const calculateCurrentDue = () => {
      const totalFeesValue = parseFloat(totalFees) || 0;
      const totalReceivedValue = parseFloat(totalReceived) || 0; // Added this line
      const currentDueValue = totalFeesValue - totalReceivedValue;
      setCurrentDue(currentDueValue.toFixed(2));
    };
    calculateCurrentDue();
  }, [totalFees, totalReceived]); // Added totalReceived here

  const handleSubmit = (event) => {
    event.preventDefault();

    axios.put(`/api/receipts/${receipt._id}`, {
      regNo,
      course,
      totalFees,
      totalReceived, // Added this line
      currentDue
    })
    .then(response => {
      console.log('Receipt updated successfully:', response.data);
      fetchReceipts(); // Refresh receipts after update
      handleClose(); // Close modal after submission
    })
    .catch(error => {
      console.error('Error updating receipt:', error);
    });
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Receipt</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span>&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="regNo">Registration Number</label>
            <input type="text" className="form-control" id="regNo" value={regNo} onChange={(e) => setRegNo(e.target.value)} required style={{color:"black"}} />
          </div>
          <div className="form-group">
            <label htmlFor="course">Course</label>
            <input type="text" className="form-control" id="course" value={course} onChange={(e) => setCourse(e.target.value)} required style={{color:"black"}} />
          </div>
          <div className="form-group">
            <label htmlFor="totalFees">Total Fees</label>
            <input type="text" className="form-control" id="totalFees" value={totalFees} onChange={(e) => setTotalFees(e.target.value)} required style={{color:"black"}} />
          </div>
          <div className="form-group">
            <label htmlFor="totalReceived">Total Received</label>
            <input type="text" className="form-control" id="totalReceived" value={totalReceived} onChange={(e) => setTotalReceived(e.target.value)} required style={{color:"black"}} /> {/* Added this line */}
          </div>
          <div className="form-group">
            <label htmlFor="currentDue">Current Due</label>
            <input type="text" className="form-control" id="currentDue" value={currentDue} onChange={(e) => setCurrentDue(e.target.value)} required style={{color:"black"}} />
          </div>
          <button type="submit" className="btn btn-primary">Save Changes</button>
        </form>
      </div>
    </div>
  );
}

export default EditReceipt;

