// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Leftbar from '../Components/Leftbar/Leftbar';
// import Modal from './Modal';
// import { FaRegEdit } from "react-icons/fa";
// import { MdDeleteForever } from "react-icons/md";
// import Newecepit from './Newecepit'; // Import Newecepit component
// import Editeceipt from './AddReceipt/Editeceipt';
// import './Receipts.css';
// import '../Pages/Modal.css';

// const Receipts = () => {
//   const [receipts, setReceipts] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedReceipt, setSelectedReceipt] = useState(null); // For edit functionality
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     fetchReceipts();
//   }, []);

//   const fetchReceipts = () => {
//     axios.get('http://localhost:8085/api/receipts')
//       .then(response => {
//         console.log('Fetched receipts:', response.data); // Log response data to verify
//         setReceipts(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the receipts!', error);
//       });
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setSelectedReceipt(null); // Reset selected receipt after closing modal
//     setSearchQuery(''); // Clear search query
//   };

//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleAddReceipt = () => {
//     setSelectedReceipt(null); // Clear selected receipt for adding new
//     handleOpenModal(); // Open modal
//   };

//   const handleEditReceipt = (receipt) => {
//     setSelectedReceipt(receipt); // Set selected receipt for editing
//     handleOpenModal(); // Open modal
//   };

//   const handleDeleteReceipt = (receiptId) => {
//     axios.delete(`http://localhost:8085/api/receipts/${receiptId}`)
//       .then(response => {
//         console.log('Receipt deleted successfully:', response.data);
//         fetchReceipts(); // Refresh receipts after deletion
//       })
//       .catch(error => {
//         console.error('Error deleting receipt:', error);
//       });
//   };

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//     console.log('Search query:', event.target.value); // Log search query to verify
//   };

//   // Filter receipts based on search query
//   const filteredReceipts = receipts.filter(receipt =>
//     receipt.regNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     receipt.course.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     receipt.totalFees.toString().includes(searchQuery.toLowerCase()) ||
//     receipt.currentDue.toString().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <>
//      <section className="page-header">
//         <div className="page-header__bg_1"
//         >
          
//         </div>
//         {/* <div
//           className="page-header__gradient"
//           style={{
//             backgroundImage:
//               "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)",
//           }}
//         ></div> */}
//         <div className="shape1 float-bob-x">
//           <img src=" ./images/sttsbanner.png" alt="banner shape" />
//         </div>
//         <div className="container">
//           <div className="page-header__inner text-center">
//             <h2>Admin</h2>

//             <ul className="thm-breadcrumb list-unstyled ml-0">
//               {/* Breadcrumb NavXT 7.2.0 */}
//               <li className="home">
//                 <span property="itemListElement" typeof="ListItem">
//                   <a
//                     property="item"
//                     typeof="WebPage"
//                     title="Go to Ontech."
//                     href="/"
//                     className="home"
//                   >
//                     <span property="name">Home</span>
//                   </a>
//                   <meta property="position" content="1" />
//                 </span>
//               </li>
//               <li className="post post-page current-item">
//                 <span property="itemListElement" typeof="ListItem">
//                   <span property="name" className="post post-page current-item">
//                  Admin
//                   </span>
//                   <meta property="url" content="" />
//                   <meta property="position" content="2" />
//                 </span>
//               </li>
//             </ul>
//           </div>
//         </div>
        
//       </section>

//       <Leftbar />
//       <div id="main-content" className='resposive_recepit_container_here'>
//         <div className="block-header responsive_recepit_container_here">
//           {/* <h2>Receipts</h2> */}
//         </div>
//         <div className="container-fluid">
//           <div className="row clearfix" >
             
        
//             <div className="col-lg-3 col-md-6" >
//               <button className="card responsive_card_here" onClick={handleAddReceipt}>
//                 <div className="body top_counter">
//                   <div className="content">
//                     <div className="icon bg-warning text-white" style={{ backgroundColor: 'pink' }}>
//                       <FaRegEdit style={{ fontSize: '25px', marginTop: '8px' }} />
//                     </div>
//                     <span style={{color:"black"}}>Add Receipt</span>
//                   </div>
//                 </div>
//               </button>
//             </div>
            
//             <div className="col-lg-3 col-md-6" >
//               <div className="form-group">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="Search..."
//                   value={searchQuery}
//                   onChange={handleSearchChange}
//                   style={{ color: 'black' }}
//                 />
//               </div>
//             </div>
            

//           </div>

//           <div className="row clearfix">
//             <div className="col-lg-12">
//               <div className="body">
//                 <div className="table-responsive">
//                   <table className="table table-bordered table-striped table-hover" >
//                     <thead>
//                       <tr>
//                         <th>SNo</th>
//                         <th>Reg. No.</th>
//                         <th>Course</th>
//                         <th>Course Fee</th>
//                         <th>Current Due</th>
//                         <th>Actions</th> {/* New column for actions */}
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {filteredReceipts.map((receipt, index) => (
//                         <tr key={receipt._id}>
//                           <td className='my_table_row'><span className='resposive_s'>S.no:{"  "}</span>{index + 1}</td>
//                           <td className='my_table_row'><span className='resposive_s'>RegNo:{"  "}</span>{receipt.regNo}</td>
//                           <td className='my_table_row'><span className='resposive_s'>Course:{"  "}</span>{receipt.course}</td>
//                           <td className='my_table_row'><span className='resposive_s'>TotalFees:{"  "}</span>{receipt.totalFees}</td>
//                           <td className='my_table_row'><span className='resposive_s'>CurrentDue:{"  "}</span>{receipt.currentDue}</td>
//                           <td>
//                             <button className="edit-btn" onClick={() => handleEditReceipt(receipt)} style={{margin:"2px"}}>
//                               <FaRegEdit />
//                             </button>
//                             <button className="delete-btn" onClick={() => handleDeleteReceipt(receipt._id)} style={{margin:"2px"}}>
//                               <MdDeleteForever />
//                             </button>
//                           </td>
//                         </tr>
//                       ))}
//                       {filteredReceipts.length === 0 && (
//                         <tr>
//                           <td colSpan="6">No receipts found</td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Render modal with EditReceipt component */}
//         {showModal && (
//           <Modal show={showModal} handleClose={handleCloseModal}>
//             {selectedReceipt ? (
//               <Editeceipt receipt={selectedReceipt} fetchReceipts={fetchReceipts} handleClose={handleCloseModal} />
//             ) : (
//               <Newecepit handleClose={handleCloseModal} fetchReceipts={fetchReceipts} />
//             )}
//           </Modal>
//         )}
//       </div>
//     </>
//   );
// }

// export default Receipts;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Leftbar from '../Components/Leftbar/Leftbar';
import Modal from './Modal';
import { FaRegEdit, FaPrint } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import Newecepit from './Newecepit'; // Import Newecepit component
import Editeceipt from './AddReceipt/Editeceipt';
import './Receipts.css';
import '../Pages/Modal.css';

const Receipts = () => {
  const [receipts, setReceipts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null); // For edit functionality
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchReceipts();
  }, []);

  const fetchReceipts = () => {
    axios.get('/api/receipts')
      .then(response => {
        console.log('Fetched receipts:', response.data); // Log response data to verify
        setReceipts(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the receipts!', error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReceipt(null); // Reset selected receipt after closing modal
    setSearchQuery(''); // Clear search query
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleAddReceipt = () => {
    setSelectedReceipt(null); // Clear selected receipt for adding new
    handleOpenModal(); // Open modal
  };

  const handleEditReceipt = (receipt) => {
    setSelectedReceipt(receipt); // Set selected receipt for editing
    handleOpenModal(); // Open modal
  };

  const handleDeleteReceipt = (receiptId) => {
    axios.delete(`/api/receipts/${receiptId}`)
      .then(response => {
        console.log('Receipt deleted successfully:', response.data);
        fetchReceipts(); // Refresh receipts after deletion
      })
      .catch(error => {
        console.error('Error deleting receipt:', error);
      });
  };

  const handlePrintReceipt = (receipt) => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print Receipt</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write('<h1>Receipt Details</h1>');
    printWindow.document.write(`<p>Reg No: ${receipt.regNo}</p>`);
    printWindow.document.write(`<p>Course: ${receipt.course}</p>`);
    printWindow.document.write(`<p>Total Fees: ${receipt.totalFees}</p>`);
    printWindow.document.write(`<p>Current Due: ${receipt.currentDue}</p>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log('Search query:', event.target.value); // Log search query to verify
  };

  // Filter receipts based on search query
  const filteredReceipts = receipts.filter(receipt =>
    receipt.regNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
    receipt.course.toLowerCase().includes(searchQuery.toLowerCase()) ||
    receipt.totalFees.toString().includes(searchQuery.toLowerCase()) ||
    receipt.currentDue.toString().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg_1"></div>
        <div className="shape1 float-bob-x">
          <img src="./images/sttsbanner.png" alt="banner shape" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Admin</h2>
            <ul className="thm-breadcrumb list-unstyled ml-0">
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a property="item" typeof="WebPage" title="Go to Ontech." href="/" className="home">
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">Admin</span>
                  <meta property="url" content="" />
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Leftbar />
      <div id="main-content" className='resposive_recepit_container_here'>
        <div className="block-header responsive_recepit_container_here"></div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6">
              <button className="card responsive_card_here" onClick={handleAddReceipt}>
                <div className="body top_counter">
                  <div className="content">
                    <div className="icon bg-warning text-white" style={{ backgroundColor: 'pink' }}>
                      <FaRegEdit style={{ fontSize: '25px', marginTop: '8px' }} />
                    </div>
                    <span style={{ color: "black" }}>Add Receipt</span>
                  </div>
                </div>
              </button>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{ color: 'black' }}
                />
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>SNo</th>
                        <th>Reg. No.</th>
                        <th>Course</th>
                        <th>Course Fee</th>
                        <th>Current Due</th>
                        <th>Action</th>
                        <th>Date</th> {/* New column for actions */}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredReceipts.map((receipt, index) => (
                        <tr key={receipt._id}>
                          <td className='my_table_row'><span className='resposive_s'>S.no:{"  "}</span>{index + 1}</td>
                          <td className='my_table_row'><span className='resposive_s'>RegNo:{"  "}</span>{receipt.regNo}</td>
                          <td className='my_table_row'><span className='resposive_s'>Course:{"  "}</span>{receipt.course}</td>
                          <td className='my_table_row'><span className='resposive_s'>TotalFees:{"  "}</span>{receipt.totalFees}</td>
                          <td className='my_table_row'><span className='resposive_s'>CurrentDue:{"  "}</span>{receipt.currentDue}</td>
                          <td className='my_table_row'><span className='resposive_s'>Date:{"  "}</span>{receipt.date}</td>   <td>
                            <button className="edit-btn" onClick={() => handleEditReceipt(receipt)} style={{ margin: "2px" }}>
                              <FaRegEdit />
                            </button>
                            <button className="delete-btn" onClick={() => handleDeleteReceipt(receipt._id)} style={{ margin: "2px" }}>
                              <MdDeleteForever />
                            </button>
                            <button className="print-btn" onClick={() => handlePrintReceipt(receipt)} style={{ margin: "2px" }}>
                              <FaPrint />
                            </button>
                          </td>
                        </tr>
                      ))}
                      {filteredReceipts.length === 0 && (
                        <tr>
                          <td colSpan="6">No receipts found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Render modal with EditReceipt component */}
        {showModal && (
          <Modal show={showModal} handleClose={handleCloseModal}>
            {selectedReceipt ? (
              <Editeceipt receipt={selectedReceipt} fetchReceipts={fetchReceipts} handleClose={handleCloseModal} />
            ) : (
              <Newecepit handleClose={handleCloseModal} fetchReceipts={fetchReceipts} />
            )}
          </Modal>
        )}
      </div>
    </>
  );
}

export default Receipts;

