import React from 'react'
import Topheader from '../Components/Topheader'
import Homeslider from '../Components/Homeslider'



const Home = () => {
  return (
   <>
   {/* <Navi /> */}
   {/* <Topheader/> */}
   <Homeslider />
   
   </>
  )
}

export default Home
