
import React, { useEffect, useState } from 'react';

import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery';

import './Review.css';

const review = () => {
  
    const testiMonials = [
        {
           img: ' /images/shawreview.png',
           details:'I very happy to be a part of this institute . Its the no.1 cad,cam institute in north Odisha . There are so many good things in this institute but i like one thing i.e this institute tech in less money nd also gives job opportunity nd.             ',
        },
        {
           img: ' /images/shawreview5.png',
           details:'I very happy to be a part of this institute.Ite the no 1 cas,cam institute in north Odisha.There are so many good things in this institute but I like one thing i.e this institute tech in less money and also gives job opportunity.I love this institute very much for giving me lots of priceless gift and give mea chance to be a part of it.'
        },
        {
            img: '/images/shawreview3.png',
            details:'I have been a member of this training institute since last 1 year.The institute provides quality training and that too according to the comfortable time of the students. All staff members are very co-operative. '
        },
        {
           img: '/images/shawreview4.png',
           details:'I am very happy to be a part of this Institute 😃 its a very good training and placement centre.. '
        },
        // {
        //     img: './shawreview5.png',
        //     details:'I am very happy to be a part of this Institute 😃 its a very good training and placement centre.. '
        //  },
    ]
    
    // Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 2000,
        smartSpeed: 850,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
    
    return (
        <section id="testimonial" className="testimonials pt-70 pb-70">
            <div className="container" style={{marginBottom: "40px", marginTop: "70px"}}>
                

                

                <div class="sec-title">
                              <div class="sec-title__tagline responsive_review">
                                <div class="inner">
                                  <div class="round-box">
                                    <img
                                      decoding="async"
                                      src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sec-title-shape.png"
                                      alt="alt"
                                      title="sec-title-shape"
                                    />{" "}
                                  </div>
                                  <div class="text">
                                    <p class="sec-title__sub-title" style={{fontSize:"23px",fontWeight:"900"}}>What Our Student Says</p>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                
                <p className="text-center"></p>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <OwlCarousel id="customer-testimonials_1" className="owl-carousel owl-theme" {...options}>
                            {testiMonials.map((testiMonialDetail, index) => (
                                <div className="item_1" key={index}>
                                    <div className="shadow-effect">
                                        <img className="img-fluid" src={testiMonialDetail.img} alt={`Testimonial ${index}`} style={{height:"90px",width:"90px",marginLeft:"105px"}}/>
                                        <p>{testiMonialDetail.details}</p>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default review;