import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { TiStar } from "react-icons/ti";

const MobileNav = ({ isLoggedIn, isAdmin, onLogout }) => {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [isNavVisible, setNavVisible] = useState(true);
    const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        onLogout();
        alert('Logged out successfully');
        navigate('/');
      };

    useEffect(() => {
        const handleResize = () => {
            // Check if the window width is less than 768px (typical mobile view)
            if (window.innerWidth < 768) {
                setNavVisible(true); // Show the navigation
            } else {
                setNavVisible(false); // Hide the navigation
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial check for window width on component mount
        handleResize();

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMobileNav = () => {
        setMobileNavOpen(!isMobileNavOpen);
    };

    return (
        <div style={{ backgroundColor: "black" }}>
                    
            {isNavVisible && ( // Render the navigation only if it's visible
                <div className={`mobile-nav-wrapper ${isNavVisible ? 'visible' : ''}`} style={{ textAlign: 'right', padding: '10px', background: isNavVisible ? 'black' : 'transparent', transition: 'background 0.3s' }}>
                    
                    {window.innerWidth < 768 && ( // Render the mobile navigation icon only if the window width is less than 768px
                        <>
                        <div
                            className="mobile-nav-icon"
                            style={{ fontSize: '25px', cursor: 'pointer', marginRight: "10px", color: "white" }}
                            onClick={toggleMobileNav}
                        >
                            ☰
                        </div>

                        <div style={{marginTop:"-30px",marginRight:"380px",width:"110px"}}>
                        <img src="/assets1/newlogo.jpg" alt="Logo" style={{ height: '50px', marginLeft: '10px' }} />
                        </div>

                        </>
                    )}
                    <div style={{ backgroundColor: "black" }}>
                    
                        {isMobileNavOpen && (
                            <div className="mobile-nav-content" style={{ top: '50px', width: "100px" }}>
                                <ul style={{ listStyle: 'none', padding: 0, marginRight: "60px" }}>
                                    <li className='underline-hover-effect'><Link to="/" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>HOME</b></Link></li>
                                    <li className='underline-hover-effect'><Link to="/about" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>ABOUT</b></Link></li>
                                    <li className='underline-hover-effect'><Link to="/Courses" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>COURSES</b></Link></li>
                                    <li className='underline-hover-effect'><Link to="/gallery" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>GALLERY</b></Link></li>
                                    <li className='underline-hover-effect'><Link to="/contact" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>CONTACT</b></Link></li>
                                    <li className='underline-hover-effect'><Link to="/display" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>CAREERS</b></Link></li>
                                    
                                    <li className='underline-hover-effect'>
                                                    {isAdmin && (
                                            <li className="menu search-option">
                                            <Link to="/dashboard" className="get-start-button d-flex align-items-center" style={{ width: "20px", marginTop: "-8px", height: "30px" }}>
                                                <b style={{ color: "white" }}>ADMIN</b>
                                            </Link>
                                            </li>
                                        )}
                                    </li>

                                    <li className='underline-hover-effect'>
                                    {isLoggedIn ? (
                                        <button onClick={handleLogout} className="btn" style={{color:'black',backgroundColor:'#ffb237' }}>Logout</button>
                                        ) : (
                                        <Link to="/login" className="btn" style={{color:'black',backgroundColor:'#ffb237',padding:"10px" }}>Login</Link>
                                        )}
                                    </li>
                                    
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>

        // <div style={{ backgroundColor: "black" }}>
        //     {isNavVisible && ( // Render the navigation only if it's visible
        //         <div className={`mobile-nav-wrapper ${isNavVisible ? 'visible' : ''}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', background: isNavVisible ? 'black' : 'transparent', transition: 'background 0.3s' }}>
        //             <div>
        //                 {/* Include your logo here */}
        //                 <img src="path_to_your_logo" alt="Logo" style={{ height: '50px', marginLeft: '10px' }} />
        //             </div>
        //             {window.innerWidth < 768 && ( // Render the mobile navigation icon only if the window width is less than 768px
        //                 <div
        //                     className="mobile-nav-icon"
        //                     style={{ fontSize: '25px', cursor: 'pointer', marginRight: "10px", color: "white" }}
        //                     onClick={toggleMobileNav}
        //                 >
        //                     ☰
        //                 </div>
        //             )}
        //             <div style={{ backgroundColor: "black" }}>
        //                 {isMobileNavOpen && (
        //                     <div className="mobile-nav-content" style={{ top: '50px', width: "100px" }}>
        //                         <ul style={{ listStyle: 'none', padding: 0, marginRight: "60px" }}>
        //                             <li><a href="/" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>HOME</b></a></li>
        //                             <li><a href="/about" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>ABOUT</b></a></li>
        //                             <li><a href="/Courses" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>COURSES</b></a></li>
        //                             <li><a href="/gallery" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>GALLERY</b></a></li>
        //                             <li><a href="/contact" style={{ fontSize: "18px" }}><b style={{ color: "white" }}>CONTACT</b></a></li>
        //                         </ul>
        //                     </div>
        //                 )}
        //             </div>
        //         </div>
        //     )}
        // </div>
    );
};

export default MobileNav;