// import React, { useState } from 'react';
// import axios from 'axios';
// import '../Pages/Newrecepit.css';

// const Newecepit = () => {
//   const [activeTab, setActiveTab] = useState('London');
//   const [formData, setFormData] = useState({
//     date: '',
//     regNo: '',
//     receiptNo: '',
//     studentName: '',
//     address: '',
//     course: '',
//     totalFees: '',
//     amountCollected: '',
//     totalReceived: '',
//     currentDue: ''
//   });

//   const openCity = (cityName) => {
//     setActiveTab(cityName);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   const handleSubmitReceipt = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('/api/receipts', formData);
//       console.log('Receipt added:', response.data);
//       alert('Receipt added successfully');
//       // Clear form after successful submission
//       setFormData({
//         date: '',
//         regNo: '',
//         receiptNo: '',
//         studentName: '',
//         address: '',
//         course: '',
//         totalFees: '',
//         // amountCollected: '',
//         totalReceived: '',
//         currentDue: ''
//       });
//     } catch (error) {
//       console.error('Error adding receipt:', error);
//       alert('Error adding receipt');
//     }
//   };

//   const handleCancel = () => {
//     // Reset form data
//     setFormData({
//       date: '',
//       regNo: '',
//       receiptNo: '',
//       studentName: '',
//       address: '',
//       course: '',
//       totalFees: '',
//       // amountCollected: '',
//       totalReceived: '',
//       currentDue: ''
//     });
//   };

//   const handleCloseForm = () => {
//     // Reset form data and close the form
//     setFormData({
//       date: '',
//       regNo: '',
//       receiptNo: '',
//       studentName: '',
//       address: '',
//       course: '',
//       totalFees: '',
//       // amountCollected: '',
//       totalReceived: '',
//       currentDue: ''
//     });
//     setActiveTab('');
//   };

//   return (
//     <div>
//       {/* <h2>Tabs</h2>
//       <p>Click on the buttons inside the tabbed menu:</p> */}

//       {/* <div className="tab">
//         <button
//           className={`tablinks ${activeTab === 'London' ? 'active' : ''}`}
//           onClick={() => openCity('London')}
//           style={{ margin: "10px" }}
//         >
//           Add Receipt
//         </button>
//       </div> */}

//       <div id="London" className={`tabcontent ${activeTab === 'London' ? 'show' : ''}`}>
//         <form className="form" onSubmit={handleSubmitReceipt}>
//           <p className="title" style={{textAlign:"left",marginBottom:"10px"}}>Add Receipt</p>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black",width:"195px" }}
//               />
//               {/* <span>Date</span> */}
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="regNo"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Reg.No</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="receiptNo"
//                 value={formData.receiptNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Receipt Number</span>
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="studentName"
//                 value={formData.studentName}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Student Name</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Address</span>
//             </label>
        
          
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="course"
//                 value={formData.course}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Course</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="totalFees"
//                 value={formData.totalFees}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Course Fees</span>
//             </label>
//             {/* <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="amountCollected"
//                 value={formData.amountCollected}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Amount Collected</span>
//             </label> */}
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="totalReceived"
//                 value={formData.totalReceived}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Total Received</span>
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="currentDue"
//                 value={formData.currentDue}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Current Due</span>
//             </label>
//           </div>
//           <div className="flex">
//             <button type="submit" className="submit" style={{ width: "50%", marginRight: "5px" }}>Submit</button>
//             <button type="button" className="submit" onClick={handleCancel} style={{ width: "50%", marginLeft: "5px",marginTop:"9px" }}>Cancel</button>
//             {/* <button type="button" className="submit" onClick={handleCloseForm} style={{ width: "100%", marginTop: "10px" }}>Close Form</button> */}
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Newecepit;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../Pages/Newrecepit.css';

// const Newecepit = () => {
//   const [activeTab, setActiveTab] = useState('London');
//   const [formData, setFormData] = useState({
//     date: '',
//     regNo: '',
//     receiptNo: '',
//     studentName: '',
//     address: '',
//     course: '',
//     totalFees: '',
//     totalReceived: '',
//     currentDue: ''
//   });

//   const openCity = (cityName) => {
//     setActiveTab(cityName);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value
//     });
//   };

//   // Update currentDue whenever totalFees or totalReceived changes
//   useEffect(() => {
//     const calculateCurrentDue = () => {
//       const totalFees = parseFloat(formData.totalFees) || 0;
//       const totalReceived = parseFloat(formData.totalReceived) || 0;
//       const currentDue = totalFees - totalReceived;
//       setFormData(prevData => ({
//         ...prevData,
//         currentDue: currentDue.toFixed(2)  // Update currentDue with calculated value
//       }));
//     };
//     calculateCurrentDue();
//   }, [formData.totalFees, formData.totalReceived]);

//   const handleSubmitReceipt = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8085/api/receipts', formData);
//       console.log('Receipt added:', response.data);
//       alert('Receipt added successfully');
//       // Clear form after successful submission
//       setFormData({
//         date: '',
//         regNo: '',
//         receiptNo: '',
//         studentName: '',
//         address: '',
//         course: '',
//         totalFees: '',
//         totalReceived: '',
//         currentDue: ''
//       });
//     } catch (error) {
//       console.error('Error adding receipt:', error);
//       alert('Error adding receipt');
//     }
//   };

//   const handleCancel = () => {
//     // Reset form data
//     setFormData({
//       date: '',
//       regNo: '',
//       receiptNo: '',
//       studentName: '',
//       address: '',
//       course: '',
//       totalFees: '',
//       totalReceived: '',
//       currentDue: ''
//     });
//   };

//   return (
//     <div>
//       <div id="London" className={`tabcontent ${activeTab === 'London' ? 'show' : ''}`}>
//         <form className="form" onSubmit={handleSubmitReceipt}>
//           <p className="title" style={{textAlign:"left",marginBottom:"10px"}}>Add Receipt</p>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black",width:"195px" }}
//               />
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="regNo"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Reg.No</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="receiptNo"
//                 value={formData.receiptNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Receipt Number</span>
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="studentName"
//                 value={formData.studentName}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Student Name</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Address</span>
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="text"
//                 name="course"
//                 value={formData.course}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Course</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="totalFees"
//                 value={formData.totalFees}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Course Fees</span>
//             </label>
//           </div>
//           <div className="flex">
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="totalReceived"
//                 value={formData.totalReceived}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Total Received</span>
//             </label>
//             <label>
//               <input
//                 className="input"
//                 type="number"
//                 name="currentDue"
//                 value={formData.currentDue}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               <span>Current Due</span>
//             </label>
//           </div>
//           <div className="flex">
//             <button type="submit" className="submit" style={{ width: "50%", marginRight: "5px" }}>Submit</button>
//             <button type="button" className="submit" onClick={handleCancel} style={{ width: "50%", marginLeft: "5px",marginTop:"9px" }}>Cancel</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Newecepit;



// src/Pages/Newecepit.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Pages/Newrecepit.css';

const Newecepit = ({ studentData }) => {
  const [activeTab, setActiveTab] = useState('London');
  const [formData, setFormData] = useState({
    date: '',
    regNo: studentData.regNo || '',
    receiptNo: '',
    studentName: studentData.studentName || '',
    address: '',
    course: studentData.course || '',
    totalFees: studentData.totalFees || '',
    totalReceived: '',
    currentDue: ''
  });

  const openCity = (cityName) => {
    setActiveTab(cityName);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Update currentDue whenever totalFees or totalReceived changes
  useEffect(() => {
    const calculateCurrentDue = () => {
      const totalFees = parseFloat(formData.totalFees) || 0;
      const totalReceived = parseFloat(formData.totalReceived) || 0;
      const currentDue = totalFees - totalReceived;
      setFormData(prevData => ({
        ...prevData,
        currentDue: currentDue.toFixed(2)  // Update currentDue with calculated value
      }));
    };
    calculateCurrentDue();
  }, [formData.totalFees, formData.totalReceived]);

  const handleSubmitReceipt = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/receipts', formData);
      console.log('Receipt added:', response.data);
      alert('Receipt added successfully');
      // Clear form after successful submission
      setFormData({
        date: '',
        regNo: '',
        receiptNo: '',
        studentName: '',
        address: '',
        course: '',
        totalFees: '',
        totalReceived: '',
        currentDue: ''
      });
    } catch (error) {
      console.error('Error adding receipt:', error);
      alert('Error adding receipt');
    }
  };

  const handleCancel = () => {
    // Reset form data
    setFormData({
      date: '',
      regNo: studentData.regNo || '',
      receiptNo: '',
      studentName: studentData.studentName || '',
      address: '',
      course: studentData.course || '',
      totalFees: studentData.totalFees || '',
      totalReceived: '',
      currentDue: ''
    });
  };

  return (
    <div>
      <div id="London" className={`tabcontent ${activeTab === 'London' ? 'show' : ''}`}>
        <form className="form" onSubmit={handleSubmitReceipt}>
          <p className="title" style={{textAlign:"left",marginBottom:"10px"}}>Add Receipt</p>
          <div className="flex">
            <label>
              <input
                className="input"
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
                style={{ color: "black",width:"195px" }}
              />
            </label>
            <label>
              <input
                className="input"
                type="text"
                name="regNo"
                value={formData.regNo}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Reg.No</span>
            </label>
          </div>
          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                name="receiptNo"
                value={formData.receiptNo}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Receipt Number</span>
            </label>
            <label>
              <input
                className="input"
                type="text"
                name="studentName"
                value={formData.studentName}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Student Name</span>
            </label>
          </div>
          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Address</span>
            </label>
            <label>
              <input
                className="input"
                type="text"
                name="course"
                value={formData.course}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Course</span>
            </label>
          </div>
          <div className="flex">
            <label>
              <input
                className="input"
                type="number"
                name="totalFees"
                value={formData.totalFees}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Course Fees</span>
            </label>
          </div>
          <div className="flex">
            <label>
              <input
                className="input"
                type="number"
                name="totalReceived"
                value={formData.totalReceived}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Total Received</span>
            </label>
            <label>
              <input
                className="input"
                type="number"
                name="currentDue"
                value={formData.currentDue}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              <span>Current Due</span>
            </label>
          </div>
          <div className="flex">
            <button type="submit" className="submit" style={{ width: "50%", marginRight: "5px" }}>Submit</button>
            <button type="button" className="submit" onClick={handleCancel} style={{ width: "50%", marginLeft: "5px",marginTop:"9px" }}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Newecepit;
