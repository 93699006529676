import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./Studentform1.css";
import Leftbar from "../Leftbar/Leftbar";

function Studentform1() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    collegeName: "",
    fatherName: "",
    course: "",
    centreName: "",
    grade: "",
    courseDuration: "",
    awardedDate: "",
    softwareCovered: "",
    regNo: "",
    gender: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("api/addCourseStudent", formData);
      console.log("Data added successfully:", response.data);

      setFormData({
        name: "",
        email: "",
        collegeName: "",
        fatherName: "",
        course: "",
        centreName: "",
        grade: "",
        courseDuration: "",
        awardedDate: "",
        softwareCovered: "",
        regNo: "",
        gender: "",
      });

      toast.success("Student data added successfully!");
    } catch (error) {
      console.error("Error adding data:", error.message);
      toast.error("Error adding student data. Please try again.");
    }
  };

  return (
    <>
      <Leftbar />
      <div className="form-container">
        <h2>Add Student Data</h2>
        <ToastContainer />
        <form onSubmit={handleSubmit} className="form_1">
          <div className="form-row">
            <div className="input-container">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="collegeName">College Name:</label>
              <input
                type="text"
                id="collegeName"
                value={formData.collegeName}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="fatherName">Father's Name:</label>
              <input
                type="text"
                id="fatherName"
                value={formData.fatherName}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="course">Course</label>
              <input
                type="text"
                id="course"
                value={formData.course}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="centreName">Centre Name</label>
              <input
                type="text"
                id="centreName"
                value={formData.centreName}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="grade">Grade</label>
              <input
                type="text"
                id="grade"
                value={formData.grade}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="courseDuration">Course Duration:</label>
              <input
                type="text"
                id="courseDuration"
                placeholder="hours"
                value={formData.courseDuration}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="awardedDate">Awarded Date:</label>
              <input
                type="date"
                id="awardedDate"
                placeholder="e.g., 30 June 2022"
                value={formData.awardedDate}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="softwareCovered">Software Covered:</label>
              <input
                type="text"
                id="softwareCovered"
                value={formData.softwareCovered}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="regNo">Registration Number:</label>
              <input
                type="text"
                id="regNo"
                value={formData.regNo}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
            </div>
            <div className="input-container">
              <label htmlFor="gender">Gender:</label>
              <select
                id="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              >
                <option value="">Select Gender</option>
                <option value="MALE">MALE</option>
                <option value="FEMALE">FEMALE</option>
                {/* <option value="Other">Other</option> */}
              </select>
            </div>
          </div>
          <div className="submit-container">
            <button
              style={{ marginRight: "70px", width: "200px" }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        {/* <div className="link-container">
          <Link to="/certificate-view">View Certificates</Link>
        </div> */}
      </div>
    </>
  );
}

export default Studentform1;
