// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         console.log('Fetching details for:', regNo, id); // Debug line
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div style={{marginLeft:'500px'}}>
//       <h2 style={{ textAlign: 'center' }}>Student Details</h2>
//       <p><strong>Reg No:</strong> {student.regNo}</p>
//       <p><strong>Name:</strong> {student.name}</p>
//       <p><strong>Email:</strong> {student.email}</p>
//       <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//       <p><strong>Course Name:</strong> {student.course}</p>
//       <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//       <p><strong>Registration Date:</strong> {student.Date}</p>
//       {/* <p><strong>Correspondence Address:</strong> {student.correspondenceAddress}</p> */}
//       <p><strong>Permanent Address:</strong> {student.permanentAddress}</p>
//       <p><strong>Qualification:</strong> {student.qualification}</p>
//       <p><strong>College:</strong> {student.college}</p>
//       <p><strong>Course Fee:</strong> {student.courseFee}</p>
//       <p><strong>GST:</strong> {student.gst}</p>
//       <p><strong>Total Fee:</strong> {student.totalFee}</p>

//     </div>
//   );
// }

// export default StudentDetails;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         console.log('Fetching details for:', regNo, id); // Debug line
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//     <Leftbar />
//     <div style={{ marginLeft: '500px' }}>
//       <h2 style={{ textAlign: 'center' }}>Student Details</h2>
//       <p><strong>Reg No:</strong> {student.RegNo}</p>
//       <p><strong>Name:</strong> {student.name}</p>
//       <p><strong>Email:</strong> {student.email}</p>
//       <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//       <p><strong>Course Name:</strong> {student.course}</p>
//       <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//       <p><strong>Registration Date:</strong> {student.Date}</p>
//       <p><strong>Permanent Address:</strong> {student.permanentAddress}</p>
//       <p><strong>Qualification:</strong> {student.qualification}</p>
//       <p><strong>College:</strong> {student.collegeName}</p>
//       <p><strong>Course Fee:</strong> {student.courseFee}</p>
//       <p><strong>GST:</strong> {student.gst}</p>
//       <p><strong>Total Fee:</strong> {student.totalFee}</p>
//     </div>
//     </>
//   );
// }

// export default StudentDetails;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';
// import Newecepit from '../../Pages/Newecepit';
// import Modal1 from './Modal1';
// import './StudentDetails.css'; // Import your CSS for styling

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [showModal, setShowModal] = useState(false); // State to manage modal visibility

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         console.log('Fetching details for:', regNo, id); // Debug line
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const handleOpenModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   const containerStyle = {
//     marginLeft: isMobile ? '0' : '500px',
//     marginTop: isMobile ? '0' : '-400px',
//     padding: isMobile ? '10px' : '20px',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   const headingStyle = {
//     textAlign: 'center',
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={containerStyle}>
//         <h2 style={headingStyle}>Student Details</h2>
//         <p><strong>Reg No:</strong> {student.RegNo}</p>
//         <p><strong>Name:</strong> {student.name}</p>
//         <p><strong>Email:</strong> {student.email}</p>
//         <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//         <p><strong>Course Name:</strong> {student.course}</p>
//         <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//         <p><strong>Registration Date:</strong> {student.Date}</p>
//         <p><strong>College:</strong> {student.collegeName}</p>
//         <p><strong>Course Fee:</strong> {student.courseFee}</p>

//         {/* Receipt icon to open modal */}
//         <button onClick={handleOpenModal} className="receipt-btn">Add Receipt</button>

//         {/* Render modal with Newecepit component */}
//         <Modal1 show={showModal} handleClose={handleCloseModal}>
//           <Newecepit />
//         </Modal1>
//       </div>
//     </>
//   );
// }

// export default StudentDetails;

// src/Components/StudentData/StudentDetails.js

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';
// import Newecepit from '../../Pages/Newecepit';
// import Modal1 from './Modal1';
// import './StudentDetails.css'; // Import your CSS for styling

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [showModal, setShowModal] = useState(false); // State to manage modal visibility

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         console.log('Fetching details for:', regNo, id); // Debug line
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const handleOpenModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   const containerStyle = {
//     marginLeft: isMobile ? '0' : '500px',
//     marginTop: isMobile ? '0' : '-400px',
//     padding: isMobile ? '10px' : '20px',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   const headingStyle = {
//     textAlign: 'center',
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={containerStyle}>
//         <h2 style={headingStyle}>Student Details</h2>
//         <p><strong>Reg No:</strong> {student.RegNo}</p>
//         <p><strong>Name:</strong> {student.name}</p>
//         <p><strong>Email:</strong> {student.email}</p>
//         <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//         <p><strong>Course Name:</strong> {student.course}</p>
//         <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//         <p><strong>Registration Date:</strong> {student.Date}</p>
//         <p><strong>College:</strong> {student.collegeName}</p>
//         <p><strong>Course Fee:</strong> {student.courseFee}</p>

//         {/* Receipt icon to open modal */}
//         <button onClick={handleOpenModal} className="receipt-btn">Add Receipt</button>

//         {/* Render modal with Newecepit component and pass student data as props */}
//         <Modal1 show={showModal} handleClose={handleCloseModal}>
//           <Newecepit
//             studentData={{
//               regNo: student.RegNo,
//               studentName: student.name,
//               course: student.course,
//               totalFees: student.courseFee
//             }}
//           />
//         </Modal1>
//       </div>
//     </>
//   );
// }

// export default StudentDetails;
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';
// import Newecepit from '../../Pages/Newecepit';
// import Modal1 from './Modal1';
// import './StudentDetails.css'; // Import your CSS for styling

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [receipts, setReceipts] = useState([]);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [showModal, setShowModal] = useState(false); // State to manage modal visibility

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   const fetchReceipts = async () => {
//     try {
//       const response = await axios.get(`/api/receipts/${encodeURIComponent(regNo)}/${id}`);
//       setReceipts(response.data);
//     } catch (error) {
//       console.error('Error fetching receipts:', error);
//     }
//   };

//   useEffect(() => {
//     fetchReceipts();
//   }, [regNo, id]);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const handleOpenModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   const totalReceived = receipts.reduce((total, receipt) => total + receipt.amountReceived, 0);
//   const currentDue = student.courseFee - totalReceived;

//   const containerStyle = {
//     marginLeft: isMobile ? '0' : '500px',
//     marginTop: isMobile ? '0' : '-400px',
//     padding: isMobile ? '10px' : '20px',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   const headingStyle = {
//     textAlign: 'center',
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={containerStyle}>
//         <h2 style={headingStyle}>Student Details</h2>
//         <p><strong>Reg No:</strong> {student.RegNo}</p>
//         <p><strong>Name:</strong> {student.name}</p>
//         <p><strong>Email:</strong> {student.email}</p>
//         <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//         <p><strong>Course Name:</strong> {student.course}</p>
//         <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//         <p><strong>Registration Date:</strong> {student.Date}</p>
//         <p><strong>College:</strong> {student.collegeName}</p>
//         <p><strong>Course Fee:</strong> {student.courseFee}</p>
//         <p><strong>Total Received:</strong> {totalReceived}</p>
//         <p><strong>Current Due:</strong> {currentDue}</p>

//         {/* Receipt icon to open modal */}
//         <button onClick={handleOpenModal} className="receipt-btn">Add Receipt</button>

//         {/* Render modal with Newecepit component and pass student data as props */}
//         <Modal1 show={showModal} handleClose={handleCloseModal}>
//           <Newecepit
//             studentData={{
//               regNo: student.RegNo,
//               studentName: student.name,
//               course: student.course,
//               totalFees: student.courseFee
//             }}
//             fetchReceipts={fetchReceipts} // Function to refresh receipts after adding a new one
//           />
//         </Modal1>
//       </div>
//     </>
//   );
// }
// ///////////////////////correct code
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   useEffect(() => {
//     const fetchStudentDetails = async () => {
//       try {
//         console.log('Fetching details for:', regNo, id);
//         const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//         setStudent(response.data);
//       } catch (error) {
//         console.error('Error fetching student details:', error);
//       }
//     };

//     fetchStudentDetails();
//   }, [regNo, id]);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   if (!student) {
//     return <div>Loading...</div>;
//   }

//   // Calculate current due
//   const currentDue = parseFloat(student.courseFee) - parseFloat(student.amountReceived);

//   // Inline CSS styles
//   const containerStyle = {
//     marginLeft: isMobile ? '0' : '500px',
//     marginTop: isMobile ? '0' : '-400px',
//     padding: isMobile ? '10px' : '20px',
//     maxWidth: '1200px',
//     margin: '0 auto',
//   };

//   const headingStyle = {
//     textAlign: 'center',
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={containerStyle}>
//         <h2 style={headingStyle}>Student Details</h2>
//         <p><strong>Reg No:</strong> {student.RegNo}</p>
//         <p><strong>Name:</strong> {student.name}</p>
//         <p><strong>Email:</strong> {student.email}</p>
//         <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//         <p><strong>Course Name:</strong> {student.course}</p>
//         <p><strong>Software Covered:</strong> {student.softwareCovered}</p>
//         <p><strong>Registration Date:</strong> {new Date(student.Date).toLocaleDateString()}</p>
//         <p><strong>College:</strong> {student.collegeName}</p>
//         <p><strong>Course Fee:</strong> ₹{student.courseFee}</p>
//         <p><strong>Amount Received:</strong> ₹{student.amountReceived}</p>
//         <p><strong>Current Due:</strong> ₹{currentDue.toFixed(2)}</p>
//       </div>
//     </>
//   );
// }

// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import Leftbar from '../Leftbar/Leftbar';
// import { jsPDF } from 'jspdf';

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [newPayment, setNewPayment] = useState({ amount: '', date: '' });
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

//   const printRef = useRef();

//   useEffect(() => {
//     fetchStudentDetails();
//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [regNo, id]);

//   const fetchStudentDetails = async () => {
//     try {
//       const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
//       setStudent(response.data);
//     } catch (error) {
//       console.error('Error fetching student details:', error);
//     }
//   };

//   const handleNewPayment = async (e) => {
//     e.preventDefault();

//     const amountReceived = parseFloat(student.amountReceived) || 0;
//     const courseFee = parseFloat(student.courseFee) || 0;
//     const newPaymentAmount = parseFloat(newPayment.amount) || 0;

//     if (newPaymentAmount + amountReceived > courseFee) {
//       alert('Payment amount cannot exceed the course fee.');
//       return;
//     }

//     try {
//       await axios.post(`/api/students/${id}/payments`, newPayment);
//       setNewPayment({ amount: '', date: '' });
//       fetchStudentDetails();
//     } catch (error) {
//       console.error('Error adding new payment:', error);
//     }
//   };

//   const handlePrint = () => {
//     const printWindow = window.open('', '_blank');
//     printWindow.document.write('<html><head><title>Print</title>');
//     printWindow.document.write('<style>');
//     printWindow.document.write(`
//       body { font-family: Arial, sans-serif; }
//       .detail-box { border: 1px solid #ddd; padding: 10px; margin-bottom: 10px; }
//       .header { background-color: #ffc800; padding: 20px; text-align: center; }
//       .content { padding: 20px; }
//       .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
//       .field { width: 30%; }
//     `);
//     printWindow.document.write('</style></head><body>');

//     // Add header with logo
//     printWindow.document.write('<div class="header">');
//     printWindow.document.write('<img src="/images/newlogo.jpg" alt="Logo" style="height: 50px; margin-right: 20px;">');
//     printWindow.document.write('<h2>SHAW TECH TRAINING & SERVICE</h2>');
//     printWindow.document.write('<h3>STUDENT RECEIPT FORM</h3>');
//     printWindow.document.write('</div>');

//     printWindow.document.write('<div class="content">');
//     printWindow.document.write('<h2>Student Details</h2>');

//     // Student details
//     const details = [
//       { label: "Name", value: student.name },
//       { label: "Reg No", value: student.RegNo },
//       { label: "Course", value: student.course },
//       { label: "Father Name", value: student.fatherName },
//       { label: "Receipt No", value: student.receiptNo },
//       { label: "Course Fee", value: `₹${student.courseFee}` },
//       { label: "Initial Payment", value: `₹${student.amountReceived} on ${new Date(student.Date).toLocaleDateString()}` }
//     ];

//     for (let i = 0; i < details.length; i += 3) {
//       printWindow.document.write('<div class="row">');
//       for (let j = i; j < i + 3 && j < details.length; j++) {
//         printWindow.document.write(`
//           <div class="field">
//             <strong>${details[j].label}:</strong> ${details[j].value}
//           </div>
//         `);
//       }
//       printWindow.document.write('</div>');
//     }

//     // Additional payments
//     if (student.additionalPayments && student.additionalPayments.length > 0) {
//       printWindow.document.write('<h3>Additional Payments</h3>');
//       for (let i = 0; i < student.additionalPayments.length; i += 3) {
//         printWindow.document.write('<div class="row">');
//         for (let j = i; j < i + 3 && j < student.additionalPayments.length; j++) {
//           const payment = student.additionalPayments[j];
//           printWindow.document.write(`
//             <div class="field">
//               <strong>Installment ${j + 1}:</strong> ₹${payment.amount} on ${new Date(payment.date).toLocaleDateString()}
//             </div>
//           `);
//         }
//         printWindow.document.write('</div>');
//       }
//     }

//     // Payment summary
//     printWindow.document.write('<h3>Payment Summary</h3>');
//     printWindow.document.write('<div class="row">');
//     printWindow.document.write(`
//       <div class="field"><strong>Total Paid:</strong> ₹${totalPaid.toFixed(2)}</div>
//       <div class="field"><strong>Current Due:</strong> ₹${currentDue.toFixed(2)}</div>
//     `);
//     printWindow.document.write('</div>');

//     printWindow.document.write('</div>'); // Close content div
//     printWindow.document.write('<button onclick="window.print()">Print</button>');
//     printWindow.document.write('</body></html>');
//     printWindow.document.close();
//   };
//   // const handleDownloadPDF = () => {
//   //   const pdf = new jsPDF('p', 'mm', 'a4');
//   //   const pageWidth = pdf.internal.pageSize.getWidth();
//   //   const pageHeight = pdf.internal.pageSize.getHeight();
//   //   const margin = 20;
//   //   const lineHeight = 10;

//   //   // Load the logo
//   //   const logoPath = '/images/newlogo.jpg';

//   //   // Yellow header
//   //   pdf.setFillColor(255, 204, 0);
//   //   pdf.rect(0, 0, pageWidth, 40, 'F');

//   //   // Border around the header
//   //   pdf.setDrawColor(0);
//   //   pdf.setLineWidth(0.5);
//   //   pdf.rect(margin, 5, pageWidth - 2 * margin, 30);

//   //   // Add logo to top-left corner
//   //   pdf.addImage(logoPath, 'JPG', margin + 2, 7, 26, 26);

//   //   // Add title
//   //   pdf.setFont("helvetica", "bold");
//   //   pdf.setFontSize(16);
//   //   pdf.setTextColor(0, 0, 0);
//   //   pdf.text('SHAW TECH TRAINING & SERVICE', pageWidth / 2, 20, { align: 'center' });

//   //   pdf.setFont("helvetica", "normal");
//   //   pdf.setFontSize(12);
//   //   pdf.text('STUDENT RECEIPT FORM', pageWidth / 2, 30, { align: 'center' });

//   //   let yPosition = 50;

//   //   const addHeading = (text) => {
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.setFontSize(14);
//   //     pdf.setTextColor(0, 102, 204);
//   //     pdf.text(text, margin, yPosition);
//   //     yPosition += lineHeight * 1.5;
//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.setFontSize(12);
//   //     pdf.setTextColor(0, 0, 0);
//   //   };

//   //   const addDetail = (label, value) => {
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text(label, margin, yPosition);
//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.text(`: ${value}`, margin + 40, yPosition);
//   //     yPosition += lineHeight;
//   //   };

//   //   addHeading("Student Details");
//   //   addDetail("Name", student.name);
//   //   addDetail("Reg No", student.RegNo);
//   //   addDetail("Course", student.course);
//   //   addDetail("Father Name", student.fatherName);
//   //   addDetail("Receipt No", student.receiptNo);
//   //   addDetail("Mobile No", student.mobileNo);
//   //   addDetail("Course Fee", `Rs.${student.courseFee}`);
//   //   addDetail("Initial Payment", `Rs.${student.amountReceived} on ${new Date(student.Date).toLocaleDateString()}`);

//   //   yPosition += lineHeight;

//   //   if (student.additionalPayments && student.additionalPayments.length > 0) {
//   //     addHeading("Additional Payments");
//   //     student.additionalPayments.forEach((payment, index) => {
//   //       addDetail(`Installment ${index + 1}`, `Rs.${payment.amount} on ${new Date(payment.date).toLocaleDateString()}`);
//   //     });
//   //     yPosition += lineHeight;
//   //   }

//   //   const totalPaid = parseFloat(student.amountReceived) +
//   //     (student.additionalPayments ? student.additionalPayments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0) : 0);
//   //   const currentDue = Math.max(0, parseFloat(student.courseFee) - totalPaid);

//   //   addHeading("Payment Summary");
//   //   addDetail("Total Paid", `Rs.${totalPaid.toFixed(2)}`);
//   //   addDetail("Current Due", `Rs.${currentDue.toFixed(2)}`);

//   //   // Add border around the content
//   //   pdf.setDrawColor(0);
//   //   pdf.setLineWidth(0.5);
//   //   pdf.rect(margin - 5, 45, pageWidth - 2 * (margin - 5), yPosition - 40);

//   //   pdf.save(`${student.RegNo}_fee_details.pdf`);
//   // };

//   const handleDownloadPDF = () => {
//     const pdf = new jsPDF('p', 'mm', 'a4');
//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const margin = 10;
//     const lineHeight = 8;
//     const logoPath = '/images/newlogo.jpg';

//     // Load the logo
//     pdf.addImage(logoPath, 'JPG', pageWidth - 50, 10, 40, 20);

//     // Add title
//     pdf.setFont("helvetica", "bold");
//     pdf.setFontSize(18);
//     pdf.text('RECEIPT', pageWidth / 2, 30, { align: 'center' });

//     // Add header
//     pdf.setFontSize(14);
//     pdf.setFont("times", "italic");
//     pdf.text('SHAW TECH TRAINING AND SERVICES', margin, 40);
//     pdf.setFont("times", "normal");
//     pdf.setFontSize(12);
//     pdf.text('W.NO 10, GOLAPBAG, IDBI BANK BUILDING BARIPADA, MAYURBHANJ', margin, 45);
//     pdf.text('Ph: +91-9692114217', margin, 50);
//     pdf.text('E-Mail: sttstrainings@gmail.com', margin, 55);

//     // Add details
//     let yPosition = 70;
//     const detailCol1 = margin;
//     const detailCol2 = pageWidth / 2 + margin;
//     const detailColWidth = 70;

//     const addDetail = (label, value, x = detailCol1, y = yPosition, width = detailColWidth) => {
//         pdf.setFont("helvetica", "bold");
//         pdf.text(label, x, y);
//         pdf.setFont("helvetica", "normal");
//         pdf.text(`: ${value}`, x + width, y);
//     };

//     addDetail("Student Name", student.name);
//     addDetail("Student Reg. No.", student.RegNo, detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Address", student.address);
//     addDetail("Receipts No.", student.receiptNo, detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Course Title", student.course);
//     addDetail("Date", new Date(student.Date).toLocaleDateString(), detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Received Rs.", `Rs.${student.amountReceived}`);
//     addDetail("Course Fee", `Rs.${student.courseFee}`, detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Payment By", student.paymentMode);
//     addDetail("Add Service Tax", `Rs.${student.serviceTax}`, detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Total Fee", `Rs.${student.totalFee}`);
//     addDetail("Current Due Amount", `Rs.${student.dueAmount}`, detailCol2, yPosition);

//     yPosition += lineHeight;
//     addDetail("Total Collected", `Rs.${student.totalCollected}`);

//     // Add terms and conditions
//     yPosition += lineHeight * 2;
//     pdf.setFont("helvetica", "bold");
//     pdf.text("Terms & Conditions:", margin, yPosition);
//     pdf.setFont("helvetica", "normal");
//     pdf.setFontSize(10);
//     const terms = [
//         "1. Student are requested to pay full course fee at the time of registration.",
//         "2. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule agreed at the time of registration.",
//         "3. All disputes are subject to the jurisdiction of the courts where the institute is located.",
//         "4. The fee once paid will not be refunded under any circumstances.",
//         "5. The fee paid cannot be transferred or adjusted for any other course under any circumstances.",
//         "6. The receipt must be produced for any future reference."
//     ];

//     terms.forEach(term => {
//         yPosition += lineHeight;
//         pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
//     });

//     // Signature section
//     yPosition += lineHeight * 2;
//     pdf.setFont("helvetica", "bold");
//     pdf.text("Student's Signature:", margin, yPosition);
//     pdf.text("Authorized Center", pageWidth - margin - 50, yPosition);

//     yPosition += lineHeight;
//     pdf.setFont("helvetica", "normal");
//     pdf.text("_________________________", margin, yPosition);
//     pdf.text("_________________________", pageWidth - margin - 50, yPosition);
//     yPosition += lineHeight;
//     pdf.text("(Seal & Signature)", pageWidth - margin - 50, yPosition);

//     pdf.save(`${student.RegNo}_fee_details.pdf`);
// };

//   if (!student) return <div>Loading...</div>;

//   const totalPaid = parseFloat(student.amountReceived) +
//     (student.additionalPayments ? student.additionalPayments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0) : 0);
//   const currentDue = Math.max(0, parseFloat(student.courseFee) - totalPaid);

//   const containerStyle = {
//     margin: isMobile ? '20px' : '20px 20px 20px 520px',
//     marginTop: isMobile ? '20px' : '-340px',
//     padding: '20px',
//     border: '1px solid #ddd',
//     borderRadius: '8px',
//     display: 'flex',
//     flexDirection: 'column',
//     maxWidth: isMobile ? 'none' : '600px',
//   };

//   const detailsContainerStyle = {
//     display: 'flex',
//     flexWrap: 'wrap',
//     gap: '20px'
//   };

//   const detailBoxStyle = {
//     flex: isMobile ? '1 1 100%' : '1 1 30%',
//     padding: '10px',
//     border: '1px solid #ddd',
//     borderRadius: '8px'
//   };

//   const formStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '10px'
//   };

//   const inputStyle = {
//     padding: '10px',
//     border: '1px solid #ddd',
//     borderRadius: '4px',
//     color: '#000',
//     width: '280px'
//   };

//   const buttonStyle = {
//     padding: '10px',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer'
//   };

//   const downloadButtonStyle = {
//     marginTop: '20px',
//     padding: '10px',
//     border: 'none',
//     borderRadius: '4px',
//     backgroundColor: '#fb237s',
//     color: '#fff',
//     cursor: 'pointer'
//   };

//   const printButtonStyle = {
//     marginTop: '10px',
//     padding: '10px',
//     border: 'none',
//     borderRadius: '4px',
//     backgroundColor: '#fb237s',
//     color: '#fff',
//     cursor: 'pointer'
//   };

//   return (
//     <>
//     <section className="page-header">
//         <div className="page-header__bg_1"
//         >

//         </div>
//         {/* <div
//           className="page-header__gradient"
//           style={{
//             backgroundImage:
//               "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)",
//           }}
//         ></div> */}
//         <div className="shape1 float-bob-x">
//           <img src=" ./images/sttsbanner.png" alt="banner shape" />
//         </div>
//         <div className="container">
//           <div className="page-header__inner text-center">
//             <h2>Admin</h2>

//             <ul className="thm-breadcrumb list-unstyled ml-0">
//               {/* Breadcrumb NavXT 7.2.0 */}
//               <li className="home">
//                 <span property="itemListElement" typeof="ListItem">
//                   <a
//                     property="item"
//                     typeof="WebPage"
//                     title="Go to Ontech."
//                     href="/"
//                     className="home"
//                   >
//                     <span property="name">Home</span>
//                   </a>
//                   <meta property="position" content="1" />
//                 </span>
//               </li>
//               <li className="post post-page current-item">
//                 <span property="itemListElement" typeof="ListItem">
//                   <span property="name" className="post post-page current-item">
//                  Admin
//                   </span>
//                   <meta property="url" content="" />
//                   <meta property="position" content="2" />
//                 </span>
//               </li>
//             </ul>
//           </div>
//         </div>

//       </section>
//       <Leftbar />
//       <div style={containerStyle}>
//         <div id="printable-content" ref={printRef}>
//           <h2 style={{ marginBottom: '20px' }}>Student Details</h2>
//           <div style={detailsContainerStyle}>
//             <div style={detailBoxStyle}>
//               <p><strong>Name:</strong> {student.name}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Reg No:</strong> {student.RegNo}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Course:</strong> {student.course}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>FatherName:</strong> {student.fatherName}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Receipt No:</strong> {student.receiptNo}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Mobile No:</strong> {student.mobileNo}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Course Fee:</strong> ₹{student.courseFee}</p>
//             </div>
//             <div style={detailBoxStyle}>
//               <p><strong>Initial Payment:</strong> ₹{student.amountReceived} on {new Date(student.Date).toLocaleDateString()}</p>
//             </div>
//           </div>

//           {student.additionalPayments && student.additionalPayments.length > 0 && (
//             <div style={{ marginTop: '20px' }}>
//               <h3>Additional Payments</h3>
//               {student.additionalPayments.map((payment, index) => (
//                 <p key={index}><strong>Installment Payment {index + 1}:</strong> ₹{payment.amount} on {new Date(payment.date).toLocaleDateString()}</p>
//               ))}
//             </div>
//           )}

//           <div style={{ marginTop: '20px' }}>
//             <p><strong>Total Paid:</strong> ₹{totalPaid.toFixed(2)}</p>
//             <p><strong>Current Due:</strong> ₹{currentDue.toFixed(2)}</p>
//           </div>
//         </div>

//         <h3 style={{ marginTop: '20px' }}>Add New Payment</h3>
//         <form onSubmit={handleNewPayment} style={formStyle}>
//           <input
//             type="number"
//             value={newPayment.amount}
//             onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
//             placeholder="Amount"
//             required
//             style={inputStyle}
//           />
//           <input
//             type="date"
//             value={newPayment.date}
//             onChange={(e) => setNewPayment({ ...newPayment, date: e.target.value })}
//             required
//             style={inputStyle}
//           />
//           <button type="submit" style={{ ...buttonStyle, backgroundColor: '#fb237', color: '#fff' }}>
//             Add Payment
//           </button>
//         </form>

//         <button onClick={handlePrint} style={printButtonStyle}>
//           Print
//         </button>

//         <button onClick={handleDownloadPDF} style={downloadButtonStyle}>
//           Download PDF
//         </button>
//       </div>
//       <style>
//         {`
//           @media print {
//             body * {
//               visibility: hidden;
//             }
//             #printable-content, #printable-content * {
//               visibility: visible;
//             }
//             #printable-content {
//               position: absolute;
//               left: 0;
//               top: 0;
//               width: 100%;
//               height: 100%;
//               background: #fff;
//             }
//           }
//         `}
//       </style>
//     </>
//   );
// }

// export default StudentDetails;
// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import { jsPDF } from "jspdf";
// import "./StudentDetails.css";

// function StudentDetails() {
//   const { regNo, id } = useParams();
//   const [student, setStudent] = useState(null);
//   const [newPayment, setNewPayment] = useState({ amount: "", date: "" });
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [isGradeDateSaved, setIsGradeDateSaved] = useState(false);

//   const printRef = useRef();

//   useEffect(() => {
//     fetchStudentDetails();
//     const handleResize = () => setIsMobile(window.innerWidth <= 768);
//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, [regNo, id]);

//   const fetchStudentDetails = async () => {
//     try {
//       const response = await axios.get(
//         `/api/students/${encodeURIComponent(regNo)}/${id}`
//       );
//       setStudent(response.data);
//     } catch (error) {
//       console.error("Error fetching student details:", error);
//     }
//   };

//   // const handleNewPayment = async (e) => {
//   //   e.preventDefault();

//   //   const amountReceived = parseFloat(student.amountReceived) || 0;
//   //   const courseFee = parseFloat(student.courseFee) || 0;
//   //   const newPaymentAmount = parseFloat(newPayment.amount) || 0;

//   //   if (newPaymentAmount + amountReceived > courseFee) {
//   //     alert("Payment amount cannot exceed the course fee.");
//   //     return;
//   //   }

//   //   try {
//   //     await axios.post(
//   //       `/api/students/${id}/payments`,
//   //       newPayment
//   //     );
//   //     setNewPayment({ amount: "", date: "" });
//   //     fetchStudentDetails();
//   //     setIsGradeDateSaved(true); // Mark as saved
//   //   } catch (error) {
//   //     console.error("Error adding new payment:", error);
//   //   }
//   // };

//   const handlePrint = () => {
//     const printWindow = window.open("", "_blank");
//     printWindow.document.write("<html><head><title>Print</title>");
//     printWindow.document.write("<style>");
//     printWindow.document.write(`
//       body { font-family: Arial, sans-serif; }
//       .detail-box { border: 1px solid #ddd; padding: 10px; margin-bottom: 10px; }
//       .header { background-color: #ffc800; padding: 20px; text-align: center; }
//       .content { padding: 20px; }
//       .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
//       .field { width: 30%; }
//     `);
//     printWindow.document.write("</style></head><body>");

//     // Add header with logo
//     printWindow.document.write('<div class="header">');
//     printWindow.document.write(
//       '<img src="/images/newlogo.jpg" alt="Logo" style="height: 50px; margin-right: 20px;">'
//     );
//     printWindow.document.write("<h2>SHAW TECH TRAINING & SERVICE</h2>");
//     printWindow.document.write("<h3>STUDENT RECEIPT FORM</h3>");
//     printWindow.document.write("</div>");

//     printWindow.document.write('<div class="content">');
//     printWindow.document.write("<h2>Student Details</h2>");

//     // Student details
//     const details = [
//       { label: "Name", value: student.name },
//       { label: "Reg No", value: student.RegNo },
//       { label: "Course", value: student.course },
//       { label: "Father Name", value: student.fatherName },
//       { label: "Receipt No", value: student.receiptId },
//       { label: "Course Fee", value: `₹${student.courseFee}` },
//       {
//         label: "Initial Payment",
//         value: `₹${student.amountReceived} on ${new Date(
//           student.Date
//         ).toLocaleDateString()}`,
//       },
//     ];

//     for (let i = 0; i < details.length; i += 3) {
//       printWindow.document.write('<div class="row">');
//       for (let j = i; j < i + 3 && j < details.length; j++) {
//         printWindow.document.write(`
//           <div class="field">
//             <strong>${details[j].label}:</strong> ${details[j].value}
//           </div>
//         `);
//       }
//       printWindow.document.write("</div>");
//     }

//     // Additional payments
//     if (student.additionalPayments && student.additionalPayments.length > 0) {
//       printWindow.document.write("<h3>Additional Payments</h3>");
//       for (let i = 0; i < student.additionalPayments.length; i += 3) {
//         printWindow.document.write('<div class="row">');
//         for (
//           let j = i;
//           j < i + 3 && j < student.additionalPayments.length;
//           j++
//         ) {
//           const payment = student.additionalPayments[j];
//           printWindow.document.write(`
//             <div class="field">
//               <strong>Installment ${j + 1}:</strong> ₹${
//             payment.amount
//           } on ${new Date(payment.date).toLocaleDateString()}
//             </div>
//           `);
//         }
//         printWindow.document.write("</div>");
//       }
//     }

//     // Payment summary
//     const totalPaid =
//       parseFloat(student.amountReceived) +
//       (student.additionalPayments
//         ? student.additionalPayments.reduce(
//             (sum, payment) => sum + parseFloat(payment.amount),
//             0
//           )
//         : 0);
//     const currentDue = Math.max(0, parseFloat(student.courseFee) - totalPaid);

//     printWindow.document.write("<h3>Payment Summary</h3>");
//     printWindow.document.write('<div class="row">');
//     printWindow.document.write(`
//       <div class="field"><strong>Total Paid:</strong> ₹${totalPaid.toFixed(
//         2
//       )}</div>
//       <div class="field"><strong>Current Due:</strong> ₹${currentDue.toFixed(
//         2
//       )}</div>
//     `);
//     printWindow.document.write("</div>");

//     printWindow.document.write("</div>"); // Close content div
//     printWindow.document.write(
//       '<button onclick="window.print()">Print</button>'
//     );
//     printWindow.document.write("</body></html>");
//     printWindow.document.close();
//   };

//   const handleNewPayment = async (e) => {
//     e.preventDefault();

//     const amountReceived = parseFloat(student.amountReceived) || 0;
//     const courseFee = parseFloat(student.courseFee) || 0;
//     const newPaymentAmount = parseFloat(newPayment.amount) || 0;

//     if (newPaymentAmount + amountReceived > courseFee) {
//       alert("Payment amount cannot exceed the course fee.");
//       return;
//     }

//     // Calculate the new currentDue
//     const newCurrentDue = student.currentDue - newPaymentAmount;

//     try {
//       // Post the new payment to the API
//       await axios.post(
//         `/api/students/${id}/payments`,
//         newPayment
//       );

//       // Update the student's record with the new currentDue and additionalPayments
//       await axios.put(`/api/students/${id}`, {
//         currentDue: newCurrentDue,
//         $push: {
//           additionalPayments: {
//             amount: newPaymentAmount,
//             date: newPayment.date,
//           },
//         },
//       });

//       setNewPayment({ amount: "", date: "" });
//       fetchStudentDetails();
//       setIsGradeDateSaved(true); // Mark as saved
//     } catch (error) {
//       console.error("Error adding new payment:", error);
//     }
//   };

//   const handleDownloadPDF = () => {
//     const pdf = new jsPDF("p", "mm", "a4");
//     const pageWidth = pdf.internal.pageSize.getWidth();
//     const margin = 10;
//     const lineHeight = 7;
//     const logoPath = "/images/newlogo.jpg"; // Ensure the logo path is correct and accessible

//     // Add logo
//     pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20); // Moved the logo up

//     // Add title
//     pdf.setFont("helvetica", "bold");
//     pdf.setFontSize(24);
//     pdf.setTextColor(0, 0, 0); // Black color
//     pdf.text("RECEIPT", margin, 20);

//     // Add horizontal line
//     pdf.setDrawColor(0);
//     pdf.line(margin, 25, pageWidth - margin, 25);

//     // Add header
//     pdf.setTextColor(0, 128, 0); // Green color
//     pdf.setFontSize(14);
//     pdf.setFont("helvetica", "bold");
//     pdf.text("SHAW TECH TRAINING AND", margin, 35);
//     pdf.text("SERVICES", margin, 40);
//     pdf.setTextColor(0); // Reset to black
//     pdf.setFont("helvetica", "normal");
//     pdf.setFontSize(10);
//     pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
//     pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
//     pdf.text("Ph: +91-9692114217 ", margin, 55);
//     pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);

//     // Add Student Reg. No.
//     pdf.setFont("helvetica", "bold");
//     pdf.text("Student Reg. No.", pageWidth - 80, 35);
//     pdf.rect(pageWidth - 50, 30, 40, 7);
//     pdf.setFont("helvetica", "normal");
//     pdf.text(student.RegNo, pageWidth - 48, 35);

//     // Main content area
//     const contentStartY = 65;
//     pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 100); // Increased height for the table

//     // Table headers
//     const col1 = margin + 5;
//     const col2 = pageWidth / 2 - 20; // Adjusted for better spacing
//     const col3 = pageWidth / 2 + 5;
//     const col4 = pageWidth - margin - 40;

//     pdf.setFont("helvetica", "bold");
//     pdf.text("Student Name", col1, contentStartY + 10);
//     pdf.text("Received Amount", col3, contentStartY + 10);
//     pdf.text("Date", col4, contentStartY + 10);

//     pdf.text("Course Title", col1, contentStartY + 20);
//     pdf.text("Course Fee", col3, contentStartY + 20);

//     pdf.text("Installments", col1, contentStartY + 30);
//     pdf.text("Amount", col3, contentStartY + 30);

//     // Table content
//     pdf.setFont("helvetica", "normal");
//     pdf.text(`: ${student.name}`, col2, contentStartY + 10);
//     pdf.text(`: ${student.amountReceived} Rs.`, col4, contentStartY + 10);
//     pdf.text(
//       `: ${new Date(student.Date).toLocaleDateString()}`,
//       col4,
//       contentStartY + 20
//     );
//     pdf.text(`: ${student.course}`, col2, contentStartY + 20);
//     pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 20);

//     // Add installment payments
//     const installments = student.additionalPayments || [];
//     let currentY = contentStartY + 40;

//     pdf.setFont("helvetica", "bold");
//     pdf.text("Installment 1", col1, currentY);
//     pdf.text(`Received Amount`, col2, currentY);
//     pdf.setFont("helvetica", "normal");
//     pdf.text(`: ${student.amountReceived} Rs.`, col2, currentY);

//     currentY += lineHeight;

//     installments.forEach((installment, index) => {
//       pdf.setFont("helvetica", "bold");
//       pdf.text(`Installment ${index + 2}`, col1, currentY);
//       pdf.setFont("helvetica", "normal");
//       pdf.text(`: ${installment.amount} Rs`, col2, currentY);
//       pdf.text(
//         `Date: ${new Date(installment.date).toLocaleDateString()}`,
//         col4,
//         currentY
//       );
//       currentY += lineHeight;
//     });

//     // Continue with remaining content
//     pdf.setFont("helvetica", "bold");
//     pdf.text("Total Fee", col1, currentY);
//     pdf.setFont("helvetica", "normal");
//     pdf.text(`: ${student.courseFee} Rs.`, col2, currentY);

//     currentY += lineHeight;

//     pdf.setFont("helvetica", "bold");
//     pdf.text("Total Collected", col1, currentY);
//     pdf.text("Current Due Amount", col3, currentY);

//     pdf.setFont("helvetica", "normal");
//     pdf.text(
//       `: ${
//         student.amountReceived +
//         installments.reduce((acc, item) => acc + item.amount, 0)
//       } Rs.`,
//       col2,
//       currentY
//     );
//     pdf.text(`: ${student.currentDue} Rs.`, col4, currentY);

//     // Terms and conditions
//     let yPosition = currentY + 20; // Adjusted position for terms & conditions
//     pdf.setFontSize(10);
//     pdf.setFont("helvetica", "bold");
//     pdf.text("Terms & Conditions:", margin, yPosition);

//     pdf.setFont("helvetica", "normal");
//     const terms = [
//       "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
//       "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
//       "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
//       "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
//     ];

//     terms.forEach((term, index) => {
//       yPosition += 13;
//       pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
//     });

//     // Signature box
//     yPosition += 20; // Adjusted spacing before the signature box
//     pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
//     pdf.setFont("helvetica", "bold");
//     pdf.text(
//       "I have read the terms & conditions printed overleaf,",
//       margin + 5,
//       yPosition + 10
//     );
//     pdf.text(
//       "Understood the same and hereby confirm having accepted the same for joining the course",
//       margin + 5,
//       yPosition + 15
//     );

//     pdf.text("Student's Signature", margin + 5, yPosition + 25);
//     pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
//     pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);

//     pdf.save("receipt.pdf");
//   };

//   //   const handleDownloadPDF = () => {
//   //     const pdf = new jsPDF('p', 'mm', 'a4');
//   //     const pageWidth = pdf.internal.pageSize.getWidth();
//   //     const margin = 10;
//   //     const lineHeight = 7;
//   //     const logoPath = '/images/newlogo.jpg'; // Ensure the logo path is correct and accessible

//   //     // Add logo
//   //     pdf.addImage(logoPath, 'JPG', pageWidth - 50, 5, 40, 20); // Moved the logo up

//   //     // Add title
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.setFontSize(24);
//   //     pdf.setTextColor(0, 0, 0); // Black color
//   //     pdf.text('RECEIPT', margin, 20);

//   //     // Add horizontal line
//   //     pdf.setDrawColor(0);
//   //     pdf.line(margin, 25, pageWidth - margin, 25);

//   //     // Add header
//   //     pdf.setTextColor(0, 128, 0); // Green color
//   //     pdf.setFontSize(14);
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('SHAW TECH TRAINING AND', margin, 35);
//   //     pdf.text('SERVICES', margin, 40);
//   //     pdf.setTextColor(0); // Reset to black
//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.setFontSize(10);
//   //     pdf.text('W.NO 10, GOLAPBAG, IDBI BANK', margin, 45);
//   //     pdf.text('BUILDING BARIPADA, MAYURBHANJ', margin, 50);
//   //     pdf.text('Ph: +91-9692114217 ', margin, 55);
//   //     pdf.text('E-Mail: sttstrainings@gmail.com', margin, 60);

//   //     // Add Student Reg. No.
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('Student Reg. No.', pageWidth - 80, 35);
//   //     pdf.rect(pageWidth - 50, 30, 40, 7);
//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.text(student.RegNo, pageWidth - 48, 35);

//   //     // Main content area
//   //     const contentStartY = 65;
//   //     pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 70); // Increased height for the table

//   //     // Table headers
//   //     const col1 = margin + 5;
//   //     const col2 = pageWidth / 2 - 20; // Adjusted for better spacing
//   //     const col3 = pageWidth / 2 + 5;
//   //     const col4 = pageWidth - margin - 40;

//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('Student Name', col1, contentStartY + 10);
//   //     pdf.text('Receipts No.', col3, contentStartY + 10);
//   //     pdf.text('Address', col1, contentStartY + 20);
//   //     pdf.text('Date', col3, contentStartY + 20);
//   //     pdf.text('Course Title', col1, contentStartY + 30);
//   //     pdf.text('Course Fee', col3, contentStartY + 30);
//   //     pdf.text('Received Rs.', col1, contentStartY + 40);
//   //     pdf.text('Add Service Tax', col3, contentStartY + 40);
//   //     pdf.text('Payment By', col1, contentStartY + 50);
//   //     pdf.text('Total Fee', col3, contentStartY + 50);

//   //     // Table content
//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.text(`: ${student.name}`, col2, contentStartY + 10);
//   //     pdf.text(`: ${student.receiptId}`, col4, contentStartY + 10);
//   //     pdf.text(`: ${student.Address}`, col2, contentStartY + 20);
//   //     pdf.text(`: ${new Date(student.Date).toLocaleDateString()}`, col4, contentStartY + 20);
//   //     pdf.text(`: ${student.course}`, col2, contentStartY + 30);
//   //     pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 30);

//   //     // Add installment payments
//   //     const installments = student.installments || [];
//   //     let currentY = contentStartY + 40;
//   //     installments.forEach((installment, index) => {
//   //         pdf.setFont("helvetica", "bold");
//   //         pdf.text(`Installment ${index + 1}`, col1, currentY);
//   //         pdf.setFont("helvetica", "normal");
//   //         pdf.text(`: ${installment.amount} Rs`, col2, currentY);
//   //         currentY += lineHeight;
//   //     });

//   //     // Continue with remaining content
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('Add Service Tax', col3, currentY);
//   //     pdf.text('Payment By', col1, currentY + 10);
//   //     pdf.text('Total Fee', col3, currentY + 10);

//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.text(`: ${student.serviceTax || 0} Rs.`, col4, currentY);
//   //     pdf.text(`: ${student.PaymentBy}`, col2, currentY + 10);
//   //     pdf.text(`: ${student.courseFee} Rs.`, col4, currentY + 10);

//   //     // Additional fields
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('Total Collected', col1, currentY + 20);
//   //     pdf.text('Current Due Amount', col3, currentY + 20);

//   //     pdf.setFont("helvetica", "normal");
//   //     pdf.text(`: ${student.amountReceived} Rs.`, col2, currentY + 20);
//   //     pdf.text(`: ${student.currentDue} Rs.`, col4, currentY + 20);

//   //     // Terms and conditions
//   //     let yPosition = currentY + 40; // Adjusted position for terms & conditions
//   //     pdf.setFontSize(10);
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text('Terms & Conditions:', margin, yPosition);

//   //     pdf.setFont("helvetica", "normal");
//   //     const terms = [
//   //         "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
//   //         "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
//   //         "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
//   //         "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control."
//   //     ];

//   //     terms.forEach((term, index) => {
//   //         yPosition += 13;
//   //         pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
//   //     });

//   //     // Signature box
//   //     yPosition += 20; // Adjusted spacing before the signature box
//   //     pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
//   //     pdf.setFont("helvetica", "bold");
//   //     pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, yPosition + 10);
//   //     pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, yPosition + 15);

//   //     pdf.text("Student's Signature", margin + 5, yPosition + 25);
//   //     pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
//   //     pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);

//   //     pdf.save('receipt.pdf');
//   // };

//   const handleGenerateCertificate = () => {
//     const doc = new jsPDF();
//     const pageWidth = doc.internal.pageSize.getWidth();
//     const margin = 20;

//     doc.setFontSize(20);
//     doc.setFont("Helvetica", "bold");
//     doc.text("Certificate of Completion", pageWidth / 2, margin, {
//       align: "center",
//     });

//     doc.setFontSize(16);
//     doc.setFont("Helvetica", "normal");
//     doc.text(`This is to certify that ${student.name}`, margin, margin + 30);
//     doc.text(`has completed the course ${student.course}`, margin, margin + 40);
//     doc.text(
//       `on ${new Date(student.Date).toLocaleDateString()}`,
//       margin,
//       margin + 50
//     );

//     doc.save("certificate.pdf");
//   };

//   //   return (
//   //     <div className="student-details-container">
//   //       <Leftbar />
//   //       <div className="student-details-content">
//   //         {student && (
//   //           <div>
//   //             <h1>Student Details</h1>
//   //             <div className="details-box">
//   //               <div className="detail">
//   //                 <strong>Name:</strong> {student.name}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Reg No:</strong> {student.RegNo}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Course:</strong> {student.course}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Father Name:</strong> {student.fatherName}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Receipt No:</strong> {student.receiptId}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Course Fee:</strong> ₹{student.courseFee}
//   //               </div>
//   //               <div className="detail">
//   //                 <strong>Initial Payment:</strong> ₹{student.amountReceived} on {new Date(student.Date).toLocaleDateString()}
//   //               </div>
//   //             </div>

//   //             {student.additionalPayments && student.additionalPayments.length > 0 && (
//   //               <div>
//   //                 <h2>Additional Payments</h2>
//   //                 <ul>
//   //                   {student.additionalPayments.map((payment, index) => (
//   //                     <li key={index}>
//   //                       Installment {index + 1}: ₹{payment.amount} on {new Date(payment.date).toLocaleDateString()}
//   //                     </li>
//   //                   ))}
//   //                 </ul>
//   //               </div>
//   //             )}

//   //             <form onSubmit={handleNewPayment}>
//   //               <h2>Add New Payment</h2>
//   //               <input
//   //                 type="number"
//   //                 placeholder="Amount"
//   //                 value={newPayment.amount}
//   //                 onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
//   //                 required
//   //               />
//   //               <input
//   //                 type="date"
//   //                 placeholder="Date"
//   //                 value={newPayment.date}
//   //                 onChange={(e) => setNewPayment({ ...newPayment, date: e.target.value })}
//   //                 required
//   //               />
//   //               <button type="submit">Add Payment</button>
//   //             </form>

//   //             <div className="button-container">
//   //               <button onClick={handlePrint}>Print</button>
//   //               <button onClick={handleDownloadPDF}>Download PDF</button>
//   //               <button
//   //                 onClick={handleGenerateCertificate}
//   //                 disabled={!isGradeDateSaved}
//   //               >
//   //                 Generate Certificate
//   //               </button>
//   //             </div>
//   //           </div>
//   //         )}
//   //       </div>
//   //     </div>
//   //   );
//   // }
//   // return (
//   //   <div className="student-details-container">
//   //     <Leftbar />
//   //     <div className="student-details-content">

//   //       {student && (
//   //         <div>
//   //           <h1>Student Details</h1>
//   //           <div className="details-box">
//   //             <div className="detail">
//   //               <strong>Name:</strong> {student.name}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Reg No:</strong> {student.RegNo}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Course:</strong> {student.course}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Father Name:</strong> {student.fatherName}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Receipt No:</strong> {student.receiptId}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Course Fee:</strong> ₹{student.courseFee}
//   //             </div>
//   //             <div className="detail">
//   //               <strong>Initial Payment:</strong> ₹{student.amountReceived} on {new Date(student.Date).toLocaleDateString()}
//   //             </div>
//   //           </div>

//   //           {student.additionalPayments && student.additionalPayments.length > 0 && (
//   //             <div>
//   //               <h2>Additional Payments</h2>
//   //               <ul>
//   //                 {student.additionalPayments.map((payment, index) => (
//   //                   <li key={index}>
//   //                     Installment {index + 1}: ₹{payment.amount} on {new Date(payment.date).toLocaleDateString()}
//   //                   </li>
//   //                 ))}
//   //               </ul>
//   //             </div>
//   //           )}

//   //           <div className="right-side-container">
//   //             <form onSubmit={handleNewPayment}>
//   //               <h2>Add New Payment</h2>
//   //               <input
//   //                 type="number"
//   //                 placeholder="Amount"
//   //                 value={newPayment.amount}
//   //                 onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
//   //                 required
//   //               />
//   //               <input
//   //                 type="date"
//   //                 placeholder="Date"
//   //                 value={newPayment.date}
//   //                 onChange={(e) => setNewPayment({ ...newPayment, date: e.target.value })}
//   //                 required
//   //               />
//   //               <button type="submit">Add Payment</button>
//   //             </form>

//   //             <div className="button-container">
//   //               <button onClick={handlePrint}>Print</button>
//   //               <button onClick={handleDownloadPDF}>Download PDF</button>
//   //               <button onClick={handleGenerateCertificate}>
//   //                 Generate Certificate
//   //               </button>
//   //             </div>
//   //           </div>
//   //         </div>
//   //       )}
//   //     </div>
//   //   </div>
//   // );
//   // }
//   return (
//     <div className="student-details-container">
//       <Leftbar />
//       <div className="student-details-content">
//         {student && (
//           <div>
//             <h1>Student Details</h1>
//             <div className="details-box">
//               <div className="detail">
//                 <strong>Name:</strong> {student.name}
//               </div>
//               <div className="detail">
//                 <strong>Reg No:</strong> {student.RegNo}
//               </div>
//               <div className="detail">
//                 <strong>Course:</strong> {student.course}
//               </div>
//               <div className="detail">
//                 <strong>Father Name:</strong> {student.fatherName}
//               </div>
//               <div className="detail">
//                 <strong>Receipt No:</strong> {student.receiptId}
//               </div>
//               <div className="detail">
//                 <strong>Course Fee:</strong> ₹{student.courseFee}
//               </div>
//               <div className="detail">
//                 <strong>Initial Payment:</strong> ₹{student.amountReceived} on{" "}
//                 {new Date(student.Date).toLocaleDateString()}
//               </div>
//             </div>

//             {student.additionalPayments &&
//               student.additionalPayments.length > 0 && (
//                 <div>
//                   <h2>Additional Payments</h2>
//                   <ul>
//                     {student.additionalPayments.map((payment, index) => (
//                       <li key={index}>
//                         Installment {index + 1}: ₹{payment.amount} on{" "}
//                         {new Date(payment.date).toLocaleDateString()}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}

//             <div className="right-side-container">
//               <form onSubmit={handleNewPayment}>
//                 <h2>Add New Payment</h2>
//                 <input
//                   type="number"
//                   placeholder="Amount"
//                   value={newPayment.amount}
//                   onChange={(e) =>
//                     setNewPayment({ ...newPayment, amount: e.target.value })
//                   }
//                   required
//                   style={{ color: "black", border: "black 1px solid" }}
//                 />
//                 <input
//                   type="date"
//                   placeholder="Date"
//                   value={newPayment.date}
//                   onChange={(e) =>
//                     setNewPayment({ ...newPayment, date: e.target.value })
//                   }
//                   required
//                   style={{ color: "black", border: "black 1px solid" }}
//                 />
//                 <button type="submit">Add Payment</button>
//               </form>

//               <div className="button-container">
//                 <button onClick={handlePrint}>Print</button>
//                 <button onClick={handleDownloadPDF}>Download PDF</button>
//                 {/* <button onClick={handleGenerateCertificate}>
//                 Generate Certificate
//               </button> */}
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default StudentDetails;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Leftbar from "../Leftbar/Leftbar";
import { jsPDF } from "jspdf";
import "./StudentDetails.css";

function StudentDetails() {
  const { regNo, id } = useParams();
  const [student, setStudent] = useState(null);
  const [newPayment, setNewPayment] = useState({ amount: "", date: "" });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isGradeDateSaved, setIsGradeDateSaved] = useState(false);

  const printRef = useRef();

  useEffect(() => {
    fetchStudentDetails();
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [regNo, id]);

  // const fetchStudentDetails = async () => {
  //   try {
  //     const response = await axios.get(
  //       `/api/students/${encodeURIComponent(regNo)}/${id}`
  //     );
  //     setStudent(response.data);
  //   } catch (error) {
  //     console.error("Error fetching student details:", error);
  //   }
  // };

  // const fetchStudentDetails = async () => {
  //   try {
  //     console.log(`Fetching details for regNo: ${regNo}, id: ${id}`);
  //     const response = await axios.get(`/api/students/${encodeURIComponent(regNo)}/${id}`);
  //     console.log('API response:', response.data);
  //     setStudent(response.data);
  //   } catch (error) {
  //     console.error("Error fetching student details:", error.response || error);
  //   }
  // };

  // const fetchStudentDetails = async () => {
  //   try {
  //     console.log(`Fetching details for regNo: ${regNo}, id: ${id}`);
  //     // Remove encodeURIComponent from the URL construction
  //     const response = await axios.get(`/api/students/${regNo}/${id}`);
  //     console.log('API response:', response.data);
  //     setStudent(response.data);
  //   } catch (error) {
  //     console.error("Error fetching student details:", error);
  //     if (error.response) {
  //       console.error("Response data:", error.response.data);
  //       console.error("Response status:", error.response.status);
  //     }
  //   }
  // };
  const fetchStudentDetails = async () => {
    try {
      console.log(`Fetching details for regNo: ${regNo}`);
  
      const encodedRegNo = encodeURIComponent(regNo); // Encode the regNo
      const response = await axios.get(`/api/students/${encodedRegNo}`);
      
      console.log('API response:', response.data);
      setStudent(response.data);
    } catch (error) {
      console.error("Error fetching student details:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    }
  };
  

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      body { font-family: Arial, sans-serif; }
      .detail-box { border: 1px solid #ddd; padding: 10px; margin-bottom: 10px; }
      .header { background-color: #ffc800; padding: 20px; text-align: center; }
      .content { padding: 20px; }
      .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
      .field { width: 30%; }
      button { 
        background-color: #007bff; 
        color: white; 
        border: none; 
        padding: 10px 20px; 
        cursor: pointer; 
        border-radius: 5px; 
        font-size: 16px; 
      }
      button:hover { background-color: #FFB237; }
    `);
    printWindow.document.write("</style></head><body>");

    // Add header with logo
    printWindow.document.write('<div class="header">');
    printWindow.document.write(
      '<img src="/images/newlogo.jpg" alt="Logo" style="height: 50px; margin-right: 20px;">'
    );
    printWindow.document.write("<h2>SHAW TECH TRAINING & SERVICE</h2>");
    printWindow.document.write("<h3>STUDENT RECEIPT FORM</h3>");
    printWindow.document.write("</div>");

    printWindow.document.write('<div class="content">');
    printWindow.document.write("<h2>Student Details</h2>");

    // Student details
    const details = [
      { label: "Name", value: student.name },
      { label: "Reg No", value: student.RegNo },
      { label: "Course", value: student.course },
      { label: "Father Name", value: student.fatherName },
      { label: "Receipt No", value: student.receiptId },
      { label: "Course Fee", value: `₹${student.courseFee}` },
      {
        label: "Initial Payment",
        value: `₹${student.amountReceived} on ${new Date(
          student.Date
        ).toLocaleDateString()}`,
      },
    ];

    for (let i = 0; i < details.length; i += 3) {
      printWindow.document.write('<div class="row">');
      for (let j = i; j < i + 3 && j < details.length; j++) {
        printWindow.document.write(`
          <div class="field">
            <strong>${details[j].label}:</strong> ${details[j].value}
          </div>
        `);
      }
      printWindow.document.write("</div>");
    }

    // Additional payments
    if (student.additionalPayments && student.additionalPayments.length > 0) {
      printWindow.document.write("<h3>Additional Payments</h3>");
      for (let i = 0; i < student.additionalPayments.length; i += 3) {
        printWindow.document.write('<div class="row">');
        for (
          let j = i;
          j < i + 3 && j < student.additionalPayments.length;
          j++
        ) {
          const payment = student.additionalPayments[j];
          printWindow.document.write(`
            <div class="field">
              <strong>Installment ${j + 1}:</strong> ₹${
            payment.amount
          } on ${new Date(payment.date).toLocaleDateString()}
            </div>
          `);
        }
        printWindow.document.write("</div>");
      }
    }

    // Payment summary
    // const totalPaid =
    //   parseFloat(student.amountReceived) +
    //   (student.additionalPayments
    //     ? student.additionalPayments.reduce(
    //         (sum, payment) => sum + parseFloat(payment.amount),
    //         0
    //       )
    //     : 0);
    // const currentDue = Math.max(0, parseFloat(student.courseFee) - totalPaid);

    printWindow.document.write("<h3>Payment Summary</h3>");
    printWindow.document.write('<div class="row">');
    printWindow.document.write(`
      <div class="field"><strong>Total Paid:</strong> ₹${totalPaid.toFixed(
        2
      )}</div>
      <div class="field"><strong>Current Due:</strong> ₹${currentDue.toFixed(
        2
      )}</div>
    `);
    printWindow.document.write("</div>");

    printWindow.document.write("</div>"); // Close content div
    printWindow.document.write(
      '<button onclick="window.print()">Print</button>'
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
  };

  const handleDownloadPDF = () => {
    const pdf = new jsPDF("p", "mm", "a4");
    const pageWidth = pdf.internal.pageSize.getWidth();
    const margin = 10;
    const lineHeight = 7;
    const logoPath = "/images/newlogo.jpg";

    // Add logo
    pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);

    // Add title
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(24);
    pdf.setTextColor(0, 0, 0);
    pdf.text("RECEIPT", margin, 20);

    // Add horizontal line
    pdf.setDrawColor(0);
    pdf.line(margin, 25, pageWidth - margin, 25);

    // Add header
    pdf.setTextColor(0, 128, 0);
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.text("SHAW TECH TRAINING AND", margin, 35);
    pdf.text("SERVICES", margin, 40);
    pdf.setTextColor(0);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(10);
    pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
    pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
    pdf.text("Ph: +91-9692114217 ", margin, 55);
    pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);

    // Add Student Reg. No.
    pdf.setFont("helvetica", "bold");
    pdf.text("Student Reg. No.", pageWidth - 80, 35);
    pdf.rect(pageWidth - 50, 30, 40, 7);
    pdf.setFont("helvetica", "normal");
    pdf.text(student.RegNo, pageWidth - 48, 35);

    // Main content area
    const contentStartY = 65;
    pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 90);

    // Table headers
    const col1 = margin + 5;
    const col2 = pageWidth / 2 - 30; // Reduced gap
    const col3 = pageWidth / 2 + 5;
    const col4 = pageWidth - margin - 50; // Reduced gap
    const colData = col2 + 25; // Adjusted position for fetched data

    pdf.setFont("helvetica", "bold");
    pdf.text("Student Name", col1, contentStartY + 10);
    pdf.text("Receipts No.", col3, contentStartY + 10);
    pdf.text("Address", col1, contentStartY + 20);
    pdf.text("Date", col3, contentStartY + 20);
    pdf.text("Course Title", col1, contentStartY + 30);
    pdf.text("Course Fee", col3, contentStartY + 30);

    // Table content
    pdf.setFont("helvetica", "normal");
    pdf.text(`: ${student.name}`, col2, contentStartY + 10);
    pdf.text(`: ${student.receiptId}`, col4, contentStartY + 10); // Adjust position
    pdf.text(`: ${student.Address}`, col2, contentStartY + 20);
    pdf.text(
      `: ${new Date(student.Date).toLocaleDateString()}`,
      col4,
      contentStartY + 20
    );
    pdf.text(`: ${student.course}`, col2, contentStartY + 30);
    pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 30);

    // Received Amount and Additional Payments
    pdf.setFont("helvetica", "bold");
    pdf.text("Received Amount ", col1, contentStartY + 40);
    pdf.text("Date", col3, contentStartY + 40);
    pdf.setFont("helvetica", "normal");
    pdf.text(`: ${student.amountReceived} Rs.`, col2, contentStartY + 40);
    pdf.text(
      `: ${new Date(student.Date).toLocaleDateString()}`,
      col4,
      contentStartY + 40
    );

    const installments = student.additionalPayments || [];
    let currentY = contentStartY + 50;

    installments.forEach((installment, index) => {
      pdf.setFont("helvetica", "bold");
      pdf.text(`Received Amount `, col1, currentY); // Starting from Installment 2
      pdf.text("Date", col3, currentY);
      pdf.setFont("helvetica", "normal");
      pdf.text(`: ${installment.amount} Rs.`, col2, currentY);
      pdf.text(
        `: ${new Date(installment.date).toLocaleDateString()}`,
        col4,
        currentY
      );
      currentY += lineHeight;
    });

    // Remaining Content
    pdf.setFont("helvetica", "bold");
    pdf.text("Add Service Tax", col3, currentY);
    pdf.text("Payment By", col1, currentY + 10);
    pdf.text("Total Fee", col3, currentY + 10);

    pdf.setFont("helvetica", "normal");
    pdf.text(`: ${student.serviceTax || 0} Rs.`, col4, currentY);
    pdf.text(`: ${student.PaymentBy}`, col2, currentY + 10);
    pdf.text(`: ${student.courseFee} Rs.`, col4, currentY + 10);

    // Additional fields
    pdf.setFont("helvetica", "bold");
    pdf.text("Total Collected", col1, currentY + 20);
    pdf.text("Current Due Amount", col3, currentY + 20);

    pdf.setFont("helvetica", "normal");
    pdf.text(
      `: ${
        student.amountReceived +
        installments.reduce((sum, inst) => sum + inst.amount, 0)
      } Rs.`,
      col2,
      currentY + 20
    );
    pdf.text(`: ${student.currentDue} Rs.`, col4, currentY + 20);

    // Terms and conditions
    let yPosition = currentY + 60;
    pdf.setFontSize(10);
    pdf.setFont("helvetica", "bold");
    pdf.text("Terms & Conditions:", margin, yPosition);

    pdf.setFont("helvetica", "normal");
    const terms = [
      "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
      "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
      "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
      "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
    ];

    terms.forEach((term, index) => {
      yPosition += 13;
      pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
    });

    // Signature box
    yPosition += 20;
    pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
    pdf.setFont("helvetica", "bold");
    pdf.text(
      "I have read the terms & conditions printed overleaf,",
      margin + 5,
      yPosition + 10
    );
    pdf.text(
      "Understood the same and hereby confirm having accepted the same for joining the course",
      margin + 5,
      yPosition + 15
    );

    pdf.text("Student's Signature", margin + 5, yPosition + 25);
    pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
    pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);

    pdf.save("receipt.pdf");
  };


  // const handleAddPayment = async () => {
  //   try {
  //     console.log("Adding payment:", newPayment); // Debugging line
  //     await axios.post(`/api/students/${}/payments`, newPayment);
  //     fetchStudentDetails();
  //     setNewPayment({ amount: "", date: "" });
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //   }
  // };


  // const handleAddPayment = async () => {
  //   try {
  //     console.log("Adding payment:", newPayment); // Debugging line
  //     await axios.post(`/api/students/${student.RegNo}/payments`, newPayment);
  //     fetchStudentDetails();
  //     setNewPayment({ amount: "", date: "" });
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //   }
  // };

  const handleAddPayment = async () => {
    try {
      const encodedRegNo = encodeURIComponent(regNo); // Encode the regNo
      console.log("Adding payment:", newPayment); // Debugging line
      await axios.post(`/api/students/${encodedRegNo}/payments`, newPayment);
      fetchStudentDetails();
      setNewPayment({ amount: "", date: "" });
    } catch (error) {
      console.error("Error adding payment:", error.response ? error.response.data : error.message);
    }
  };
  
  // Calculate the current due amount
  const totalPaid =
    parseFloat(student?.amountReceived || 0) +
    (student?.additionalPayments || []).reduce(
      (sum, payment) => sum + parseFloat(payment.amount),
      0
    );
  const currentDue = Math.max(
    0,
    parseFloat(student?.courseFee || 0) - totalPaid
  );

  // Disable the "Add Payment" button if the current due amount is zero
  const isButtonDisabled = currentDue === 0;

  if (!student) return <div>Loading...</div>;

  
  return (
    <div className="student-details-container">
      <Leftbar />
      <div className="student-details-content">
        {student && (
          <div>
            <h1>Student Details</h1>
            <div className="details-box">
              <div className="detail">
                <strong>Name:</strong> {student.name}
              </div>
              <div className="detail">
                <strong>Reg No:</strong> {student.RegNo}
              </div>
              <div className="detail">
                <strong>Course:</strong> {student.course}
              </div>
              <div className="detail">
                <strong>Father Name:</strong> {student.fatherName}
              </div>
              <div className="detail">
                <strong>Receipt No:</strong> {student.receiptId}
              </div>
              <div className="detail">
                <strong>Course Fee:</strong> ₹{student.courseFee}
              </div>
              {/* <div className="detail">
                <strong>Current Due:</strong> ₹{student.currentDue} 
              </div> */}
              <div className="detail">
                <strong>Initial Payment:</strong> ₹{student.amountReceived} on{" "}
                {new Date(student.Date).toLocaleDateString()}
              </div>
              
            </div>

            {/* {student.additionalPayments &&
              student.additionalPayments.length > 0 && (
                <div>
                  <h2>Additional Payments</h2>
                  <ul>
                    {student.additionalPayments.map((payment, index) => (
                      <li key={index}>
                        Installment {index + 1}: ₹{payment.amount} on{" "}
                        {new Date(payment.date).toLocaleDateString()}
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}

            <div className="right-side-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleAddPayment();
                }}
              >
                <h2>Add New Payment</h2>
                <input
                  type="number"
                  placeholder="Amount"
                  value={newPayment.amount}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, amount: e.target.value })
                  }
                  required
                  style={{ color: "black", border: "black 1px solid" }}
                />
                <input
                  type="date"
                  placeholder="Date"
                  value={newPayment.date}
                  onChange={(e) =>
                    setNewPayment({ ...newPayment, date: e.target.value })
                  }
                  required
                  style={{ color: "black", border: "black 1px solid" }}
                />
                {/* <button type="submit" disabled={isButtonDisabled}>
                  Add Payment
                </button> */}
                <button
                  type="submit"
                  disabled={currentDue === 0}
                  style={{
                    backgroundColor: currentDue === 0 ? "#d3d3d3" : "#FFB237", // Disabled color and enabled color
                    color: "#fff", // Text color
                    border: "none", // Remove default border
                    padding: "10px 20px", // Add some padding
                    borderRadius: "5px", // Rounded corners
                    cursor: currentDue === 0 ? "not-allowed" : "pointer", // Change cursor when disabled
                    opacity: currentDue === 0 ? "0.6" : "1", // Reduce opacity when disabled
                    fontSize: "16px", // Font size
                  }}
                >
                  Add Payment
                </button>
              </form>

              <div className="button-container">
                <button onClick={handlePrint}>Print</button>
                <button onClick={handleDownloadPDF}>Download PDF</button>
                {/* <button onClick={handleGenerateCertificate}>
              Generate Certificate
            </button> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentDetails;
