// // import React, { useState } from "react";
// // import "./E1.css";
// // import { MdCancel } from "react-icons/md";

// // const E1 = () => {
// //   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);

// //   const handleQuickOptionClick = () => {
// //     setDemoOptionOpen(!isDemoOptionOpen);
// //   };

// //   const [status, setStatus] = useState("Submit");

// //   const [info, setInfo] = useState({
// //     name: "",
// //     mobilenumber: "",
// //     email: "",
// //     selectedOption: "",
// //     subOption: "",
// //   });

// //   const handleChange = (event) => {
// //     setInfo({
// //       ...info,
// //       [event.target.name]: event.target.value,
// //     });
// //   };
// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     console.log(info);
// //     setStatus("Sending...");

// //     let response = await fetch(`/api/inquiry`, {
// //       method: "POST",
// //       headers: {
// //         "Access-Control-Allow-Origin": "*",
// //         Accept: "application/json",
// //         //'Content-Type': 'application/json',
// //         "Content-Type": "application/json;charset=utf-8",
// //       },
// //       body: JSON.stringify(info),
// //       //body: JSON.stringify(details),
// //     });
// //     //console.log(details);
// //     console.log(info);
// //     setStatus("Submit");
// //     setInfo({
// //       name: "",
// //       mobilenumber: "",
// //       email: "",
// //       selectedOption: "",
// //       subOption: "",
// //     });
// //     let result = await response.json();
// //     //alert(result.status);
// //   };
// //   const getOptions = () => {
// //     switch (info.selectedOption) {
// //       case "Mechanical":
// //         return [
// //           "AUTOCAD",
// //           "CATIA",
// //           "NXCAD",
// //           "CREO",
// //           "AUTO INVENTOR",
// //           "ANSYS",
// //           "REVIT MEP",
// //           "CNC",
// //           "ART CAM",
// //           "NX CAM",
// //         ];
// //       case "Civil":
// //         return [
// //           "AUTOCAD",
// //           "REVIT ARCH",
// //           "SKETCH UP",
// //           "REVIT STRU",
// //           "V-RAYS",
// //           "LUMION",
// //           "STAAD PRO",
// //           "ETAB",
// //           "CIVIL 3D",
// //           "PRIMAVERA",
// //         ];
// //       case "Electrical":
// //         return [
// //           "AUTOCAD",
// //           "AUTO ELECTRICal",
// //           "EPLAN",
// //           "MATLAB",
// //           "PLC",
// //           "SCADA",
// //         ];
// //       case "Computer":
// //         return [
// //           " C/C++",
// //           "MS-OFFICE",
// //           "TALLY",
// //           "PHOTOSHOP",
// //           "DCA",
// //           "PGDCAA",
// //           "ADVANCE PGDCAA",
// //         ];

// //       default:
// //         return [];
// //     }
// //   };

// //   const courses = [
// //     "DCA",
// //     "Advance PGDCA",
// //     "PGDCA",
// //     "MECHANICAL",
// //     "ELECTRICAL",
// //     "CIVIL & ARCH",
// //     "ANY IT",
// //     "TALLY",
// //     "PHOTOSHOP",
// //     "MS OFFICE",
// //     "ADVANCE EXCEL",
// //     "BASIC COMPUTER",
// //   ];
// //   const nameRegex = /^[a-zA-Z\s]+$/;
// //   const phoneRegex = /^\d{10}$/;
// //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// //   const handleNameChange = (e) => {
// //     const inputValue = e.target.value;
// //     if (nameRegex.test(inputValue) || inputValue === "") {
// //       info.name(inputValue);
// //     }
// //   };

// //   const handlePhoneChange = (e) => {
// //     const inputValue = e.target.value;
// //     if (phoneRegex.test(inputValue) || inputValue === "") {
// //       info.phone(inputValue);
// //     }
// //   };

// //   const handleEmailChange = (e) => {
// //     const inputValue = e.target.value;
// //     if (emailRegex.test(inputValue) || inputValue === "") {
// //       info.email(inputValue);
// //     }
// //   };

// //   return (
// //     <>
// //       <div
// //         className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}
// //       >
// //         <div className="aeroland__toolbar1">
// //           <div
// //             className="inner1"
// //             style={{ background: "orange", textAlign: "right" }}
// //           >
// //             <button
// //               className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
// //               style={{
// //                 color: "White",
// //                 transform: `rotate(180deg) ${
// //                   isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
// //                 }`,
// //                 fontSize: "18px", // Adjust the font size as needed
// //                 border: "none",
// //                 // Remove the border
// //                 background: "transparent",
// //                 fontWeight: "bold",
// //                 outline: "none" /* Remove default focus outline */,
// //                 border: "none",
// //                 // Optional: Adjust the negative margin to fine-tune the vertical position
// //                 marginLeft: "-38px",
// //                 lineHeight: "8.15",
// //                 color: "black",
// //                 textAlign: "center",
// //                 marginTop: "-20px",
// //               }}
// //               onClick={handleQuickOptionClick}
// //             >
// //               ReachUs
// //             </button>
// //           </div>
// //         </div>

// //         <div className="demo-option-wrapper">
// //           <button
// //             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
// //             onClick={handleQuickOptionClick}
// //             style={{ backgroundColor: "#FFB237" }}
// //           >
// //             <MdCancel style={{ fontSize: "40px" }} />
// //           </button>

// //           <div className="business-solution-form-wrap mr-auto ml-auto">
// //             <div className="contact-title text-center section-space--mb_40">
// //               <b>
// //                 <h3 className="mb-10">Contact Us</h3>
// //               </b>
// //             </div>

// //             <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
// //               <input type="hidden" name="valid" value="false" />
// //               <div class="contact-form__two">
// //                 <br />

// //                 <div className="contact-inner" style={{ width: "260px" }}>
// //                   <input
// //                     name="name"
// //                     type="text"
// //                     placeholder="Name *"
// //                     required
// //                     id="name"
// //                     pattern="[a-zA-Z\s]+"
// //                     title="Please enter only alphabets"
// //                     value={info.name}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="contact-inner" style={{ width: "260px" }}>
// //                   <input
// //                     name="email"
// //                     type="text"
// //                     placeholder="Email id *"
// //                     required
// //                     id="email"
// //                     pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
// //                     title="Please enter a valid email address"
// //                     value={info.email}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="contact-inner" style={{ width: "260px" }}>
// //                   <input
// //                     name="mobilenumber"
// //                     type="text"
// //                     placeholder="Contact No *"
// //                     required
// //                     id="mobilenumber"
// //                     pattern="\d{10}"
// //                     title="Please enter a 10-digit phone number"
// //                     value={info.mobilenumber}
// //                     onChange={handleChange}
// //                   />
// //                 </div>
// //                 <div className="form-group">
// //                   <label htmlFor="course">Course:</label>
// //                   <select
// //                     id="course"
// //                     value={info.course}
// //                     onChange={handleChange}
// //                     required
// //                     className="form-select1"
// //                   >
// //                     <option value="">Select a Course</option>
// //                     {courses.map((course, index) => (
// //                       <option key={index} value={course}>
// //                         {course}
// //                       </option>
// //                     ))}
// //                   </select>
// //                 </div>
// //                 <div className="mb-3">
// //                   <select
// //                     className="form-control p-3 border-0"
// //                     value={info.selectedOption || ""}
// //                     name="selectedOption"
// //                     required
// //                     onChange={handleChange}
// //                   >
// //                     <option value="">Select Category</option>
// //                     <option value="Mechanical">Mechanical</option>
// //                     <option value="Civil">Civil</option>
// //                     <option value="Electrical">Electrical</option>
// //                     <option value="Computer">Computer</option>
// //                   </select>
// //                 </div>
// //                 <div className="mb-3">
// //                   {info.selectedOption && (
// //                     <select
// //                       className="form-control p-3 border-0"
// //                       required
// //                       name="subOption"
// //                       value={info.subOption}
// //                       onChange={handleChange}
// //                     >
// //                       <option value="">Select {info.selectedOption}</option>
// //                       {getOptions().map((subOption) => (
// //                         <option key={subOption} value={subOption}>
// //                           {subOption}
// //                         </option>
// //                       ))}
// //                     </select>
// //                   )}
// //                 </div>

// //                 <br />

// //                 <div
// //                   class="comment-submit-btn text-center"
// //                   style={{ color: "#ffc107" }}
// //                 >
// //                   <button
// //                     class="ht-btn ht-btn-md"
// //                     type="submit"
// //                     name="submitEnqury"
// //                     id="submitEnqury"
// //                     style={{ backgroundColor: "#ffc107" }}
// //                   >
// //                     Submit
// //                   </button>
// //                   {/* <!--  <p class="form-messege"></p> --> */}
// //                 </div>
// //               </div>
// //             </form>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// //   );
// // };
// // export default E1;

// import React, { useState } from "react";
// import "./E1.css";
// import { MdCancel } from "react-icons/md";

// const E1 = () => {
//   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);

//   const handleQuickOptionClick = () => {
//     setDemoOptionOpen(!isDemoOptionOpen);
//   };

//   const [status, setStatus] = useState("Submit");

//   const [info, setInfo] = useState({
//     name: "",
//     phone: "",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     selectedOption: "",
//     subOption: "",
//   });

//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log(info);
//     setStatus("Sending...");

//     let response = await fetch(`/api/inquiry`, {
//       method: "POST",
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=utf-8",
//       },
//       body: JSON.stringify(info),
//     });

//     console.log(info);
//     setStatus("Submit");
//     setInfo({
//       name: "",
//       phone: "",
//       email: "",
//       address: "",
//       gender: "",
//       collegeName: "",
//       selectedOption: "",
//       subOption: "",
//     });
//     let result = await response.json();
//     //alert(result.status);
//   };

//   const getOptions = () => {
//     switch (info.selectedOption) {
//       case "Mechanical":
//         return [
//           "AUTOCAD",
//           "CATIA",
//           "NXCAD",
//           "CREO",
//           "AUTO INVENTOR",
//           "ANSYS",
//           "REVIT MEP",
//           "CNC",
//           "ART CAM",
//           "NX CAM",
//         ];
//       case "Civil":
//         return [
//           "AUTOCAD",
//           "REVIT ARCH",
//           "SKETCH UP",
//           "REVIT STRU",
//           "V-RAYS",
//           "LUMION",
//           "STAAD PRO",
//           "ETAB",
//           "CIVIL 3D",
//           "PRIMAVERA",
//         ];
//       case "Electrical":
//         return [
//           "AUTOCAD",
//           "AUTO ELECTRICal",
//           "EPLAN",
//           "MATLAB",
//           "PLC",
//           "SCADA",
//         ];
//       case "Computer":
//         return [
//           "C/C++",
//           "MS-OFFICE",
//           "TALLY",
//           "PHOTOSHOP",
//           "DCA",
//           "PGDCA",
//           "ADVANCE PGDCAA",
//         ];

//       default:
//         return [];
//     }
//   };
//   const handleCourseChange = (event) => {
//     setInfo({ ...info, selectedOption: event.target.value });
//   };

//   const handleSubOptionChange = (event) => {
//     setInfo({ ...info, subOption: event.target.value });
//   };

//   const renderSubOptions = () => {
//     const options = getOptions();
//     return options.map((option) => (
//       <option key={option} value={option}>
//         {option}
//       </option>
//     ));
//   };
//   const courses = [
//     "DCA",
//     "Advance PGDCA",
//     "PGDCA",
//     "MECHANICAL",
//     "ELECTRICAL",
//     "CIVIL & ARCH",
//     "ANY IT",
//     "TALLY",
//     "PHOTOSHOP",
//     "MS OFFICE",
//     "ADVANCE EXCEL",
//     "BASIC COMPUTER",
//   ];

//   const nameRegex = /^[a-zA-Z\s]+$/;
//   const phoneRegex = /^\d{10}$/;
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//   const handleNameChange = (e) => {
//     const inputValue = e.target.value;
//     if (nameRegex.test(inputValue) || inputValue === "") {
//       setInfo({ ...info, name: inputValue });
//     }
//   };

//   const handlePhoneChange = (e) => {
//     const inputValue = e.target.value;
//     if (phoneRegex.test(inputValue) || inputValue === "") {
//       setInfo({ ...info, phone: inputValue });
//     }
//   };

//   const handleEmailChange = (e) => {
//     const inputValue = e.target.value;
//     if (emailRegex.test(inputValue) || inputValue === "") {
//       setInfo({ ...info, email: inputValue });
//     }
//   };

//   const handleAddressChange = (e) => {
//     setInfo({ ...info, address: e.target.value });
//   };

//   const handleGenderChange = (e) => {
//     setInfo({ ...info, gender: e.target.value });
//   };

//   const handleCollegeNameChange = (e) => {
//     setInfo({ ...info, collegeName: e.target.value });
//   };

//   return (
//     <>
//       <div
//         className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}
//       >
//         <div className="aeroland__toolbar1">
//           <div
//             className="inner1"
//             style={{ background: "orange", textAlign: "right" }}
//           >
//             <button
//               className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//               style={{
//                 color: "White",
//                 transform: `rotate(180deg) ${
//                   isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
//                 }`,
//                 fontSize: "18px", // Adjust the font size as needed
//                 border: "none",
//                 // Remove the border
//                 background: "transparent",
//                 fontWeight: "bold",
//                 outline: "none" /* Remove default focus outline */,
//                 border: "none",
//                 // Optional: Adjust the negative margin to fine-tune the vertical position
//                 marginLeft: "-38px",
//                 lineHeight: "8.15",
//                 color: "black",
//                 textAlign: "center",
//                 marginTop: "-20px",
//               }}
//               onClick={handleQuickOptionClick}
//             >
//               Register
//             </button>
//           </div>
//         </div>

//         <div className="demo-option-wrapper">
//           <button
//             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//             onClick={handleQuickOptionClick}
//             style={{ backgroundColor: "#FFB237" }}
//           >
//             <MdCancel style={{ fontSize: "40px" }} />
//           </button>

//           <div className="business-solution-form-wrap mr-auto ml-auto">
//             <div className="contact-title text-center section-space--mb_40">
//               <b>
//                 <h3 className="mb-10">Register Here</h3>
//               </b>
//             </div>

//             <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
//               <input type="hidden" name="valid" value="false" />
//               <div class="contact-form__two">
//                 <br />

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     name="name"
//                     type="text"
//                     placeholder="Name *"
//                     required
//                     id="name"
//                     pattern="[a-zA-Z\s]+"
//                     title="Please enter only alphabets"
//                     value={info.name}
//                     onChange={handleNameChange}
//                   />
//                 </div>

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     name="email"
//                     type="text"
//                     placeholder="Email id *"
//                     required
//                     id="email"
//                     pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
//                     title="Please enter a valid email address"
//                     value={info.email}
//                     onChange={handleEmailChange}
//                   />
//                 </div>

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     name="phone"
//                     type="text"
//                     placeholder="Contact No *"
//                     required
//                     id="phone"
//                     pattern="\d{10}"
//                     title="Please enter a 10-digit phone number"
//                     value={info.phone}
//                     onChange={handlePhoneChange}
//                   />
//                 </div>

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     name="address"
//                     type="text"
//                     placeholder="Address"
//                     value={info.address}
//                     onChange={handleAddressChange}
//                   />
//                 </div>

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <select
//                     name="selectedOption"
//                     value={info.selectedOption}
//                     onChange={handleCourseChange}
//                   >
//                     <option value="">Select Course</option>
//                     {courses.map((course) => (
//                       <option key={course} value={course}>
//                         {course}
//                       </option>
//                     ))}
//                   </select>
//                 </div>

//                 {info.selectedOption && (
//                   <div className="contact-inner" style={{ width: "260px" }}>
//                     <select
//                       name="subOption"
//                       value={info.subOption}
//                       onChange={handleSubOptionChange}
//                     >
//                       <option value="">Select Sub-Option</option>
//                       {renderSubOptions()}
//                     </select>
//                   </div>
//                 )}

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     type="text"
//                     name="gender"
//                     placeholder="Gender"
//                     value={info.gender}
//                     onChange={handleGenderChange}
//                   />
//                 </div>

//                 <div className="contact-inner" style={{ width: "260px" }}>
//                   <input
//                     type="text"
//                     name="collegeName"
//                     placeholder="College Name"
//                     value={info.collegeName}
//                     onChange={handleCollegeNameChange}
//                   />
//                 </div>

//                 <div className="form-group mt-20">
//                   <button type="submit" className="primary-btn">
//                     {status === "Submit" ? "Submit " : status}
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default E1;

// import React, { useState } from "react";
// import "./E1.css";
// import { MdCancel } from "react-icons/md";

// const E1 = () => {
//   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
//   const [status, setStatus] = useState("Submit");
//   const [info, setInfo] = useState({
//     name: "",
//     mobileNo: "", // Match this with Mongoose schema field
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });

//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");

//     try {
//       let response = await fetch("api/studentregister", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(info),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       let result = await response.json();
//       console.log(result);

//       setStatus("Submit");
//       setInfo({
//         name: "",
//         mobileNo: "",
//         email: "",
//         address: "",
//         gender: "",
//         collegeName: "",
//         course: "",
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };

//   const courses = [
//     "ADVANCE EXCEL",
//     "ADVANCE PGDCA",
//     "ANY IT",
//     "BASIC COMPUTER",
//     "CIVIL & ARCH",
//     "DCA",
//     "ELECTRICAL",
//     "MECHANICAL",
//     "MS OFFICE",
//     "PGDCA",
//     "PHOTOSHOP",
//     "TALLY"
//   ];

//   return (
//     <div className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}>
//       <div className="aeroland__toolbar1">
//         <div
//           className="inner1"
//           style={{ background: "orange", textAlign: "right" }}
//         >
//           <button
//             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//             style={{
//               color: "White",
//               transform: `rotate(180deg) ${
//                 isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
//               }`,
//               fontSize: "18px",
//               border: "none",
//               background: "transparent",
//               fontWeight: "bold",
//               outline: "none",
//               marginLeft: "-38px",
//               lineHeight: "8.15",
//               color: "black",
//               textAlign: "center",
//               marginTop: "-37px",
//             }}
//             onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           >
//             Register
//           </button>
//         </div>
//       </div>

//       <div className="demo-option-wrapper">
//         <button
//           className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//           onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           style={{ backgroundColor: "#FFB237" }}
//         >
//           <MdCancel style={{ fontSize: "40px" }} />
//         </button>

//         <div className="business-solution-form-wrap mr-auto ml-auto">
//           <div className="contact-title text-center section-space--mb_40">
//             <b>
//               <h3 className="mb-10">Register Here</h3>
//             </b>
//           </div>

//           <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
//             <div className="contact-form__two">
//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="name"
//                   type="text"
//                   placeholder="Name *"
//                   required
//                   pattern="[a-zA-Z\s]+"
//                   title="Please enter only alphabets"
//                   value={info.name}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="email"
//                   type="text"
//                   placeholder="Email id *"
//                   required
//                   pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
//                   title="Please enter a valid email address"
//                   value={info.email}
//                   onChange={handleChange}
//                 />
//               </div>

//              <div className="contact-inner" style={{ width: "48%" }}>
//   <input
//     name="mobileNo"
//     type="text"
//     placeholder="Contact No *"
//     required
//     pattern="\d{10}"
//     title="Please enter a 10-digit phone number"
//     value={info.mobileNo}
//     onChange={handleChange}
//   />
// </div>


//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="address"
//                   type="text"
//                   placeholder="Address"
//                   value={info.address}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div>
//               <div className="contact-inner" >
//                 <select  className="crs"
//                   name="course"
//                   value={info.course}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="" >Select Course</option>
//                   {courses.map((course) => (
//                     <option key={course} value={course}>
//                       {course}
//                     </option>
//                   ))}
//                 </select>
//               </div>

//               <div className="contact-inner" style={{marginRight:"120px"}}>
//                 <select  className="gen"
//                   name="gender"
      
//                   value={info.gender}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="" style={{color:"black"}}>Select Gender</option>
//                   <option value="male">Female</option>
//                   <option value="female">Male</option>
                 
//                 </select>
//               </div>
//               </div>

             

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="collegeName"
//                   type="text"
//                   placeholder="College Name"
//                   value={info.collegeName}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="form-group mt-10">
//                 <button
//                   className="ht-btn ht-btn-md"
//                   type="submit"
//                   id="form-button"
//                 >
//                   {status}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default E1;
//--------------------------------------------------------------------------------------------------

// import React, { useState } from "react";
// import "./E1.css";
// import { MdCancel } from "react-icons/md";
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const E1 = () => {
//   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
//   const [status, setStatus] = useState("Submit");
//   const [info, setInfo] = useState({
//     name: "",
//     mobileNo: "",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });

//   const handleChange = (event) => {
//     setInfo({
//       ...info,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");

//     try {
//       let response = await fetch("/api/studentregister", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(info),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       let result = await response.json();
//       console.log(result);

//       toast.success("Form submitted successfully!");

//       setStatus("Submit");
//       setInfo({
//         name: "",
//         mobileNo: "",
//         email: "",
//         address: "",
//         gender: "",
//         collegeName: "",
//         course: "",
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       toast.error("Failed to submit the form. Please try again.");
//     }
//   };

//   const courses = [
//     "ADVANCE EXCEL",
//     "ADVANCE PGDCA",
//     "ANY IT",
//     "BASIC COMPUTER",
//     "CIVIL & ARCH",
//     "DCA",
//     "ELECTRICAL",
//     "MECHANICAL",
//     "MS OFFICE",
//     "PGDCA",
//     "PHOTOSHOP",
//     "TALLY"
//   ];

//   return (
//     <div className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}>
//       <ToastContainer />
//       <div className="aeroland__toolbar1">
//         <div
//           className="inner1"
//           style={{ background: "orange", textAlign: "right" }}
//         >
//           <button
//             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//             style={{
//               color: "White",
//               transform: `rotate(180deg) ${
//                 isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
//               }`,
//               fontSize: "18px",
//               border: "none",
//               background: "transparent",
//               fontWeight: "bold",
//               outline: "none",
//               marginLeft: "-38px",
//               lineHeight: "8.15",
//               color: "black",
//               textAlign: "center",
//               marginTop: "-37px",
//             }}
//             onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           >
//             Register
//           </button>
//         </div>
//       </div>

//       <div className="demo-option-wrapper">
//         <button
//           className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//           onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           style={{ backgroundColor: "#FFB237" }}
//         >
//           <MdCancel style={{ fontSize: "40px" }} />
//         </button>

//         <div className="business-solution-form-wrap mr-auto ml-auto">
//           <div className="contact-title text-center section-space--mb_40">
//             <b>
//               <h3 className="mb-10">Register Here</h3>
//             </b>
//           </div>

//           <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
//             <div className="contact-form__two">
//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="name"
//                   type="text"
//                   placeholder="Name *"
//                   required
//                   pattern="[a-zA-Z\s]+"
//                   title="Please enter only alphabets"
//                   value={info.name}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="email"
//                   type="text"
//                   placeholder="Email id *"
//                   required
//                   pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
//                   title="Please enter a valid email address"
//                   value={info.email}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner" style={{ width: "48%" }}>
//                 <input
//                   name="mobileNo"
//                   type="text"
//                   placeholder="Contact No *"
//                   required
//                   pattern="\d{10}"
//                   title="Please enter a 10-digit phone number"
//                   value={info.mobileNo}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="address"
//                   type="text"
//                   placeholder="Address"
//                   value={info.address}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <select
//                   className="crs"
//                   name="course"
//                   value={info.course}
//                   onChange={handleChange}
//                   required
//                   style={{ 
//                     backgroundColor: "white", 
//                     color: "rgb(0 1 0)", 
//                     padding: "10px", 
//                     borderRadius: "4px", 
//                     border: "1px solid #ccc", 
//                     marginRight: "-40px",
//                     width: "160px"
//                   }}
//                 >
//                   <option value="">Select Course</option>
//                 <option value="DCA">DCA</option>
//                 <option value="Advance PGDCA">Advance PGDCA</option>
//                 <option value="PGDCA">PGDCA</option>
//                 <option value="MECHANICAL">MECHANICAL</option>
//                 <option value="ELECTRICAL">ELECTRICAL</option>
//                 <option value="CIVIL & ARCH">CIVIL & ARCH</option>
//                 <option value="ANY IT">ANY IT</option>
//                 <option value="TALLY">TALLY</option>
//                 <option value="PHOTOSHOP">PHOTOSHOP</option>
//                 <option value="MS OFFICE">MS OFFICE</option>
//                 <option value="ADVANCE EXCEL">ADVANCE EXCEL</option>
//                 <option value="BASIC COMPUTER">BASIC COMPUTER</option>
//                 </select>
//               </div>
              

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <select
//                   className="gen"
//                   name="gender"
//                   value={info.gender}
//                   onChange={handleChange}
//                   required
//                   style={{ 
//                     backgroundColor: "white", 
//                     color: "rgb(0 1 0)", 
//                     padding: "10px", 
//                     borderRadius: "4px", 
//                     border: "1px solid #ccc",
//                     marginRight: "-40px",
//                     width: "160px"
//                   }}
//                 >
//                   <option value="">Select Gender</option>
//                   <option value="female">Female</option>
//                   <option value="male">Male</option>
//                 </select>
//               </div>

//               <div className="contact-inner" style={{ width: "260px" }}>
//                 <input
//                   name="collegeName"
//                   type="text"
//                   placeholder="College Name"
//                   value={info.collegeName}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="form-group mt-10">
//                 <button
//                   className="ht-btn ht-btn-md"
//                   type="submit"
//                   id="form-button"
//                 >
//                   {status}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default E1;

// import React, { useState } from "react";
// import "./E1.css";
// import { MdCancel } from "react-icons/md";
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const E1 = () => {
//   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
//   const [status, setStatus] = useState("Submit");
//   const [info, setInfo] = useState({
//     name: "",
//     mobileNo: "+91",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });
//   const [errors, setErrors] = useState({
//     name: "",
//     mobileNo: "",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });
//   const [countryCode, setCountryCode] = useState("+91");

//   const validate = (name, value) => {
//     let error = "";

//     switch (name) {
//       case "name":
//         if (!/^[a-zA-Z\s]+$/.test(value)) {
//           error = "Please enter only alphabets";
//         }
//         break;
//       case "email":
//         if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
//           error = "Please enter a valid email address";
//         }
//         break;
//       case "mobileNo":
//         const phoneNumber = value.replace(countryCode, "").trim();
//         if (!/^\d{10}$/.test(phoneNumber)) {
//           error = "Please enter a 10-digit phone number";
//         }
//         break;
//       default:
//         break;
//     }

//     setErrors({
//       ...errors,
//       [name]: error,
//     });
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;

//     if (name === "mobileNo") {
//       const formattedValue = `${countryCode}${value.replace(countryCode, "")}`;
//       setInfo({
//         ...info,
//         [name]: formattedValue,
//       });
//       validate(name, formattedValue);
//     } else {
//       setInfo({
//         ...info,
//         [name]: value,
//       });
//       validate(name, value);
//     }
//   };

//   const handleCountryCodeChange = (event) => {
//     const newCountryCode = event.target.value;
//     const formattedMobileNo = info.mobileNo.replace(countryCode, newCountryCode);

//     setCountryCode(newCountryCode);
//     setInfo({
//       ...info,
//       mobileNo: formattedMobileNo,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");

//     try {
//       let response = await fetch("/api/studentregister", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(info),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       let result = await response.json();
//       console.log(result);

//       toast.success("Form submitted successfully!");

//       setStatus("Submit");
//       setInfo({
//         name: "",
//         mobileNo: "+91",
//         email: "",
//         address: "",
//         gender: "",
//         collegeName: "",
//         course: "",
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       toast.error("Failed to submit the form. Please try again.");
//     }
//   };

//   const courses = [
//     "ADVANCE EXCEL",
//     "ADVANCE PGDCA",
//     "ANY IT",
//     "BASIC COMPUTER",
//     "CIVIL & ARCH",
//     "DCA",
//     "ELECTRICAL",
//     "MECHANICAL",
//     "MS OFFICE",
//     "PGDCA",
//     "PHOTOSHOP",
//     "TALLY"
//   ];

//   const countryCodes = [
//     { code: "+91" },
//     // { code: "+1", name: "USA" },
//     // { code: "+44", name: "UK" },
//     // { code: "+81", name: "Japan" },
//     // Add more country codes as needed
//   ];

//   return (
//     <div className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}>
//       <ToastContainer />
//       <div className="aeroland__toolbar1">
//         <div
//           className="inner1"
//           style={{ background: "orange", textAlign: "right" }}
//         >
//           <button
//             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//             style={{
//               color: "White",
//               transform: `rotate(180deg) ${
//                 isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
//               }`,
//               fontSize: "18px",
//               border: "none",
//               background: "transparent",
//               fontWeight: "bold",
//               outline: "none",
//               marginLeft: "-38px",
//               lineHeight: "8.15",
//               color: "black",
//               textAlign: "center",
//               marginTop: "-37px",
//             }}
//             onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           >
//             Register
//           </button>
//         </div>
//       </div>

//       <div className="demo-option-wrapper">
//         <button
//           className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//           onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           style={{ backgroundColor: "#FFB237" }}
//         >
//           <MdCancel style={{ fontSize: "40px" }} />
//         </button>

//         <div className="business-solution-form-wrap mr-auto ml-auto">
//           <div className="contact-title text-center section-space--mb_40">
//             <b>
//               <h3 className="mb-10">Register Here</h3>
//             </b>
//           </div>

//           <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
//             <div className="contact-form__two">
//               <div className="contact-inner">
//                 <input
//                   name="name"
//                   type="text"
//                   placeholder="Name *"
//                   required
//                   pattern="[a-zA-Z\s]+"
//                   title="Please enter only alphabets"
//                   value={info.name}
//                   onChange={handleChange}
//                 />
//                 {errors.name && <p className="error">{errors.name}</p>}
//               </div>

//               <div className="contact-inner">
//                 <input
//                   name="email"
//                   type="text"
//                   placeholder="Email id *"
//                   required
//                   pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
//                   title="Please enter a valid email address"
//                   value={info.email}
//                   onChange={handleChange}
//                 />
//                 {errors.email && <p className="error">{errors.email}</p>}
//               </div>

//               <div className="contact-inner contact-number-wrapper">
//                 <select
//                   name="countryCode"
//                   value={countryCode}
//                   onChange={handleCountryCodeChange}
//                   style={{
//                     backgroundColor: "white",
//                     color: "rgb(0 1 0)",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                     width: "70px",
//                   marginLeft:'-20px',
//                   whiteSpace:'10px'
//                   }}
//                 >
//                   {countryCodes.map((country, index) => (
//                     <option key={index} value={country.code}>
//                       {country.code}
//                     </option>
//                   ))}
//                 </select>

//                 <input
//                   name="mobileNo"
//                   type="text"
//                   placeholder="Contact No *"
//                   required
//                   pattern="\d{10}"
//                   title="Please enter a 10-digit phone number"
//                   value={info.mobileNo.replace(countryCode, "")}
//                   onChange={handleChange}
//                   style={{ width: "200px" }}
//                 />
//                 {errors.mobileNo && <p className="error">{errors.mobileNo}</p>}
//               </div>

//               <div className="contact-inner">
//                 <select
//                   className="gen"
//                   name="gender"
//                   value={info.gender}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     backgroundColor: "white",
//                     color: "rgb(0 1 0)",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                      width: "260px",
//                     marginLeft:'-110px'
//                   }}
//                 >
//                   <option value="">Select Gender</option>
//                   <option value="female">Female</option>
//                   <option value="male">Male</option>
//                 </select>
//               </div>

//               <div className="contact-inner">
//                 <input
//                   name="address"
//                   type="text"
//                   placeholder="Address"
//                   value={info.address}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="contact-inner">
//                 <select
//                   className="crs"
//                   name="course"
//                   value={info.course}
//                   onChange={handleChange}
//                   required
//                   style={{
//                     backgroundColor: "white",
//                     color: "rgb(0 1 0)",
//                     padding: "10px",
//                     borderRadius: "4px",
//                     border: "1px solid #ccc",
//                     width: "260px",
//                     marginLeft:'07px'
//                   }}
//                 >
//                   <option value="">Select Course</option>
//                   {courses.map((course, index) => (
//                     <option key={index} value={course}>
//                       {course}
//                     </option>
//                   ))}
//                 </select>
//               </div>

            

//               <div className="contact-inner">
//                 <input
//                   name="collegeName"
//                   type="text"
//                   placeholder="College Name"
//                   value={info.collegeName}
//                   onChange={handleChange}
//                 />
//               </div>

//               <div className="form-group mt-10">
//                 <button
//                   className="ht-btn ht-btn-md"
//                   type="submit"
//                   id="form-button"
//                 >
//                   {status}
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default E1;


// import React, { useState } from "react";
// import "./E1.css";
// import { MdCancel } from "react-icons/md";
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';

// const E1 = () => {
//   const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
//   const [status, setStatus] = useState("Submit");
//   const [info, setInfo] = useState({
//     name: "",
//     mobileNo: "+91",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });
//   const [errors, setErrors] = useState({
//     name: "",
//     mobileNo: "",
//     email: "",
//     address: "",
//     gender: "",
//     collegeName: "",
//     course: "",
//   });
//   const [countryCode, setCountryCode] = useState("+91");
//   const [formSubmitted, setFormSubmitted] = useState(false);

//   const validate = (name, value) => {
//     let error = "";

//     switch (name) {
//       case "name":
//         if (!/^[a-zA-Z\s]+$/.test(value)) {
//           error = "Please enter only alphabets";
//         }
//         break;
//       case "email":
//         if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
//           error = "Please enter a valid email address";
//         }
//         break;
//       case "mobileNo":
//         const phoneNumber = value.replace(countryCode, "").trim();
//         if (!/^\d{10}$/.test(phoneNumber)) {
//           error = "Please enter a 10-digit phone number";
//         }
//         break;
//       default:
//         break;
//     }

//     setErrors({
//       ...errors,
//       [name]: error,
//     });
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;

//     if (name === "mobileNo") {
//       const formattedValue = `${countryCode}${value.replace(countryCode, "")}`;
//       setInfo({
//         ...info,
//         [name]: formattedValue,
//       });
//       validate(name, formattedValue);
//     } else {
//       setInfo({
//         ...info,
//         [name]: value,
//       });
//       validate(name, value);
//     }
//   };

//   const handleCountryCodeChange = (event) => {
//     const newCountryCode = event.target.value;
//     const formattedMobileNo = info.mobileNo.replace(countryCode, newCountryCode);

//     setCountryCode(newCountryCode);
//     setInfo({
//       ...info,
//       mobileNo: formattedMobileNo,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("Sending...");

//     try {
//       let response = await fetch("/api/studentregister", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(info),
//       });

//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }

//       let result = await response.json();
//       console.log(result);

//       // toast.success("Form submitted successfully!");
//       setFormSubmitted(true);

//       setStatus("Submit");
//       setInfo({
//         name: "",
//         mobileNo: "+91",
//         email: "",
//         address: "",
//         gender: "",
//         collegeName: "",
//         course: "",
//       });
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       toast.error("Failed to submit the form. Please try again.");
//     }
//   };

//   const courses = [
//     "ADVANCE EXCEL",
//     "ADVANCE PGDCA",
//     "ANY IT",
//     "BASIC COMPUTER",
//     "CIVIL & ARCH",
//     "DCA",
//     "ELECTRICAL",
//     "MECHANICAL",
//     "MS OFFICE",
//     "PGDCA",
//     "PHOTOSHOP",
//     "TALLY"
//   ];

//   const countryCodes = [
//     { code: "+91" },
//     // { code: "+1", name: "USA" },
//     // { code: "+44", name: "UK" },
//     // { code: "+81", name: "Japan" },
//     // Add more country codes as needed
//   ];

//   return (
//     <div className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}>
//       <ToastContainer />
//       <div className="aeroland__toolbar1">
//         <div
//           className="inner1"
//           style={{ background: "orange", textAlign: "right" }}
//         >
//           <button
//             className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//             style={{
//               color: "White",
//               transform: `rotate(180deg) ${
//                 isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
//               }`,
//               fontSize: "18px",
//               border: "none",
//               background: "transparent",
//               fontWeight: "bold",
//               outline: "none",
//               marginLeft: "-38px",
//               lineHeight: "8.15",
//               color: "black",
//               textAlign: "center",
//               marginTop: "-37px",
//             }}
//             onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           >
//             Register
//           </button>
//         </div>
//       </div>

//       <div className="demo-option-wrapper">
//         <button
//           className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
//           onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
//           style={{ backgroundColor: "#FFB237" }}
//         >
//           <MdCancel style={{ fontSize: "40px" }} />
//         </button>

//         <div className="business-solution-form-wrap mr-auto ml-auto" >
//           {formSubmitted ? (
//             <div className="thank-you-message text-center" style={{marginTop:'200px'}}>
//               <h3>Thank You!</h3>
//               <p>Your registration has been received successfully.</p>
//             </div>
//           ) : (
//             <>
//               <div className="contact-title text-center section-space--mb_40">
//                 <b>
//                   <h3 className="mb-10">Register Here</h3>
//                 </b>
//               </div>

//               <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
//                 <div className="contact-form__two">
//                   <div className="contact-inner">
//                     <input
//                       name="name"
//                       type="text"
//                       placeholder="Name *"
//                       required
//                       pattern="[a-zA-Z\s]+"
//                       title="Please enter only alphabets"
//                       value={info.name}
//                       onChange={handleChange}
//                     />
//                     {errors.name && <p className="error">{errors.name}</p>}
//                   </div>

//                   <div className="contact-inner">
//                     <input
//                       name="email"
//                       type="text"
//                       placeholder="Email id *"
//                       required
//                       pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
//                       title="Please enter a valid email address"
//                       value={info.email}
//                       onChange={handleChange}
//                     />
//                     {errors.email && <p className="error">{errors.email}</p>}
//                   </div>

//                   <div className="contact-inner contact-number-wrapper">
//                     <select
//                       name="countryCode"
//                       value={countryCode}
//                       onChange={handleCountryCodeChange}
//                       style={{
//                         backgroundColor: "white",
//                         color: "rgb(0 1 0)",
//                         padding: "10px",
//                         borderRadius: "4px",
//                         border: "1px solid #ccc",
//                         width: "70px",
//                         marginLeft: '-20px',
//                         whiteSpace: '10px'
//                       }}
//                     >
//                       {countryCodes.map((country, index) => (
//                         <option key={index} value={country.code}>
//                           {country.code}
//                         </option>
//                       ))}
//                     </select>

//                     <input
//                       name="mobileNo"
//                       type="text"
//                       placeholder="Contact No *"
//                       required
//                       pattern="\d{10}"
//                       title="Please enter a 10-digit phone number"
//                       value={info.mobileNo.replace(countryCode, "")}
//                       onChange={handleChange}
//                       style={{ width: "200px" }}
//                     />
//                     {errors.mobileNo && <p className="error">{errors.mobileNo}</p>}
//                   </div>

//                   <div className="contact-inner">
//                     <select
//                       className="gen"
//                       name="gender"
//                       value={info.gender}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         backgroundColor: "white",
//                         color: "rgb(0 1 0)",
//                         padding: "10px",
//                         borderRadius: "4px",
//                         border: "1px solid #ccc",
//                         width: "260px",
//                         marginLeft: '-110px'
//                       }}
//                     >
//                       <option value="">Select Gender</option>
//                       <option value="female">Female</option>
//                       <option value="male">Male</option>
//                     </select>
//                   </div>

//                   <div className="contact-inner">
//                     <input
//                       name="address"
//                       type="text"
//                       placeholder="Address"
//                       value={info.address}
//                       onChange={handleChange}
//                     />
//                   </div>


//                   <div className="contact-inner">
//                     <select
//                       className="gen"
//                       name="course"
//                       value={info.course}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         backgroundColor: "white",
//                         color: "rgb(0 1 0)",
//                         padding: "10px",
//                         borderRadius: "4px",
//                         border: "1px solid #ccc",
//                         width: "260px",
//                         marginLeft: '-110px'
//                       }}
//                     >
//                       <option value="">Select Course</option>
//                       {courses.map((course, index) => (
//                         <option key={index} value={course}>
//                           {course}
//                         </option>
//                       ))}
//                     </select>
//                   </div>

//                   <div className="contact-inner">
//                 <input
//                   name="collegeName"
//                   type="text"
//                   placeholder="College Name"
//                   value={info.collegeName}
//                   onChange={handleChange}
//                 />
//               </div>
//                   <div className="contact-inner text-center">
//                     <button type="submit" className="submit-button">
//                       {status}
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default E1;


import React, { useState, useEffect } from "react";
import "./E1.css";
import { MdCancel } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const E1 = () => {
  const [isDemoOptionOpen, setDemoOptionOpen] = useState(false);
  const [status, setStatus] = useState("Submit");
  const [info, setInfo] = useState({
    name: "",
    mobileNo: "+91",
    email: "",
    address: "",
    gender: "",
    collegeName: "",
    course: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobileNo: "",
    email: "",
    address: "",
    gender: "",
    collegeName: "",
    course: "",
  });
  const [countryCode, setCountryCode] = useState("+91");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    let timer;
    if (formSubmitted) {
      timer = setTimeout(() => {
        setFormSubmitted(false);
        setInfo({
          name: "",
          mobileNo: "+91",
          email: "",
          address: "",
          gender: "",
          collegeName: "",
          course: "",
        });
      }, 3000); // 5 seconds delay
    }
    return () => clearTimeout(timer);
  }, [formSubmitted]);

  const validate = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        if (!/^[a-zA-Z\s]+$/.test(value)) {
          error = "Please enter only alphabets";
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "mobileNo":
        const phoneNumber = value.replace(countryCode, "").trim();
        if (!/^\d{10}$/.test(phoneNumber)) {
          error = "Please enter a 10-digit phone number";
        }
        break;
      default:
        break;
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "mobileNo") {
      const formattedValue = `${countryCode}${value.replace(countryCode, "")}`;
      setInfo({
        ...info,
        [name]: formattedValue,
      });
      validate(name, formattedValue);
    } else {
      setInfo({
        ...info,
        [name]: value,
      });
      validate(name, value);
    }
  };

  const handleCountryCodeChange = (event) => {
    const newCountryCode = event.target.value;
    const formattedMobileNo = info.mobileNo.replace(countryCode, newCountryCode);

    setCountryCode(newCountryCode);
    setInfo({
      ...info,
      mobileNo: formattedMobileNo,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");

    try {
      let response = await fetch("/api/studentregister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(info),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let result = await response.json();
      console.log(result);

      setFormSubmitted(true);
      setStatus("Submit");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit the form. Please try again.");
      setStatus("Submit");
    }
  };

  const courses = [
    "ADVANCE EXCEL",
    "ADVANCE PGDCA",

    "BASIC COMPUTER",
    "CIVIL & ARCH",
    "DIPLOMA CIVIL CAD",
    "DIPLOMA ELECTRICAL CAD",
    "DIPLOMA MECH CAD",
    "DCA",
    "ELECTRICAL",
    "IT & CS",
    "INTERIOR DESIGNING",

    "MECHANICAL",
    "MS OFFICE",
    "PGDCA",
    "PROFESSIONAL CIVIL CAD",
    "PROFESSIONAL ELECTRCIAL CAD",
    "PROFESSIONAL MECH CAD",

    "PHOTOSHOP",
    "TALLY"

   
    
   
    
   
    
    
    
  ];

  const countryCodes = [
    { code: "+91" },
  ];

  return (
    <div className={`demo-option-container ${isDemoOptionOpen ? "open" : ""}`}>
      <ToastContainer />
      <div className="aeroland__toolbar1">
        <div
          className="inner1"
          style={{ background: "orange", textAlign: "right" }}
        >
          <button
            className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
            style={{
              color: "White",
              transform: `rotate(180deg) ${
                isDemoOptionOpen ? "rotate(90deg)" : "rotate(90deg)"
              }`,
              fontSize: "18px",
              border: "none",
              background: "transparent",
              fontWeight: "bold",
              outline: "none",
              marginLeft: "-38px",
              lineHeight: "8.15",
              color: "black",
              textAlign: "center",
              marginTop: "-37px",
            }}
            onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
          >
            Register
          </button>
        </div>
      </div>

      <div className="demo-option-wrapper">
        <button
          className="quick-option hint--bounce hint--left hint--black primary-color-hover-important"
          onClick={() => setDemoOptionOpen(!isDemoOptionOpen)}
          style={{ backgroundColor: "#FFB237" }}
        >
          <MdCancel style={{ fontSize: "40px" }} />
        </button>

        <div className="business-solution-form-wrap mr-auto ml-auto" >
          {formSubmitted ? (
            <div className="thank-you-message text-center" style={{marginTop:'200px'}}>
              <h3>Thank You!</h3>
              <p>Your registration has been received successfully.</p>
            </div>
          ) : (
            <>
              <div className="contact-title text-center section-space--mb_40">
                <b>
                  <h3 className="mb-10">Register Here</h3>
                </b>
              </div>

              <form id="applyOnlyEnqury" onSubmit={handleSubmit}>
                <div className="contact-form__two">
                  <div className="contact-inner">
                    <input
                      name="name"
                      type="text"
                      placeholder="Name *"
                      required
                      pattern="[a-zA-Z\s]+"
                      title="Please enter only alphabets"
                      value={info.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>

                  <div className="contact-inner">
                    <input
                      name="email"
                      type="text"
                      placeholder="Email id *"
                      required
                      pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                      title="Please enter a valid email address"
                      value={info.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>

                  <div className="contact-inner contact-number-wrapper">
                    <select
                      name="countryCode"
                      value={countryCode}
                      onChange={handleCountryCodeChange}
                      style={{
                        backgroundColor: "white",
                        color: "rgb(0 1 0)",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        width: "70px",
                        marginLeft: '-20px',
                        whiteSpace: '10px'
                      }}
                    >
                      {countryCodes.map((country, index) => (
                        <option key={index} value={country.code}>
                          {country.code}
                        </option>
                      ))}
                    </select>

                    <input
                      name="mobileNo"
                      type="text"
                      placeholder="Contact No *"
                      required
                      pattern="\d{10}"
                      title="Please enter a 10-digit phone number"
                      value={info.mobileNo.replace(countryCode, "")}
                      onChange={handleChange}
                      style={{ width: "200px" }}
                    />
                    {errors.mobileNo && <p className="error">{errors.mobileNo}</p>}
                  </div>

                  <div className="contact-inner">
                    <select
                      className="gen"
                      name="gender"
                      value={info.gender}
                      onChange={handleChange}
                      required
                      style={{
                        backgroundColor: "white",
                        color: "rgb(0 1 0)",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        width: "260px",
                        marginLeft: '-110px'
                      }}
                    >
                      <option value="">Select Gender</option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                  </div>

                  <div className="contact-inner">
                    <input
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={info.address}
                      onChange={handleChange}
                    />
                  </div>


                  <div className="contact-inner">
                    <select
                      className="gen"
                      name="course"
                      value={info.course}
                      onChange={handleChange}
                      required
                      style={{
                        backgroundColor: "white",
                        color: "rgb(0 1 0)",
                        padding: "10px",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                        width: "260px",
                        marginLeft: '-110px'
                      }}
                    >
                      <option value="">Select Course</option>
                      {courses.map((course, index) => (
                        <option key={index} value={course}>
                          {course}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="contact-inner">
                    <input
                      name="collegeName"
                      type="text"
                      placeholder="College Name"
                      value={info.collegeName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="contact-inner text-center">
                    <button type="submit" className="submit-button">
                      {status}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default E1;