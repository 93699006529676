import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Topheader from "../Components/Topheader";
import { Redirect } from "react-router-dom";
import "./Cad.css";
import { PiX } from "react-icons/pi";

const CoursesDetails = ({ appsList }) => {
  const [showList1, setShowList1] = useState("");
  const [showList2, setShowList2] = useState("");
  const [showList3, setShowList3] = useState("");
  const [showList4, setShowList4] = useState("");
  const [showList5, setShowList5] = useState("");
  
  

  const toggleList = (listNumber) => {
    if (listNumber === 1) {
      setShowList1(!showList1);
      setShowList2(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
    } else if (listNumber === 2) {
      setShowList2(!showList2);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList5(false);
    } else if (listNumber === 3) {
      setShowList3(!showList3);
      setShowList2(false);
      setShowList1(false);
      setShowList4(false);
      setShowList5(false);
    } else if (listNumber === 4) {
      setShowList4(!showList4);
      setShowList1(false);
      setShowList3(false);
      setShowList2(false);
      setShowList5(false);
    } else if (listNumber === 5) {
      setShowList5(!showList5);
      setShowList1(false);
      setShowList3(false);
      setShowList4(false);
      setShowList2(false);
    }
  };


  const { appId,appName } = useParams();

  const appDetails = appsList.find((app) => app.appName === appName);

  if (!appDetails) {
    window.location.href = "/Courses";
  }

  return (
    <>

      <section className="page-header">
        <div className="page-header__bg_1"></div>
        {/* <div
          className="page-header__gradient"
          style={{
            backgroundImage:
              "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)",
          }}
        ></div> */}
        <div className="shape1 float-bob-x">
          <img src="../images/sttsbanner.png" alt="banner shape" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>{appDetails.appName}</h2>

            <ul className="thm-breadcrumb list-unstyled ml-0">
              {/* Breadcrumb NavXT 7.2.0 */}
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Ontech."
                    href="/"
                    className="home"
                  >
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">
                    {appDetails.appName}
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="vision-overview  " style={{ marginTop: "-110px" }}>
        <div className="container">
          <div
            className="section-title-wrap text-center"
            style={{ marginTop: "40px" }}
          >
            <br />
            <br />
            <h3 className="heading">
              <span
                className="text-color-primary"
                style={{ fontSize: "25px", color: "#ffc107" }}
              >
                {" "}
                Training Highlights
              </span>
            </h3>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="../images/img111.png" height={100} width={200}
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Instructor Led Training</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src=
                   "../images/img666.jpg"
                    
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">ISO Certification</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="../images/img333.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Placement Assistance</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="../images/img444.jpg"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Recorded Videos for Online Training
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="
                    ../images/img555.png
                    "
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">Weekly Doubt Clearing Session</h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="item-container d-flex align-items-center">
                <div className="image-container">
                  <img
                    src="../images/img666.png"
                    className="lazy-load"
                  />
                </div>
                <div className="content-container">
                  <h3 className="title">
                    Study Material, Assignments and Projects
                  </h3>
                  <p className="description"></p>
                </div>
              </div>
            </div>
          </div>
          <br /> <br />
          <div className="row" style={{ marginTop: "-90px" }}>
            <div className="col-lg-12">
              <br />
              <br />
              <h3 className="heading">
                <span
                  className="text-color-primary"
                  style={{ fontSize: "30px", color: "#ffc107" }}
                >
                  {" "}
                  Course Overview
                </span>
              </h3>
              <br />
              <br />
              <div>
                <p
                  style={{
                    marginTop: "-70px",
                    fontSize: "15px",
                    textAlign: "justify",
                  }}
                >
                  {appDetails.describe}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginTop: "-50px",
        }}
        class="to"
      >
        <div style={{ flex: 1 }}>
          <h3 className="heading_course_content">
            <span className="text-color-primary responsive_course_content">
              {" "}
              Course Contents
            </span>
          </h3>

          <div className="row">
            {" "}
            <div className="responsive_courese_image_container_mobile_view">
              <img
                src={appDetails.imageUrl}
                alt="Course Image"
                className="responsive_course_image_mobile_view"
              />
            </div>


            <div>
      {appDetails && appDetails.Coursescontent && appDetails.Coursescontent.map((course, index) => (
        <div key={index} className="responsive_introduction_container">
          <button
            className={`collapsed responsive_introduction_button${eval('showList' + (index + 1)) ? ' active' : ''}`}
            data-toggle="collapse"
            data-target={`#collapse__111_${index}`}
            aria-expanded="false"
            aria-controls={`collapse__111_${index}`}
            onClick={() => toggleList(index + 1)}
          >
            {eval('showList' + (index + 1)) ? (
              <i ></i>
            ) : (
              <i  style={{ color: "black" }}></i>
            )}{" "}
            {course.title}
          </button>
          {eval('showList' + (index + 1)) && (
            <ul>
              {/* <h3>{course.title}</h3> */}
              <ul>
              {course.introduction && course.introduction.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
                {course.module && course.module.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
                 {course.moduless && course.moduless.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
                 {course.moduless1 && course.moduless1.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </ul>
          )}
        </div>
      ))}
    </div>
   
    
     
          </div>
        </div>

        <div className="responsive_courese_image_container">
          <img
            src={appDetails.imageUrl}
            alt="Course Image"
            className="responsive_course_image"
          />
        </div>
      </div>

      <br />
      <br />
      <br />
    </>
  );
};

export default CoursesDetails;
