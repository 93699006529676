// import React, { useState } from "react";
// import "./NewJobDescription.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Leftbar from "../Leftbar/Leftbar";

// const Jobdescription = () => {
//   const [formData, setFormData] = useState({
//     jobName: "",
//     jobTitle: "",
//     jobDescription: "",
//     requiredQualifications: "",
//     otherMsg: "",
//     skills: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (Object.values(formData).some((field) => field === "")) {
//       toast.error("Please fill in all fields");
//       return;
//     }

//     try {
//       const response = await fetch("/api/jobapplications", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         toast.success("Job application submitted successfully");
//         setFormData({
//           jobName: "",
//           jobTitle: "",
//           jobDescription: "",
//           requiredQualifications: "",
//           otherMsg: "",
//           skills: "",
//         });
//       } else {
//         toast.error("Failed to submit job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while submitting the job application");
//     }
//   };

//   return (
//     <>
   
//       <Leftbar />
//       <div className="job-form-container responsive_job-form-container">
//         <form className="job-form" onSubmit={handleSubmit}>
//           <h2>Enter Job Description</h2>
//           <fieldset>
//             <div className="form-row">
//               <label htmlFor="jobName">Job Name</label>
//               <input
//                 type="text"
//                 id="jobName"
//                 name="jobName"
//                 value={formData.jobName}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>

//             <div className="form-row">
//               <label htmlFor="jobTitle">Job Title</label>
//               <input
//                 type="text"
//                 id="jobTitle"
//                 name="jobTitle"
//                 value={formData.jobTitle}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>

//             <div className="form-row">
//               <label htmlFor="jobDescription">Job Description</label>
//               <textarea
//                 id="jobDescription"
//                 name="jobDescription"
//                 value={formData.jobDescription}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>

//             <div className="form-row">
//               <label htmlFor="requiredQualifications">
//                 Required Qualifications
//               </label>
//               <textarea
//                 id="requiredQualifications"
//                 name="requiredQualifications"
//                 value={formData.requiredQualifications}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>

//             <div className="form-row">
//               <label htmlFor="otherMsg">Field of Job</label>
//               <textarea
//                 id="otherMsg"
//                 name="otherMsg"
//                 value={formData.otherMsg}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>

//             <div className="form-row">
//               <label htmlFor="skills">Skills</label>
//               <textarea
//                 id="skills"
//                 name="skills"
//                 value={formData.skills}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </fieldset>

//           <button style={{ width: "250px" }} type="submit">
//             Submit
//           </button>
//         </form>
//         <ToastContainer />
//       </div>
//     </>
//   );
// };

// export default Jobdescription;


// import React, { useState, useEffect } from "react";
// import "./NewJobDescription.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Leftbar from "../Leftbar/Leftbar";

// const Jobdescription = () => {
//   const [formData, setFormData] = useState({
//     jobName: "",
//     jobTitle: "",
//     jobDescription: "",
//     requiredQualifications: "",
//     otherMsg: "",
//     skills: "",
//   });
//   const [jobs, setJobs] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     fetchJobs();
//   }, []);

//   const fetchJobs = async () => {
//     try {
//       const response = await fetch("/api/jobapplications");
//       if (response.ok) {
//         const data = await response.json();
//         setJobs(data);
//       } else {
//         toast.error("Failed to fetch job applications");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while fetching job applications");
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (Object.values(formData).some((field) => field === "")) {
//       toast.error("Please fill in all fields");
//       return;
//     }
//     try {
//       const response = await fetch("/api/jobapplications", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         toast.success("Job application submitted successfully");
//         setFormData({
//           jobName: "",
//           jobTitle: "",
//           jobDescription: "",
//           requiredQualifications: "",
//           otherMsg: "",
//           skills: "",
//         });
//         fetchJobs();
//         setIsModalOpen(false);
//       } else {
//         toast.error("Failed to submit job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while submitting the job application");
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await fetch(`/api/jobapplications/${id}`, {
//         method: "DELETE",
//       });
//       if (response.ok) {
//         toast.success("Job application deleted successfully");
//         fetchJobs();
//       } else {
//         toast.error("Failed to delete job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while deleting the job application");
//     }
//   };

//   return (
//     <div className="job-page-container">
//       <Leftbar />
//       <div className="main-content">
//         <div className="job-table-container">
//           <h2>Existing Jobs</h2>
//           <button className="add-job-btn" onClick={() => setIsModalOpen(true)}>Add Job</button>
//           <table className="job-table">
//             <thead>
//               <tr>
//                 <th>Job Name</th>
//                 <th>Job Title</th>
//                 <th>Description</th>
//                 <th>Qualifications</th>
//                 <th>Field</th>
//                 <th>Skills</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {jobs.map((job) => (
//                 <tr key={job._id}>
//                   <td>{job.jobName}</td>
//                   <td>{job.jobTitle}</td>
//                   <td>{job.jobDescription.substring(0, 50)}...</td>
//                   <td>{job.requiredQualifications.substring(0, 50)}...</td>
//                   <td>{job.otherMsg}</td>
//                   <td>{job.skills}</td>
//                   <td>
//                     <button onClick={() => handleDelete(job._id)} className="delete-btn">
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {isModalOpen && (
//           <div className="modal">
//             <div className="modal-content">
//               <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
//               <h2>Add New Job</h2>
//               <form className="job-form" onSubmit={handleSubmit}>
//                 <div className="form-group">
//                   <label htmlFor="jobName">Job Name</label>
//                   <input
//                     type="text"
//                     id="jobName"
//                     name="jobName"
//                     value={formData.jobName}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="jobTitle">Job Title</label>
//                   <input
//                     type="text"
//                     id="jobTitle"
//                     name="jobTitle"
//                     value={formData.jobTitle}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="jobDescription">Job Description</label>
//                   <textarea
//                     id="jobDescription"
//                     name="jobDescription"
//                     value={formData.jobDescription}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="requiredQualifications">Required Qualifications</label>
//                   <textarea
//                     id="requiredQualifications"
//                     name="requiredQualifications"
//                     value={formData.requiredQualifications}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="otherMsg">Field of Job</label>
//                   <input
//                     type="text"
//                     id="otherMsg"
//                     name="otherMsg"
//                     value={formData.otherMsg}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="skills">Skills</label>
//                   <input
//                     type="text"
//                     id="skills"
//                     name="skills"
//                     value={formData.skills}
//                     onChange={handleChange}
//                   />
//                 </div>
//                 <button type="submit">Submit Job</button>
//               </form>
//             </div>
//           </div>
//         )}
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Jobdescription;

// import React, { useState, useEffect } from "react";
// import "./NewJobDescription.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Leftbar from "../Leftbar/Leftbar";

// const Jobdescription = () => {
//   const [formData, setFormData] = useState({
//     jobName: "",
//     jobTitle: "",
//     jobDescription: "",
//     requiredQualifications: "",
//     otherMsg: "",
//     skills: "",
//   });
//   const [jobs, setJobs] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Fetch jobs on component mount
//   useEffect(() => {
//     fetchJobs();
//   }, []);

//   // Fetch the list of jobs from the API
//   const fetchJobs = async () => {
//     try {
//       const response = await fetch("/api/jobapplications");
//       if (response.ok) {
//         const data = await response.json();
//         setJobs(data);
//       } else {
//         toast.error("Failed to fetch job applications");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while fetching job applications");
//     }
//   };

//   // Handle form input changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Handle form submission for adding a new job
//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Ensure all fields are filled
//     if (Object.values(formData).some((field) => field === "")) {
//       toast.error("Please fill in all fields");
//       return;
//     }

//     try {
//       const response = await fetch("/api/jobapplications", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         toast.success("Job application submitted successfully");

//         // Reset the form and close the modal
//         setFormData({
//           jobName: "",
//           jobTitle: "",
//           jobDescription: "",
//           requiredQualifications: "",
//           otherMsg: "",
//           skills: "",
//         });

//         fetchJobs(); // Fetch updated jobs list
//         setIsModalOpen(false); // Close the modal
//       } else {
//         toast.error("Failed to submit job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while submitting the job application");
//     }
//   };

//   // Handle delete functionality for a specific job
//   const handleDelete = async (id) => {
//     try {
//       const response = await fetch(`/api/jobapplications/${id}`, {
//         method: "DELETE",
//       });

//       if (response.ok) {
//         toast.success("Job application deleted successfully");
//         fetchJobs(); // Refresh the job list after deletion
//       } else {
//         toast.error("Failed to delete job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while deleting the job application");
//     }
//   };

//   return (
//     <div className="job-page-container">
//       <Leftbar />
//       <div className="main-content">
//         <div className="job-table-container">
//           <h2> Jobs Details</h2>
//           <button className="add-job-btn" onClick={() => setIsModalOpen(true)} >
//             Add Job
//           </button>
//           <table className="job-table"style={{marginTop:'10px'}}>
//             <thead>
//               <tr>
//                 <th>Job Name</th>
//                 <th>Job Title</th>
//                 <th>Description</th>
//                 <th>Qualifications</th>
//                 <th>Field</th>
//                 <th>Skills</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {jobs.map((job) => (
//                 <tr key={job._id}>
//                   <td>{job.jobName}</td>
//                   <td>{job.jobTitle}</td>
//                   <td>{job.jobDescription.substring(0, 50)}...</td>
//                   <td>{job.requiredQualifications.substring(0, 50)}...</td>
//                   <td>{job.otherMsg}</td>
//                   <td>{job.skills}</td>
//                   <td>
//                     <button onClick={() => handleDelete(job._id)} className="delete-btn">
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {isModalOpen && (
//           <div className="modal">
//             <div className="modal-content">
//               <span className="close" onClick={() => setIsModalOpen(false)}>
//                 &times;
//               </span>
//               <h2>Add New Job</h2>
//               {/* <form className="job-form" onSubmit={handleSubmit}>
//                 <div className="form-group">
//                   <label htmlFor="jobName">Job Name</label>
//                   <input
//                     type="text"
//                     id="jobName"
//                     name="jobName"
//                     value={formData.jobName}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="jobTitle">Job Title</label>
//                   <input
//                     type="text"
//                     id="jobTitle"
//                     name="jobTitle"
//                     value={formData.jobTitle}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="jobDescription">Job Description</label>
//                   <textarea
//                     id="jobDescription"
//                     name="jobDescription"
//                     value={formData.jobDescription}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="requiredQualifications">Required Qualifications</label>
//                   <textarea
//                     id="requiredQualifications"
//                     name="requiredQualifications"
//                     value={formData.requiredQualifications}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="otherMsg">Field of Job</label>
//                   <input
//                     type="text"
//                     id="otherMsg"
//                     name="otherMsg"
//                     value={formData.otherMsg}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <div className="form-group">
//                   <label htmlFor="skills">Skills</label>
//                   <input
//                     type="text"
//                     id="skills"
//                     name="skills"
//                     value={formData.skills}
//                     onChange={handleChange}
//                     required
//                   />
//                 </div>
//                 <button type="submit">Submit Job</button>
//               </form> */}
//               <form className="job-form" onSubmit={handleSubmit}>
//   <div className="form-row">
//     <div className="form-group">
//       <label htmlFor="jobName">Job Name</label>
//       <input
//         type="text"
//         id="jobName"
//         name="jobName"
//         value={formData.jobName}
//         onChange={handleChange}
//         required
//       />
//     </div>
//     <div className="form-group">
//       <label htmlFor="jobTitle">Job Title</label>
//       <input
//         type="text"
//         id="jobTitle"
//         name="jobTitle"
//         value={formData.jobTitle}
//         onChange={handleChange}
//         required
//       />
//     </div>
//   </div>
//   <div className="form-row">
//     <div className="form-group">
//       <label htmlFor="jobDescription">Job Description</label>
//       <textarea
//         id="jobDescription"
//         name="jobDescription"
//         value={formData.jobDescription}
//         onChange={handleChange}
//         required
//       />
//     </div>
//     <div className="form-group">
//       <label htmlFor="requiredQualifications">Required Qualifications</label>
//       <textarea
//         id="requiredQualifications"
//         name="requiredQualifications"
//         value={formData.requiredQualifications}
//         onChange={handleChange}
//         required
//       />
//     </div>
//   </div>
//   <div className="form-row">
//     <div className="form-group">
//       <label htmlFor="otherMsg">Field of Job</label>
//       <input
//         type="text"
//         id="otherMsg"
//         name="otherMsg"
//         value={formData.otherMsg}
//         onChange={handleChange}
//         required
//       />
//     </div>
//     <div className="form-group">
//       <label htmlFor="skills">Skills</label>
//       <input
//         type="text"
//         id="skills"
//         name="skills"
//         value={formData.skills}
//         onChange={handleChange}
//         required
//       />
//     </div>
//   </div>
//   <button type="submit">Submit Job</button>
// </form>

//             </div>
//           </div>
//         )}
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default Jobdescription;



// import React, { useState, useEffect } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Leftbar from "../Leftbar/Leftbar";

// const JobDescription = () => {
//   const [formData, setFormData] = useState({
//     jobName: "",
//     jobTitle: "",
//     jobDescription: "",
//     requiredQualifications: "",
//     otherMsg: "",
//     skills: "",
//   });
//   const [jobs, setJobs] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   useEffect(() => {
//     fetchJobs();
//   }, []);

//   const fetchJobs = async () => {
//     try {
//       const response = await fetch("/api/jobapplications");
//       if (response.ok) {
//         const data = await response.json();
//         setJobs(data);
//       } else {
//         toast.error("Failed to fetch job applications");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while fetching job applications");
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (Object.values(formData).some((field) => field === "")) {
//       toast.error("Please fill in all fields");
//       return;
//     }

//     try {
//       const response = await fetch("/api/jobapplications", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });

//       if (response.ok) {
//         toast.success("Job application submitted successfully");
//         setFormData({
//           jobName: "",
//           jobTitle: "",
//           jobDescription: "",
//           requiredQualifications: "",
//           otherMsg: "",
//           skills: "",
//         });
//         fetchJobs();
//         setIsModalOpen(false);
//       } else {
//         toast.error("Failed to submit job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while submitting the job application");
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       const response = await fetch(`/api/jobapplications/${id}`, {
//         method: "DELETE",
//       });

//       if (response.ok) {
//         toast.success("Job application deleted successfully");
//         fetchJobs();
//       } else {
//         toast.error("Failed to delete job application");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error("An error occurred while deleting the job application");
//     }
//   };

//   return (
//     <div className="jobDescription-container-custom">
//       <Leftbar />
//       <div className="jobDescription-content-custom">
//         <div className="jobDescription-table-container-custom">
//           <h2>Jobs Details</h2>
//           <button className="jobDescription-add-btn-custom" onClick={() => setIsModalOpen(true)}>
//             Add Job
//           </button>
//           <table className="jobDescription-table-custom" style={{ marginTop: "10px" }}>
//             <thead>
//               <tr>
//                 <th>Job Name</th>
//                 <th>Job Title</th>
//                 <th>Description</th>
//                 <th>Qualifications</th>
//                 <th>Field</th>
//                 <th>Skills</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {jobs.map((job) => (
//                 <tr key={job._id}>
//                   <td>{job.jobName}</td>
//                   <td>{job.jobTitle}</td>
//                   <td>{job.jobDescription.substring(0, 50)}...</td>
//                   <td>{job.requiredQualifications.substring(0, 50)}...</td>
//                   <td>{job.otherMsg}</td>
//                   <td>{job.skills}</td>
//                   <td>
//                     <button onClick={() => handleDelete(job._id)} className="jobDescription-delete-btn-custom">
//                       Delete
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>

//         {isModalOpen && (
//           <div className="jobDescription-modal-custom">
//             <div className="jobDescription-modal-content-custom">
//               <span className="jobDescription-close-custom" onClick={() => setIsModalOpen(false)}>
//                 &times;
//               </span>
//               <h2>Add New Job</h2>
//               <form className="jobDescription-form-custom" onSubmit={handleSubmit}>
//                 <div className="jobDescription-form-row-custom">
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="jobName"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Job Name
//                     </label>
//                     <input
//                       type="text"
//                       id="jobName"
//                       name="jobName"
//                       value={formData.jobName}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="jobTitle"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Job Title
//                     </label>
//                     <input
//                       type="text"
//                       id="jobTitle"
//                       name="jobTitle"
//                       value={formData.jobTitle}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <div className="jobDescription-form-row-custom">
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="jobDescription"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Job Description
//                     </label>
//                     <textarea
//                       id="jobDescription"
//                       name="jobDescription"
//                       value={formData.jobDescription}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="requiredQualifications"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Required Qualifications
//                     </label>
//                     <textarea
//                       id="requiredQualifications"
//                       name="requiredQualifications"
//                       value={formData.requiredQualifications}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <div className="jobDescription-form-row-custom">
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="otherMsg"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Field of Job
//                     </label>
//                     <input
//                       type="text"
//                       id="otherMsg"
//                       name="otherMsg"
//                       value={formData.otherMsg}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                   <div className="jobDescription-form-group-custom">
//                     <label
//                       htmlFor="skills"
//                       style={{ fontWeight: "bold", display: "block", marginBottom: "8px" }}
//                     >
//                       Skills
//                     </label>
//                     <input
//                       type="text"
//                       id="skills"
//                       name="skills"
//                       value={formData.skills}
//                       onChange={handleChange}
//                       required
//                       style={{
//                         padding: "10px",
//                         width: "100%",
//                         borderRadius: "5px",
//                         border: "1px solid #ced4da",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <button type="submit">Submit Job</button>
//               </form>
//             </div>
//           </div>
//         )}
//       </div>
//       <ToastContainer />
//       <style jsx>{`
//         .jobDescription-container-custom {
//           display: flex;
//           justify-content: space-between;
//           padding: 20px;
//           font-family: 'Arial', sans-serif;
//         }

//         .jobDescription-content-custom {
//           flex: 1;
//           padding: 20px;
//           background-color: #f8f9fa;
//           border-radius: 8px;
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//         }

//         .jobDescription-table-custom {
//           width: 100%;
//           border-collapse: collapse;
//         }

//         .jobDescription-table-custom th,
//         .jobDescription-table-custom td {
//           padding: 12px 15px;
//           border: 1px solid #ddd;
//           text-align: left;
//         }

//         .jobDescription-modal-custom {
//           position: fixed;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background-color: rgba(0, 0, 0, 0.5);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//         }

//         .jobDescription-modal-content-custom {
//           background-color: #fff;
//           padding: 20px;
//           border-radius: 10px;
//           width: 70%;
//           position: relative;
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//         }

//         .jobDescription-close-custom {
//           position: absolute;
//           top: 10px;
//           right: 10px;
//           font-size: 24px;
//           cursor: pointer;
//           color: #ff6b6b;
//         }

//         .jobDescription-add-btn-custom {
//           background-color: #28a745;
//           color: #ffffff;
//           padding: 10px 20px;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//           margin-bottom: 10px;
//           font-size: 16px;
//         }

//         .jobDescription-form-custom {
//           display: flex;
//           flex-wrap: wrap;
//           justify-content: space-between;
//         }

//         .jobDescription-form-row-custom {
//           display: flex;
//           width: 100%;
//         }

//         .jobDescription-form-group-custom {
//           flex: 1;
//           padding: 0 10px;
//         }

//         .jobDescription-delete-btn-custom {
//           background-color: #ff6b6b;
//           color: #ffffff;
//           padding: 8px 16px;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//           font-size: 14px;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default JobDescription;

import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Leftbar from "../Leftbar/Leftbar";

const JobDescription = () => {
  const [formData, setFormData] = useState({
    jobName: "",
    jobTitle: "",
    jobDescription: "",
    requiredQualifications: "",
    otherMsg: "",
    skills: "",
  });
  const [jobs, setJobs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await fetch("/api/jobapplications");
      if (response.ok) {
        const data = await response.json();
        setJobs(data);
      } else {
        toast.error("Failed to fetch job applications");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while fetching job applications");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((field) => field === "")) {
      toast.error("Please fill in all fields");
      return;
    }

    try {
      const response = await fetch("/api/jobapplications", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Job application submitted successfully");
        setFormData({
          jobName: "",
          jobTitle: "",
          jobDescription: "",
          requiredQualifications: "",
          otherMsg: "",
          skills: "",
        });
        fetchJobs();
        setIsModalOpen(false);
      } else {
        toast.error("Failed to submit job application");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting the job application");
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`/api/jobapplications/${id}`, {
        method: "DELETE",
      });

      if (response.ok) {
        toast.success("Job application deleted successfully");
        fetchJobs();
      } else {
        toast.error("Failed to delete job application");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while deleting the job application");
    }
  };

  return (
    <div className="jobDescription-container">
      <Leftbar />
      <div className="jobDescription-content">
        <div className="jobDescription-table-container">
          <h2>Jobs Details</h2>
          <button className="jobDescription-add-btn" onClick={() => setIsModalOpen(true)}>
            Add Job
          </button>
          <table className="jobDescription-table" style={{marginTop:'10px'}}>
            <thead>
              <tr>
                <th>Job Name</th>
                <th>Job Title</th>
                <th>Description</th>
                <th>Qualifications</th>
                <th>Field</th>
                <th>Skills</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job) => (
                <tr key={job._id}>
                  <td>{job.jobName}</td>
                  <td>{job.jobTitle}</td>
                  <td>{job.jobDescription.substring(0, 50)}...</td>
                  <td>{job.requiredQualifications.substring(0, 50)}...</td>
                  <td>{job.otherMsg}</td>
                  <td>{job.skills}</td>
                  <td>
                    <button onClick={() => handleDelete(job._id)} className="jobDescription-delete-btn">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="jobDescription-modal">
            <div className="jobDescription-modal-content">
              <span className="jobDescription-close" onClick={() => setIsModalOpen(false)}>
                &times;
              </span>
              <h2>Add New Job</h2>
              <form className="jobDescription-form" onSubmit={handleSubmit}>
                <div className="jobDescription-form-row">
                  <div className="jobDescription-form-group">
                    <label htmlFor="jobName">Job Name</label>
                    <input
                      type="text"
                      id="jobName"
                      name="jobName"
                      value={formData.jobName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="jobDescription-form-group">
                    <label htmlFor="jobTitle">Job Title</label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      value={formData.jobTitle}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="jobDescription-form-row">
                  <div className="jobDescription-form-group">
                    <label htmlFor="jobDescription">Job Description</label>
                    <textarea
                      id="jobDescription"
                      name="jobDescription"
                      value={formData.jobDescription}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="jobDescription-form-group">
                    <label htmlFor="requiredQualifications">Required Qualifications</label>
                    <textarea
                      id="requiredQualifications"
                      name="requiredQualifications"
                      value={formData.requiredQualifications}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="jobDescription-form-row">
                  <div className="jobDescription-form-group">
                    <label htmlFor="otherMsg">Field of Job</label>
                    <input
                      type="text"
                      id="otherMsg"
                      name="otherMsg"
                      value={formData.otherMsg}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="jobDescription-form-group">
                    <label htmlFor="skills">Skills</label>
                    <input
                      type="text"
                      id="skills"
                      name="skills"
                      value={formData.skills}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <button type="submit">Submit Job</button>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
      <style jsx>{`
        .jobDescription-container {
          display: flex;
          justify-content: space-between;
          padding: 20px;
          font-family: 'Arial', sans-serif;
        }

        .jobDescription-content {
          flex: 1;
          padding: 20px;
          background-color: #f8f9fa;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .jobDescription-table-container h2 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #343a40;
        }

        .jobDescription-table {
          width: 100%;
          border-collapse: collapse;
          background-color: #ffffff;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .jobDescription-table th,
        .jobDescription-table td {
          padding: 10px;
          text-align: left;
          border-bottom: 1px solid #ddd;
          font-size: 16px;
        }

        .jobDescription-table th {
          background-color: #343a40;
          color: white;
          text-transform: uppercase;
        }

        .jobDescription-table td {
          color: #495057;
        }

        .jobDescription-table tbody tr:hover {
          background-color: #f1f3f5;
          transition: background-color 0.3s;
        }

        .jobDescription-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
          overflow: auto;
          color:black;
        }

        .jobDescription-modal-content {
          background: #fff;
          padding: 20px;
          border-radius: 8px;
          width: 90%;
          max-width: 800px;
          position: relative;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          max-height: 90vh;
          overflow-y: auto;
          color:black;
        }

        .jobDescription-close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }

        .jobDescription-form {
          display: flex;
          flex-direction: column;
          gap: 15px;
          color:black;
        }

        .jobDescription-form-row {
          display: flex;
          gap: 15px;
        }

        .jobDescription-form-group {
          flex: 1;
          box-sizing: border-box;
        }

        .jobDescription-form label {
          display: block;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .jobDescription-form input,
        .jobDescription-form textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-sizing: border-box;
          color:black;
        }

        .jobDescription-form button {
          background-color: #FFB237;
          color: white;
          border: none;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          border-radius: 4px;
          transition: background-color 0.3s;
          width: 100%;
        }

        .jobDescription-form button:hover {
          background-color: #FFB237;
        }

        @media (max-width: 768px) {
          .jobDescription-modal-content {
            width: 95%;
            padding: 10px;
          }

          .jobDescription-form-row {
            flex-direction: column;
          }

          .jobDescription-form-group {
            min-width: 100%;
          }

          .jobDescription-form button {
            font-size: 14px;
            padding: 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default JobDescription;
