import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaCircleArrowRight } from "react-icons/fa6";

const Slider = () => {

    const customNavPrev = <FaLongArrowAltLeft />;
    const customNavNext = <FaCircleArrowRight />;

    
    return (
        <div>
            <section className="main-slider main-slider-one style3">
                <div className="main-slider-one__inner">
                <OwlCarousel
                        className="main-slider_carousel owl-carousel owl-theme thm-owl_carousel"
                        items={1}
                        loop
                        nav
                        // navText={[customNavPrev, customNavNext]} // Custom navigation buttons

                        smartSpeed={2000}
                        autoplay
                        autoplayTimeout={7000}
                        autoplayHoverPause={false}
                    >
                    <div className="main-slider-one__single" style={{height:"510px"}}>
                                                <div className="image-layer" style={{ backgroundImage: "url(/assets1/images/slider/slid1.jpg)" }}>
                                                </div>
                                                
                                                <div className="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                                                <div className="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                                                <div className="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                                                <div className="container">
                                                    <div className="main-slider-one__content text-center">
                                                        
                                                        <div className="title">
                                                            <h2> Mechanical innovation</h2>
                                                        </div>
                    
                                                       
                    
                                                    </div>
                    
                                                </div>
                                            </div>
                                            <div className="main-slider-one__single" style={{height:"510px"}}>
                            <div className="image-layer" style={{ backgroundImage: "url(/assets1/images/slider/slid2.jpg)" }}>
                            </div>
                            
                            <div className="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div className="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div className="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div className="container">
                                <div className="main-slider-one__content text-center">
                                    
                                    <div className="title">
                                        <h2>CIVIL AND ARCHITECT</h2>
                                    </div>

                                   

                                </div>

                            </div>
                        </div>
                        <div className="main-slider-one__single" style={{height:"510px"}}>
                            <div className="image-layer" style={{ backgroundImage: "url(/assets1/images/slider/slid.jpg)" }}>
                            </div>
                            
                            <div className="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div className="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div className="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div className="container">
                                <div className="main-slider-one__content text-center">
                                    
                                    <div className="title">
                                        <h2>ELECTRICAL</h2>
                                    </div>

                                   

                                </div>

                            </div>
                        </div>




                        <div className="main-slider-one__single">
                            <div className="image-layer" style={{ backgroundImage: "url(assets1/images/slider/cc.jpg)" }}>
                            </div>
                            {/* <div className="outer-content">
                                <div className="outer-content-bg"></div>
                                <h2>Ready to transform your business? IT Professional Development</h2>
                            </div> */}
                            <div className="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div className="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div className="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div className="container">
                                <div className="main-slider-one__content text-center">
                                    {/* <div className="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div className="title">
                                        <h2>Where precision and performance converge</h2>
                                    </div>

                                    {/* <div className="main-slider-one__content-btn">
                                        <div className="btn-one"><a className="thm-btn" href="contact.html">View Our Services</a>
                                        </div>
                                        <div className="btn-two"><a className="thm-btn" href="contact.html">Learn More</a></div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div class="main-slider-one__single">
                            <div class="image-layer" style={{ backgroundImage: "url(assets1/images/slider/c1c.jpg)" }}>
                            </div>
                            {/* <div class="outer-content">
                                <div class="outer-content-bg"></div>
                                <h2>Ready to transform your business? IT Profestional Development</h2>
                            </div> */}
                            <div class="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div class="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div class="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div class="container">
                                <div class="main-slider-one__content text-center">
                                    {/* <div class="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div class="title">
                                        <h2>Precision-driven solutions for the modern world</h2>
                                    </div>

                                    {/* <div class="main-slider-one__content-btn">
                                        <div class="btn-one"><a class="thm-btn" href="contact.html">View Our Services</a>
                                        </div>
                                        <div class="btn-two"><a class="thm-btn" href="contact.html">Learn More</a></div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div class="main-slider-one__single">
                            <div class="image-layer" style={{ backgroundImage: "url(assets1/images/slider/cncc.jpg)" }}>
                            </div>
                            {/* <div class="outer-content">
                                <div class="outer-content-bg"></div>
                                <h2>Ready to transform your business? IT Profestional Development</h2>
                            </div> */}
                            <div class="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div class="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div class="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div class="container">
                                <div class="main-slider-one__content text-center">
                                    {/* <div class="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div class="title">
                                        <h2>Empowering progress through mechanical ingenuity</h2>
                                    </div>

                                    {/* <div class="main-slider-one__content-btn">
                                        <div class="btn-one"><a class="thm-btn" href="contact.html">View Our Services</a>
                                        </div>
                                        <div class="btn-two"><a class="thm-btn" href="contact.html">Learn More</a></div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div class="main-slider-one__single">
                            <div class="image-layer" style={{ backgroundImage: "url(assets1/images/slider/ee.jpg)" }}>
                            </div>
                            {/* <div class="outer-content">
                                <div class="outer-content-bg"></div>
                                <h2>Ready to transform your business? IT Profestional Development</h2>
                            </div> */}
                            <div class="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div class="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div class="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div class="container">
                                <div class="main-slider-one__content text-center">
                                    {/* <div class="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div class="title">
                                        <h2>Elevating industries, one mechanism at a time</h2>
                                    </div>

                                    {/* <div class="main-slider-one__content-btn">
                                        <div class="btn-one"><a class="thm-btn" href="contact.html">View Our Services</a>
                                        </div>
                                        <div class="btn-two"><a class="thm-btn" href="contact.html">Learn More</a></div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div class="main-slider-one__single">
                            <div class="image-layer" style={{ backgroundImage: "url(assets1/images/slider/tt.jpg)" }}>
                            </div>
                            {/* <div class="outer-content">
                                <div class="outer-content-bg"></div>
                                <h2>Ready to transform your business? IT Profestional Development</h2>
                            </div> */}
                            <div class="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div class="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div class="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div class="container">
                                <div class="main-slider-one__content text-center">
                                    {/* <div class="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div class="title">
                                        <h2>Engineering excellence, beyond boundaries</h2>
                                    </div>

                                    {/* <div class="main-slider-one__content-btn">
                                        <div class="btn-one"><a class="thm-btn" href="contact.html">View Our Services</a>
                                        </div>
                                        <div class="btn-two"><a class="thm-btn" href="contact.html">Learn More</a></div>
                                    </div> */}

                                </div>

                            </div>
                        </div>

                        <div className="main-slider-one__single">
                            <div className="image-layer" style={{ backgroundImage: "url(/assets1/accad.jpeg)" }}>
                            </div>
                            
                            <div className="shape3"><img src="assets1/images/shapes/slider-v3-shape2.png" alt="#" /></div>
                            <div className="shape4"><img src="assets1/images/shapes/slider-v3-shape3.png" alt="#" /></div>
                            <div className="shape5"><img src="assets1/images/shapes/slider-v3-shape4.png" alt="#" /></div>
                            <div className="container">
                                <div className="main-slider-one__content text-center">
                                    {/* <div className="img-box">
                                        <img src="assets1/images/shapes/slider-v3-shape1.png" alt="#" />
                                    </div> */}
                                    <div className="title">
                                        <h2>Driving the future through mechanical innovation</h2>
                                    </div>

                                   

                                </div>

                            </div>
                        </div>


                        {/* <div className="main-slider-one__single">
                            <img src='./assets1/images/slider/sli1.png' />
                            
                            </div> */}




                      




                  







                            {/* <div className="main-slider-one__single">
                            <img src='./assets1/images/slider/sli2.png' />
                            </div>


                            <div className="main-slider-one__single">
                            <img src='./assets1/images/slider/sli3.png' />
                            </div> */}

                            

                       
                        {/* Add more slides as needed */}
                    </OwlCarousel>
                </div>
            </section>
        </div>

    );
}
export default Slider