import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Login/Login.css";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/login", { username, password });
      if (
        (username === "SttsAdmin" && password === "Stts@2024") ||
        (username === "AdminStts" && password === "Stts@2024")
      ) {
        if (response.status === 200) {
          onLogin(true);
          alert("Login successful");
          navigate("/dashboard");
        }
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {

      alert("Invalid credentials");
    }
  };

  return (
    <div className="container h-100">
      <div
        className="row h-100 justify-content-center align-items-center"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        <form className="col-md-9" onSubmit={handleSubmit}>
          <div className="AppForm shadow-lg">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center align-items-center">
                <div className="AppFormLeft">
                  <h1>Login</h1>
                  <div className="form-group position-relative mb-4">
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
                      id="username"
                      placeholder="Username"
                      style={{ color: "black" ,padding:"11px"}}
                    />
                    <i>
                      <MdDriveFileRenameOutline />
                    </i>
                  </div>
                  <div className="form-group position-relative mb-4">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control border-top-0 border-right-0 border-left-0 rounded-0 shadow-none"
                      id="password"
                      placeholder="Password"
                      style={{ color: "black" }}
                    />
                    <i>
                      <RiLockPasswordFill />
                    </i>
                  </div>
                  <div className="row mt-4 mb-4">
                    <div className="col-md-6">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="defaultCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <a href="#">Forgot Password?</a>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-success btn-block shadow border-0 py-2 text-uppercase"
                  >
                    Login
                  </button>
                  <Link to="/register" className="text-center mt-5">
                    Don't have an account? <span>Create your account</span>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="AppFormRight position-relative d-flex justify-content-center flex-column align-items-center text-center p-5 text-white">
                  <h2 className="position-relative px-4 pb-3 mb-4">Welcome</h2>
                  <p>
                    Sign in to unlock exclusive content and manage your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
