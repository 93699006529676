import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaInstagram,
  FaLinkedin,
  FaSkype,
  FaEnvelope,
} from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import './Footer.css'

function Footer() {
  return (
    <div>
      <div className="page-wrapper">
        <section class="google-map-one">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4562.753041141002!2d-118.80123790098536!3d34.152323469614075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80e82469c2162619%3A0xba03efb7998eef6d!2sCostco+Wholesale!5e0!3m2!1sbn!2sbd!4v1562518641290!5m2!1sbn!2sbd"
    class="google-map-one__map" allowfullscreen></iframe> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.8956145437137!2d86.7274486743334!3d21.938564055911677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1daf19f126161d%3A0x9749bd35ae99ea36!2sSHAW%20TECH%20TRAINING%20%26%20SERVICES!5e0!3m2!1sen!2sin!4v1707389642344!5m2!1sen!2sin"
            width="1400"
            height="350"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>

        <section class="contact-info-one">
          <div class="container">
            <div class="contact-info-one__inner">
              <div
                class="contact-info-one__bg"
                style={{
                  backgroundImage:
                    "url(assets1/images/backgrounds/contact-info-v1-bg.jpg)", marginTop:"-40px"
                }}
              ></div>
              <div class="row">
                <div class="col-xl-4 col-lg-4">
                  <div class="contact-info-one__single">
                    <div class="icon-box">
                      <FaLocationDot style={{ color: "#FFB237" }} />
                    </div>

                    <div class="content-box">
                      <h3 className="responsive_wn">
                        <b>WN 10, Golapbagh</b> <br />
                        <b>IDBI Bank Building</b> <br /> 
                        <b>Baripada-757001</b>
                      </h3>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4">
                  <div class="contact-info-one__single ml75" style={{marginTop:"13px",marginLeft:"40px"}}>
                    <div class="icon-box">
                      <FiPhoneCall style={{ color: "#FFB237" }} />
                    </div>

                    <div class="content-box responsive_wn">
                      <p><b>Get In Touch</b></p>
                      <h3>
                        <a href="tel:9692114127"><b>+91 9692114127 / 9337219166</b></a>
                      </h3>
                    </div>
                  </div>
                </div>

                {/* <div class="col-xl-4 col-lg-4">
                  <div class="contact-info-one__single ml70" style={{marginTop:"14px",marginLeft:"-9px"}}>
                    <div class="icon-box" style={{width:"60px"}}>
                      <FaEnvelope
                        className="icon-email"
                        style={{ color: "#FFB237" }}
                      />
                    </div>

                    <div class="content-box">
                      <p><b>Quick Email us</b></p>
                      <h3>
                        <a href="mailto:sttstrainings@gmail.com">
                        <b>sttstrainings@gmail.com</b>
                        </a>
                      </h3>
                    </div>
                  </div>
                </div> */}

               <div class="col-xl-4 col-lg-4">
                  <div class="contact-info-one__single ml75" style={{marginTop:"13px"}}>
                    <div class="icon-box">
                      <FaEnvelope style={{ color: "#FFB237" }} />
                    </div>

                    <div class="content-box responsive_wn">
                      <p><b>Quick Email us</b></p>
                      <h3>
                       <a href="mailto:sttstrainings@gmail.com">
                        <b>sttstrainings@gmail.com</b></a>
                      </h3>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <footer class="footer-one">
          <div
            class="footer-one__bg"
            style={{
              backgroundImage: "url(assets1/images/backgrounds/footer-bg.jpg)",
            }}
          ></div>
          <div class="shape1 float-bob-x">
            <img src="assets1/images/shapes/footer-v1-shape1.png" alt="#" />
          </div>
          <div class="shape2 float-bob-y">
            <img src="assets1/images/shapes/footer-v1-shape2.png" alt="#" />
          </div>
          <div class="shape3 float-bob-y">
            <img src="assets1/images/shapes/footer-v1-shape3.png" alt="#" />
          </div>
          <div class="shape4 float-bob-x">
            <img src="assets1/images/shapes/footer-v1-shape4.png" alt="#" />
          </div>

          <div class="footer-one__top">
            <div class="container">
              <div class="row">
                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div class="footer-one__single footer-one__about">
                    <div class="footer-one__about-logo">
                      <a href="index.html">
                        <img
                          src="/images/newlogo.jpg"
                          alt="Shaw tech"
                          style={{width:"190px", marginTop:"-40px"}}
                        />
                      </a>
                    </div>

                    <div class="footer-one__about-text">
                      <p>
                      To engage in providing skill development training and make available affordable employment-oriented and training across the country 
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div class="footer-one__single footer-one__post responsive_company" >
                    <div class="title-box">
                      <h3>company</h3>
                    </div>
                    {/* <div class="footer-one__post-box" style={{marginLeft:"-40px"}}>
                      <ul class="footer-one__post-list">
                        <li>
                          <div class="img-box">
                            <img
                              src="assets1/images/resources/footer-v1-img1.jpg"
                              alt="#"
                            />
                          </div>

                          <div class="content-box">
                            <p>
                              <span class="icon-calendar2"></span> 02 Jan 2023
                            </p>
                            <h4>
                              <a href="blog.html">
                                Benefits of Cloud-powered <br />
                                disaster recovery network
                              </a>
                            </h4>
                          </div>
                        </li>

                        <li>
                          <div class="img-box">
                            <img
                              src="assets1/images/resources/footer-v1-img2.jpg"
                              alt="#"
                            />
                          </div>

                          <div class="content-box">
                            <p>
                              <span class="icon-calendar2"></span> 05 Jan 2023
                            </p>
                            <h4>
                              <a href="blog.html">
                                UX/UI design benefits for <br />
                                businesses in 2023
                              </a>
                            </h4>
                          </div>
                        </li>
                      </ul>
                    </div> */}
                     <ul class="footer-one__links-list" style={{marginLeft:"0px"}}>
                        <li >
                          <a href="/About">About</a>
                        </li>
                        <li>
                          <a href="/Courses">Our Courses</a>
                        </li>
                        <li>
                          <a href="/">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="/">Pricing</a>
                        </li>
                       
                      </ul>

                  </div>
                </div>

                <div
                  class="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div class="footer-one__single footer-one__links" >
                    <div class="title-box">
                      <h3>Links</h3>
                    </div>
                    <div class="footer-one__links-box" style={{marginLeft:"-40px"}}>
                      <ul class="footer-one__links-list">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/About">About</a>
                        </li>
                        <li>
                          <a href="/Courses">Courses</a>
                        </li>
                        <li>
                          <a href="/Gallery">Gallery</a>
                        </li>
                        <li>
                          <a href="/Contact">Contact Us</a>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div class="footer-one__single footer-one__newsletter">
                    <div class="title-box">
                      <h3>Courses Available</h3>
                    </div>
                    <div class="footer-one__newsletter-box">
                      <div class="footer-one__newsletter-text1">
                        <p></p>
                      </div>
                      {/* <form class="footer-one__newsletter-form">
                        <div class="footer-one__newsletter-form-input-box">
                          <div class="icon">
                            <span class="icon-email2"></span>
                          </div>
                          <input
                            type="email"
                            placeholder="Email Address"
                            name="email"
                          />
                        </div>

                        
                      </form> */}

                       <ul class="footer-one__links-list" style={{marginLeft:"0px"}}>
                        <li>
                          <a href="/Courses">Mechanical</a>
                        </li>
                        <li>
                          <a href="/Courses">Civil</a>
                        </li>
                        <li>
                          <a href="/Courses">Electrical</a>
                        </li>
                        <li>
                          <a href="/Courses">Computer</a>
                        </li>
                      
                       
                      </ul>

                        <div class="footer-one__newsletter-btn" style={{width:"190px"}}>
                          <button class="thm-btn" type="submit">
                            
                            <a href="/contact">Register</a>
                          </button>
                        </div>


                      <div class="footer-one__newsletter-text2">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-one__bottom">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="footer-one__bottom-inner">
                    <div class="footer-one__bottom-left">
                      <div class="title-box">
                        {/* <p>Social </p> */}
                      </div>

                      <div class="social-links" style={{marginLeft:"-45px"}}>
                        <ul className="social-icons">
                          <li>
                            <a href="https://www.facebook.com/profile.php?id=61556382478829" target="_blank">
                              <FaFacebook />
                            </a>
                          </li>
                          
                          <li>
                            <a href="https://www.linkedin.com/in/shawtech-training-51b92b2b3" target="_blank">
                            <FaLinkedin/>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/shawtechtrainings" target="_blank">
                              <FaInstagram />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="footer-one__bottom-right">
                      <p>Designed By
                        <a href="https://www.excerptech.com/" target="_blank"><b style={{color:"white"}}> Excerpt Technologies Pvt Ltd</b></a> 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
