// ApplyJobForm.js
import React, { useState } from "react";
import "./ApplyJobForm.css";
import axios from "axios";

const ApplyJobForm = ({ isOpen, jobId, onClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [resume, setResume] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("resume", resume);
    formData.append("jobId", jobId);

    try {
      await axios.post("/apply", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Application submitted successfully");
      resetForm();
      onClose();
    } catch (error) {
      console.error("Error submitting application", error);
      alert("Failed to submit application");
    }
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setResume(null);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* <button className="close-button" onClick={onClose}>X</button> */}
        <h2>Apply for Job</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              required
              value={name}
              style={{ color: "black" }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              required
              value={email}
              style={{ color: "black" }}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone No:</label>
            <input
              type="number"
              id="phone"
              name="phone"
              className="form-control"
              required
              value={phone}
              style={{ color: "black" }}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="resume">Resume:</label>
            <input
              type="file"
              id="resume"
              name="resume"
              className="form-control"
              required
              style={{ color: "black" }}
              onChange={(e) => setResume(e.target.files[0])}
            />
          </div>
          <div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyJobForm;
