import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import {
  FaFacebook,
  FaTwitter,
  FaPinterest,
  FaInstagram,
  FaLinkedin,
  FaSkype,
  FaEnvelope,
} from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import MobileNav1 from "./Mobilenav1";
import MobileNav from "./MobileNav";
import Login from "../Pages/Login/Login";
import DisplayJobs from "./Careers/DisplayJobs";

const Topheader = ({ isLoggedIn, isAdmin, onLogout }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    onLogout();
    alert("Logged out successfully");
    navigate("/");
  };

  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header main-header-one main-header-one--three clearfix">
          <div className="main-header-one--three__top">
            <div className="container">
              <div className="main-header-one--three__top-inner">
                <MobileNav1 />
                <div className="main-header-one--three__top-left">
                  <div className="logo-three">
                    <div className="shape1" style={{ width: "290px" }}>
                      <img
                        src="/images/newlogo.jpg"
                        alt="stts"
                        style={{ height: "100px" }}
                      />
                    </div>
                  </div>

                  <div
                    className="main-header__contact-info"
                    style={{ marginLeft: "120px" }}
                  >
                    <ul>
                      <li>
                        <div className="inner" style={{ marginLeft: "0px" }}>
                          <div
                            className="icon-box"
                            style={{ fontSize: "larger" }}
                          >
                            <FaEnvelope
                              className="icon-email"
                              style={{ color: "black", fontSize: "larger" }}
                            />
                          </div>
                          <div className="text-box">
                            <p>You may send an Email</p>
                            <h4>
                              <a href="mailto: sttstrainings@gmail.com">
                                {" "}
                                sttstrainings@gmail.com
                              </a>
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner" style={{ marginLeft: "-40px" }}>
                          <div
                            className="icon-box"
                            style={{ fontSize: "20px" }}
                          >
                            <FiPhoneCall
                              style={{ color: "black", fontSize: "larger" }}
                            />
                          </div>
                          <div className="text-box">
                            <p>Get In Touch</p>
                            <h4>+91 9692114127 / 9337219166</h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="main-header-one--three__top-right">
                  <ul className="social-link">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/shawtech-training-51b92b2b3"
                        target="_blank"
                      >
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/shawtechtrainings"
                        target="_blank"
                      >
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61556382478829"
                        target="_blank"
                      >
                        <FaFacebook />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="main-header-one__bottom">
            <nav className="main-menu clearfix">
              <div className="main-menu__wrapper clearfix">
                <div className="container">
                  <div className="main-header-one__bottom-inner">
                    <div className="main-header-one__bottom-left">
                      <div className="main-menu__main-menu-box">
                        <div
                          className="mobile-nav__toggler"
                          onClick={toggleMobileMenu}
                        >
                          <FontAwesomeIcon icon={faBars} />
                        </div>

                        <ul
                          className={`main-menu__list ${
                            isMobileMenuOpen ? "show" : ""
                          }`}
                        >
                          <li className="dropdown underline-hover-effect">
                            <Link to="/">
                              <b style={{ color: "white" }}>Home</b>
                            </Link>
                          </li>
                          <li className="dropdown underline-hover-effect">
                            <Link to="/about">
                              <b style={{ color: "white" }}>About</b>
                            </Link>
                          </li>
                          <li className="dropdown underline-hover-effect">
                            <Link to="/Courses">
                              <b style={{ color: "white" }}>Courses</b>
                            </Link>
                          </li>
                          <li className="dropdown underline-hover-effect">
                            <Link to="/gallery">
                              <b style={{ color: "white" }}>Gallery</b>
                            </Link>
                          </li>
                          <li className="dropdown underline-hover-effect">
                            <Link to="/contact">
                              <b style={{ color: "white" }}>Contact</b>
                            </Link>
                          </li>
                          <li className="dropdown underline-hover-effect">
                            <Link to="/display">
                              <b style={{ color: "white" }}>Careers</b>
                            </Link>
                          </li>

                          {isAdmin && (
                            <li className="menu search-option">
                              <Link
                                to="/dashboard"
                                className="get-start-button d-flex align-items-center"
                                style={{
                                  width: "20px",
                                  marginTop: "-8px",
                                  height: "30px",
                                }}
                              >
                                <b style={{ color: "white" }}>Admin</b>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="main-header-one__bottom-right">
                      {/* {isLoggedIn ? (
                      <div className="main-header-one--three__bottom-btn" onClick={handleLogoutClick}>
                        <b style={{ color: "white" }}>Logout</b>
                      </div>
                    ) : (
                      <div className="main-header-one--three__bottom-btn">
                        <Link to="/login"><b style={{ color: "black" }}>Login</b></Link>
                      </div>
                    )} */}

                      <div className="main-header-one--three__bottom-btn">
                        <a href="/Contact">Contact us</a>
                      </div>
                      <div className="main-header-one--three__bottom-btn">
                        <Link to="/feedback">FeedBack</Link>
                      </div>

                      {isLoggedIn ? (
                        <button
                          onClick={handleLogout}
                          className="btn"
                          style={{ color: "black", backgroundColor: "#ffb237" }}
                        >
                          Logout
                        </button>
                      ) : (
                        <Link
                          to="/login"
                          className="btn"
                          style={{
                            color: "black",
                            backgroundColor: "#ffb237",
                            width:"90px",
                            padding: "11px",
                          }}
                        >
                          <h6>Login</h6>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <MobileNav
            isLoggedIn={isLoggedIn}
            isAdmin={isAdmin}
            onLogout={handleLogout}
          />
        </header>

        <div className="stricky-header stricky-header__three stricked-menu main-menu">
          <div className="sticky-header__content">
            <div className="main-menu__wrapper clearfix">
              <div className="container">
                <div className="main-header-one__bottom-inner">
                  <div className="main-header-one__bottom-left">
                    <div className="main-menu__main-menu-box">
                      <a href="#" className="mobile-nav__toggler">
                        <i className="fa fa-bars"></i>
                      </a>
                      <ul className="main-menu__list">
                        <li className="dropdown current">
                          <Link to="/">
                            <b style={{ color: "white" }}>Home</b>
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/About">
                            <b style={{ color: "white" }}>About</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Courses">
                            <b style={{ color: "white" }}>Courses</b>
                          </Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/Gallery">
                            <b style={{ color: "white" }}>Gallery</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact">
                            <b style={{ color: "white" }}>Contact</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/Careers">
                            <b style={{ color: "white" }}>Careers</b>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="main-header-one__bottom-right">
                    <div className="main-header-one--three__bottom-btn">
                      <a href="/Contact">Contact us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topheader;
