


import React, { useState } from 'react';
import '../Pages/Feedback.css'

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: '',
    registration: '',
    email: '',
    location: '',
    course: '',
    phone: '',
    review: '',
  });
  const [photo, setPhoto] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setPhoto(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    if (photo) {
      formDataToSend.append('photo', photo);
    }

    try {
      const response = await fetch('/submit-feedback', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        alert('Feedback submitted successfully!');
        // Reset form
        setFormData({
          name: '',
          registration: '',
          email: '',
          location: '',
          course: '',
          phone: '',
          review: '',
        });
        setPhoto(null);
        setImagePreview(null);
      } else {
        alert('Failed to submit feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="feedback-form">
      <div className="form-header">
        <h2>STUDENT FEEDBACK FORM</h2>
        <p>We appreciate your participation in sharing your feedback. Your valuable feedback will help us with future improvement.</p>
        {/* <a href='https://sttsonline.com/' target='_black' style={{textAlign:"center"}}>https://sttsonline.com/</a> */}
        <div className="company-logo">
            <img src="./images/newlogo.jpg" alt="stts" style={{ height: "40px" }} />
        </div>
      </div>
      <div className="form-body">
        <div className="personal-info">
          <h3>Personal Information</h3>
          <div className="input-group">
            <label htmlFor="name" className="input-label">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} required className="text-input" style={{color:"black"}}/>
          </div>
          <div className="input-group">
            <label htmlFor="registration" className="input-label">Registration No</label>
            <input type="text" id="registration" name="registration" value={formData.registration} onChange={handleInputChange} required className="text-input" style={{color:"black"}}/>
          </div>
          <div className="input-group">
            <label htmlFor="email" className="input-label">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} required className="text-input" style={{color:"black"}}/>
          </div>
          <div className="input-group">
            <label htmlFor="location" className="input-label">Location</label>
            <input type="text" id="location" name="location" value={formData.location} onChange={handleInputChange} className="text-input" style={{color:"black"}}/>
          </div>
          <div className="input-group">
            <label htmlFor="course" className="input-label">Course Enrolled</label>
            <input type="text" id="course" name="course" value={formData.course} onChange={handleInputChange} className="text-input" style={{color:"black"}}/>
          </div>
          <div className="input-group">
            <label htmlFor="phone" className="input-label">Phone</label>
            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} className="tel-input" style={{color:"black"}}/>
          </div>
        </div>
        <div className="image-upload">
          <input
            type="file"
            id="photo"
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <label htmlFor="photo" className="image-upload-label">
            {imagePreview ? (
              <img src={imagePreview} alt="Preview" className="uploaded-image" />
            ) : (
              <div className="upload-text">
                <span>Click to upload photo</span>
              </div>
            )}
          </label>
        </div>
      </div>
      <div className="review-area">
        <h3>Write your Review:</h3>
        <textarea rows="5" name="review" value={formData.review} onChange={handleInputChange} className="review-input" style={{color:"black"}}></textarea>
      </div>
      <div className="signature-area">
        <p>Student Signature</p>
      </div>
      <button type="submit" className="submit-button">Submit Feedback</button>
    </form>
  );
};

export default Feedback;