import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDashboard } from "react-icons/md";
import { PiCertificateFill } from "react-icons/pi";
import { BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { FaUserGraduate } from "react-icons/fa6";
import { IoReceiptSharp } from "react-icons/io5";
import { MdWork } from "react-icons/md";
import '../Leftbar/Leftbar.css'
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGooglesheets } from "react-icons/si";
import { IoPeople } from "react-icons/io5";

const Leftbar = () => {
    const [showStudentsDropdown, setShowStudentsDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowStudentsDropdown(prevState => !prevState);
    };

    return (
        <div id="left-sidebar" className="sidebar">
            <div className="sidebar_list">
                <div className="tab-content" id="main-menu">
                    <div className="tab-pane active" id="Home-icon">
                        <nav id="left-sidebar-nav" className="sidebar-nav sidebar-scroll">
                            <ul className="metismenu">
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/dashboard"><FaChalkboardTeacher style={{fontSize:'20px',marginRight:"10px"}}/><span>  Dashboard</span></Link></li>
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/student"><IoPeople style={{fontSize:'20px',marginRight:"10px"}}/><span>  Students</span></Link></li>
{/*                                
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/certificate"><PiCertificateFill style={{fontSize:'20px'}}/><span>  Certificate</span></Link></li> */}
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/certificateview"><PiCertificateFill style={{fontSize:'20px',marginRight:"10px"}}/><span>  Certificate</span></Link></li>
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/marksheet"><SiGooglesheets style={{fontSize:'20px',marginRight:"10px"}}/><span>  Marksheet</span></Link></li>
                                {/* <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/receipt" ><IoReceiptSharp style={{fontSize:'20px',marginRight:"10px"}}/><span style={{marginRight:"20px"}}>  Receipts</span></Link></li> */}
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/job" ><FaUserGraduate style={{fontSize:'20px',marginRight:"10px"}}/><span  style={{marginRight:"20px"}}>   Careers</span></Link></li>
                                <li className="active" style={{backgroundColor:"#FFB237",borderRadius:"3px",marginBottom:"3px"}}><Link to="/applicants"><MdWork style={{fontSize:'20px',marginRight:"10px"}}/><span>   Applicants</span></Link></li>
                            </ul>
                        </nav>
                    </div>
                    {/* Other tab panes */}
                </div>
            </div>
        </div>
    );
};

export default Leftbar;

