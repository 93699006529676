import {Component} from 'react'
import TabItem from './TabItem'
import AppItem from './AppItem'
import { FaSearch } from "react-icons/fa";
import './Appstore.css'

const tabsList = [
  {tabId: 'MECH', displayText: 'Mechanical'},
  {tabId: 'CIVI', displayText: 'Civil'},
  {tabId: 'ELEC', displayText: 'Electrical'},
  {tabId: 'COMP', displayText: 'Computer'},
  {tabId: 'ADVC', displayText: 'AdvCourses'},
  {tabId: 'PROF', displayText: 'ProfCourses'},
]

const appsList = [
  {
    appId: 0,
    appName: 'AUTOCAD',
    imageUrl:
      '/images/Autocad-Logo.png',
    backinformation:"Widely used for 2D and 3D drafting.",  
    category: 'MECH',
    link:"/Autocad"
  },
  {
    appId: 1,
    appName: 'SOLID WORK',
    imageUrl:
      '/images/soildwork.png',
    backinformation:"3D modeling software for mechanical engineering..", 
    category: 'MECH',
    link:"/Solidwork"
  },
  {
    appId: 2,
    appName: 'CATIA',
    imageUrl:
      '/images/catia.png',
    backinformation:"Comprehensive CAD/CAM/CAE suite for industries.", 
    category: 'MECH',
    link:"/Catia"
  },
  {
    appId: 3,
    appName: 'NXCAD',
    imageUrl:
      '/images/nxcad.png',
    backinformation:"Advanced CAD/CAM/CAE software by Siemens.", 
    category: 'MECH',
    link:"/Nxcad"

  },
  {
    appId: 4,
    appName: 'CREO',
    imageUrl:
      '/images/creologo.png',
    backinformation:"Parametric 3D modeling software for engineering.", 
    category: 'MECH',
    link:"/Creo"

  },
  {
    appId: 5,
    appName: 'AUTO INVENTOR',
    imageUrl:
      '/images/autoinvernter.png',
    backinformation:" Professional-grade 3D mechanical design software.", 
    category: 'MECH',
    link:"/Autoinventor"

  },
  {
    appId: 6,
    appName: 'ANSYS',
    imageUrl:
      '/images/ansys.png',
      backinformation:"Simulation software for engineering analysis tasks.",
    category: 'MECH',
    link:"/Ansys"

  },
  {
    appId: 7,
    appName: 'REVIT MEP',
    imageUrl:
      '/images/revit.png',
      backinformation:"Building design software for mechanical engineers.",

    category: 'MECH',
    link:"/Revitmep"

  },
  {
    appId: 8,
    appName: 'CNC',
    imageUrl:
      '/images/cnc.png',
      backinformation:"Automated machining control system for manufacturing.",

    category: 'MECH',
    link:"/Cnc"

  },
  {
    appId: 9,
    appName: 'ART CAM',
    imageUrl:
      '/images/artcam.png',
      backinformation:"Software for artistic design and manufacturing.",

    category: 'MECH',
    link:"/Artcam"

  },
  {
    appId: 10,
    appName: 'NX CAM',
    imageUrl:
      '/images/nxcam.png',
      backinformation:"Advanced CAM software for machining tasks.",

    category: 'MECH',
    link:"/Nxcam"

  },


 
  {
    appId: 11,
    appName: 'AUTOCAD',
    imageUrl:
      '/images/Autocad-Logo.png',
      backinformation:"Widely used for 2D and 3D drafting.",
    category: 'CIVI',
    link:"./Autocad"
  },
  {
    appId: 12,
    appName: 'REVIT ARCH',
    imageUrl:
      './images/revit.png',
      backinformation:" BIM software for architectural design projects.",

    category: 'CIVI',
    link:"./revitarch"
  },
  {
    appId: 13,
    appName: 'SKETCH UP',
    imageUrl:
      './images/sketchup.png',
      backinformation:"3D modeling software for architectural visualization.",

    category: 'CIVI',
    link:'./Sketchup'
  },
  {
    appId: 14,
    appName: 'REVIT STRU',
    imageUrl:
      './images/revitstructure.png',
      backinformation:"BIM software for structural engineering projects.",

    category: 'CIVI',
    link:"./revitstr"
  },
  {
    appId: 15,
    appName: 'V-RAYS',
    imageUrl:
      './images/vrays.png',
      backinformation:" Rendering software for high-quality visualizations.",

    category: 'CIVI',
    link:"./vrays"
  },
  {
    appId: 16,
    appName: 'LUMION',
    imageUrl:
      './images/lumion.png',
      backinformation:"Real-time 3D visualization software for architects.",

    category: 'CIVI',
    link:"./lumion"
  },
  {
    appId: 17,
    appName: 'STAAD PRO',
    imageUrl:
      './images/staadpro.png',
      backinformation:"Structural analysis and design software tool.",

    category: 'CIVI',
    link:"./staadpro"
  },
  {
    appId: 18,
    appName: 'ETAB',
    imageUrl:
      './images/etabs.png',
      backinformation:"Structural analysis and design software solution.",

    category: 'CIVI',
    link:"./etab"
  },
  {
    appId: 19,
    appName: 'CIVIL 3D',
    imageUrl:
      './images/civil3d.png',
      backinformation:"Civil engineering design and documentation software.",

    category: 'CIVI',
    link:"./civil3d"
  },
  {
    appId: 20,
    appName: 'PRIMAVERA',
    imageUrl:
      './images/primvera.png',
      backinformation:"Project management software for construction projects.",

    category: 'CIVI',
  },
  {
    appId: 21,
    appName: 'AUTOCAD',
    imageUrl:
      './images/Autocad-Logo.png',
      backinformation:"Widely used for 2D and 3D drafting.",

    category: 'ELEC',
  },
  {
    appId: 22,
    appName: 'AUTO ELECTRIC',
    imageUrl:
      './images/autoelectric.png',
      backinformation:" Software for automotive electrical systems.",

    category: 'ELEC',
  },
  {
    appId: 23,
    appName: 'EPLAN',
    imageUrl:
      './images/elpan.png',
      backinformation:" Electrical engineering software for circuit design.",

    category: 'ELEC',
  },
  {
    appId: 24,
    appName: 'MATLAB',
    imageUrl:
      './images/matlab.png',
      backinformation:"High-level programming language and numerical computing.",

    category: 'ELEC',
  },
  {
    appId: 25,
    appName: 'PLC',
    imageUrl:
      './images/plc.png',
      backinformation:"Programmable Logic Controller for industrial automation.",

    category: 'ELEC',
  },
  {
    appId: 26,
    appName: 'SCADA',
    imageUrl:
      './images/scada.png',
      backinformation:"Supervisory Control and Data Acquisition system.",

    category: 'ELEC',
  },
  {
    appId: 27,
    appName: 'C/C++',
    imageUrl:
      './images/c.png',
      backinformation:"Programming languages for software development.",

    category: 'COMP',
  },
  {
    appId: 28,
    appName: 'MS-OFFICE',
    imageUrl:
      './images/msoffice.png',
      backinformation:"Suite of productivity software by Microsoft.",

    category: 'COMP',
  },
  {
    appId: 29,
    appName: 'TALLY',
    imageUrl:
      './images/tally.png',
      backinformation:"Accounting software for businesses and individuals.",

    category: 'COMP',
  },
  {
    appId: 30,
    appName: 'PHOTOSHOP',
    imageUrl:
      './images/photoshop.png',
      backinformation:"Image editing software for graphic designers.",

    category: 'COMP',
  },
  {
    appId: 31,
    appName: 'DCA',
    imageUrl:
      './images/dca.png',
      backinformation:"Diploma in Computer Applications course.",

    category: 'COMP',
  },
  {
    appId: 32,
    appName: 'PGDCA',
    imageUrl:
      './images/pgdcad.png',
      backinformation:" Post Graduate Diploma in Computer Applications",

    category: 'COMP',
  },
  {
    appId: 33,
    appName: 'ADVANCE PGDCA',
    imageUrl:
      './images/pgdcad.png',
      backinformation:"Advanced Post Graduate Diploma in Computer Applications.",

    category: 'COMP',
  },
 

  {
    appId: 34,
    appName: '  JAVA',
    imageUrl:
    './images/java.png',
      backinformation:"Development covering both frontend and backend.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/corejava",
    my:"/courses"
  },
  {
    appId: 35,
    appName: 'PYTHON',
    imageUrl:
    './images/python.png',
      backinformation:"Development covering both frontend and backend.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/python",
    my:"/courses"
  },
  {
    appId: 36,
    appName: 'MERN FULLSTACK',
    imageUrl:
      './images/fullstack.png',
      backinformation:"Development covering both frontend and backend.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/mernstack",
    my:"/courses"
  },
  {
    appId: 37,
    appName: 'CYBER-SECURITY',
    imageUrl:
      ' ./images/cibi.jpeg',
      backinformation:"A legally break into computers and networks to test and oraganization security.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/ethicalhacking",
    my:"/courses"
  },
 
  {
    appId: 38,
    appName: 'DIGITAL-MARKETING',
    imageUrl:
      ' ./images/digi.jpeg',
      backinformation:"The most powerfull tool to grow business research.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/digitalmarketing",
    my:"/courses"
  },
  



  {
    appId: 39,
    appName: 'Datascience with python',
    imageUrl:
      './images/DS-with-Python.jpg',
      backinformation:"Data science with Python performs data analysis, data visualization.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/python",
    my:"/courses"
  },
  {
    appId: 40,
    appName: '.Net',
    imageUrl:
      './images/net.jpg',
      backinformation:"Most powerful, secure, and flexible end-to-end analytics platform for data",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/.net",
    my:"/courses"
  },
  {
    appId: 41,
    appName: 'Fullstack Developer',
    imageUrl:
      './images/full-stack.jpg',
      backinformation:"a professional responsible for working on both front-end and back-end development processes.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/fullstack",
    my:"/courses"
  },
  {
    appId: 42,
    appName: 'Andriod',
    imageUrl:
      './images/andriod.jpg',
      backinformation:"a professional responsible for working on both front-end and back-end development processes.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/android",
    my:"/courses"
  },
  {
    appId: 43,
    appName: 'PHP',
    imageUrl:
      './images/php.png',
      backinformation:"a professional responsible for working on both front-end and back-end development processes.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/php",
    my:"/courses"
  },
 

  {
    appId: 44,
    appName: 'Selenium',
    imageUrl:
    './images/selenium.jpg',
      backinformation:"a professional responsible for working on both front-end and back-end development processes.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/selenium",
    my:"/courses"
  },
  {
    appId: 45,
    appName: 'AWS',
    imageUrl:
    './images/aws.jpg',
      backinformation:"a professional responsible for working on both front-end and back-end development processes.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/aws",
    my:"/courses"
  },
  {
    appId: 46,
    appName: 'Mysql',
    imageUrl:
      './images/mysql.jpg',
      backinformation:"fully managed database service for transactions, real- time analytics across data warehouses.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/mysql",
    my:"/courses"
  },
  {
    appId: 47,
    appName: 'Sap',
    imageUrl:
      ' ./images/sap.jpg',
      backinformation:"fully managed database service for transactions, real- time analytics across data warehouses.",

    category: 'ADVC',
    link:"https://itdeskmahadevpura.com/sap",
    my:"/courses"
  },
  
  {
    appId: 48,
    appName: 'GD&T',
    imageUrl:
      '/images/gdt.png',
      backinformation:" GD&T is a system that defines and communicates tolerances on the physical geometry of parts.",

    category: 'MECH',
    link:"/GDT"

  },
  {
    appId: 49,
    appName: '3DS MAX',
    imageUrl:
      './images/3D.png',
      backinformation:"3DS MAX is for creating 3D models, visualizations, and animations of architectural designs and structures.",

    category: 'CIVI',
  },
  {
    appId: 50,
    appName: 'QTO',
    imageUrl:
      './images/qto.jpg',
      backinformation:"QTO for a construction project based on project drawings and specifications.",

    category: 'CIVI',
  },
  
  {
    appId: 51,
    appName: 'Product Design & Manufacturing',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and Solidworks / CATIA / CREO ,CNC and GD&T",

    category: 'MECH',
  },
  {
    appId: 52,
    appName: 'Product Design & Analysis',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and Solidworks / CATIA / CREO , Ansys Workbench",

    category: 'MECH',
  },

  {
    appId: 53,
    appName: 'Automotive Design',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and Solidworks / CATIA /",

    category: 'MECH',
  },
  {
    appId: 54,
    appName: 'Manufacturing',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD,CNC and GD&T",

    category: 'MECH',
  },
  {
    appId: 55,
    appName: 'HVAC Design',
    imageUrl:
      './images/Dip.png',
      backinformation:"Auto CAD and Revit MEP",

    category: 'MECH',
  },

  {
    appId: 56,
    appName: 'Building Design',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and Revit Arch / Sketch up and  vray / Lumion",

    category: 'CIVI',
  },

  {
    appId: 57,
    appName: 'Structural Design',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and STAAD and Etab.",

    category: 'CIVI',
  },

  {
    appId: 58,
    appName: 'Building design and Estimate',
    imageUrl:
      './images/prof.png',
      backinformation:"Auto CAD and  Revit ARCH and QTO.",

    category: 'CIVI',
  },
  {
    appId: 58,
    appName: 'Highway & Infrastructure design',
    imageUrl:
      './images/Dip.png',
      backinformation:"Auto CAD and civil 3D.",

    category: 'CIVI',
  },

  {
    appId: 59,
    appName: 'BIM technology',
    imageUrl:
      './images/mass.png',
      backinformation:"Auto CAD and REVIT ARCH and REVIT STRUCTURE and STAAD.",

    category: 'CIVI',
  },

  {
    appId: 60,
    appName: 'Interior Design',
    imageUrl:
      './images/mass.png',
      backinformation:"Auto CAD and REVIT ARCH and 3DS MAX and VRAY",

    category: 'CIVI',
  },
  {
    appId: 61,
    appName: 'Control System Design',
    imageUrl:
      './images/mass.png',
      backinformation:"Auto CAD ELECTRICAL and Eplan",

    category: 'ELEC',
  },

  {
    appId: 62,
    appName: 'Control System Design',
    imageUrl:
      './images/mass.png',
      backinformation:"Auto CAD ELECTRICAL and Eplan",

    category: 'PROF',
  },



  {
    appId: 63,
    appName: 'Sap',
    imageUrl:
      ' ./images/sap.jpg',
      backinformation:"fully managed database service for transactions, real- time analytics across data warehouses.",

    category: 'PROF',
    profcontent: [
      {
        title: "CIVIL & ARCHITECT",
        courses: [
          {
            title: "Professional in Building Design",
            skills: ["Auto CAD", "REVIT ARCH/ Sketch Up", "VRAY / Lumion"]
          },
          {
            title: "Professional in Structural Design",
            skills: ["Auto CAD", "STAAD", "ETab"]
          },
          {
            title: "Master in Interior Design",
            skills: ["Auto CAD", "REVIT ARCH", "3DS MAX", "VRAY"]
          },
          {
            title: "Master in BIM Technology",
            skills: ["Auto CAD", "REVIT ARCH", "REVIT Structure", "STAAD"]
          },
          {
            title: "Professional in Building Design and Estimate",
            skills: ["Auto CAD", "REVIT ARCH", "QTO"]
          },
          {
            title: "Diploma in Highway & Infrastructure Design",
            skills: ["Auto CAD", "Civil 3D"]
          }
        ]
      },
    ]
  },

 
 

  {
    appId: 64,
    appName: 'asp',
    imageUrl:
      ' ./images/asp.jpg',
      backinformation:"fully managed database service for transactions, real- time analytics across data warehouses.",

    category: 'PROF',
    profcontent: [
      {
        title: "ELECTRICAL",
        courses: [
          {
            title: "Control System Design",
            skills: ["Auto CAD ELECTRICAL", "EPlan"]
          }
        ]
      },
      
     
    ]
  },
  

  {
    appId: 65,
    appName: 'sp',
    imageUrl:
      ' ./images/sp.jpg',
      backinformation:"fully managed database service for transactions, real- time analytics across data warehouses.",

    category: 'PROF',
    profcontent: [
     
      
      {
        title: "MECHANICAL",
        courses: [
          {
            title: "Professional in Product Design & Manufacturing",
            skills: ["Auto CAD", "Solidworks / CATIA / CREO", "CNC", "GD&T"]
          },
          {
            title: "Professional in Product Design & Analysis",
            skills: ["Auto CAD", "Solidworks / CATIA / CREO", "Ansys Workbench"]
          },
          {
            title: "Professional in Automotive Design",
            skills: ["Auto CAD", "Solidworks", "CATIA"]
          },
          {
            title: "Professional in Manufacturing",
            skills: ["Auto CAD", "CNC", "GD&T"]
          },
          {
            title: "Diploma in HVAC Design",
            skills: ["Auto CAD", "REVIT MEP"]
          }
        ]
      }
    ]
  },

]

// Write your code here
class AppStore extends Component {
  // state = { activeTabId: tabsList[0].tabId, searchInput: '' };
  constructor(props){
    super(props);
    this.state={activeTabId:"",
    searchInput:"",
  }
  }

  componentDidMount(){
    const {hash} = window.location
    const tabId = hash ? hash.substring(1):tabsList[0].tabId
    this.setState({activeTabId:tabId})
  }

  getFilteredProjects = () => {
    const { activeTabId } = this.state;
    const filterdata = appsList.filter(
      (eachdetails) => eachdetails.category === activeTabId
    );
    return filterdata;
  };

  onchangestatus = (tabId) => {
    this.setState({ activeTabId: tabId });
  };

  getinputdetails = (event) => {
    const lowercaseSearchInput = event.target.value.toLowerCase();
    this.setState({ searchInput: lowercaseSearchInput });
  };

  render() {
    const { activeTabId, searchInput } = this.state;
    const fliterdataproject = this.getFilteredProjects();
    const searchresult = fliterdataproject.filter((eachfilterdata) =>
      eachfilterdata.appName.toLowerCase().includes(searchInput)
    )
    // const filterdata = appsList.filter((eachdetails)=>(eachdetails.category === activeTabId))
    return (
      <div className="appcontainer">
        <div className="cardcontainer">
          <div className="upcontainer">
            {/* <h1 className="heading" style={{marginBottom: "20px",fontSize:"50px"}}>Courses</h1> */}
                          <div class="sec-title">
                              <div class="sec-title__tagline">
                                <div class="inner">
                                  <div class="round-box">
                                    <img
                                      decoding="async"
                                      src="https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/sec-title-shape.png"
                                      alt="alt"
                                      title="sec-title-shape"
                                    />{" "}
                                  </div>
                                  <div class="text">
                                    <p class="sec-title__sub-title" style={{fontSize:"17px"}}>Courses</p>
                                  </div>
                                </div>
                              </div>
                              
                            </div>

            
              {/* <input
                type="search"
                className="search-input"
                placeholder="Search"
                onChange={this.getinputdetails}
                style={{color:"black",boxshadow: "4px 4px 4px",border: "1px solid"}}
              /> */}
             
              <div style={{display:"flex",alignItems:"center",border:"5px black solid",borderRadius:"50px"}}>
{/* 
              <input onChange={this.getinputdetails} type='search' style={{color:"#d3d8e0"}} placeholder='Search'className="search-input-container"/>
               <img src="https://assets.ccbp.in/frontend/react-js/app-store/app-store-search-img./images/Autocad-Logo.png" alt='search' className='search_icon'/> */}
               <input onChange={this.getinputdetails} type='search' style={{color:"black",borderRadius:"50px"}} placeholder='Search'className="search-input-container"/>
              
              <FaSearch className='search_icon'/>
               </div>
            
          </div>

          <ul className="tabitemcontainer">
            {tabsList.map(each => (
              <TabItem
                tabname={each}
                key={each.tabId}
                onchangestatus={this.onchangestatus}
                isActive={activeTabId === each.tabId}
              />
            ))}
          </ul>

          <ul className="appcontainer">
            {searchresult.map(each => (
              <AppItem Appitem={each} key={each.appName} />
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default AppStore
