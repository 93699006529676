

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5); // Number of students to display per page
//   const [editStudent, setEditStudent] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [style, setStyle] = useState({});
//   const [sortOrder, setSortOrder] = useState("asc"); // Sorting order for RegNo
//   const [nameSortOrder, setNameSortOrder] = useState("asc"); // Sorting order for student name
//   const [searchQuery, setSearchQuery] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(Array.isArray(response.data) ? response.data : []);
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//         setStudents([]); // Ensure students is an empty array on error
//       }
//     };

//     fetchStudents();
//   }, []);

//   useEffect(() => {
//     const updateStyles = () => {
//       if (window.innerWidth < 768) {
//         // Mobile view
//         setStyle({
//           marginLeft: "10px",
//           marginTop: "20px",
//           cardWidth: "100%", // Adjust card width for mobile view
//           tableFontSize: "14px", // Adjust table font size for mobile view
//         });
//       } else {
//         // Desktop view
//         setStyle({
//           marginLeft: "300px",
//           marginTop: "-400px",
//           cardWidth: "20%", // Adjust card width for desktop view
//           tableFontSize: "16px", // Adjust table font size for desktop view
//         });
//       }
//     };

//     updateStyles();
//     window.addEventListener("resize", updateStyles);
//     return () => window.removeEventListener("resize", updateStyles);
//   }, []);

//   const handleEditClick = (student) => {
//     setEditStudent(student);
//     setModalIsOpen(true);
//   };

//   const handleEditFormChange = (e) => {
//     setEditStudent({
//       ...editStudent,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`/api/students/${editStudent._id}`, editStudent);
//       // Optionally refetch students
//       const response = await axios.get("api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };

//   const handleDeleteClick = async (studentId) => {
//     try {
//       await axios.delete(`/api/students/${studentId}`);
//       // Optionally refetch students
//       const response = await axios.get("api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//     } catch (error) {
//       console.error("Error deleting student data:", error);
//     }
//   };

//   const handleSortByRegNo = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (sortOrder === "asc") {
//         return a.RegNo.localeCompare(b.RegNo);
//       } else {
//         return b.RegNo.localeCompare(a.RegNo);
//       }
//     });
//     setStudents(sortedStudents);
//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//   };

//   const handleSortByName = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (nameSortOrder === "asc") {
//         return a.name.localeCompare(b.name);
//       } else {
//         return b.name.localeCompare(a.name);
//       }
//     });
//     setStudents(sortedStudents);
//     setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
//   };

//   // Filter students based on the search query
//   const filteredStudents = students.filter(
//     (student) =>
//       // student.RegNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       student.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   // Get current students
//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = filteredStudents.slice(
//     indexOfFirstStudent,
//     indexOfLastStudent
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const paginationStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "10px",
//     marginTop: "20px",
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//   };
//   const courseIdentifiers = {
//     DCA: "DCA",
//     "Advance PGDCA": "APG",
//     PGDCA: "PG",
//     MECHANICAL: "MCAD",
//     ELECTRICAL: "ECAD",
//     "CIVIL & ARCH": "ACAD",
//     "ANY IT": "IT",
//     TALLY: "TY",
//     PHOTOSHOP: "PS",
//     "MS OFFICE": "MSO",
//     "ADVANCE EXCEL": "AE",
//     "BASIC COMPUTER": "BC",
//   };
  
 
//     const [studentCount, setStudentCount] = useState(0);
//     const [formData, setFormData] = useState({});
//     // Fetch student count on mount
//     useEffect(() => {
//       const fetchStudentCount = async () => {
//         try {
//           const response = await axios.get("/api/student-count");
//           setStudentCount(response.data.count);
//         } catch (error) {
//           console.error("Error fetching student count:", error);
//         }
//       };
  
//       fetchStudentCount();
//     }, []);
  
//     // Generate registration number if not already present
//     useEffect(() => {
//       if (editStudent && !editStudent.RegNo && formData.course) {
//         const courseIdentifier = courseIdentifiers[formData.course] || "XXX";
//         const incrementedNumber = studentCount + 300; // Assuming `studentCount` starts from 0 and increments
  
//         setFormData((prevData) => ({
//           ...prevData,
//           RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//         }));
//       }
//     }, [editStudent, formData.course, studentCount, setFormData]);
  
//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="row clearfix">
//               <div className="col-lg-12"></div>
//             </div>
//             <div className="row clearfix">
//               <div
//                 className="col-lg-3 col-md-6"
//                 style={{ width: style.cardWidth }}
//               >
//                 <Link to="/stud" className="card">
//                   <div className="body top_counter">
//                     <div className="content">
//                       <div className="icon bg-warning text-white">
//                         <PiStudentBold style={{ fontSize: "35px" }} />
//                         <span style={{ marginLeft: "30px", fontSize: "20px" }}>
//                           Add Student
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//               <div className="col-lg-9 col-md-6" style={{ width: "",  marginLeft:"60%"}}>
//                 <div
//                   className="search-bar"
//                   style={{ marginBottom: "20px", display: "flex" ,marginTop:"-40px"}}
//                 >
//                   <input
//                     type="text"
//                     placeholder="Search by Reg.No or Name"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     style={{
//                       padding: "10px",
//                       fontSize: "16px",
//                       border: "1px solid black",
//                       borderRadius: "5px",
//                       marginRight: "10px",
//                       color: "black",
//                       // marginLeft: "500px",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row clearfix">
//             <div className="col-12 col-sm-12">
//               <div className="">
//                 <div className="header"></div>
//                 <div
//                   className="table-responsive"
//                   style={{ marginBottom: "30px" }}
//                 >
//                   <table
//                     className="table table-custom spacing5 mb-0"
//                     style={{ fontSize: style.tableFontSize }}
//                   >
//                     <thead>
//                       <tr>
//                         <th>
//                           <strong
//                             onClick={handleSortByRegNo}
//                             style={{ cursor: "pointer" }}
//                           >
//                             Reg.No {sortOrder === "asc" ? "▲" : "▼"}
//                           </strong>
//                         </th>
//                         <th>
//                           <strong
//                             onClick={handleSortByName}
//                             style={{ cursor: "pointer" }}
//                           >
//                             Student Name {nameSortOrder === "asc" ? "▲" : "▼"}
//                           </strong>
//                         </th>
//                         <th>
//                           <strong>Email</strong>
//                         </th>
//                         <th>
//                           <strong>Mobile No</strong>
//                         </th>
//                         <th>
//                           <strong>Course Name</strong>
//                         </th>
//                         <th>
//                           <strong>Software Covered</strong>
//                         </th>
//                         <th>
//                           <strong>RegDate</strong>
//                         </th>
//                         <th>
//                           <strong>Fee Status</strong>
//                         </th>
//                         <th>
//                           <strong>Action</strong>
//                         </th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Array.isArray(currentStudents) &&
//                       currentStudents.length > 0 ? (
//                         currentStudents.map((student, index) => (
//                           <tr key={index}>
//                             <td>
//                               <Link
//                                 to={`/detailes/${encodeURIComponent(
//                                   student.RegNo
//                                 )}/${student._id}`}
//                               >
//                                 {student.RegNo}
//                               </Link>
//                             </td>
//                             <td>{student.name}</td>
//                             <td>{student.email}</td>
//                             <td>{student.mobileNo}</td>
//                             <td>{student.course}</td>
//                             <td>{student.softwareCovered}</td>
//                             <td>
//                               {new Date(student.Date).toLocaleDateString()}
//                             </td>
//                             <td>
//                               <button
//                                 style={{
//                                   backgroundColor:
//                                     Number(student.currentDue) === 0
//                                       ? "green"
//                                       : "red",
//                                   color: "white",
//                                   padding: "5px 10px",
//                                   border: "none",
//                                   borderRadius: "5px",
//                                 }}
//                               >
//                                 {Number(student.currentDue) === 0
//                                   ? "Paid"
//                                   : "Due"}
//                               </button>
//                             </td>
//                             <td>
//                               <div style={{ display: "flex", gap: "10px" }}>
//                                 <button
//                                   onClick={() => handleEditClick(student)}
//                                 >
//                                   <FaFilePen
//                                     style={{
//                                       fontSize: "18px",
//                                       marginTop: "8px",
//                                     }}
//                                   />
//                                 </button>
//                                 <button
//                                   onClick={() => handleDeleteClick(student._id)}
//                                   style={{
//                                     color: "red",
//                                     display: "flex",
//                                     alignItems: "center",
//                                   }}
//                                 >
//                                   <FaTrashAlt
//                                     style={{
//                                       marginRight: "5px",
//                                       color: "white",
//                                     }}
//                                   />
//                                 </button>
//                                 <Link
//                                   to={`/detailes/${encodeURIComponent(
//                                     student.RegNo
//                                   )}/${student._id}`}
//                                 >
//                                   <button
//                                     style={{
//                                       border: "none",
//                                       backgroundColor: "#ffb237",
//                                       color: "#fff",
//                                       padding: "0.5rem 1rem",
//                                       borderRadius: "0.25rem",
//                                       cursor: "pointer",
//                                       display: "flex",
//                                       alignItems: "center",
//                                     }}
//                                   >
//                                     <BiSolidReceipt
//                                       style={{ marginRight: "0.5rem" }}
//                                     />
//                                     Receipt
//                                   </button>
//                                 </Link>
//                               </div>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan="9" style={{ textAlign: "center" }}>
//                             No Students Found
//                           </td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div style={paginationStyle}>
//                   {currentPage > 1 && (
//                     <button
//                       onClick={() => paginate(currentPage - 1)}
//                       style={buttonStyle}
//                     >
//                       Previous
//                     </button>
//                   )}
//                   {Array.from(
//                     {
//                       length: Math.ceil(
//                         filteredStudents.length / studentsPerPage
//                       ),
//                     },
//                     (_, index) => (
//                       <button
//                         key={index + 1}
//                         onClick={() => paginate(index + 1)}
//                         style={{
//                           ...buttonStyle,
//                           backgroundColor:
//                             currentPage === index + 1 ? "#ddd" : "#fff",
//                         }}
//                       >
//                         {index + 1}
//                       </button>
//                     )
//                   )}
//                   {currentPage <
//                     Math.ceil(filteredStudents.length / studentsPerPage) && (
//                     <button
//                       onClick={() => paginate(currentPage + 1)}
//                       style={buttonStyle}
//                     >
//                       Next
//                     </button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {editStudent && (
       

//     <Modal
//       isOpen={modalIsOpen}
//       onRequestClose={() => setModalIsOpen(false)}
//       contentLabel="Edit Student"
//       style={{
//         content: {
//           position: "fixed",
//           top: "50%",
//           left: "50%",
//           right: "auto",
//           bottom: "auto",
//           marginRight: "-50%",
//           transform: "translate(-50%, -50%)",
//           width: "90%",
//           maxWidth: "1000px",
//           maxHeight: "90vh",
//           padding: "20px",
//           backgroundColor: "#f7f7f7",
//           border: "1px solid #ddd",
//           borderRadius: "8px",
//           boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//           overflowY: "auto",
//           overflowX: "hidden",
//           zIndex: 1050,
//         },
//         overlay: {
//           backgroundColor: "rgba(0, 0, 0, 0.5)",
//           zIndex: 1040,
//         },
//       }}
//     >
//       <button
//         onClick={() => setModalIsOpen(false)}
//         style={{
//           position: "absolute",
//           top: "10px",
//           right: "10px",
//           backgroundColor: "transparent",
//           border: "none",
//           fontSize: "24px",
//           cursor: "pointer",
//           color: "#333",
//         }}
//         aria-label="Close Modal"
//       >
//         &times;
//       </button>
//       <h2 style={{ marginBottom: "20px", textAlign: "center", color: "#333" }}>
//         Edit Student
//       </h2>
//       <form
//         onSubmit={handleEditFormSubmit}
//         style={{
//           display: "grid",
//           gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
//           gap: "10px",
//           marginBottom: "20px",
//         }}
//       >
//         {[
//           { id: 'name', label: 'Name:', type: 'text' },
//           { id: 'email', label: 'Email:', type: 'email' },
//           { id: 'fatherName', label: 'Father\'s Name:', type: 'text' },
//           { id: 'gender', label: 'Gender:', type: 'select', options: ['Select Gender', 'Male', 'Female'] },
//           { id: 'mobileNo', label: 'Mobile No:', type: 'text' },
//           { id: 'Address', label: 'Address:', type: 'text' },
//           { id: 'collegeName', label: 'College Name:', type: 'text' },
//           { id: 'course', label: 'Course:', type: 'select', options: ['Select Course', 'DCA', 'Advance PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'ANY IT', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER'] },
//           { id: 'RegNo', label: 'Registration No:', type: 'text' },
//           { id: 'centreName', label: 'Centre Name:', type: 'text' },
//           { id: 'courseDuration', label: 'Course Duration:', type: 'text' },
//           { id: 'courseFee', label: 'Course Fee:', type: 'number' },
//           { id: 'amountReceived', label: 'Amount Received:', type: 'number' },
//           { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true },
//           { id: 'Date', label: 'Date:', type: 'date' },
//           { id: 'softwareCovered', label: 'Software Covered:', type: 'text' },
//           { id: 'PaymentBy', label: 'Payment By:', type: 'text' },
//           { id: 'receiptId', label: 'Receipt ID:', type: 'text', readOnly: true }
//         ].map(({ id, label, type, options, readOnly }) => (
//           <div key={id} className="input-container" style={{ marginBottom: "10px" }}>
//             <label htmlFor={id} style={{ marginBottom: "5px", display: "block" }}>{label}</label>
//             {type === 'select' ? (
//               <select
//                 id={id}
//                 value={editStudent?.[id] || ""}
//                 onChange={handleEditFormChange}
//                 required
//                 style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//               >
//                 {options.map((option, index) => (
//                   <option key={index} value={option === 'Select Gender' || option === 'Select Course' ? '' : option}>
//                     {option}
//                   </option>
//                 ))}
//               </select>
//             ) : (
//               <input
//                 type={type}
//                 id={id}
//                 value={editStudent?.[id] || ""}
//                 onChange={handleEditFormChange}
//                 readOnly={readOnly}
//                 required={!readOnly}
//                 style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//               />
//             )}
//           </div>
//         ))}
//         <div
//           style={{
//             gridColumn: "span 3",
//             display: "flex",
//             justifyContent: "center",
//             gap: "10px",
//             marginTop: "20px",
//           }}
//         >
//           <button
//             type="submit"
//             style={{
//               backgroundColor: "#4CAF50",
//               color: "white",
//               border: "none",
//               padding: "10px 20px",
//               textAlign: "center",
//               textDecoration: "none",
//               display: "inline-block",
//               fontSize: "16px",
//               cursor: "pointer",
//               borderRadius: "4px",
//             }}
//           >
//             Submit
//           </button>
//           <button
//             type="button"
//             onClick={() => setModalIsOpen(false)}
//             style={{
//               backgroundColor: "#f44336",
//               color: "white",
//               border: "none",
//               padding: "10px 20px",
//               textAlign: "center",
//               textDecoration: "none",
//               display: "inline-block",
//               fontSize: "16px",
//               cursor: "pointer",
//               borderRadius: "4px",
//             }}
//           >
//             Close
//           </button>
//         </div>
//       </form>
//     </Modal>
//       )}
//     </>
//   );
// }

// export default Studentview;
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// // Ensure Modal's styles are applied correctly
// Modal.setAppElement('#root'); // Make sure to set the app element for accessibility

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [editStudent, setEditStudent] = useState(null);
//   const [formData, setFormData] = useState({});
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [studentCount, setStudentCount] = useState(0);

//   const courseIdentifiers = {
//     DCA: "DCA",
//     "Advance PGDCA": "ADVPGDCA",
//     PGDCA: "PGDCA",
//     MECHANICAL: "MECH",
//     ELECTRICAL: "ELEC",
//     CIVIL: "CIVIL",
//     ARCH: "ARCH",
//     "ANY IT": "IT",
//     TALLY: "TALLY",
//     PHOTOSHOP: "PHOTOSHOP",
//     "MS OFFICE": "MSOFFICE",
//     "ADVANCE EXCEL": "ADVEXCEL",
//     "BASIC COMPUTER": "BASIC",
//   };

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(response.data);
//       } catch (error) {
//         console.error("Error fetching students:", error);
//       }
//     };

//     const fetchStudentCount = async () => {
//       try {
//         const response = await axios.get("/api/student-count");
//         setStudentCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching student count:", error);
//       }
//     };

//     fetchStudents();
//     fetchStudentCount();
//   }, []);

//   useEffect(() => {
//     if (editStudent) {
//       const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
//       const incrementedNumber = studentCount + 300;
//       setFormData({
//         ...editStudent,
//         RegNo: editStudent.RegNo || `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//       });
//     }
//   }, [editStudent, studentCount]);

//   const handleEditClick = (student) => {
//     const courseIdentifier = courseIdentifiers[student.course] || "XXX";
//     const incrementedNumber = studentCount + 300;
//     const regNo = student.RegNo || `STTS/${courseIdentifier}/24-${incrementedNumber}`;

//     setEditStudent(student);
//     setFormData({
//       ...student,
//       RegNo: regNo, // Generate RegNo if not present
//     });
//     setModalIsOpen(true);
//   };

//   const handleEditFormChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`/api/students/${editStudent._id}`, formData);
//       const response = await axios.get("/api/students");
//       setStudents(response.data);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: "250px", marginTop: "20px" }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="student-list">
//               <table>
//                 <thead>
//                   <tr>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Course</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {students.map((student) => (
//                     <tr key={student._id}>
//                       <td>{student.name}</td>
//                       <td>{student.email}</td>
//                       <td>{student.course}</td>
//                       <td>
//                         <button onClick={() => handleEditClick(student)}>
//                           <FaFilePen />
//                         </button>
//                         <button>
//                           <BiSolidReceipt />
//                         </button>
//                         <button>
//                           <FaTrashAlt />
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>

//       <Modal
//         isOpen={modalIsOpen}
//         onRequestClose={() => setModalIsOpen(false)}
//         contentLabel="Edit Student"
//         style={{
//           content: {
//             position: "relative",
//             top: "10%",
//             left: "15%",
//             right: "15%",
//             bottom: "auto",
//             margin: "auto",
//             padding: "20px",
//             maxWidth: "600px",
//             width: "100%",
//             maxHeight: "80vh",
//             overflowY: "auto",
//           },
//           overlay: {
//             backgroundColor: "rgba(0, 0, 0, 0.5)",
//           },
//         }}
//       >
//         <button
//           onClick={() => setModalIsOpen(false)}
//           style={{
//             position: "absolute",
//             top: "10px",
//             right: "10px",
//             backgroundColor: "transparent",
//             border: "none",
//             fontSize: "24px",
//             cursor: "pointer",
//             color: "#333",
//           }}
//           aria-label="Close Modal"
//         >
//           &times;
//         </button>
//         <h2>Edit Student</h2>
//         <form onSubmit={handleEditFormSubmit}>
//           {Object.keys(formData).map((key) => (
//             <div className="form-group" key={key}>
//               <label>{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</label>
//               <input
//                 type={key === "email" ? "email" : key.includes("date") ? "date" : "text"}
//                 name={key}
//                 value={formData[key] || ""}
//                 onChange={handleEditFormChange}
//                 readOnly={key === "RegNo"}
//               />
//             </div>
//           ))}
//           <button
//             type="submit"
//             style={{ marginTop: "20px", padding: "10px 20px", border: "none", backgroundColor: "#4CAF50", color: "#fff", fontSize: "16px", borderRadius: "4px", cursor: "pointer" }}
//           >
//             Update
//           </button>
//         </form>
//       </Modal>
//     </>
//   );
// }

// export default Studentview;



// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5); // Number of students to display per page
//   const [editStudent, setEditStudent] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [style, setStyle] = useState({});
//   const [sortOrder, setSortOrder] = useState("asc"); // Sorting order for RegNo
//   const [nameSortOrder, setNameSortOrder] = useState("asc"); // Sorting order for student name
//   const [searchQuery, setSearchQuery] = useState("");
//   const [studentCount, setStudentCount] = useState(0);
//   const [courseIdentifiers, setCourseIdentifiers] = useState({
//     DCA: "DCA",
//     "ADVANCE PGDCA": "APG",
//     PGDCA: "PG",
//     MECHANICAL: "MCAD",
//     ELECTRICAL: "ECAD",
//     "CIVIL & ARCH": "ACAD",
//     "ANY IT": "IT",
//     TALLY: "TY",
//     PHOTOSHOP: "PS",
//     "MS OFFICE": "MSO",
//     "ADVANCE EXCEL": "AE",
//     "BASIC COMPUTER": "BC",
//   });
  
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(Array.isArray(response.data) ? response.data : []);
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//         setStudents([]); // Ensure students is an empty array on error
//       }
//     };

//     fetchStudents();
//   }, []);

//   useEffect(() => {
//     const fetchStudentCount = async () => {
//       try {
//         const response = await axios.get("/api/student-count");
//         setStudentCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching student count:", error);
//       }
//     };

//     fetchStudentCount();
//   }, []);

//   useEffect(() => {
//     const updateStyles = () => {
//       if (window.innerWidth < 768) {
//         // Mobile view
//         setStyle({
//           marginLeft: "10px",
//           marginTop: "20px",
//           cardWidth: "100%", // Adjust card width for mobile view
//           tableFontSize: "14px", // Adjust table font size for mobile view
//         });
//       } else {
//         // Desktop view
//         setStyle({
//           marginLeft: "300px",
//           marginTop: "-400px",
//           cardWidth: "20%", // Adjust card width for desktop view
//           tableFontSize: "16px", // Adjust table font size for desktop view
//         });
//       }
//     };

//     updateStyles();
//     window.addEventListener("resize", updateStyles);
//     return () => window.removeEventListener("resize", updateStyles);
//   }, []);

//   useEffect(() => {
//     if (editStudent && !editStudent.RegNo && editStudent.course) {
//       const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
//       const incrementedNumber = studentCount + 301; // Assuming `studentCount` starts from 0 and increments

//       setEditStudent((prevStudent) => ({
//         ...prevStudent,
//         RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//       }));
//     }
//   }, [editStudent, courseIdentifiers, studentCount]);

//   const handleEditClick = (student) => {
//     setEditStudent(student);
//     setModalIsOpen(true);
//   };

//   const handleEditFormChange = (e) => {
//     setEditStudent({
//       ...editStudent,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`/api/students/${editStudent._id}`, editStudent);
//       // Optionally refetch students
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };

//   const handleDeleteClick = async (studentId) => {
//     try {
//       await axios.delete(`/api/students/${studentId}`);
//       // Optionally refetch students
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//     } catch (error) {
//       console.error("Error deleting student data:", error);
//     }
//   };

//   const handleSortByRegNo = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (sortOrder === "asc") {
//         return a.RegNo.localeCompare(b.RegNo);
//       } else {
//         return b.RegNo.localeCompare(a.RegNo);
//       }
//     });
//     setStudents(sortedStudents);
//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//   };

//   const handleSortByName = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (nameSortOrder === "asc") {
//         return a.name.localeCompare(b.name);
//       } else {
//         return b.name.localeCompare(a.name);
//       }
//     });
//     setStudents(sortedStudents);
//     setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
//   };

//   // Filter students based on the search query
//   const filteredStudents = students.filter(
//     (student) =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   // Get current students
//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = filteredStudents.slice(
//     indexOfFirstStudent,
//     indexOfLastStudent
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const paginationStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "10px",
//     marginTop: "20px",
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="row clearfix">
//               <div className="col-lg-12"></div>
//             </div>
//             <div className="row clearfix">
//               <div className="col-lg-3 col-md-6" style={{ width: style.cardWidth }}>
//                 <Link to="/stud" className="card">
//                   <div className="body top_counter">
//                     <div className="content">
//                       <div className="icon bg-warning text-white">
//                         <PiStudentBold style={{ fontSize: "35px" }} />
//                         <span style={{ marginLeft: "30px", fontSize: "20px" }}>
//                           Add Student
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//               <div className="col-lg-9 col-md-6" style={{ width: "", marginLeft: "60%" }}>
//                 <div className="search-bar" style={{ marginBottom: "20px", display: "flex", marginTop: "-40px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search by Reg.No or Name"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     style={{
//                       padding: "10px",
//                       fontSize: "16px",
//                       border: "1px solid black",
//                       borderRadius: "5px",
//                       marginRight: "10px",
//                       color: "black",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row clearfix" style={{ overflowX: "auto" }}>
//               <div className="col-lg-12">
//                 <table className="table table-bordered table-hover" style={{ fontSize: style.tableFontSize }}>
//                   <thead>
//                     <tr>
//                       <th>
//                         <button onClick={handleSortByRegNo} style={buttonStyle}>
//                           Reg.No {sortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>
//                         <button onClick={handleSortByName} style={buttonStyle}>
//                           Name {nameSortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>Email</th>
//                       <th>Course</th>
//                       <th>Mobile No</th>
//                       <th>Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {currentStudents.map((student) => (
//                       <tr key={student._id}>
//                         <td>{student.RegNo}</td>
//                         <td>{student.name}</td>
//                         <td>{student.email}</td>
//                         <td>{student.course}</td>
//                         <td>{student.mobileNo}</td>
//                         {/* <td>
//                           <button
//                             onClick={() => handleEditClick(student)}
//                             className="btn btn-primary"
//                             style={{ marginRight: "5px" }}
//                           >
//                             <FaFilePen />
//                           </button>
//                           <button
//                             onClick={() => handleDeleteClick(student._id)}
//                             className="btn btn-danger"
//                           >
//                             <FaTrashAlt />
//                           </button>
//                           <Link
//                                   to={`/detailes/${encodeURIComponent(
//                                     student.RegNo
//                                   )}/${student._id}`}
//                                 >
//                                   <button
//                                     style={{
//                                       border: "none",
//                                       backgroundColor: "#ffb237",
//                                       color: "#fff",
//                                       padding: "0.5rem 1rem",
//                                       borderRadius: "0.25rem",
//                                       cursor: "pointer",
//                                       display: "flex",
//                                       alignItems: "center",
//                                     }}
//                                   >
//                                     <BiSolidReceipt
//                                       style={{ marginRight: "0.5rem" }}
//                                     />
//                                     Receipt
//                                   </button>
//                                 </Link>
//                         </td> */}
//                         <td>
//   <button
//     onClick={() => handleEditClick(student)}
//     className="btn "
//     style={{ marginRight: "5px", backgroundColor:'#FFB237',color:'white' }}
//   >
//     <FaFilePen />
//   </button>
//   <button
//     onClick={() => handleDeleteClick(student._id)}
//     className="btn "
//     style={{ marginRight: "5px",backgroundColor:'#FFB237',color:"white" }}
//   >
//     <FaTrashAlt style={{backgroundColor:'#FFB237'}} />
//   </button>
//   <Link to={`/detailes/${encodeURIComponent(student.RegNo)}/${student._id}`}>
//     <button
//      className="btn "
//      style={{ marginRight: "5px" ,backgroundColor:'#FFB237',color:'white'}}
    
     
//     >
//       <BiSolidReceipt   />
//       Receipt
//     </button>
//   </Link>
// </td>

//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <div style={paginationStyle}>
//                   <button
//                     onClick={() => paginate(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     style={buttonStyle}
//                   >
//                     Previous
//                   </button>
//                   {[...Array(Math.ceil(filteredStudents.length / studentsPerPage)).keys()].map((number) => (
//                     <button
//                       key={number}
//                       onClick={() => paginate(number + 1)}
//                       style={buttonStyle}
//                     >
//                       {number + 1}
//                     </button>
//                   ))}
//                   <button
//                     onClick={() => paginate(currentPage + 1)}
//                     disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
//                     style={buttonStyle}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* <Modal
//           isOpen={modalIsOpen}
//           onRequestClose={() => setModalIsOpen(false)}
//           style={{
//             content: {
//               top: "50%",
//               left: "50%",
//               right: "auto",
//               bottom: "auto",
//               marginRight: "-50%",
//               transform: "translate(-50%, -50%)",
//               width: "90%",
//               maxWidth: "800px",
//             },
//           }}
//         >
//           <h2>Edit Student</h2>
//           {editStudent && (
//             <form onSubmit={handleEditFormSubmit}>
//               <label>
//                 Reg.No:
//                 <input
//                   type="text"
//                   name="RegNo"
//                   value={editStudent.RegNo || ""}
//                   onChange={handleEditFormChange}
//                   disabled
//                 />
//               </label>
//               <label>
//                 Name:
//                 <input
//                   type="text"
//                   name="name"
//                   value={editStudent.name || ""}
//                   onChange={handleEditFormChange}
//                 />
//               </label>
//               <label>
//                 Email:
//                 <input
//                   type="email"
//                   name="email"
//                   value={editStudent.email || ""}
//                   onChange={handleEditFormChange}
//                 />
//               </label>
//               <label>
//                 Course:
//                 <input
//                   type="text"
//                   name="course"
//                   value={editStudent.course || ""}
//                   onChange={handleEditFormChange}
//                 />
//               </label>
//               <label>
//                 Mobile No:
//                 <input
//                   type="text"
//                   name="mobileNo"
//                   value={editStudent.mobileNo || ""}
//                   onChange={handleEditFormChange}
//                 />
//               </label>
//               <button type="submit">Save</button>
//               <button onClick={() => setModalIsOpen(false)}>Close</button>
//             </form>
//           )}
//         </Modal> */}
//         <Modal
//   isOpen={modalIsOpen}
//   onRequestClose={() => setModalIsOpen(false)}
//   contentLabel="Edit Student"
//   style={{
//     content: {
//       position: "fixed",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90vh",
//       padding: "20px",
//       backgroundColor: "#f7f7f7",
//       border: "1px solid #ddd",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//       overflowY: "auto",
//       overflowX: "hidden",
//       zIndex: 1050,
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       zIndex: 1040,
//     },
//   }}
// >
//   <button
//     onClick={() => setModalIsOpen(false)}
//     style={{
//       position: "absolute",
//       top: "10px",
//       right: "10px",
//       backgroundColor: "transparent",
//       border: "none",
//       fontSize: "24px",
//       cursor: "pointer",
//       color: "#333",
//     }}
//     aria-label="Close Modal"
//   >
//     &times;
//   </button>
//   <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
//     Edit Student
//   </h2>
//   <form
//     onSubmit={handleEditFormSubmit}
//     style={{
//       display: "grid",
//       gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
//       gap: "5px",  // Reduced gap
//       marginBottom: "15px", // Reduced margin
//     }}
//   >
//     {[
//       { id: 'name', label: 'Name:', type: 'text',name:'Name' },
//       { id: 'email', label: 'Email:', type: 'email',name:'email' },
//       { id: 'fatherName', label: 'Father\'s Name:', type: 'text',name:'fatherName' },
//       // { id: 'gender', label: 'Gender:', type: 'select',name:'gender', options: ['Select Gender', 'Male', 'Female'] },
//       { id: 'mobileNo', label: 'Mobile No:', type: 'text' ,name:'mobileNo'},
//       { id: 'Address', label: 'Address:', type: 'text',name:'Address' },
//       { id: 'collegeName', label: 'College Name:', type: 'text',name:'collegeName' },
//       { id: 'course',name:'course' ,label: 'Course:', type: 'select', options: ['Select Course', 'DCA', 'ADVANCE PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'ANY IT', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER'] },
//       { id: 'RegNo', name:'RegNo',label: 'Registration No:', type: 'text' },
//       { id: 'centreName', label: 'Centre Name:', type: 'text',name:'centreName' },
//       { id: 'courseDuration', label: 'Course Duration:', type: 'text',name:'courseDuration' },
//       { id: 'courseFee', label: 'Course Fee:', type: 'number',name:'courseFee' },
//       { id: 'amountReceived', label: 'Amount Received:', type: 'number',name:'amountReceived' },
//       { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true,name:'currentDue' },
//       { id: 'Date', label: 'Date:', type: 'date',name:'Date' },
//       { id: 'softwareCovered', label: 'Software Covered:', type: 'text',name:'softwareCovered' },
//       // { id: 'PaymentBy', label: 'Payment By:', type: 'text' },
//       // { id: 'receiptId', label: 'Receipt ID:', type: 'text', readOnly: true }
//     ].map(({ id, label,name, type, options, readOnly }) => (
//       <div key={id} className="input-container" style={{ marginBottom: "5px" }}>  {/* Reduced marginBottom */}
//         <label htmlFor={id} style={{ marginBottom: "3px", display: "block" }}>{label}</label>
//         {type === 'select' ? (
//           <select
//             id={id}
//             value={editStudent?.[id] || ""}
//             onChange={handleEditFormChange}
//             required
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           >
//             {options.map((option, index) => (
//               <option key={index} value={option === 'Select Gender' || option === 'Select Course' ? '' : option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         ) : (
//           <input
//             type={type}
//             id={id}
//             name={name}
//             value={editStudent?.[id] || ""}
//             onChange={handleEditFormChange}
//             readOnly={readOnly}
//             required={!readOnly}
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           />
//         )}
//       </div>
//     ))}
//     <div
//       style={{
//         gridColumn: "span 3",
//         display: "flex",
//         justifyContent: "center",
//         gap: "10px",
//         marginTop: "15px",  // Reduced marginTop
//       }}
//     >
//       <button
//         type="submit"
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Submit
//       </button>
//       <button
//         type="button"
//         onClick={() => setModalIsOpen(false)}
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Close
//       </button>
//     </div>
//   </form>
// </Modal>

//       </div>
//     </>
//   );
// }

// export default Studentview;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [editStudent, setEditStudent] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [style, setStyle] = useState({});
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [nameSortOrder, setNameSortOrder] = useState("asc");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [studentCount, setStudentCount] = useState(0);
//   const [courseIdentifiers, setCourseIdentifiers] = useState({
//     DCA: "DCA",
//     "ADVANCE PGDCA": "APG",
//     PGDCA: "PG",
//     MECHANICAL: "MCAD",
//     ELECTRICAL: "ECAD",
//     "CIVIL & ARCH": "ACAD",
//     "ANY IT": "IT",
//     TALLY: "TY",
//     PHOTOSHOP: "PS",
//     "MS OFFICE": "MSO",
//     "ADVANCE EXCEL": "AE",
//     "BASIC COMPUTER": "BC",
//   });
  
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(Array.isArray(response.data) ? response.data : []);
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//         setStudents([]);
//       }
//     };

//     fetchStudents();
//   }, []);

//   useEffect(() => {
//     const fetchStudentCount = async () => {
//       try {
//         const response = await axios.get("/api/student-count");
//         setStudentCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching student count:", error);
//       }
//     };

//     fetchStudentCount();
//   }, []);

//   useEffect(() => {
//     const updateStyles = () => {
//       if (window.innerWidth < 768) {
//         setStyle({
//           marginLeft: "10px",
//           marginTop: "20px",
//           cardWidth: "100%",
//           tableFontSize: "14px",
//         });
//       } else {
//         setStyle({
//           marginLeft: "300px",
//           marginTop: "-400px",
//           cardWidth: "20%",
//           tableFontSize: "16px",
//         });
//       }
//     };

//     updateStyles();
//     window.addEventListener("resize", updateStyles);
//     return () => window.removeEventListener("resize", updateStyles);
//   }, []);

//   useEffect(() => {
//     if (editStudent && !editStudent.RegNo && editStudent.course) {
//       const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
//       const incrementedNumber = studentCount + 301;

//       setEditStudent((prevStudent) => ({
//         ...prevStudent,
//         RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//       }));
//     }
//   }, [editStudent, courseIdentifiers, studentCount]);

//   const handleEditClick = (student) => {
//     setEditStudent(student);
//     setModalIsOpen(true);
//   };

//   // const handleEditFormChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setEditStudent(prevStudent => {
//   //     const updatedStudent = { ...prevStudent, [name]: value };
      
//   //     // Calculate current due
//   //     if (name === 'courseFee' || name === 'amountReceived') {
//   //       const courseFee = parseFloat(updatedStudent.courseFee) || 0;
//   //       const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
//   //       updatedStudent.currentDue = courseFee - amountReceived;
//   //     }
      
//   //     return updatedStudent;
//   //   });
//   // };
//   const handleEditFormChange = (e) => {
//     const { name, value } = e.target;
//     setEditStudent(prevStudent => {
//       // Convert value to float if necessary
//       const newValue = parseFloat(value) || 0;
//       const updatedStudent = { ...prevStudent, [name]: newValue };
  
//       // Calculate current due if courseFee or amountReceived is updated
//       if (name === 'courseFee' || name === 'amountReceived') {
//         const courseFee = parseFloat(updatedStudent.courseFee) || 0;
//         const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
//         updatedStudent.currentDue = courseFee - amountReceived;
//       }
  
//       // Debugging outputs
//       console.log("Updated Student:", updatedStudent);
//       console.log("Course Fee:", updatedStudent.courseFee);
//       console.log("Amount Received:", updatedStudent.amountReceived);
//       console.log("Current Due:", updatedStudent.currentDue);
  
//       return updatedStudent;
//     });
//   };
  

  
//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
  
//     // Recalculate current due before submission
//     const courseFee = parseFloat(editStudent.courseFee) || 0;
//     const amountReceived = parseFloat(editStudent.amountReceived) || 0;
//     const currentDue = courseFee - amountReceived;
  
//     const updatedStudent = { ...editStudent, currentDue };
  
//     try {
//       await axios.put(`/api/students/${editStudent._id}`, updatedStudent);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };
  
//   // const handleEditFormSubmit = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     await axios.put(`/api/students/${editStudent._id}`, editStudent);
//   //     const response = await axios.get("/api/students");
//   //     setStudents(Array.isArray(response.data) ? response.data : []);
//   //     setModalIsOpen(false);
//   //   } catch (error) {
//   //     console.error("Error updating student data:", error);
//   //   }
//   // };

//   const handleDeleteClick = async (studentId) => {
//     try {
//       await axios.delete(`/api/students/${studentId}`);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//     } catch (error) {
//       console.error("Error deleting student data:", error);
//     }
//   };

//   const handleSortByRegNo = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (sortOrder === "asc") {
//         return a.RegNo.localeCompare(b.RegNo);
//       } else {
//         return b.RegNo.localeCompare(a.RegNo);
//       }
//     });
//     setStudents(sortedStudents);
//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//   };

//   const handleSortByName = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (nameSortOrder === "asc") {
//         return a.name.localeCompare(b.name);
//       } else {
//         return b.name.localeCompare(a.name);
//       }
//     });
//     setStudents(sortedStudents);
//     setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
//   };

//   const filteredStudents = students.filter(
//     (student) =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = filteredStudents.slice(
//     indexOfFirstStudent,
//     indexOfLastStudent
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const paginationStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "10px",
//     marginTop: "20px",
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="row clearfix">
//               <div className="col-lg-12"></div>
//             </div>
//             <div className="row clearfix">
//               <div className="col-lg-3 col-md-6" style={{ width: style.cardWidth }}>
//                 <Link to="/stud" className="card">
//                   <div className="body top_counter">
//                     <div className="content">
//                       <div className="icon bg-warning text-white">
//                         <PiStudentBold style={{ fontSize: "35px" }} />
//                         <span style={{ marginLeft: "30px", fontSize: "20px" }}>
//                           Add Student
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//               <div className="col-lg-9 col-md-6" style={{ width: "", marginLeft: "60%" }}>
//                 <div className="search-bar" style={{ marginBottom: "20px", display: "flex", marginTop: "-40px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search by Reg.No or Name"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     style={{
//                       padding: "10px",
//                       fontSize: "16px",
//                       border: "1px solid black",
//                       borderRadius: "5px",
//                       marginRight: "10px",
//                       color: "black",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row clearfix" style={{ overflowX: "auto" }}>
//               <div className="col-lg-12">
//                 <table className="table table-bordered table-hover" style={{ fontSize: style.tableFontSize }}>
//                   <thead>
//                     <tr>
//                       <th>
//                         <button onClick={handleSortByRegNo} style={buttonStyle}>
//                           Reg.No {sortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>
//                         <button onClick={handleSortByName} style={buttonStyle}>
//                           Name {nameSortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>Email</th>
//                       <th>Course</th>
//                       <th>Mobile No</th>
//                       <th>Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {currentStudents.map((student) => (
//                       <tr key={student._id}>
//                         <td>{student.RegNo}</td>
//                         <td>{student.name}</td>
//                         <td>{student.email}</td>
//                         <td>{student.course}</td>
//                         <td>{student.mobileNo}</td>
//                         <td>
//                           <button
//                             onClick={() => handleEditClick(student)}
//                             className="btn "
//                             style={{ marginRight: "5px", backgroundColor:'#FFB237',color:'white' }}
//                           >
//                             <FaFilePen />
//                           </button>
//                           <button
//                             onClick={() => handleDeleteClick(student._id)}
//                             className="btn "
//                             style={{ marginRight: "5px",backgroundColor:'#FFB237',color:"white" }}
//                           >
//                             <FaTrashAlt style={{backgroundColor:'#FFB237'}} />
//                           </button>
//                           <Link to={`/detailes/${encodeURIComponent(student.RegNo)}/${student._id}`}>
//                             <button
//                               className="btn "
//                               style={{ marginRight: "5px" ,backgroundColor:'#FFB237',color:'white'}}
//                             >
//                               <BiSolidReceipt   />
//                               Receipt
//                             </button>
//                           </Link>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <div style={paginationStyle}>
//                   <button
//                     onClick={() => paginate(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     style={buttonStyle}
//                   >
//                     Previous
//                   </button>
//                   {[...Array(Math.ceil(filteredStudents.length / studentsPerPage)).keys()].map((number) => (
//                     <button
//                       key={number}
//                       onClick={() => paginate(number + 1)}
//                       style={buttonStyle}
//                     >
//                       {number + 1}
//                     </button>
//                   ))}
//                   <button
//                     onClick={() => paginate(currentPage + 1)}
//                     disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
//                     style={buttonStyle}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Modal
//   isOpen={modalIsOpen}
//   onRequestClose={() => setModalIsOpen(false)}
//   contentLabel="Edit Student"
//   style={{
//     content: {
//       position: "fixed",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90vh",
//       padding: "20px",
//       backgroundColor: "#f7f7f7",
//       border: "1px solid #ddd",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//       overflowY: "auto",
//       overflowX: "hidden",
//       zIndex: 1050,
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       zIndex: 1040,
//     },
//   }}
// >
//   <button
//     onClick={() => setModalIsOpen(false)}
//     style={{
//       position: "absolute",
//       top: "10px",
//       right: "10px",
//       backgroundColor: "transparent",
//       border: "none",
//       fontSize: "24px",
//       cursor: "pointer",
//       color: "#333",
//     }}
//     aria-label="Close Modal"
//   >
//     &times;
//   </button>
//   <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
//     Edit Student
//   </h2>
//   <form
//     onSubmit={handleEditFormSubmit}
//     style={{
//       display: "grid",
//       gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
//       gap: "5px",
//       marginBottom: "15px",
//     }}
//   >
//     {[
//       { id: 'name', label: 'Name:', type: 'text', name: 'name' },
//       { id: 'email', label: 'Email:', type: 'email', name: 'email' },
//       { id: 'fatherName', label: 'Father\'s Name:', type: 'text', name: 'fatherName' },
//       { id: 'mobileNo', label: 'Mobile No:', type: 'text', name: 'mobileNo' },
//       { id: 'Address', label: 'Address:', type: 'text', name: 'Address' },

//       { id: 'collegeName', label: 'College Name:', type: 'text', name: 'collegeName' },
//       { id: 'course', name: 'course', label: 'Course:', type: 'select', options: ['Select Course', 'DCA', 'ADVANCE PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'ANY IT', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER'] },
//       { id: 'RegNo', name: 'RegNo', label: 'Registration No:', type: 'text' },
//       { id: 'centreName', label: 'Centre Name:', type: 'text', name: 'centreName' },
//       { id: 'courseDuration', label: 'Course Duration:', type: 'text', name: 'courseDuration' },
//       { id: 'courseFee', label: 'Course Fee:', type: 'number', name: 'courseFee' },
//       { id: 'amountReceived', label: 'Amount Received:', type: 'number', name: 'amountReceived' },
//       { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true, name: 'currentDue' },
//       { id: 'payment', name: 'payment', label: 'Payment:', type: 'select', options: ['Select Course', 'UPI', 'BANK TRANSFER', 'CASH'] },
//       { id: 'Date', label: 'Date:', type: 'date', name: 'Date' },

//       { id: 'softwareCovered', label: 'Software Covered:', type: 'text', name: 'softwareCovered' },
//     ].map(({ id, label, name, type, options, readOnly }) => (
//       <div key={id} className="input-container" style={{ marginBottom: "5px" }}>
//         <label htmlFor={id} style={{ marginBottom: "3px", display: "block" }}>{label}</label>
//         {type === 'select' ? (
//           <select
//             id={id}
//             name={name}
//             value={editStudent?.[id] || ""}
//             onChange={handleEditFormChange}
//             required
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           >
//             {options.map((option, index) => (
//               <option key={index} value={option === 'Select Course' ? '' : option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         ) : (
//           <input
//             type={type}
//             id={id}
//             name={name}
//             value={id === 'Date' ? (editStudent?.[id] ? new Date(editStudent[id]).toISOString().split('T')[0] : '') : (editStudent?.[id] || "")}
//             onChange={(e) => {
//               handleEditFormChange(e);
             
//             }}
//             readOnly={readOnly}
//             required={!readOnly}
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           />
//         )}
//       </div>
//     ))}
//     <div
//       style={{
//         gridColumn: "span 3",
//         display: "flex",
//         justifyContent: "center",
//         gap: "10px",
//         marginTop: "15px",
//       }}
//     >
//       <button
//         type="submit"
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Submit
//       </button>
//       <button
//         type="button"
//         onClick={() => setModalIsOpen(false)}
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Close
//       </button>
//     </div>
//   </form>
// </Modal>


//       </div>
//     </>
//   );
// }

// export default Studentview;


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [editStudent, setEditStudent] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [style, setStyle] = useState({});
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [nameSortOrder, setNameSortOrder] = useState("asc");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [studentCount, setStudentCount] = useState(0);
//   const [courseIdentifiers, setCourseIdentifiers] = useState({
//     DCA: "DCA",
//     "ADVANCE PGDCA": "APG",
//     PGDCA: "PG",
//     MECHANICAL: "MCAD",
//     ELECTRICAL: "ECAD",
//     "CIVIL & ARCH": "ACAD",
//     "ANY IT": "IT",
//     TALLY: "TY",
//     PHOTOSHOP: "PS",
//     "MS OFFICE": "MSO",
//     "ADVANCE EXCEL": "AE",
//     "BASIC COMPUTER": "BC",
//   });
  
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(Array.isArray(response.data) ? response.data : []);
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//         setStudents([]);
//       }
//     };

//     fetchStudents();
//   }, []);

//   useEffect(() => {
//     const fetchStudentCount = async () => {
//       try {
//         const response = await axios.get("/api/student-count");
//         setStudentCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching student count:", error);
//       }
//     };

//     fetchStudentCount();
//   }, []);

//   useEffect(() => {
//     const updateStyles = () => {
//       if (window.innerWidth < 768) {
//         setStyle({
//           marginLeft: "10px",
//           marginTop: "20px",
//           cardWidth: "100%",
//           tableFontSize: "14px",
//         });
//       } else {
//         setStyle({
//           marginLeft: "300px",
//           marginTop: "-400px",
//           cardWidth: "20%",
//           tableFontSize: "16px",
//         });
//       }
//     };

//     updateStyles();
//     window.addEventListener("resize", updateStyles);
//     return () => window.removeEventListener("resize", updateStyles);
//   }, []);

//   useEffect(() => {
//     if (editStudent && !editStudent.RegNo && editStudent.course) {
//       const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
//       const incrementedNumber = studentCount + 301;

//       setEditStudent((prevStudent) => ({
//         ...prevStudent,
//         RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//       }));
//     }
//   }, [editStudent, courseIdentifiers, studentCount]);

//   const handleEditClick = (student) => {
//     setEditStudent(student);
//     setModalIsOpen(true);
//   };

//   const handleEditFormChange = (e) => {
//     const { name, value } = e.target;
//     setEditStudent(prevStudent => {
//       const updatedStudent = { ...prevStudent, [name]: value };
      
//       // Calculate current due
//       if (name === 'courseFee' || name === 'amountReceived') {
//         const courseFee = parseFloat(updatedStudent.courseFee) || 0;
//         const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
//         updatedStudent.currentDue = courseFee - amountReceived;
//       }
      
//       return updatedStudent;
//     });
//   };
//   // const handleEditFormChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setEditStudent(prevStudent => {
//   //     // Convert value to float if necessary
//   //     const newValue = parseFloat(value) || 0;
//   //     const updatedStudent = { ...prevStudent, [name]: newValue };
  
//   //     // Calculate current due if courseFee or amountReceived is updated
//   //     if (name === 'courseFee' || name === 'amountReceived') {
//   //       const courseFee = parseFloat(updatedStudent.courseFee) || 0;
//   //       const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
//   //       updatedStudent.currentDue = courseFee - amountReceived;
//   //     }
  
//   //     // Debugging outputs
//   //     console.log("Updated Student:", updatedStudent);
//   //     console.log("Course Fee:", updatedStudent.courseFee);
//   //     console.log("Amount Received:", updatedStudent.amountReceived);
//   //     console.log("Current Due:", updatedStudent.currentDue);
  
//   //     return updatedStudent;
//   //   });
//   // };
  
//   // const handleEditFormSubmit = async (e) => {
//   //   e.preventDefault();
  
//   //   // Validate input values
//   //   const courseFee = parseFloat(editStudent.courseFee) || 0;
//   //   const amountReceived = parseFloat(editStudent.amountReceived) || 0;
  
//   //   if (!editStudent._id) {
//   //     console.error("Student ID is missing.");
//   //     return;
//   //   }
  
//   //   const currentDue = courseFee - amountReceived;
  
//   //   // Create a new object with updated values
//   //   const updatedStudent = { ...editStudent, currentDue };
  
//   //   try {
//   //     // Update student data
//   //     const updateResponse = await axios.put(`/api/students/${editStudent._id}`, updatedStudent);
  
//   //     if (updateResponse.status === 200) {
//   //       // Fetch updated student list
//   //       const response = await axios.get("/api/students");
//   //       if (Array.isArray(response.data)) {
//   //         setStudents(response.data);
//   //       } else {
//   //         console.error("Unexpected response format:", response.data);
//   //       }
//   //     } else {
//   //       console.error("Failed to update student data. Status code:", updateResponse.status);
//   //     }
      
//   //     // Close modal after successful update
//   //     setModalIsOpen(false);
  
//   //   } catch (error) {
//   //     console.error("Error updating student data:", error);
//   //   }
//   // };
  
  
//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
  
//     // Recalculate current due before submission
//     const courseFee = parseFloat(editStudent.courseFee) || 0;
//     const amountReceived = parseFloat(editStudent.amountReceived) || 0;
//     const currentDue = courseFee - amountReceived;
  
//     const updatedStudent = { ...editStudent, currentDue };
  
//     try {
//       await axios.put(`/api/students/${editStudent._id}`, updatedStudent);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };
  
//   // const handleEditFormSubmit = async (e) => {
//   //   e.preventDefault();
//   //   try {
//   //     await axios.put(`/api/students/${editStudent._id}`, editStudent);
//   //     const response = await axios.get("/api/students");
//   //     setStudents(Array.isArray(response.data) ? response.data : []);
//   //     setModalIsOpen(false);
//   //   } catch (error) {
//   //     console.error("Error updating student data:", error);
//   //   }
//   // };

//   const handleDeleteClick = async (studentId) => {
//     try {
//       await axios.delete(`/api/students/${studentId}`);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//     } catch (error) {
//       console.error("Error deleting student data:", error);
//     }
//   };

//   const handleSortByRegNo = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (sortOrder === "asc") {
//         return a.RegNo.localeCompare(b.RegNo);
//       } else {
//         return b.RegNo.localeCompare(a.RegNo);
//       }
//     });
//     setStudents(sortedStudents);
//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//   };

//   const handleSortByName = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (nameSortOrder === "asc") {
//         return a.name.localeCompare(b.name);
//       } else {
//         return b.name.localeCompare(a.name);
//       }
//     });
//     setStudents(sortedStudents);
//     setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
//   };

//   const filteredStudents = students.filter(
//     (student) =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = filteredStudents.slice(
//     indexOfFirstStudent,
//     indexOfLastStudent
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const paginationStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "10px",
//     marginTop: "20px",
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="row clearfix">
//               <div className="col-lg-12"></div>
//             </div>
//             <div className="row clearfix">
//               <div className="col-lg-3 col-md-6" style={{ width: style.cardWidth }}>
//                 <Link to="/stud" className="card">
//                   <div className="body top_counter">
//                     <div className="content">
//                       <div className="icon bg-warning text-white">
//                         <PiStudentBold style={{ fontSize: "35px" }} />
//                         <span style={{ marginLeft: "30px", fontSize: "20px" }}>
//                           Add Student
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//               <div className="col-lg-9 col-md-6" style={{ width: "", marginLeft: "60%" }}>
//                 <div className="search-bar" style={{ marginBottom: "20px", display: "flex", marginTop: "-40px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search by Reg.No or Name"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     style={{
//                       padding: "10px",
//                       fontSize: "16px",
//                       border: "1px solid black",
//                       borderRadius: "5px",
//                       marginRight: "10px",
//                       color: "black",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="row clearfix" style={{ overflowX: "auto" }}>
//               <div className="col-lg-12">
//                 <table className="table table-bordered table-hover" style={{ fontSize: style.tableFontSize }}>
//                   <thead>
//                     <tr>
//                       <th>
//                         <button onClick={handleSortByRegNo} style={buttonStyle}>
//                           Reg.No {sortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>
//                         <button onClick={handleSortByName} style={buttonStyle}>
//                           Name {nameSortOrder === "asc" ? "↑" : "↓"}
//                         </button>
//                       </th>
//                       <th>Email</th>
//                       <th>Course</th>
//                       <th>Mobile No</th>
//                       <th>
//                            <strong>Status</strong>
//                          </th>
//                       <th>Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {currentStudents.map((student) => (
//                       <tr key={student._id}>
//                         <td>{student.RegNo}</td>
//                         <td>{student.name}</td>
//                         <td>{student.email}</td>
//                         <td>{student.course}</td>
//                         <td>{student.mobileNo}</td>
//                                                    <td>
//                               <button
//                                 style={{
//                                   backgroundColor:
//                                     Number(student.currentDue) === 0
//                                       ? "green"
//                                       : "red",
//                                   color: "white",
//                                   padding: "5px 10px",
//                                   border: "none",
//                                   borderRadius: "5px",
//                                 }}
//                               >
//                                 {Number(student.currentDue) === 0
//                                   ? "Paid"
//                                   : "Due"}
//                               </button>
//                             </td>
//                         <td>
//                           <button
//                             onClick={() => handleEditClick(student)}
//                             className="btn "
//                             style={{ marginRight: "5px", backgroundColor:'#FFB237',color:'white' }}
//                           >
//                             <FaFilePen />
//                           </button>
//                           <button
//                             onClick={() => handleDeleteClick(student._id)}
//                             className="btn "
//                             style={{ marginRight: "5px",backgroundColor:'#FFB237',color:"white" }}
//                           >
//                             <FaTrashAlt style={{backgroundColor:'#FFB237'}} />
//                           </button>
//                           {/* <Link to={`/detailes/${encodeURIComponent(student.RegNo)}/${student._id}`}>
//                             <button
//                               className="btn "
//                               style={{ marginRight: "5px" ,backgroundColor:'#FFB237',color:'white'}}
//                             >
//                               <BiSolidReceipt   />
//                               Receipt
//                             </button> */}

// <Link to={`/detailes/${encodeURIComponent(student.RegNo)}`}>
//   <button
//     className="btn"
//     style={{ marginRight: "5px", backgroundColor: '#FFB237', color: 'white' }}
//   >
//     <BiSolidReceipt />
//     Receipt
//   </button>
// </Link>

                         
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//                 <div style={paginationStyle}>
//                   <button
//                     onClick={() => paginate(currentPage - 1)}
//                     disabled={currentPage === 1}
//                     style={buttonStyle}
//                   >
//                     Previous
//                   </button>
//                   {[...Array(Math.ceil(filteredStudents.length / studentsPerPage)).keys()].map((number) => (
//                     <button
//                       key={number}
//                       onClick={() => paginate(number + 1)}
//                       style={buttonStyle}
//                     >
//                       {number + 1}
//                     </button>
//                   ))}
//                   <button
//                     onClick={() => paginate(currentPage + 1)}
//                     disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
//                     style={buttonStyle}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Modal
//   isOpen={modalIsOpen}
//   onRequestClose={() => setModalIsOpen(false)}
//   contentLabel="Edit Student"
//   style={{
//     content: {
//       position: "fixed",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "1000px",
//       maxHeight: "90vh",
//       padding: "20px",
//       backgroundColor: "#f7f7f7",
//       border: "1px solid #ddd",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//       overflowY: "auto",
//       overflowX: "hidden",
//       zIndex: 1050,
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       zIndex: 1040,
//     },
//   }}
// >
//   <button
//     onClick={() => setModalIsOpen(false)}
//     style={{
//       position: "absolute",
//       top: "10px",
//       right: "10px",
//       backgroundColor: "transparent",
//       border: "none",
//       fontSize: "24px",
//       cursor: "pointer",
//       color: "#333",
//     }}
//     aria-label="Close Modal"
//   >
//     &times;
//   </button>
//   <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
//     Edit Student
//   </h2>
//   <form
//     onSubmit={handleEditFormSubmit}
//     style={{
//       display: "grid",
//       gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
//       gap: "5px",
//       marginBottom: "15px",
//     }}
//   >
//     {[
//       { id: 'name', label: 'Name:', type: 'text', name: 'name' },
//       { id: 'email', label: 'Email:', type: 'email', name: 'email' },
//       { id: 'fatherName', label: 'Father\'s Name:', type: 'text', name: 'fatherName' },
//       { id: 'mobileNo', label: 'Mobile No:', type: 'text', name: 'mobileNo' },
//       { id: 'Address', label: 'Address:', type: 'text', name: 'Address' },

//       { id: 'collegeName', label: 'College Name:', type: 'text', name: 'collegeName' },
//       { id: 'course', name: 'course', label: 'Course:', type: 'select', options: ['Select Course', 'DCA', 'ADVANCE PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'ANY IT', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER'] },
//       { id: 'RegNo', name: 'RegNo', label: 'Registration No:', type: 'text' },
//       { id: 'centreName', label: 'Centre Name:', type: 'text', name: 'centreName' },
//       { id: 'courseDuration', label: 'Course Duration:', type: 'text', name: 'courseDuration' },
//       { id: 'courseFee', label: 'Course Fee:', type: 'number', name: 'courseFee' },
//       { id: 'amountReceived', label: 'Amount Received:', type: 'number', name: 'amountReceived' },
//       { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true, name: 'currentDue' },
//       { id: 'payment', name: 'payment', label: 'Payment:', type: 'select', options: ['Select Course', 'UPI', 'BANK TRANSFER', 'CASH'] },
//       { id: 'Date', label: 'Date:', type: 'date', name: 'Date' },

//       { id: 'softwareCovered', label: 'Software Covered:', type: 'text', name: 'softwareCovered' },
//     ].map(({ id, label, name, type, options, readOnly }) => (
//       <div key={id} className="input-container" style={{ marginBottom: "5px" }}>
//         <label htmlFor={id} style={{ marginBottom: "3px", display: "block" }}>{label}</label>
//         {type === 'select' ? (
//           <select
//             id={id}
//             name={name}
//             value={editStudent?.[id] || ""}
//             onChange={handleEditFormChange}
//             required
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           >
//             {options.map((option, index) => (
//               <option key={index} value={option === 'Select Course' ? '' : option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         ) : (
//           <input
//             type={type}
//             id={id}
//             name={name}
//             value={id === 'Date' ? (editStudent?.[id] ? new Date(editStudent[id]).toISOString().split('T')[0] : '') : (editStudent?.[id] || "")}
//             onChange={(e) => {
//               handleEditFormChange(e);
             
//             }}
           
//             // required={!readOnly}
//             style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
//           />
//         )}
//       </div>
//     ))}
//     <div
//       style={{
//         gridColumn: "span 3",
//         display: "flex",
//         justifyContent: "center",
//         gap: "10px",
//         marginTop: "15px",
//       }}
//     >
//       <button
//         type="submit"
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Submit
//       </button>
//       <button
//         type="button"
//         onClick={() => setModalIsOpen(false)}
//         style={{
//           backgroundColor: "#FFB237",
//           color: "black",
//           border: "none",
//           padding: "10px 20px",
//           textAlign: "center",
//           textDecoration: "none",
//           display: "inline-block",
//           fontSize: "16px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         Close
//       </button>
//     </div>
//   </form>
// </Modal>


//       </div>
//     </>
//   );
// }

// export default Studentview;



//testing code 

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { PiStudentBold } from "react-icons/pi";
// import { FaFilePen } from "react-icons/fa6";
// import { BiSolidReceipt } from "react-icons/bi";
// import { FaTrashAlt } from "react-icons/fa";
// import { Link, useNavigate } from "react-router-dom";
// import Leftbar from "../Leftbar/Leftbar";
// import Modal from "react-modal";

// function Studentview() {
//   const [students, setStudents] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [editStudent, setEditStudent] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [receiptModalIsOpen, setReceiptModalIsOpen] = useState(false);
//   const [selectedStudent, setSelectedStudent] = useState(null);
//   const [style, setStyle] = useState({});
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [nameSortOrder, setNameSortOrder] = useState("asc");
//   const [searchQuery, setSearchQuery] = useState("");
//   const [studentCount, setStudentCount] = useState(0);
//   const [paymentAmount, setPaymentAmount] = useState("");
// const [paymentDate, setPaymentDate] = useState("");

//   const [courseIdentifiers, setCourseIdentifiers] = useState({
//     DCA: "DCA",
//     "ADVANCE PGDCA": "APG",
//     PGDCA: "PG",
//     MECHANICAL: "MCAD",
//     ELECTRICAL: "ECAD",
//     "CIVIL & ARCH": "ACAD",
//     "ANY IT": "IT",
//     TALLY: "TY",
//     PHOTOSHOP: "PS",
//     "MS OFFICE": "MSO",
//     "ADVANCE EXCEL": "AE",
//     "BASIC COMPUTER": "BC",
//   });

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStudents = async () => {
//       try {
//         const response = await axios.get("/api/students");
//         setStudents(Array.isArray(response.data) ? response.data : []);
//       } catch (error) {
//         console.error("Error fetching student data:", error);
//         setStudents([]);
//       }
//     };

//     fetchStudents();
//   }, []);

//   useEffect(() => {
//     const fetchStudentCount = async () => {
//       try {
//         const response = await axios.get("/api/student-count");
//         setStudentCount(response.data.count);
//       } catch (error) {
//         console.error("Error fetching student count:", error);
//       }
//     };

//     fetchStudentCount();
//   }, []);

//   useEffect(() => {
//     const updateStyles = () => {
//       if (window.innerWidth < 768) {
//         setStyle({
//           marginLeft: "10px",
//           marginTop: "20px",
//           cardWidth: "100%",
//           tableFontSize: "14px",
//         });
//       } else {
//         setStyle({
//           marginLeft: "300px",
//           marginTop: "-400px",
//           cardWidth: "20%",
//           tableFontSize: "16px",
//         });
//       }
//     };

//     updateStyles();
//     window.addEventListener("resize", updateStyles);
//     return () => window.removeEventListener("resize", updateStyles);
//   }, []);

//   useEffect(() => {
//     if (editStudent && !editStudent.RegNo && editStudent.course) {
//       const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
//       const incrementedNumber = studentCount + 301;

//       setEditStudent((prevStudent) => ({
//         ...prevStudent,
//         RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
//       }));
//     }
//   }, [editStudent, courseIdentifiers, studentCount]);

//   const handleEditClick = (student) => {
//     setEditStudent(student);
//     setModalIsOpen(true);
//   };

//   const handleReceiptClick = (student) => {
//     setSelectedStudent(student);
//     setReceiptModalIsOpen(true);
//   };
//   const handleAddPayment = async (e) => {
//     e.preventDefault();
  
//     const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parseFloat(paymentAmount);
//     const newCurrentDue = selectedStudent.courseFee - newAmountReceived;
  
//     try {
//       await axios.put(`/api/students/update-by-regno`, {
//         RegNo: selectedStudent.RegNo, // Use RegNo to identify the student
//         amountReceived: newAmountReceived,
//         currentDue: newCurrentDue,
//         paymentDate: paymentDate,
//       });
      
//       // Update the local state
//       setStudents((prevStudents) =>
//         prevStudents.map((student) =>
//           student.RegNo === selectedStudent.RegNo
//             ? { ...student, amountReceived: newAmountReceived, currentDue: newCurrentDue }
//             : student
//         )
//       );
//       setReceiptModalIsOpen(false);
//     } catch (error) {
//       console.error("Error adding payment:", error);
//     }
//   };
  
//   const handleEditFormChange = (e) => {
//     const { name, value } = e.target;
//     setEditStudent(prevStudent => {
//       const updatedStudent = { ...prevStudent, [name]: value };
      
//       // Calculate current due
//       if (name === 'courseFee' || name === 'amountReceived') {
//         const courseFee = parseFloat(updatedStudent.courseFee) || 0;
//         const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
//         updatedStudent.currentDue = courseFee - amountReceived;
//       }
      
//       return updatedStudent;
//     });
//   };

//   const handleEditFormSubmit = async (e) => {
//     e.preventDefault();
  
//     const courseFee = parseFloat(editStudent.courseFee) || 0;
//     const amountReceived = parseFloat(editStudent.amountReceived) || 0;
//     const currentDue = courseFee - amountReceived;
  
//     const updatedStudent = { ...editStudent, currentDue };
  
//     try {
//       await axios.put(`/api/students/${editStudent._id}, updatedStudent`);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//       setModalIsOpen(false);
//     } catch (error) {
//       console.error("Error updating student data:", error);
//     }
//   };

//   const handleDeleteClick = async (studentId) => {
//     try {
//       await axios.delete(`/api/students/${studentId}`);
//       const response = await axios.get("/api/students");
//       setStudents(Array.isArray(response.data) ? response.data : []);
//     } catch (error) {
//       console.error("Error deleting student data:", error);
//     }
//   };

//   const handleSortByRegNo = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (sortOrder === "asc") {
//         return a.RegNo.localeCompare(b.RegNo);
//       } else {
//         return b.RegNo.localeCompare(a.RegNo);
//       }
//     });
//     setStudents(sortedStudents);
//     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//   };

//   const handleSortByName = () => {
//     const sortedStudents = [...students].sort((a, b) => {
//       if (nameSortOrder === "asc") {
//         return a.name.localeCompare(b.name);
//       } else {
//         return b.name.localeCompare(a.name);
//       }
//     });
//     setStudents(sortedStudents);
//     setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
//   };

//   const filteredStudents = students.filter(
//     (student) =>
//       student.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = filteredStudents.slice(
//     indexOfFirstStudent,
//     indexOfLastStudent
//   );

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const paginationStyle = {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     gap: "10px",
//     marginTop: "20px",
//   };

//   const buttonStyle = {
//     padding: "5px 10px",
//     border: "1px solid #ddd",
//     borderRadius: "5px",
//     cursor: "pointer",
//     color: "black",
//   };

//   return (
//     <>
//       <Leftbar />
//       <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
//         <div id="main-content">
//           <div className="block-header">
//             <h2>Students</h2>
//           </div>
//           <div className="container-fluid">
//             <div className="row clearfix">
//               <div className="col-lg-12"></div>
//             </div>
//             <div className="row clearfix">
//               <div className="col-lg-3 col-md-6" style={{ width: style.cardWidth }}>
//                 <Link to="/stud" className="card">
//                   <div className="body top_counter">
//                     <div className="content">
//                       <div className="icon bg-warning text-white">
//                         <PiStudentBold style={{ fontSize: "35px" }} />
//                         <span style={{ marginLeft: "30px", fontSize: "20px" }}>
//                           Add Student
//                         </span>
//                       </div>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//               <div className="col-lg-9 col-md-6" style={{ width: "", marginLeft: "60%" }}>
//                 <div className="search-bar" style={{ marginBottom: "20px", display: "flex", marginTop: "-40px" }}>
//                   <input
//                     type="text"
//                     placeholder="Search by Reg.No or Name"
//                     value={searchQuery}
//                     onChange={(e) => setSearchQuery(e.target.value)}
//                     style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div className="table-responsive">
//               <table className="table table-bordered">
//                 <thead>
//                   <tr>
//                     <th onClick={handleSortByRegNo} style={{ cursor: "pointer" }}>
//                       Reg.No
//                       {sortOrder === "asc" ? " 🔽" : " 🔼"}
//                     </th>
//                     <th onClick={handleSortByName} style={{ cursor: "pointer" }}>
//                       Name
//                       {nameSortOrder === "asc" ? " 🔽" : " 🔼"}
//                     </th>
//                     <th>Course</th>
//                     <th>Course Fee</th>
//                     <th>Amount Received</th>
//                     <th>Current Due</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {currentStudents.map((student) => (
//                     <tr key={student._id}>
//                       <td>{student.RegNo}</td>
//                       <td>{student.name}</td>
//                       <td>{student.course}</td>
//                       <td>${student.courseFee}</td>
//                       <td>${student.amountReceived}</td>
//                       <td>${student.currentDue}</td>
//                       <td>
//                         <button onClick={() => handleEditClick(student)} style={buttonStyle}>
//                           <FaFilePen />
//                         </button>
//                         <button onClick={() => handleReceiptClick(student)} style={buttonStyle}>
//                           <BiSolidReceipt />
//                         </button>
//                         <button onClick={() => handleDeleteClick(student._id)} style={buttonStyle}>
//                           <FaTrashAlt />
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>

//             <div style={paginationStyle}>
//               <button
//                 onClick={() => paginate(currentPage - 1)}
//                 disabled={currentPage === 1}
//                 style={buttonStyle}
//               >
//                 Previous
//               </button>
//               <button
//                 onClick={() => paginate(currentPage + 1)}
//                 disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
//                 style={buttonStyle}
//               >
//                 Next
//               </button>
//             </div>
//           </div>
//         </div>

//         {/* Edit Modal */}
//         <Modal
//           isOpen={modalIsOpen}
//           onRequestClose={() => setModalIsOpen(false)}
//           contentLabel="Edit Student"
//           style={{
//             content: {
//               position: "fixed",
//               top: "50%",
//               left: "50%",
//               right: "auto",
//               bottom: "auto",
//               marginRight: "-50%",
//               transform: "translate(-50%, -50%)",
//               width: "90%",
//               maxWidth: "800px",
//               maxHeight: "80vh",
//               padding: "20px",
//               backgroundColor: "#f7f7f7",
//               border: "1px solid #ddd",
//               borderRadius: "8px",
//               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//               overflowY: "auto",
//               overflowX: "hidden",
//               zIndex: 1050,
//             },
//             overlay: {
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//               zIndex: 1040,
//             },
//           }}
//         >
//           <button
//             onClick={() => setModalIsOpen(false)}
//             style={{
//               position: "absolute",
//               top: "10px",
//               right: "10px",
//               backgroundColor: "transparent",
//               border: "none",
//               fontSize: "24px",
//               cursor: "pointer",
//               color: "#333",
//             }}
//             aria-label="Close Modal"
//           >
//             &times;
//           </button>
//           <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
//             Edit Student Details
//           </h2>
//           <form onSubmit={handleEditFormSubmit}>
//             <div style={{ marginBottom: "15px" }}>
//               <label>
//                 Name:
//                 <input
//                   type="text"
//                   name="name"
//                   value={editStudent?.name || ""}
//                   onChange={handleEditFormChange}
//                   style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//                 />
//               </label>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <label>
//                 Course Fee:
//                 <input
//                   type="number"
//                   name="courseFee"
//                   value={editStudent?.courseFee || ""}
//                   onChange={handleEditFormChange}
//                   style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//                 />
//               </label>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <label>
//                 Amount Received:
//                 <input
//                   type="number"
//                   name="amountReceived"
//                   value={editStudent?.amountReceived || ""}
//                   onChange={handleEditFormChange}
//                   style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//                 />
//               </label>
//             </div>
//             <div style={{ marginBottom: "15px" }}>
//               <label>
//                 Current Due:
//                 <input
//                   type="number"
//                   name="currentDue"
//                   value={editStudent?.currentDue || ""}
//                   readOnly
//                   style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//                 />
//               </label>
//             </div>
//             <button type="submit" style={buttonStyle}>
//               Save Changes
//             </button>
//           </form>
//         </Modal>

//         {/* Receipt Modal */}
//       {/* Receipt Modal */}
// <Modal
//   isOpen={receiptModalIsOpen}
//   onRequestClose={() => setReceiptModalIsOpen(false)}
//   contentLabel="Receipt"
//   style={{
//     content: {
//       position: "fixed",
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       width: "90%",
//       maxWidth: "800px",
//       maxHeight: "80vh",
//       padding: "20px",
//       backgroundColor: "#f7f7f7",
//       border: "1px solid #ddd",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//       overflowY: "auto",
//       overflowX: "hidden",
//       zIndex: 1050,
//     },
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       zIndex: 1040,
//     },
//   }}
// >
//   <button
//     onClick={() => setReceiptModalIsOpen(false)}
//     style={{
//       position: "absolute",
//       top: "10px",
//       right: "10px",
//       backgroundColor: "transparent",
//       border: "none",
//       fontSize: "24px",
//       cursor: "pointer",
//       color: "#333",
//     }}
//     aria-label="Close Modal"
//   >
//     &times;
//   </button>
//   <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
//     Receipt for {selectedStudent?.name}
//   </h2>
//   <div>
//     <p><strong>Registration No:</strong> {selectedStudent?.RegNo}</p>
//     <p><strong>Name:</strong> {selectedStudent?.name}</p>
//     <p><strong>Course:</strong> {selectedStudent?.course}</p>
//     <p><strong>Amount Received:</strong> ${selectedStudent?.amountReceived}</p>
//     <p><strong>Current Due:</strong> ${selectedStudent?.currentDue}</p>
    
//     <div style={{ marginTop: "20px" }}>
//       <h3>Add Payment</h3>
//       <form onSubmit={handleAddPayment}>
//         <div style={{ marginBottom: "15px" }}>
//           <label>
//             Amount:
//             <input
//               type="number"
//               value={paymentAmount}
//               onChange={(e) => setPaymentAmount(e.target.value)}
//               style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//             />
//           </label>
//         </div>
//         <div style={{ marginBottom: "15px" }}>
//           <label>
//             Date:
//             <input
//               type="date"
//               value={paymentDate}
//               onChange={(e) => setPaymentDate(e.target.value)}
//               style={{ padding: "8px", fontSize: "16px", border: "1px solid #ddd", borderRadius: "4px", width: "100%" }}
//             />
//           </label>
//         </div>
//         <button type="submit" style={buttonStyle}>
//           Add Payment
//         </button>
//       </form>
//     </div>
//   </div>
// </Modal>

//       </div>
//     </>
//   );
// }

// export default Studentview;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { PiStudentBold } from "react-icons/pi";
import { FaFilePen } from "react-icons/fa6";
import { BiSolidReceipt } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Leftbar from "../Leftbar/Leftbar";
import Modal from "react-modal";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function Studentview() {
  const [students, setStudents] = useState([]);

  const [selectedStudentId, setSelectedStudentId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(5);
  const [editStudent, setEditStudent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [receiptModalIsOpen, setReceiptModalIsOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [style, setStyle] = useState({});
  const [sortOrder, setSortOrder] = useState("asc");
  const [nameSortOrder, setNameSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [studentCount, setStudentCount] = useState(0);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const[AvailableSoftware,setAvailableSoftware]=useState("")

  const [courseIdentifiers, setCourseIdentifiers] = useState({
    DCA: "DCA",
    "ADVANCE PGDCA": "APG",
    PGDCA: "PG",
    MECHANICAL: "MCAD",
    ELECTRICAL: "ECAD",
    "CIVIL & ARCH": "ACAD",
    "IT & CS": "IT",
    TALLY: "TY",
    PHOTOSHOP: "PS",
    "MS OFFICE": "MSO",
    "ADVANCE EXCEL": "AE",
    "BASIC COMPUTER": "BC",
   "DIPLOMA MECH CAD":"DMCAD",
    "PROFESSIONAL MECH CAD":"PMCAD",
    "DIPLOMA CIVIL CAD":"DACD",
    "PROFESSIONAL CIVIL CAD":"PACAD",
    "DIPLOMA ELECTRICAL CAD":"DECAD",
   "PROFESSIONAL ELECTRCIAL CAD":"PECAD",
   "INTERIOR DESIGNING":"IAD"
  });



  // EditStudentForm component

  
    // Set available software based on the course
    useEffect(() => {
      if (editStudent && editStudent.course) {
        const suggestedSoftware = softwareOptions[editStudent.course.toUpperCase()] || [];
        setAvailableSoftware(editStudent.softwareCovered || suggestedSoftware);
      }
    }, [editStudent]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get("/api/students");
        setStudents(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching student data:", error);
        setStudents([]);
      }
    };

    fetchStudents();
  }, []);

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await axios.get("/api/student-count");
        setStudentCount(response.data.count);
      } catch (error) {
        console.error("Error fetching student count:", error);
      }
    };

    fetchStudentCount();
  }, []);

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 768) {
        setStyle({
          marginLeft: "10px",
          marginTop: "20px",
          cardWidth: "100%",
          tableFontSize: "14px",
        });
      } else {
        setStyle({
          marginLeft: "300px",
          marginTop: "-400px",
          cardWidth: "20%",
          tableFontSize: "16px",
        });
      }
    };

    updateStyles();
    window.addEventListener("resize", updateStyles);
    return () => window.removeEventListener("resize", updateStyles);
  }, []);

  useEffect(() => {
    if (editStudent && !editStudent.RegNo && editStudent.course) {
      const courseIdentifier = courseIdentifiers[editStudent.course] || "XXX";
      const incrementedNumber = studentCount + 301;

      setEditStudent((prevStudent) => ({
        ...prevStudent,
        RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
      }));
    }
  }, [editStudent, courseIdentifiers, studentCount]);

  const handleEditClick = (student) => {
    setEditStudent(student);
    setModalIsOpen(true);
  };

  const handleReceiptClick = (student) => {
    setSelectedStudent(student);
    setReceiptModalIsOpen(true);
  };

  // const handleAddPayment = async (e) => {
  //   e.preventDefault();
  
  //   if (selectedStudent.installmentsPaid >= 3) {
  //     alert("Maximum number of installments (3) has already been reached. No more payments can be added.");
  //     return;
  //   }
  
  //   const parsedPaymentAmount = parseFloat(paymentAmount);
  //   const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parsedPaymentAmount;
  //   const newCurrentDue = selectedStudent.courseFee - newAmountReceived;
  
  //   const newPayment = {
  //     amount: parsedPaymentAmount,
  //     date: paymentDate,
  //   };
  
  //   try {
  //     const response = await axios.put(`/api/students/update-by-regno`, {
  //       RegNo: selectedStudent.RegNo,
  //       amountReceived: newAmountReceived,
  //       currentDue: newCurrentDue,
  //       paymentDate: paymentDate,
  //       additionalPayment: newPayment,
  //     });
  
  //     setStudents((prevStudents) =>
  //       prevStudents.map((student) =>
  //         student.RegNo === selectedStudent.RegNo
  //           ? response.data
  //           : student
  //       )
  //     );
  
  //     setPaymentAmount('');
  //     setPaymentDate('');
  //     setReceiptModalIsOpen(false);
  
  //     const installmentsPaid = response.data.installmentsPaid;
  //     if (installmentsPaid >= 3) {
  //       alert(`Payment added successfully. All 3 installments have been paid. No more installments can be added.`);
  //     } else {
  //       alert(`Payment added successfully. ${installmentsPaid} out of 3 installments paid.`);
  //     }
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //     if (error.response?.status === 400 && error.response.data.includes("Maximum number of installments")) {
  //       alert("Maximum number of installments (3) has already been reached. No more payments can be added.");
  //     } else {
  //       alert(error.response?.data || "Error adding payment. Please try again.");
  //     }
  //   }
  // };

  // const installmentsPaid = selectedStudent?.installmentsPaid || 0;
  // const remainingInstallments = Math.max(3 - installmentsPaid, 0);
  // const isFullyPaid = selectedStudent?.currentDue <= 0;
  // const isMaxInstallmentsReached = installmentsPaid >= 3;

  // const getInstallmentMessage = () => {
  //   if (isFullyPaid) return "Fully paid";
  //   if (isMaxInstallmentsReached) return "Maximum installments reached";
  //   return `${remainingInstallments} installment${remainingInstallments !== 1 ? 's' : ''} remaining`;
  // };

  // const getButtonText = () => {
  //   if (isFullyPaid) return "Fully Paid";
  //   if (isMaxInstallmentsReached) return "No More Installments";
  //   return `Add Payment (${remainingInstallments} left)`;
  // };

  const handleAddPayment = async (e) => {
    e.preventDefault();

    if (!selectedStudent) return;

    if (selectedStudent.installmentsPaid >= 3) {
      alert("Maximum number of installments (3) has already been reached. No more payments can be added.");
      return;
    }

    const parsedPaymentAmount = parseFloat(paymentAmount);
    const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parsedPaymentAmount;
    const newCurrentDue = selectedStudent.courseFee - newAmountReceived;

    // Check if this is the third installment
    if (selectedStudent.installmentsPaid === 2) {
      // For the third installment, ensure the payment amount matches the current due
      if (parsedPaymentAmount !== selectedStudent.currentDue) {
        alert(`For the final installment, the payment amount must be equal to the current due: ${selectedStudent.currentDue}`);
        return;
      }
    }

    const newPayment = {
      amount: parsedPaymentAmount,
      date: paymentDate,
    };

    try {
      const response = await axios.put(`/api/students/update-by-regno`, {
        RegNo: selectedStudent.RegNo,
        amountReceived: newAmountReceived,
        currentDue: newCurrentDue,
        paymentDate: paymentDate,
        additionalPayment: newPayment,
      });

      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.RegNo === selectedStudent.RegNo
            ? response.data
            : student
        )
      );

      setPaymentAmount('');
      setPaymentDate('');
      setReceiptModalIsOpen(false);

      const installmentsPaid = response.data.installmentsPaid;
      if (installmentsPaid >= 3) {
        alert(`Payment added successfully. All 3 installments have been paid. No more installments can be added.`);
      } else {
        alert(`Payment added successfully. ${installmentsPaid} out of 3 installments paid.`);
      }
    } catch (error) {
      console.error("Error adding payment:", error);
      if (error.response?.status === 400 && error.response.data.includes("Maximum number of installments")) {
        alert("Maximum number of installments (3) has already been reached. No more payments can be added.");
      } else {
        alert(error.response?.data || "Error adding payment. Please try again.");
      }
    }
  };

  const getInstallmentMessage = () => {
    if (!selectedStudent) return "";
  
    const installmentsPaid = selectedStudent.installmentsPaid || 0;
    const remainingInstallments = Math.max(3 - installmentsPaid, 0);
    const isFullyPaid = selectedStudent.currentDue <= 0;
    const isMaxInstallmentsReached = installmentsPaid >= 3;
  
    if (isFullyPaid) return "Fully paid";
    if (isMaxInstallmentsReached) return "Maximum installments reached";
  
    if (remainingInstallments === 1) {
      return `Final installment: Payment must be equal to the current due of ${selectedStudent.currentDue}`;
    }
  
    return `${remainingInstallments} installment${remainingInstallments !== 1 ? 's' : ''} remaining`;
  };

  const getButtonText = () => {
    if (!selectedStudent) return "";

    const installmentsPaid = selectedStudent.installmentsPaid || 0;
    const remainingInstallments = Math.max(3 - installmentsPaid, 0);
    const isFullyPaid = selectedStudent.currentDue <= 0;
    const isMaxInstallmentsReached = installmentsPaid >= 3;

    if (isFullyPaid) return "Fully Paid";
    if (isMaxInstallmentsReached) return "No More Installments";
    if (remainingInstallments === 1) return `Add Final Payment (${selectedStudent.currentDue})`;
    return `Add Payment (${remainingInstallments} left)`;
  };

  const isFullyPaid = selectedStudent ? selectedStudent.currentDue <= 0 : false;
  const isMaxInstallmentsReached = selectedStudent ? selectedStudent.installmentsPaid >= 3 : false;


  // const handleAddPayment = async (e) => {
  //   e.preventDefault();
  
  //   // Convert payment amount to a number
  //   const parsedPaymentAmount = parseFloat(paymentAmount);
  
  //   // Check if this would exceed the maximum number of installments
  //   if ((selectedStudent.additionalPayments?.length || 0) >= 3) {
  //     alert("Maximum number of installments (3) reached.");
  //     return;
  //   }
  
  //   // Calculate new values
  //   const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parsedPaymentAmount;
  //   const newCurrentDue = selectedStudent.courseFee - newAmountReceived;
  
  //   // Create a new payment object
  //   const newPayment = {
  //     amount: parsedPaymentAmount,
  //     date: paymentDate,
  //   };
  
  //   try {
  //     // Update student record including additional payments
  //     const response = await axios.put(`/api/students/update-by-regno`, {
  //       RegNo: selectedStudent.RegNo,
  //       amountReceived: newAmountReceived,
  //       currentDue: newCurrentDue,
  //       paymentDate: paymentDate,
  //       additionalPayments: [...(selectedStudent.additionalPayments || []), newPayment],
  //     });
  
  //     // Update the local student state
  //     setStudents((prevStudents) =>
  //       prevStudents.map((student) =>
  //         student.RegNo === selectedStudent.RegNo
  //           ? { 
  //               ...student, 
  //               amountReceived: newAmountReceived, 
  //               currentDue: newCurrentDue, 
  //               additionalPayments: [...(student.additionalPayments || []), newPayment],
  //               installmentsPaid: (student.additionalPayments?.length || 0) + 1
  //             }
  //           : student
  //       )
  //     );
  
  //     // Reset form fields
  //     setPaymentAmount('');
  //     setPaymentDate('');
  //     setReceiptModalIsOpen(false);
  
  //     // Display the number of installments paid
  //     alert(`Payment added successfully. ${response.data.installmentsPaid} out of 3 installments paid.`);
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //     alert("Error adding payment. Please try again.");
  //   }
  // };
//correctly working 
  // const handleAddPayment = async (e) => {
  //   e.preventDefault();
  
  //   // Convert payment amount to a number
  //   const parsedPaymentAmount = parseFloat(paymentAmount);
  
  //   // Calculate new values
  //   const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parsedPaymentAmount;
  //   const newCurrentDue = selectedStudent.courseFee - newAmountReceived;
  
  //   // Create a new payment object
  //   const newPayment = {
  //     amount: parsedPaymentAmount,
  //     date: paymentDate,
  //   };
  
  //   try {
  //     // Update student record including additional payments
  //     await axios.put(`/api/students/update-by-regno`, {
  //       RegNo: selectedStudent.RegNo,
  //       amountReceived: newAmountReceived,
  //       currentDue: newCurrentDue,
  //       paymentDate: paymentDate,
  //       additionalPayments: [...(selectedStudent.additionalPayments || []), newPayment], // Add new payment to existing array
  //     });
  
  //     // Update the local student state
  //     setStudents((prevStudents) =>
  //       prevStudents.map((student) =>
  //         student.RegNo === selectedStudent.RegNo
  //           ? { ...student, amountReceived: newAmountReceived, currentDue: newCurrentDue, additionalPayments: [...(student.additionalPayments || []), newPayment] }
  //           : student
  //       )
  //     );
  
  //     // Reset form fields
  //     setPaymentAmount('');
  //     setPaymentDate('');
  //     setReceiptModalIsOpen(false);
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //   }
  // };
  

  // const handleAddPayment = async (e) => {
  //   e.preventDefault();

  //   const newAmountReceived = parseFloat(selectedStudent.amountReceived) + parseFloat(paymentAmount);
  //   const newCurrentDue = selectedStudent.courseFee - newAmountReceived;

  //   try {
  //     await axios.put(`/api/students/update-by-regno`, {
  //       RegNo: selectedStudent.RegNo,
  //       amountReceived: newAmountReceived,
  //       currentDue: newCurrentDue,
  //       paymentDate: paymentDate,
  //     });

  //     setStudents((prevStudents) =>
  //       prevStudents.map((student) =>
  //         student.RegNo === selectedStudent.RegNo
  //           ? { ...student, amountReceived: newAmountReceived, currentDue: newCurrentDue }
  //           : student
  //       )
  //     );
  //     setPaymentAmount(''); 
  //     setPaymentDate('');
  //     setReceiptModalIsOpen(false);
  //   } catch (error) {
  //     console.error("Error adding payment:", error);
  //   }
  // };

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditStudent(prevStudent => {
      const updatedStudent = { ...prevStudent, [name]: value };

      if (name === 'courseFee' || name === 'amountReceived') {
        const courseFee = parseFloat(updatedStudent.courseFee) || 0;
        const amountReceived = parseFloat(updatedStudent.amountReceived) || 0;
        updatedStudent.currentDue = courseFee - amountReceived;
      }

      return updatedStudent;
    });
  };
 


  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    const courseFee = parseFloat(editStudent.courseFee) || 0;
    const amountReceived = parseFloat(editStudent.amountReceived) || 0;
    const currentDue = courseFee - amountReceived;

    const updatedStudent = { ...editStudent, currentDue };

    try {
      await axios.put(`/api/students/${editStudent._id}`, updatedStudent);
      const response = await axios.get("/api/students");
      setStudents(Array.isArray(response.data) ? response.data : []);
      setModalIsOpen(false);
    } catch (error) {
      console.error("Error updating student data:", error);
    }
  };

  const handleDeleteClick = async (studentId) => {
    try {
      await axios.delete(`/api/students/${studentId}`);
      const response = await axios.get("/api/students");
      setStudents(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error deleting student data:", error);
    }
  };
  useEffect(() => {
    if (selectedStudentId) {
      const student = students.find(s => s.RegNo === selectedStudentId);
      setSelectedStudent(student);
      // Save to local storage
      localStorage.setItem('selectedStudent', JSON.stringify(student));
    }
  }, [students, selectedStudentId]);

  useEffect(() => {
    // Retrieve from local storage
    const savedStudent = JSON.parse(localStorage.getItem('selectedStudent'));
    if (savedStudent) {
      setSelectedStudent(savedStudent);
    }
  }, []);
  const handleSortByRegNo = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.RegNo.localeCompare(b.RegNo);
      } else {
        return b.RegNo.localeCompare(a.RegNo);
      }
    });
    setStudents(sortedStudents);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSortByName = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (nameSortOrder === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setStudents(sortedStudents);
    setNameSortOrder(nameSortOrder === "asc" ? "desc" : "asc");
  };

  const filteredStudents = students.filter(
    (student) =>
      student.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const paginationStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  };

  const buttonStyle = {
    padding: "5px 10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    cursor: "pointer",
    color: "black",
  };
  const handlePrint = (student) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("<style>");
    printWindow.document.write(`
      body { font-family: Arial, sans-serif; }
      .header { background-color: #ffc800; padding: 20px; text-align: center; display: flex; align-items: center; justify-content: center; }
      .header img { height: 50px; margin-right: 20px; }
      .header h2 { margin: 0; }
      .header h3 { margin: 0; }
      .content { padding: 20px; }
      .detail-box { border: 1px solid #ddd; padding: 10px; margin-bottom: 10px; }
      .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
      .field { width: 30%; }
      button { 
        background-color: #007bff; 
        color: white; 
        border: none; 
        padding: 10px 20px; 
        cursor: pointer; 
        border-radius: 5px; 
        font-size: 16px; 
      }
      button:hover { background-color: #FFB237; }
      .receipt-title { 
        text-align: center; 
        margin: 20px 0; 
        font-size: 24px; 
        font-weight: bold; 
      }
    `);
    printWindow.document.write("</style></head><body>");
  
    // Add header with logo
    printWindow.document.write('<div class="header">');
    printWindow.document.write(
      '<img src="/images/newlogo.jpg" alt="Logo">'
    );
    printWindow.document.write("<h2>SHAW TECH TRAINING & SERVICE</h2>");
    printWindow.document.write("</div>");
  
    // Centered receipt title
    printWindow.document.write('<div class="receipt-title">STUDENT RECEIPT FORM</div>');
  
    // Add content
    printWindow.document.write('<div class="content">');
    printWindow.document.write("<h2>Student Details</h2>");
  
    // Student details
    const details = [
      { label: "Name", value: student.name },
      { label: "Reg No", value: student.RegNo },
      { label: "Course Title", value: student.course },
      { label: "Father Name", value: student.fatherName },
      { label: "Receipt No", value: student.receiptId },
      { label: "Course Fee", value: `₹${student.courseFee}` },
      {
        label: "Initial Payment",
        value: `₹${student.amountReceived} on ${new Date(
          student.Date
        ).toLocaleDateString('en-GB')}`,
      },
    ];
  
    for (let i = 0; i < details.length; i += 3) {
      printWindow.document.write('<div class="row">');
      for (let j = i; j < i + 3 && j < details.length; j++) {
        printWindow.document.write(`
          <div class="field">
            <strong>${details[j].label}:</strong> ${details[j].value}
          </div>
        `);
      }
      printWindow.document.write("</div>");
    }
  
    // Additional payments
    if (student.additionalPayments && student.additionalPayments.length > 0) {
      printWindow.document.write("<h3>Additional Payments</h3>");
      for (let i = 0; i < student.additionalPayments.length; i += 3) {
        printWindow.document.write('<div class="row">');
        for (
          let j = i;
          j < i + 3 && j < student.additionalPayments.length;
          j++
        ) {
          const payment = student.additionalPayments[j];
          printWindow.document.write(`
            <div class="field">
              <strong>Installment ${j + 1}:</strong> ₹${payment.amount} on ${new Date(payment.date).toLocaleDateString('en-GB')}
            </div>
          `);
        }
        printWindow.document.write("</div>");
      }
    }
  
    // Payment summary
    const totalPaid =
      parseFloat(student.amountReceived) +
      (student.additionalPayments
        ? student.additionalPayments.reduce(
            (sum, payment) => sum + parseFloat(payment.amount),
            0
          )
        : 0);
    const currentDue = Math.max(0, parseFloat(student.courseFee) - totalPaid);
  
    printWindow.document.write("<h3>Payment Summary</h3>");
    printWindow.document.write('<div class="row">');
    printWindow.document.write(`
      <div class="field"><strong>Total Paid:</strong> ₹${totalPaid.toFixed(2)}</div>
      <div class="field"><strong>Current Due:</strong> ₹${currentDue.toFixed(2)}</div>
    `);
    printWindow.document.write("</div>");
  
    printWindow.document.write("</div>"); // Close content div
    printWindow.document.write(
      '<button onclick="window.print()">Print</button>'
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
  };
 
  const softwareOptions = {
    "ADVANCE EXCEL": [
      "Microsoft Excel"
    ],
    "ADVANCE PGDCA": [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "MS Office",
     "Internet",
     "Paint",
  "Notepad",
  "WordPad",
  "C",
  "C++",
  "HTML",
  "TALLY",
 ],
    "BASIC COMPUTER": [
      "Paint",
"Notepad",
"WordPad",
"MS Office",
"C",
"C++",
"HTML",
"TALLY",
"Ms DOs & Windows",
"Internet"
    ],
   "CIVIL & ARCH": [
    "Auto CAD",
    "Revit Architecture",
    "Revit Structure",
    "Sketch up",
    "3Ds Max",
    "Vray",
    "Enscape",
    "Lumion",
    "Tekla",
    "Quantity Take Off",
    "Staad Pro",
    "ETAB",
    "PRIMAVERA"
  ],
    DCA: [
      "Paint",
  "Notepad",
  "WordPad",
  "MS Office",
  "C",
  "C++",
  "HTML",
  "TALLY",
  "Ms DOs & Windows",
  "Internet",   "Software Hacking and IT Security",
  "ER Basics",
  
  "E-Business",
      
    ],
    "DIPLOMA CIVIL CAD":[
      "Auto CAD",
      "Revit Architecture",
      "Revit Structure",
      "Sketch up",
      "3Ds Max",
      "Vray",
      "Enscape",
      "Lumion",
      "Tekla",
      "Quantity Take Off",
      "Staad Pro",
      "ETAB",
      "PRIMAVERA"
    ],
    "DIPLOMA ELECTRICAL CAD":[
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
    "DIPLOMA MECH CAD":[
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    ELECTRICAL: [
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
   "INTERIOR DESIGNING":[
    "Auto CAD",
    "Revit Architecture",
    "Revit Structure",
    "Sketch up",
    "3Ds Max",
    "Vray",
    "Enscape",
    "Lumion",
    "Tekla",
    "Quantity Take Off",
    "Staad Pro",
    "ETAB",
    "PRIMAVERA"
  ],
    "IT & CS": [
      "C",
      "C++",
      "Java",
      "Python",
      "Power BI"
    ],
    
    MECHANICAL: [
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    
    
    
    
    "MS OFFICE": [
      "Microsoft Word",
      "Microsoft Excel",
      "Microsoft PowerPoint"
    ],
    PGDCA:  [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "MS Office",
     "Internet",
     "Paint",
  "Notepad",
  "WordPad",
  "C",
  "C++",
  "HTML",
  "TALLY",
 ],
    PHOTOSHOP: [
      "Basics of Photoshop",
      "Image Editing",
      "Layer Management",
      "Working with Paths"
    ],
    "PROFESSIONAL CIVIL CAD":[
      "Auto CAD",
      "Revit Architecture",
      "Revit Structure",
      "Sketch up",
      "3Ds Max",
      "Vray",
      "Enscape",
      "Lumion",
      "Tekla",
      "Quantity Take Off",
      "Staad Pro",
      "ETAB",
      "PRIMAVERA"
    ],
    "PROFESSIONAL ELECTRCIAL CAD":[
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
    "PROFESSIONAL MECH CAD":[
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    TALLY: [
      "Company Creation",
      "Accounting Features",
      "Inventory Management"
    ],
    
    
    
   
  };
  
  // const handlePrint = (student) => {
  //   const printWindow = window.open("", "_blank");
  //   printWindow.document.write("<html><head><title>Print</title>");
  //   printWindow.document.write("<style>");
  //   printWindow.document.write(`
  //     body { font-family: Arial, sans-serif; }
  //     .detail-box { border: 1px solid #ddd; padding: 10px; margin-bottom: 10px; }
  //     .header { background-color: #ffc800; padding: 20px; text-align: center; }
  //     .content { padding: 20px; }
  //     .row { display: flex; justify-content: space-between; margin-bottom: 10px; }
  //     .field { width: 30%; }
  //     button { 
  //       background-color: #007bff; 
  //       color: white; 
  //       border: none; 
  //       padding: 10px 20px; 
  //       border-radius: 5px; 
  //       cursor: pointer; 
  //     }
  //   `);
  //   printWindow.document.write("</style></head><body>");
  //   printWindow.document.write(`<div class="header"><h1>Receipt for ${student.name}</h1></div>`);
  //   printWindow.document.write(`<div class="content">`);
  //   printWindow.document.write(`<div class="detail-box">
  //     <div class="row">
  //       <div class="field"><strong>Reg No:</strong> ${student.RegNo}</div>
  //       <div class="field"><strong>Course:</strong> ${student.course}</div>
  //     </div>
  //     <div class="row">
  //       <div class="field"><strong>Course Fee:</strong> ${student.courseFee}</div>
  //       <div class="field"><strong>Amount Received:</strong> ${student.amountReceived}</div>
  //     </div>
  //     <div class="row">
  //       <div class="field"><strong>Current Due:</strong> ${student.currentDue}</div>
  //     </div>
  //   </div>`);
  //   printWindow.document.write(`<button onclick="window.print()">Print</button>`);
  //   printWindow.document.write("</div></body></html>");
  //   printWindow.document.close();
  //   printWindow.focus();
  // };
  // const handleDownloadPDF = (student) => {

    

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const margin = 10;
  //   const lineHeight = 7;
  //   const logoPath = "/images/newlogo.jpg";
  
  //   // Add logo
  //   pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);
  
  //   // Add title
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(24);
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("RECEIPT", margin, 20);
  
  //   // Add horizontal line
  //   pdf.setDrawColor(0);
  //   pdf.line(margin, 25, pageWidth - margin, 25);
  
  //   // Add header
  //   pdf.setTextColor(0, 128, 0);
  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("SHAW TECH TRAINING AND", margin, 35);
  //   pdf.text("SERVICES", margin, 40);
  //   pdf.setTextColor(0);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(10);
  //   pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
  //   pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
  //   pdf.text("Ph: +91-9692114217 ", margin, 55);
  //   pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);
  
  //   // Add Student Reg. No.
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Reg. No.", pageWidth - 80, 35);
  //   pdf.rect(pageWidth - 50, 30, 40, 7);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(student.RegNo, pageWidth - 48, 35);
  
  //   // Main content area
  //   const contentStartY = 65;
  //   const contentWidth = pageWidth - 2 * margin;
  //   const contentHeight = 80; // Adjusted height
  //   pdf.rect(margin, contentStartY, contentWidth, contentHeight);
  
  //   // Table headers and content
  //   const col1 = margin + 5;
  //   const col2 = margin + 35; // Reduced gap
  //   const col3 = pageWidth / 2 + 15; // Moved right column further right
  //   const col4 = pageWidth / 2 + 40; // Adjusted value column
  
  //   const addRow = (label, value, label2, value2, y) => {
  //     pdf.setFont("helvetica", "bold");
  //     pdf.text(label, col1, y);
  //     if (label2) pdf.text(label2, col3, y);
  //     pdf.setFont("helvetica", "normal");
  //     pdf.text(`: ${value}`, col2, y);
  //     if (value2) pdf.text(`: ${value2}`, col4, y);
  //   };
  
  //   let currentY = contentStartY + 10;
  //   addRow("Student Name", student.name, "Receipt No.", student.receiptId, currentY);
  //   currentY += lineHeight;
  //   addRow("Address", student.Address, "Date", new Date(student.Date).toLocaleDateString(), currentY);
  //   currentY += lineHeight;
  
  //   // Handle long course titles
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Course Title", col1, currentY);
  //   pdf.text("Course Fee", col3, currentY);
  //   pdf.setFont("helvetica", "normal");
  //   const splitTitle = pdf.splitTextToSize(`: ${student.course}`, col3 - col2 - 5);
  //   pdf.text(splitTitle, col2, currentY);
  //   pdf.text(`: ${student.courseFee} Rs.`, col4, currentY);
  //   currentY += Math.max(splitTitle.length * 5, lineHeight);
  
  //   addRow("Initial Payment", `${student.initialPayment.amount} Rs.`, "Date", new Date(student.initialPayment.date).toLocaleDateString(), currentY);
  //   currentY += lineHeight;
  
  //   // Additional Payments (Installments)
  //   const installments = student.additionalPayments || [];
  //   installments.slice(0, 3).forEach((installment, index) => {
  //     addRow(`Installment ${index + 1}`, `${installment.amount} Rs.`, "Date", new Date(installment.date).toLocaleDateString(), currentY);
  //     currentY += lineHeight;
  //   });
  
  //   // Remaining Content
  //   currentY += lineHeight;
  //   addRow("Payment By", student.PaymentBy, "Total Fee", `${student.courseFee} Rs.`, currentY);
  //   currentY += lineHeight;
  //   addRow("Amount Received", `${student.amountReceived} Rs.`, "Current Due", `${student.currentDue} Rs.`, currentY);
  
  //   // Terms and conditions
  //   currentY = contentStartY + contentHeight + 10;
  //   pdf.setFontSize(10);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Terms & Conditions:", margin, currentY);
  
  //   pdf.setFont("helvetica", "normal");
  //   const terms = [
  //     "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
  //     "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
  //     "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
  //     "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
  //   ];
  
  //   terms.forEach((term) => {
  //     currentY += 13;
  //     pdf.text(term, margin, currentY, { maxWidth: pageWidth - 2 * margin });
  //   });
    
  //   // Signature box
  //   currentY += 15;
  //   pdf.rect(margin, currentY, pageWidth - 2 * margin, 30);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, currentY + 10);
  //   pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, currentY + 15);
  
  //   pdf.text("Student's Signature", margin + 5, currentY + 25);
  //   pdf.text("Authorized Center", pageWidth - 60, currentY + 25);
  //   pdf.text("(Seal & Signature)", pageWidth - 60, currentY + 35);
  
  //   pdf.save("receipt.pdf");
  
  // };

  const handleDownloadPDF = async (student) => {
    try {
      // Fetch the current receipt count from the backend and increment it
      const response = await fetch("/api/incrementReceiptCount", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
  
      const { newReceiptId } = await response.json();
  
      // Update the student object with the new receipt ID
      student.receiptId = newReceiptId;
  
      // Initialize PDF creation
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 10;
      const lineHeight = 7;
      const logoPath = "/images/newlogo.jpg";
  
      // Add logo
      pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);
  
      // Add title
      pdf.setFont("helvetica", "bold");
      pdf.setFontSize(24);
      pdf.setTextColor(0, 0, 0);
      pdf.text("RECEIPT", margin, 20);
  
      // Add horizontal line
      pdf.setDrawColor(0);
      pdf.line(margin, 25, pageWidth - margin, 25);
  
      // Add header
      pdf.setTextColor(0, 128, 0);
      pdf.setFontSize(14);
      pdf.setFont("helvetica", "bold");
      pdf.text("SHAW TECH TRAINING AND", margin, 35);
      pdf.text("SERVICES", margin, 40);
      pdf.setTextColor(0);
      pdf.setFont("helvetica", "normal");
      pdf.setFontSize(10);
      pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
      pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
      pdf.text("Ph: +91-9692114217 ", margin, 55);
      pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);
  
      // Add Student Reg. No.
      pdf.setFont("helvetica", "bold");
      pdf.text("Student Reg. No.", pageWidth - 80, 35);
      pdf.rect(pageWidth - 50, 30, 40, 7);
      pdf.setFont("helvetica", "normal");
      pdf.text(student.RegNo, pageWidth - 48, 35);
  
      // Main content area
      const contentStartY = 65;
      const contentWidth = pageWidth - 2 * margin;
      const contentHeight = 80; // Adjusted height
      pdf.rect(margin, contentStartY, contentWidth, contentHeight);
  
      // Table headers and content
      const col1 = margin + 5;
      const col2 = margin + 35; // Reduced gap
      const col3 = pageWidth / 2 + 15; // Moved right column further right
      const col4 = pageWidth / 2 + 40; // Adjusted value column
  
      const addRow = (label, value, label2, value2, y) => {
        pdf.setFont("helvetica", "bold");
        pdf.text(label, col1, y);
        if (label2) pdf.text(label2, col3, y);
        pdf.setFont("helvetica", "normal");
        pdf.text(`: ${value}`, col2, y);
        if (value2) pdf.text(`: ${value2}`, col4, y);
      };
  
      let currentY = contentStartY + 10;
      addRow("Student Name", student.name, "Receipt No.", student.receiptId, currentY);
      currentY += lineHeight;
      // addRow("Address", student.Address, "Date", new Date(student.Date).toLocaleDateString(), currentY);
      addRow("Address", student.Address, "Date", new Date(student.Date).toLocaleDateString('en-GB'), currentY);

      currentY += lineHeight;
  
      // Handle long course titles
      pdf.setFont("helvetica", "bold");
      pdf.text("Course Title", col1, currentY);
      pdf.text("Course Fee", col3, currentY);
      pdf.setFont("helvetica", "normal");
      const splitTitle = pdf.splitTextToSize(`: ${student.course}`, col3 - col2 - 5);
      pdf.text(splitTitle, col2, currentY);
      pdf.text(`: ${student.courseFee} Rs.`, col4, currentY);
      currentY += Math.max(splitTitle.length * 5, lineHeight);
  
      // addRow("Initial Payment", `${student.initialPayment.amount} Rs.`, "Date", new Date(student.initialPayment.date).toLocaleDateString(), currentY);
      addRow("Initial Payment", `${student.initialPayment.amount} Rs.`, "Date", new Date(student.initialPayment.date).toLocaleDateString('en-GB'), currentY);

      currentY += lineHeight;
  
      // Additional Payments (Installments)
      const installments = student.additionalPayments || [];
      installments.slice(0, 3).forEach((installment, index) => {
        addRow(`Installment ${index + 1}`, `${installment.amount} Rs.`, "Date", new Date(installment.date).toLocaleDateString('en-GB'), currentY);
        currentY += lineHeight;
      });
  
      // Remaining Content
      currentY += lineHeight;
      addRow("Payment By", student.PaymentBy, "Total Fee", `${student.courseFee} Rs.`, currentY);
      currentY += lineHeight;
      addRow("Amount Received", `${student.amountReceived} Rs.`, "Current Due", `${student.currentDue} Rs.`, currentY);
  
      // Terms and conditions
      currentY = contentStartY + contentHeight + 10;
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      pdf.text("Terms & Conditions:", margin, currentY);
  
      pdf.setFont("helvetica", "normal");
      const terms = [
        "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
        "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
        "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
        "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
      ];
  
      terms.forEach((term) => {
        currentY += 13;
        pdf.text(term, margin, currentY, { maxWidth: pageWidth - 2 * margin });
      });
  
      // Signature box
      currentY += 15;
      pdf.rect(margin, currentY, pageWidth - 2 * margin, 30);
      pdf.setFont("helvetica", "bold");
      pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, currentY + 10);
      pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, currentY + 15);
  
      pdf.text("Student's Signature", margin + 5, currentY + 25);
      pdf.text("Authorized Center", pageWidth - 60, currentY + 25);
      pdf.text("(Seal & Signature)", pageWidth - 60, currentY + 35);
  
      pdf.save("receipt.pdf");
  
    } catch (error) {
      console.error("Error generating PDF or updating receipt count:", error);
    }
  };
  

  

////courrently working
  // const handleDownloadPDF = (student) => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const margin = 10;
  //   const lineHeight = 7;
  //   const logoPath = "/images/newlogo.jpg";
  
  //   // Add logo
  //   pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);
  
  //   // Add title
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(24);
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("RECEIPT", margin, 20);
  
  //   // Add horizontal line
  //   pdf.setDrawColor(0);
  //   pdf.line(margin, 25, pageWidth - margin, 25);
  
  //   // Add header
  //   pdf.setTextColor(0, 128, 0);
  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("SHAW TECH TRAINING AND", margin, 35);
  //   pdf.text("SERVICES", margin, 40);
  //   pdf.setTextColor(0);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(10);
  //   pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
  //   pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
  //   pdf.text("Ph: +91-9692114217 ", margin, 55);
  //   pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);
  
  //   // Add Student Reg. No.
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Reg. No.", pageWidth - 80, 35);
  //   pdf.rect(pageWidth - 50, 30, 40, 7);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(student.RegNo, pageWidth - 48, 35);
  
  //   // Main content area
  //   const contentStartY = 65;
  //   const contentWidth = pageWidth - 2 * margin;
  //   const contentHeight = 80; // Adjusted height
  //   pdf.rect(margin, contentStartY, contentWidth, contentHeight);
  
  //   // Table headers and content
  //   const col1 = margin + 5;
  //   const col2 = margin + 45;
  //   const col3 = pageWidth / 2 + 5;
  //   const col4 = pageWidth - margin - 45;
  
  //   const addRow = (label, value, label2, value2, y) => {
  //     pdf.setFont("helvetica", "bold");
  //     pdf.text(label, col1, y);
  //     if (label2) pdf.text(label2, col3, y);
  //     pdf.setFont("helvetica", "normal");
  //     pdf.text(`: ${value}`, col2, y);
  //     if (value2) pdf.text(`: ${value2}`, col4, y);
  //   };
  
  //   let currentY = contentStartY + 10;
  //   addRow("Student Name", student.name, "Receipt No.", student.receiptId, currentY);
  //   currentY += lineHeight;
  //   addRow("Address", student.Address, "Date", new Date(student.Date).toLocaleDateString(), currentY);
  //   currentY += lineHeight;
  //   addRow("Course Title", student.course, "Course Fee", `${student.courseFee} Rs.`, currentY);
  //   currentY += lineHeight;
  //   addRow("Initial Payment", `${student.initialPayment.amount} Rs.`, "Date", new Date(student.initialPayment.date).toLocaleDateString(), currentY);
  //   currentY += lineHeight;
  
  //   // Additional Payments (Installments)
  //   const installments = student.additionalPayments || [];
  //   installments.slice(0, 3).forEach((installment, index) => {
  //     addRow(`Installment ${index + 1}`, `${installment.amount} Rs.`, "Date", new Date(installment.date).toLocaleDateString(), currentY);
  //     currentY += lineHeight;
  //   });
  
  //   // Remaining Content
  //   currentY += lineHeight;
  //   addRow("Payment By", student.PaymentBy, "Total Fee", `${student.courseFee} Rs.`, currentY);
  //   currentY += lineHeight;
  //   addRow("Amount Received", `${student.amountReceived} Rs.`, "Current Due Amount", `${student.currentDue} Rs.`, currentY);
  
  //   // Terms and conditions
  //   currentY = contentStartY + contentHeight + 10; // Start terms below the main content box
  //   pdf.setFontSize(10);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Terms & Conditions:", margin, currentY);
  
  //   pdf.setFont("helvetica", "normal");
  //   const terms = [
  //     "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
  //     "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
  //     "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
  //     "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
  //   ];
  
  //   terms.forEach((term) => {
  //     currentY += 13;
  //     pdf.text(term, margin, currentY, { maxWidth: pageWidth - 2 * margin });
  //   });
  
  //   // Signature box
  //   currentY += 19;
  //   pdf.rect(margin, currentY, pageWidth - 2 * margin, 30);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, currentY + 10);
  //   pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, currentY + 15);
  
  //   pdf.text("Student's Signature", margin + 5, currentY + 25);
  //   pdf.text("Authorized Center", pageWidth - 60, currentY + 25);
  //   pdf.text("(Seal & Signature)", pageWidth - 60, currentY + 35);
  
  //   pdf.save("receipt.pdf");
  // };
  // const handleDownloadPDF = (student) => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const margin = 10;
  //   const lineHeight = 7;
  //   const logoPath = "/images/newlogo.jpg";
  
  //   // Add logo
  //   pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);
  
  //   // Add title
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(24);
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("RECEIPT", margin, 20);
  
  //   // Add horizontal line
  //   pdf.setDrawColor(0);
  //   pdf.line(margin, 25, pageWidth - margin, 25);
  
  //   // Add header
  //   pdf.setTextColor(0, 128, 0);
  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("SHAW TECH TRAINING AND", margin, 35);
  //   pdf.text("SERVICES", margin, 40);
  //   pdf.setTextColor(0);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(10);
  //   pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
  //   pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
  //   pdf.text("Ph: +91-9692114217 ", margin, 55);
  //   pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);
  
  //   // Add Student Reg. No.
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Reg. No.", pageWidth - 80, 35);
  //   pdf.rect(pageWidth - 50, 30, 40, 7);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(student.RegNo, pageWidth - 48, 35);
  
  //   // Main content area
  //   const contentStartY = 65;
  //   pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 100);
  
  //   // Table headers
  //   const col1 = margin + 5;
  //   const col2 = pageWidth / 2 - 30;
  //   const col3 = pageWidth / 2 + 5;
  //   const col4 = pageWidth - margin - 50;
  
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Name", col1, contentStartY + 10);
  //   pdf.text("Receipt No.", col3, contentStartY + 10);
  //   pdf.text("Address", col1, contentStartY + 20);
  //   pdf.text("Date", col3, contentStartY + 20);
  //   pdf.text("Course Title", col1, contentStartY + 30);
  //   pdf.text("Course Fee", col3, contentStartY + 30);
  
  //   // Table content
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.name}`, col2, contentStartY + 10);
  //   pdf.text(`: ${student.receiptId}`, col4, contentStartY + 10);
  //   pdf.text(`: ${student.Address}`, col2, contentStartY + 20);
  //   pdf.text(`: ${new Date(student.Date).toLocaleDateString()}`, col4, contentStartY + 20);
  //   pdf.text(`: ${student.course}`, col2, contentStartY + 30);
  //   pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 30);
  
  //   // Initial Payment
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Initial Payment", col1, contentStartY + 40);
  //   pdf.text("Date", col3, contentStartY + 40);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.initialPayment.amount} Rs.`, col2, contentStartY + 40);
  //   pdf.text(`: ${new Date(student.initialPayment.date).toLocaleDateString()}`, col4, contentStartY + 40);
  
  //   // Additional Payments (Installments)
  //   const installments = student.additionalPayments || [];
  //   let currentY = contentStartY + 50;
  
  //   installments.forEach((installment, index) => {
  //     pdf.setFont("helvetica", "bold");
  //     pdf.text(`Installment ${index + 1}`, col1, currentY);
  //     pdf.text("Date", col3, currentY);
  //     pdf.setFont("helvetica", "normal");
  //     pdf.text(`: ${installment.amount} Rs.`, col2, currentY);
  //     pdf.text(`: ${new Date(installment.date).toLocaleDateString()}`, col4, currentY);
  //     currentY += lineHeight;
  //   });
  
  //   // Remaining Content
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Payment By", col1, currentY + 10);
  //   pdf.text("Total Fee", col3, currentY + 10);
  
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.PaymentBy}`, col2, currentY + 10);
  //   pdf.text(`: ${student.courseFee} Rs.`, col4, currentY + 10);
  
  //   // Additional fields
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Amount Received", col1, currentY + 20);
  //   pdf.text("Current Due Amount", col3, currentY + 20);
  
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.amountReceived} Rs.`, col2, currentY + 20);
  //   pdf.text(`: ${student.currentDue} Rs.`, col4, currentY + 20);
  
  //   // Terms and conditions
  //   let yPosition = currentY + 45;
  //   pdf.setFontSize(10);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Terms & Conditions:", margin, yPosition);
  
  //   pdf.setFont("helvetica", "normal");
  //   const terms = [
  //     "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
  //     "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
  //     "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
  //     "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
  //   ];
  
  //   terms.forEach((term, index) => {
  //     yPosition += 13;
  //     pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
  //   });
  
  //   // Signature box
  //   yPosition += 20;
  //   pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, yPosition + 10);
  //   pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, yPosition + 15);
  
  //   pdf.text("Student's Signature", margin + 5, yPosition + 25);
  //   pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
  //   pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);
  
  //   pdf.save("receipt.pdf");
  // };
 //recent one
  // const handleDownloadPDF = (student) => {
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const margin = 10;
  //   const lineHeight = 7;
  //   const logoPath = "/images/newlogo.jpg";
  
  //   // Add logo
  //   pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);
  
  //   // Add title
  //   pdf.setFont("helvetica", "bold");
  //   pdf.setFontSize(24);
  //   pdf.setTextColor(0, 0, 0);
  //   pdf.text("RECEIPT", margin, 20);
  
  //   // Add horizontal line
  //   pdf.setDrawColor(0);
  //   pdf.line(margin, 25, pageWidth - margin, 25);
  
  //   // Add header
  //   pdf.setTextColor(0, 128, 0);
  //   pdf.setFontSize(14);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("SHAW TECH TRAINING AND", margin, 35);
  //   pdf.text("SERVICES", margin, 40);
  //   pdf.setTextColor(0);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.setFontSize(10);
  //   pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
  //   pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
  //   pdf.text("Ph: +91-9692114217 ", margin, 55);
  //   pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);
  
  //   // Add Student Reg. No.
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Reg. No.", pageWidth - 80, 35);
  //   pdf.rect(pageWidth - 50, 30, 40, 7);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(student.RegNo, pageWidth - 48, 35);
  
  //   // Main content area
  //   const contentStartY = 65;
  //   pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 100); // Increased height to accommodate all content
  
  //   // Table headers
  //   const col1 = margin + 5;
  //   const col2 = pageWidth / 2 - 30;
  //   const col3 = pageWidth / 2 + 5;
  //   const col4 = pageWidth - margin - 50;
  
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Student Name", col1, contentStartY + 10);
  //   pdf.text("Receipt No.", col3, contentStartY + 10);
  //   pdf.text("Address", col1, contentStartY + 20);
  //   pdf.text("Date", col3, contentStartY + 20);
  //   pdf.text("Course Title", col1, contentStartY + 30);
  //   pdf.text("Course Fee", col3, contentStartY + 30);
  
  //   // Table content
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.name}`, col2, contentStartY + 10);
  //   pdf.text(`: ${student.receiptId}`, col4, contentStartY + 10);
  //   pdf.text(`: ${student.Address}`, col2, contentStartY + 20);
  //   pdf.text(`: ${new Date(student.Date).toLocaleDateString()}`, col4, contentStartY + 20);
  //   pdf.text(`: ${student.course}`, col2, contentStartY + 30);
  //   pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 30);
  
  //   // Received Amount and Additional Payments
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Received Amount", col1, contentStartY + 40);
  //   pdf.text("Date", col3, contentStartY + 40);
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.amountReceived} Rs.`, col2, contentStartY + 40);
  //   pdf.text(`: ${new Date(student.Date).toLocaleDateString()}`, col4, contentStartY + 40);
  
  //   const installments = student.additionalPayments || [];
  //   let currentY = contentStartY + 50;
  
  //   installments.forEach((installment, index) => {
  //     pdf.setFont("helvetica", "bold");
  //     pdf.text(`Installment ${index + 1}`, col1, currentY);
  //     pdf.text("Date", col3, currentY);
  //     pdf.setFont("helvetica", "normal");
  //     pdf.text(`: ${installment.amount} Rs.`, col2, currentY);
  //     pdf.text(`: ${new Date(installment.date).toLocaleDateString()}`, col4, currentY);
  //     currentY += lineHeight;
  //   });
  
  //   // Remaining Content
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Add Service Tax", col3, currentY);
  //   pdf.text("Payment By", col1, currentY + 10);
  //   pdf.text("Total Fee", col3, currentY + 10);
  
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.serviceTax || 0} Rs.`, col4, currentY);
  //   pdf.text(`: ${student.PaymentBy}`, col2, currentY + 10);
  //   pdf.text(`: ${student.courseFee} Rs.`, col4, currentY + 10);
  
  //   // Additional fields
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Total Collected", col1, currentY + 20);
  //   pdf.text("Current Due Amount", col3, currentY + 20);
  
  //   pdf.setFont("helvetica", "normal");
  //   pdf.text(`: ${student.amountReceived + installments.reduce((sum, inst) => sum + inst.amount, 0)} Rs.`, col2, currentY + 20);
  //   pdf.text(`: ${student.currentDue} Rs.`, col4, currentY + 20);
  
  //   // Terms and conditions
  //   let yPosition = currentY + 40;
  //   pdf.setFontSize(10);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("Terms & Conditions:", margin, yPosition);
  
  //   pdf.setFont("helvetica", "normal");
  //   const terms = [
  //     "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
  //     "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
  //     "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
  //     "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
  //   ];
  
  //   terms.forEach((term, index) => {
  //     yPosition += 13;
  //     pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
  //   });
  
  //   // Signature box
  //   yPosition += 20;
  //   pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
  //   pdf.setFont("helvetica", "bold");
  //   pdf.text("I have read the terms & conditions printed overleaf,", margin + 5, yPosition + 10);
  //   pdf.text("Understood the same and hereby confirm having accepted the same for joining the course", margin + 5, yPosition + 15);
  
  //   pdf.text("Student's Signature", margin + 5, yPosition + 25);
  //   pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
  //   pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);
  
  //   pdf.save("receipt.pdf");
  // };
// const handleDownloadPDF = (student) => {
//   const pdf = new jsPDF("p", "mm", "a4");
//   const pageWidth = pdf.internal.pageSize.getWidth();
//   const margin = 10;
//   const lineHeight = 7;
//   const logoPath = "/images/newlogo.jpg";

//   // Add logo
//   pdf.addImage(logoPath, "JPG", pageWidth - 50, 5, 40, 20);

//   // Add title
//   pdf.setFont("helvetica", "bold");
//   pdf.setFontSize(24);
//   pdf.setTextColor(0, 0, 0);
//   pdf.text("RECEIPT", margin, 20);

//   // Add horizontal line
//   pdf.setDrawColor(0);
//   pdf.line(margin, 25, pageWidth - margin, 25);

//   // Add header
//   pdf.setTextColor(0, 128, 0);
//   pdf.setFontSize(14);
//   pdf.setFont("helvetica", "bold");
//   pdf.text("SHAW TECH TRAINING AND", margin, 35);
//   pdf.text("SERVICES", margin, 40);
//   pdf.setTextColor(0);
//   pdf.setFont("helvetica", "normal");
//   pdf.setFontSize(10);
//   pdf.text("W.NO 10, GOLAPBAG, IDBI BANK", margin, 45);
//   pdf.text("BUILDING BARIPADA, MAYURBHANJ", margin, 50);
//   pdf.text("Ph: +91-9692114217 ", margin, 55);
//   pdf.text("E-Mail: sttstrainings@gmail.com", margin, 60);

//   // Add Student Reg. No.
//   pdf.setFont("helvetica", "bold");
//   pdf.text("Student Reg. No.", pageWidth - 80, 35);
//   pdf.rect(pageWidth - 50, 30, 40, 7);
//   pdf.setFont("helvetica", "normal");
//   pdf.text(student.RegNo, pageWidth - 48, 35);

//   // Main content area
//   const contentStartY = 65;
//   pdf.rect(margin, contentStartY, pageWidth - 2 * margin, 90);

//   // Table headers
//   const col1 = margin + 5;
//   const col2 = pageWidth / 2 - 30; // Adjusted gap
//   const col3 = pageWidth / 2 + 5;
//   const col4 = pageWidth - margin - 50; // Adjusted gap
//   const colData = col2 + 25; // Adjusted position for fetched data

//   pdf.setFont("helvetica", "bold");
//   pdf.text("Student Name", col1, contentStartY + 10);
//   pdf.text("Receipt No.", col3, contentStartY + 10);
//   pdf.text("Address", col1, contentStartY + 20);
//   pdf.text("Date", col3, contentStartY + 20);
//   pdf.text("Course Title", col1, contentStartY + 30);
//   pdf.text("Course Fee", col3, contentStartY + 30);

//   // Table content
//   pdf.setFont("helvetica", "normal");
//   pdf.text(`: ${student.name}`, col2, contentStartY + 10);
//   pdf.text(`: ${student.receiptId}`, col4, contentStartY + 10);
//   pdf.text(`: ${student.Address}`, col2, contentStartY + 20);
//   pdf.text(
//     `: ${new Date(student.Date).toLocaleDateString()}`,
//     col4,
//     contentStartY + 20
//   );
//   pdf.text(`: ${student.course}`, col2, contentStartY + 30);
//   pdf.text(`: ${student.courseFee} Rs.`, col4, contentStartY + 30);

//   // Received Amount and Additional Payments
//   pdf.setFont("helvetica", "bold");
//   pdf.text("Received Amount", col1, contentStartY + 40);
//   pdf.text("Date", col3, contentStartY + 40);
//   pdf.setFont("helvetica", "normal");
//   pdf.text(`: ${student.amountReceived} Rs.`, col2, contentStartY + 40);
//   pdf.text(
//     `: ${new Date(student.Date).toLocaleDateString()}`,
//     col4,
//     contentStartY + 40
//   );

//   const installments = student.additionalPayments || [];
//   let currentY = contentStartY + 50;

//   installments.forEach((installment, index) => {
//     pdf.setFont("helvetica", "bold");
//     pdf.text(`Received Amount`, col1, currentY);
//     pdf.text("Date", col3, currentY);
//     pdf.setFont("helvetica", "normal");
//     pdf.text(`: ${installment.amount} Rs.`, col2, currentY);
//     pdf.text(
//       `: ${new Date(installment.date).toLocaleDateString()}`,
//       col4,
//       currentY
//     );
//     currentY += lineHeight;
//   });

//   // Remaining Content
//   pdf.setFont("helvetica", "bold");
//   pdf.text("Add Service Tax", col3, currentY);
//   pdf.text("Payment By", col1, currentY + 10);
//   pdf.text("Total Fee", col3, currentY + 10);

//   pdf.setFont("helvetica", "normal");
//   pdf.text(`: ${student.serviceTax || 0} Rs.`, col4, currentY);
//   pdf.text(`: ${student.PaymentBy}`, col2, currentY + 10);
//   pdf.text(`: ${student.courseFee} Rs.`, col4, currentY + 10);

//   // Additional fields
//   pdf.setFont("helvetica", "bold");
//   pdf.text("Total Collected", col1, currentY + 20);
//   pdf.text("Current Due Amount", col3, currentY + 20);

//   pdf.setFont("helvetica", "normal");
//   pdf.text(
//     `: ${
//       student.amountReceived +
//       installments.reduce((sum, inst) => sum + inst.amount, 0)
//     } Rs.`,
//     col2,
//     currentY + 20
//   );
//   pdf.text(`: ${student.currentDue} Rs.`, col4, currentY + 20);

//   // Terms and conditions
//   let yPosition = currentY + 53;
//   pdf.setFontSize(10);
//   pdf.setFont("helvetica", "bold");
//   pdf.text("Terms & Conditions:", margin, yPosition);

//   pdf.setFont("helvetica", "normal");
//   const terms = [
//     "1. Student must ensure to pay full course fee at the time of registration. In case of course fees being paid in installment, all payments should be made as per the fee payment schedule.",
//     "2. Any disputes arising between the holder of this receipt and the center/franchisee holding this receipt shall be deemed to have arisen in the state where the franchisee functions and is subject to the competent jurisdiction of the place where the franchisee center is located.",
//     "3. Course fee once paid shall not be refunded under any circumstances nor can be adjusted in any other course. However, the course amount can be exchanged for another course or another person for a training.",
//     "4. No liability for any damage on the part of delay or non fulfillment of any term of the contract caused by force majeure or by any industrial dispute, default by any sub-contractor, service, defect, necessary of material or labor flood, incitement or course of other beyond our control.",
//   ];

//   terms.forEach((term, index) => {
//     yPosition += 13;
//     pdf.text(term, margin, yPosition, { maxWidth: pageWidth - 2 * margin });
//   });

//   // Signature box
//   yPosition += 20;
//   pdf.rect(margin, yPosition, pageWidth - 2 * margin, 30);
//   pdf.setFont("helvetica", "bold");
//   pdf.text(
//     "I have read the terms & conditions printed overleaf,",
//     margin + 5,
//     yPosition + 10
//   );
//   pdf.text(
//     "Understood the same and hereby confirm having accepted the same for joining the course",
//     margin + 5,
//     yPosition + 15
//   );

//   pdf.text("Student's Signature", margin + 5, yPosition + 25);
//   pdf.text("Authorized Center", pageWidth - 60, yPosition + 25);
//   pdf.text("(Seal & Signature)", pageWidth - 60, yPosition + 35);

//   pdf.save("receipt.pdf");
// };
useEffect(() => {
  const student = students.find(s => s.RegNo === selectedStudentId);
  console.log("Selected Student:", student);
  setSelectedStudent(student);
}, [students, selectedStudentId]);




  // const handleDownloadPDF = (student) => {
  //   const pdf = new jsPDF();
  //   const pageWidth = pdf.internal.pageSize.width;
  //   const pageHeight = pdf.internal.pageSize.height;

  //   pdf.setFontSize(20);
  //   pdf.text(`Receipt for ${student.name}`, pageWidth / 2, 20, { align: "center" });

  //   pdf.setFontSize(16);
  //   pdf.text(`Reg No: ${student.RegNo}`, 10, 40);
  //   pdf.text(`Course: ${student.course}`, 10, 50);
  //   pdf.text(`Course Fee: ${student.courseFee}`, 10, 60);
  //   pdf.text(`Amount Received: ${student.amountReceived}`, 10, 70);
  //   pdf.text(`Current Due: ${student.currentDue}`, 10, 80);

  //   pdf.save(`Receipt_${student.RegNo}.pdf`);
  // };


  const [selectedSoftware, setSelectedSoftware] = useState(editStudent?.softwareCovered || []);
  const handleCourseChange = (e) => {
    const { name, value } = e.target;

    // Update the course field
    handleEditFormChange(e);

    // Update the softwareCovered options based on the selected course
    const updatedSoftwareOptions = softwareOptions[value.toUpperCase()] || [];

    setSelectedSoftware(updatedSoftwareOptions);

    // Update the form data with the new softwareCovered options
    handleEditFormChange({ target: { name: 'softwareCovered', value: [] } });
  };

  useEffect(() => {
    if (editStudent?.course) {
      const initialSoftwareOptions = softwareOptions[editStudent.course.toUpperCase()] || [];
      setSelectedSoftware(initialSoftwareOptions);
    }
  }, [editStudent]);
  
  // const handleCourseChange = (e) => {
  //   const { name, value } = e.target;

  //   // Update the course field
  //   handleEditFormChange(e);

  //   // Update softwareCovered options based on the selected course
  //   const updatedSoftwareOptions = softwareOptions[value.toUpperCase()] || [];
  //   setSelectedSoftware(updatedSoftwareOptions);
  //   handleEditFormChange({ target: { name: 'softwareCovered', value: updatedSoftwareOptions } });
  // };

  useEffect(() => {
    // If softwareCovered data is fetched, use it; otherwise, use selectedSoftware
    if (!editStudent?.softwareCovered) {
      setSelectedSoftware(softwareOptions[editStudent?.course?.toUpperCase()] || []);
    }
  }, [editStudent]);


  return (
    <>
      <Leftbar />
      <div style={{ marginLeft: style.marginLeft, marginTop: style.marginTop }}>
        <div id="main-content">
          <div className="block-header">
            <h2>Students</h2>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12"></div>
            </div>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6" style={{ width: style.cardWidth }}>
                <Link to="/stud" className="card">
                  <div className="body top_counter">
                    <div className="content">
                      <div className="icon bg-warning text-white">
                        <PiStudentBold style={{ fontSize: "35px" }} />
                        <span style={{ marginLeft: "30px", fontSize: "20px" }}>
                          Add Student
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              </div>
              </div>
              <div className="col-lg-9 col-md-6" style={{ width: "", marginLeft: "60%" }}>
                <div className="search-bar" style={{ marginBottom: "20px", display: "flex", marginTop: "-40px" }}>
                  <input
                    type="text"
                    placeholder="Search by Reg.No or Name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                      padding: "10px",
                      fontSize: "16px",
                      border: "1px solid black",
                      borderRadius: "5px",
                      marginRight: "10px",
                      color: "black",
                    }}
                  />
                </div>
              </div>
            </div>
        <table>
          <thead>
            <tr>
              <th onClick={handleSortByRegNo} style={{ cursor: "pointer" }}>
                Reg No {sortOrder === "asc" ? "▲" : "▼"}
              </th>
              <th onClick={handleSortByName} style={{ cursor: "pointer" }}>
                Name {nameSortOrder === "asc" ? "▲" : "▼"}
              </th>
              <th>Course Title</th>
              <th>Father Name</th>
              {/* <th>Receipt No</th> */}
              <th>Course Fee</th>
              <th>
                           <strong>Status</strong>
                         </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentStudents.map((student) => (
              <tr key={student._id}>
                <td>{student.RegNo}</td>
                <td>{student.name}</td>
                <td>{student.course}</td>
                <td>{student.fatherName}</td>
              
                <td>{student.courseFee}</td>
                <td>
                              <button
                                style={{
                                  backgroundColor:
                                    Number(student.currentDue) === 0
                                      ? "green"
                                      : "red",
                                  color: "white",
                                  padding: "5px 10px",
                                  border: "none",
                                  borderRadius: "5px",
                                }}
                              >
                                {Number(student.currentDue) === 0
                                  ? "Paid"
                                  : "Due"}
                              </button>
                            </td>
              
                            <td>
  <button
    onClick={() => handleEditClick(student)}
    style={{ marginRight: "5px", backgroundColor: '#FFB237', color: 'black' }}
    title="Edit"
  >
    <FaFilePen />
  </button>
  <button
    onClick={() => handleReceiptClick(student)}
    style={{ marginRight: "5px", backgroundColor: '#FFB237', color: 'black' }}
    title="Receipt"
  >
    <BiSolidReceipt />
  </button>
  <button
    onClick={() => handleDeleteClick(student._id)}
    style={{ marginRight: "5px", backgroundColor: '#FFB237', color: 'black' }}
    title="Delete"
  >
    <FaTrashAlt />
  </button>
</td>

              </tr>
            ))}
          </tbody>
        </table>
        <div style={paginationStyle}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            style={buttonStyle}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {Math.ceil(filteredStudents.length / studentsPerPage)}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(filteredStudents.length / studentsPerPage)}
            style={buttonStyle}
          >
            Next
          </button>
        </div>
      </div>

      {/* Edit Student Modal */}
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={() => setModalIsOpen(false)}
  contentLabel="Edit Student"
  style={{
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "1000px",
      maxHeight: "90vh",
      padding: "20px",
      backgroundColor: "#f7f7f7",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      overflowY: "auto",
      overflowX: "hidden",
      zIndex: 1050,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1040,
    },
  }}
>
  <button
    onClick={() => setModalIsOpen(false)}
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#333",
    }}
    aria-label="Close Modal"
  >
    &times;
  </button>
  <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
    Edit Student
  </h2>






{/* <form
  onSubmit={handleEditFormSubmit}
  style={{
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    marginBottom: "15px",
  }}
>
  {[
    { id: 'name', label: 'Name:', type: 'text', name: 'name' },
    { id: 'email', label: 'Email:', type: 'email', name: 'email' },
    { id: 'fatherName', label: 'Father\'s Name:', type: 'text', name: 'fatherName' },
    { id: 'mobileNo', label: 'Mobile No:', type: 'text', name: 'mobileNo' },
    { id: 'Address', label: 'Address:', type: 'text', name: 'Address' },
    { id: 'collegeName', label: 'College Name:', type: 'text', name: 'collegeName' },
    {
      id: 'course',
      name: 'course',
      label: 'Course Title:',
      type: 'select',
      options: ['Select Course', 'DCA', 'ADVANCE PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'ANY IT', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER']
    },
    {
      id: 'RegNo',
      name: 'RegNo',
      label: 'Registration No:',
      type: 'text'
    },
    { id: 'centreName', label: 'Centre Name:', type: 'text', name: 'centreName' },
    { id: 'courseDuration', label: 'Course Duration:', type: 'text', name: 'courseDuration' },
    { id: 'courseFee', label: 'Course Fee:', type: 'number', name: 'courseFee' },
    { id: 'amountReceived', label: 'Amount Received:', type: 'number', name: 'amountReceived' },
    { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true, name: 'currentDue' },
    {
      id: 'payment',
      name: 'payment',
      label: 'Payment:',
      type: 'select',
      options: ['Select Payment Method', 'UPI', 'BANK TRANSFER', 'CASH'],
    },
    { id: 'Date', label: 'Date:', type: 'date', name: 'Date' },
    {
      id: 'softwareCovered',
      label: 'Software Covered:',
      type: 'checkboxGroup',
      name: 'softwareCovered',
      options: selectedSoftware // Updated to use the dynamically updated software options
    },
  ].map(({ id, label, name, type, options, readOnly }) => (
    <div
      key={id}
      className="input-container"
      style={{ marginBottom: "10px", gridColumn: id === 'softwareCovered' ? '1 / -1' : '' }}
    >
      <label htmlFor={id} style={{ marginBottom: "5px", display: "block" }}>{label}</label>
      {type === 'select' ? (
        <select
          id={id}
          name={name}
          value={editStudent?.[id] || ""}
          onChange={handleCourseChange}
          required
          style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
        >
          {options.map((option, index) => (
            <option key={index} value={option === 'Select Course' ? '' : option}>
              {option}
            </option>
          ))}
        </select>
      ) : type === 'checkboxGroup' ? (
        <div
          id={id}
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            marginTop: "10px",
            width: "100%",
            gridColumn: '1 / -1', // Span the entire width of the form
          }}
        >
          {options.map((option, index) => (
            <label key={index} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
              <input
                type="checkbox"
                name={name}
                value={option}
                checked={editStudent?.softwareCovered?.includes(option) || false}
                onChange={(e) => {
                  const value = e.target.checked
                    ? [...(editStudent?.softwareCovered || []), option]
                    : editStudent?.softwareCovered?.filter(item => item !== option);
                  handleEditFormChange({ target: { name: 'softwareCovered', value } });
                }}
                style={{ marginRight: "5px" }}
              />
              {option}
            </label>
          ))}
        </div>
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          value={id === 'softwareCovered'
            ? (editStudent?.softwareCovered || []).join(', ')
            : (id === 'Date'
              ? (editStudent?.[id] ? new Date(editStudent[id]).toISOString().split('T')[0] : '')
              : (editStudent?.[id] || ""))}
          onChange={handleEditFormChange}
          readOnly={readOnly}
          style={{ width: "100%", color: "#333", padding: "8px", fontSize: "14px", borderRadius: "4px", border: "1px solid #ccc", boxSizing: "border-box" }}
        />
      )}
    </div>
  ))}
  <div
    style={{
      gridColumn: "span 3",
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginTop: "15px",
    }}
  >
    <button
      type="submit"
      style={{
        backgroundColor: "#FFB237",
        color: "black",
        border: "none",
        padding: "10px 20px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "4px",
      }}
    >
      Submit
    </button>
    <button
      type="button"
      onClick={() => setModalIsOpen(false)}
      style={{
        backgroundColor: "#FFB237",
        color: "black",
        border: "none",
        padding: "10px 20px",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "4px",
      }}
    >
      Close
    </button>
  </div>
</form> */}

<form
      onSubmit={handleEditFormSubmit}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
        marginBottom: "15px",
      }}
    >
      {[
        { id: 'name', label: 'Name:', type: 'text', name: 'name' },
        { id: 'email', label: 'Email:', type: 'email', name: 'email' },
        { id: 'fatherName', label: 'Father\'s Name:', type: 'text', name: 'fatherName' },
        { id: 'mobileNo', label: 'Mobile No:', type: 'text', name: 'mobileNo' },
        { id: 'Address', label: 'Address:', type: 'text', name: 'Address' },
        { id: 'collegeName', label: 'College Name:', type: 'text', name: 'collegeName' },
        {
          id: 'course',
          name: 'course',
          label: 'Course Title:',
          type: 'select',
          options: ['Select Course', 'DCA', 'ADVANCE PGDCA', 'PGDCA', 'MECHANICAL', 'ELECTRICAL', 'CIVIL & ARCH', 'IT & CS', 'TALLY', 'PHOTOSHOP', 'MS OFFICE', 'ADVANCE EXCEL', 'BASIC COMPUTER','DIPLOMA MECH CAD','PROFESSIONAL MECH CAD','DIPLOMA CIVIL CAD','PROFESSIONAL CIVIL CAD','DIPLOMA ELECTRICAL CAD','PROFESSIONAL ELECTRCIAL CAD','INTERIOR DESIGNING']
        },
        {
          id: 'RegNo',
          name: 'RegNo',
          label: 'Registration No:',
          type: 'text'
        },
        { id: 'centreName', label: 'Centre Name:', type: 'text', name: 'centreName' },
        { id: 'courseDuration', label: 'Course Duration:', type: 'text', name: 'courseDuration' },
        { id: 'courseFee', label: 'Course Fee:', type: 'number', name: 'courseFee' },
        { id: 'amountReceived', label: 'Amount Received:', type: 'number', name: 'amountReceived' },
        { id: 'currentDue', label: 'Current Due:', type: 'number', readOnly: true, name: 'currentDue' },
        {
          id: 'PaymentBy',
          name: 'PaymentBy',
          label: 'Payment:',
          type: 'select',
          options: ['Select Payment Method', 'UPI', 'BANK TRANSFER', 'CASH'],
        },
        { id: 'Date', label: 'Date:', type: 'date', name: 'Date' },
      ].map(({ id, label, name, type, options, readOnly }) => (
        <div
          key={id}
          className="input-container"
          style={{ marginBottom: "10px" }}
        >
          <label htmlFor={id} style={{ marginBottom: "5px", display: "block" }}>{label}</label>
          {type === 'select' ? (
            <select
              id={id}
              name={name}
              value={editStudent?.[id] || ""}
              onChange={handleCourseChange}
              required
              disabled={id === 'course' && editStudent?.course}
              style={{ 
                width: "100%", 
                color: "#333", 
                padding: "8px", 
                fontSize: "14px", 
                borderRadius: "4px", 
                border: "1px solid #ccc", 
                boxSizing: "border-box",
                backgroundColor: id === 'course' && editStudent?.course ? '#f0f0f0' : 'white'
              }}
            >
              {options.map((option, index) => (
                <option key={index} value={option === 'Select Course' ? '' : option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              id={id}
              name={name}
              value={id === 'Date'
                ? (editStudent?.[id] ? new Date(editStudent[id]).toISOString().split('T')[0] : '')
                : (editStudent?.[id] || "")}
              onChange={handleEditFormChange}
              readOnly={readOnly || id === 'RegNo'}
              disabled={id === 'RegNo' && editStudent?.RegNo}
              style={{ 
                width: "100%", 
                color: "#333", 
                padding: "8px", 
                fontSize: "14px", 
                borderRadius: "4px", 
                border: "1px solid #ccc", 
                boxSizing: "border-box",
                backgroundColor: id === 'RegNo' && editStudent?.RegNo ? '#f0f0f0' : 'white'
              }}
            />
          )}
        </div>
      ))}
      
      <div className="input-container" style={{ gridColumn: '1 / -1', marginBottom: "10px" }}>
        <label style={{ marginBottom: "5px", display: "block" }}>Software Covered:</label>
        <div style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          padding: "15px",
          border: "1px solid #e0e0e0",
          borderRadius: "5px",
          backgroundColor: "#f9f9f9"
        }}>
          {selectedSoftware.map((software, index) => (
            <div key={index} style={{ 
              display: "flex", 
              alignItems: "center", 
              width: "calc(20% - 10px)", 
              fontSize: "14px" 
            }}>
              <input
                type="checkbox"
                name="softwareCovered"
                value={software}
                checked={editStudent?.softwareCovered?.includes(software) || false}
                onChange={(e) => {
                  const value = e.target.checked
                    ? [...(editStudent?.softwareCovered || []), software]
                    : editStudent?.softwareCovered?.filter(item => item !== software);
                  handleEditFormChange({ target: { name: 'softwareCovered', value } });
                }}
                style={{ marginRight: "6px", width: "16px", height: "16px" }}
              />
              <label style={{ cursor: "pointer", flex: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {software}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div
        style={{
          gridColumn: "span 3",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginTop: "15px",
        }}
      >
        <button
          type="submit"
          style={{
            backgroundColor: "#FFB237",
            color: "black",
            border: "none",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "4px",
          }}
        >
          Submit
        </button>
        <button
          type="button"
          onClick={() => setModalIsOpen(false)}
          style={{
            backgroundColor: "#FFB237",
            color: "black",
            border: "none",
            padding: "10px 20px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px",
            cursor: "pointer",
            borderRadius: "4px",
           marginTop:'10px'
          }}
        >
          Close
        </button>
      </div>
    </form>






</Modal>
<Modal
  isOpen={receiptModalIsOpen}
  onRequestClose={() => setReceiptModalIsOpen(false)}
  contentLabel="Receipt"
  style={{
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "1000px",
      maxHeight: "90vh",
      padding: "20px",
      backgroundColor: "#f7f7f7",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      overflowY: "auto",
      display: "flex",
      flexDirection: "column",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1040,
    },
  }}
>
  <button
    onClick={() => setReceiptModalIsOpen(false)}
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#333",
    }}
    aria-label="Close Modal"
  >
    &times;
  </button>

  {/* Main Content Section */}
  <div style={{ display: "flex", flexDirection: "row", gap: "20px", marginBottom: "20px" }}>
    {/* Left Side Content */}
    <div style={{ flex: "1", display: "flex", flexDirection: "column" }}>
      <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
        Receipt for {selectedStudent?.name}
      </h2>

      {/* Student Details Section */}


<div> {selectedStudent ? (
        <>
          <h3>Student Details</h3>
          <div className="detail-box">
            <div className="row">
              <div className="field"><strong>Reg No:</strong> {selectedStudent.RegNo}</div>
            </div>
            <div className="row">
              <div className="field"><strong>Course Title:</strong> {selectedStudent.course}</div>
            </div>
            <div className="row">
              <div className="field"><strong>Course Fee:</strong> ₹{selectedStudent.courseFee}</div>
            </div>
            <div className="row">
              <div className="field"><strong>Amount Received:</strong> ₹{selectedStudent.amountReceived}</div>
            </div>
            <div className="row">
              <div className="field"><strong>Current Due:</strong> ₹{selectedStudent.currentDue}</div>
            </div>
          </div>

          {/* Additional Payments */}
          {selectedStudent.additionalPayments && selectedStudent.additionalPayments.length > 0 ? (
            <>
              <h3>Additional Payments</h3>
              {selectedStudent.additionalPayments.map((payment, index) => (
                <div key={`${selectedStudent.RegNo}-${index}`} className="detail-box">
                  <div className="row">
                    <div className="field">
                      <strong>Installment {index + 1}:</strong> ₹{payment.amount} on {new Date(payment.date.$date || payment.date).toLocaleDateString('en-GB')}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p>No additional payments found.</p>
          )}
        </>
      ) : (
        <p>Please select a student to view details.</p>
      )}</div>

      {/* Student Details */}
    
    </div>



   

    {/* Right Side - Add Payment Section */}
    {/* <div style={{ flex: "0 0 300px", padding: "20px", border: "1px solid #ddd", borderRadius: "4px", backgroundColor: "#fff",marginLeft:'-140px',marginTop:'40px' }}>
      <h3>Add Payment</h3>
      <form onSubmit={handleAddPayment} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <label>
          Payment Amount:
          <input
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            style={{ width: "100%", padding: "6px", color: 'black', borderRadius: "4px", border: "1px solid #ccc" }}
            required
          />
        </label>
        <label>
          Payment Date:
          <input
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            style={{ width: "100%", padding: "6px", borderRadius: "4px", color: 'black', border: "1px solid #ccc" }}
            required
          />
        </label>
        <button
          type="submit"
          disabled={selectedStudent?.currentDue <= 0} // Disable button if currentDue is 0 or less
          style={{
            backgroundColor: selectedStudent?.currentDue <= 0 ? "#ccc" : "#FFB237", // Change color when disabled
            color: selectedStudent?.currentDue <= 0 ? "#666" : "black", // Change text color when disabled
            border: "none",
            padding: "6px 12px",
            borderRadius: "4px",
            cursor: selectedStudent?.currentDue > 0 ? "pointer" : "not-allowed",
            fontSize: "14px",
            width: "100%",
          }}
        >
          Add Payment
        </button>
      </form>
    </div> */}
   <div style={{ 
      flex: "0 0 300px", 
      padding: "20px", 
      border: "1px solid #ddd", 
      borderRadius: "4px", 
      backgroundColor: "#fff",
      marginLeft: '-140px',
      marginTop: '40px' 
    }}>
      <h3>Add Payment</h3>
      <form onSubmit={handleAddPayment} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <label>
          Payment Amount:
          <input
            type="number"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            style={{ width: "100%", padding: "6px", color: 'black', borderRadius: "4px", border: "1px solid #ccc" }}
            required
            disabled={isFullyPaid || isMaxInstallmentsReached}
          />
        </label>
        <label>
          Payment Date:
          <input
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            style={{ width: "100%", padding: "6px", borderRadius: "4px", color: 'black', border: "1px solid #ccc" }}
            required
            disabled={isFullyPaid || isMaxInstallmentsReached}
          />
        </label>
        <div style={{ 
          marginBottom: "10px", 
          fontSize: "14px", 
          color: isMaxInstallmentsReached ? "#e74c3c" : "#666",
          fontWeight: isMaxInstallmentsReached ? "bold" : "normal"
        }}>
          {getInstallmentMessage()}
        </div>
        {/* <button
              type="submit"
              disabled={!selectedStudent || isFullyPaid || isMaxInstallmentsReached}
              style={{
                backgroundColor: (!selectedStudent || isFullyPaid || isMaxInstallmentsReached) ? "#ccc" : "#FFB237",
                color: (!selectedStudent || isFullyPaid || isMaxInstallmentsReached) ? "#666" : "black",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: (selectedStudent && !isFullyPaid && !isMaxInstallmentsReached) ? "pointer" : "not-allowed",
                fontSize: "14px",
                width: "100%",
              }}
            >
              {getButtonText()}
            </button> */}
             <button
              type="submit"
              disabled={!selectedStudent || isFullyPaid || isMaxInstallmentsReached}
              style={{
                backgroundColor: (!selectedStudent || isFullyPaid || isMaxInstallmentsReached) ? "#ccc" : "#FFB237",
                color: (!selectedStudent || isFullyPaid || isMaxInstallmentsReached) ? "#666" : "black",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: (selectedStudent && !isFullyPaid && !isMaxInstallmentsReached) ? "pointer" : "not-allowed",
                fontSize: "14px",
                width: "100%",
              }}
            >
              {getButtonText()}
            </button>
        
      </form>
    </div>
  </div>

  {/* Action Buttons Section */}
  <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
    <button
      onClick={() => handlePrint(selectedStudent)}
      style={{
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        padding: "6px 12px",  // Reduced padding for buttons
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "14px",
      }}
    >
      Print Receipt
    </button>
    <button
      onClick={() => handleDownloadPDF(selectedStudent)}
      style={{
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        padding: "6px 12px",  // Reduced padding for buttons
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "14px",
      }}
    >
      Download PDF
    </button>
  </div>
</Modal>


{/* <Modal
  isOpen={receiptModalIsOpen}
  onRequestClose={() => setReceiptModalIsOpen(false)}
  contentLabel="Receipt"
  style={{
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      maxWidth: "1000px",
      maxHeight: "90vh",
      padding: "20px",
      backgroundColor: "#f7f7f7",
      border: "1px solid #ddd",
      borderRadius: "8px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      overflowY: "auto",
      overflowX: "hidden",
      zIndex: 1050,
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1040,
    },
  }}
>
  <button
    onClick={() => setReceiptModalIsOpen(false)}
    style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "transparent",
      border: "none",
      fontSize: "24px",
      cursor: "pointer",
      color: "#333",
    }}
    aria-label="Close Modal"
  >
    &times;
  </button>
  <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
    Receipt for {selectedStudent?.name}
  </h2>
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div style={{ flex: 1 }}>
      <h3>Student Details</h3>
      <div className="detail-box">
        <div className="row">
          <div className="field"><strong>Reg No:</strong> {selectedStudent?.RegNo}</div>
          <div className="field"><strong>Course Title:</strong> {selectedStudent?.course}</div>
        </div>
        <div className="row">
          <div className="field"><strong>Course Fee:</strong> ₹{selectedStudent?.courseFee}</div>
          <div className="field"><strong>Amount Received:</strong> ₹{selectedStudent?.amountReceived}</div>
        </div>
        <div className="row">
          <div className="field"><strong>Current Due:</strong> ₹{selectedStudent?.currentDue}</div>
        </div>
      </div>

      {/* Additional Payments */}
      {/* {selectedStudent?.additionalPayments && selectedStudent.additionalPayments.length > 0 && (
        <>
          <h3>Additional Payments</h3>
          {selectedStudent.additionalPayments.map((payment, index) => (
            <div key={index} className="detail-box">
              <div className="row">
                <div className="field">
                  <strong>Installment {index + 1}:</strong> ₹{payment.amount} on {new Date(payment.date).toLocaleDateString()}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
    <div style={{ flex: 0.4, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
      <button
        onClick={() => handlePrint(selectedStudent)}
        style={{
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        Print Receipt
      </button>
      <button
        onClick={() => handleDownloadPDF(selectedStudent)}
        style={{
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          padding: "10px 20px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Download PDF
      </button>
    </div>
  </div> */}

  {/* Payment Section */}
  {/* <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #ddd", borderRadius: "4px", backgroundColor: "#fff" }}>
    <h3>Add Payment</h3>
    <form onSubmit={handleAddPayment} style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <label>
        Payment Amount:
        <input
          type="number"
          value={paymentAmount}
          onChange={(e) => setPaymentAmount(e.target.value)}
          style={{ width: "100%", padding: "8px",color:'black', borderRadius: "4px", border: "1px solid #ccc" }}
          required
        />
      </label>
      <label>
        Payment Date:
        <input
          type="date"
          value={paymentDate}
          onChange={(e) => setPaymentDate(e.target.value)}
          style={{ width: "100%", padding: "8px", borderRadius: "4px",color:'black', border: "1px solid #ccc" }}
          required
        />
      </label>
      <button
        type="submit"
        disabled={selectedStudent?.currentDue <= 0} // Disable button if currentDue is 0 or less
        style={{
          backgroundColor: selectedStudent?.currentDue <= 0 ? "#ccc" : "#FFB237", // Change color when disabled
          color: selectedStudent?.currentDue <= 0 ? "#666" : "black", // Change text color when disabled
          border: "none",
          padding: "10px 20px",
          borderRadius: "4px",
          cursor: selectedStudent?.currentDue > 0 ? "pointer" : "not-allowed",
          fontSize: "16px",
        }}
      >
        Add Payment
      </button>
    </form>
  </div> 
</Modal> */}


    </>
  );
}

export default Studentview;
