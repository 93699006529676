import React, { useState, useEffect } from "react";
import "./DisplayJobs.css"; // Import your CSS file
import ApplyJobForm from "./ApplyJobForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TiLocationArrowOutline } from "react-icons/ti";
import { FaUserGraduate } from "react-icons/fa6";

const DisplayJobs = () => {
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const fetchJobs = async () => {
    try {
      const response = await fetch("/api/jobapplications");
      if (response.ok) {
        const data = await response.json();
        setJobList(data);
      } else {
        toast.error("Failed to fetch job applications");
      }
    } catch (error) {
      console.error("Error:", error);
      // toast.error('An error occurred while fetching job applications');
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleApply = (job) => {
    setSelectedJob(job);
  };

  const handleCloseForm = () => {
    setSelectedJob(null);
  };

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg_10"></div>
        {/* <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div> */}

        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Careers</h2>
            <ul className="thm-breadcrumb list-unstyled ml-0">
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Ontech."
                    href="/"
                    className="home"
                  >
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">
                    Careers
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="job-list-container">
        <h2 style={{ marginTop: "30px", justifyContent: "center" }}>
          Job Applications
        </h2>

        <div className="job-list">
          <div>
            <div className="card_carrer_container">
              <div className="card_sub_container">
                <h3 style={{ color: "white", marginTop: "40px" }}>
                  Get your dream jobs
                </h3>
                <h4 style={{ color: "#FFB237", marginTop: "70px" }}>
                  Find your perfect job match
                </h4>
                <p style={{ color: "white" }}>
                  Connect with our nearest office or submit an online inquiry
                  for business opportunities.
                </p>
                <button
                  style={{ marginTop: "40px" }}
                  className="button_22"
                  onClick={() => handleApply(jobList[0])}
                >
                  Apply
                </button>
              </div>
              {/* <ApplyJobForm isOpen={isFormOpen} onClose={closeForm} /> */}
            </div>
          </div>
          {jobList.map((job, index) => (
            <div key={index} className="job-item">
              <div className="icon-wrapper">
                <FaUserGraduate />
              </div>
              <h3>{job.jobName}</h3>
              <p>
                <TiLocationArrowOutline />
                <strong>Description : </strong> {job.jobDescription}
              </p>
              <p>
                <TiLocationArrowOutline />
                <strong>Title : </strong> {job.jobTitle}
              </p>

              <p>
                <TiLocationArrowOutline />
                <strong>Qualifications :</strong> {job.requiredQualifications}
              </p>
              <p>
                <TiLocationArrowOutline />
                <strong>Field : </strong> {job.otherMsg}
              </p>
              <p>
                <TiLocationArrowOutline />
                <strong>Skills: </strong> {job.skills}
              </p>
              <div className="job-item-buttons">
                <button className="button_22" onClick={() => handleApply(job)}>
                  Apply
                </button>
              </div>
            </div>
          ))}
        </div>
        {selectedJob && (
          <ApplyJobForm
            isOpen={true}
            jobId={selectedJob._id}
            onClose={handleCloseForm}
          />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default DisplayJobs;
