
////current

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import QRCode from "qrcode";
// import Modal from "react-modal";
// import "./CertificateView.css";
// import Leftbar from "../Leftbar/Leftbar";
// import { BsPrinterFill } from "react-icons/bs";
// import { MdDeleteForever } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import "./certi.css";

// Modal.setAppElement("#root");

// function CertificateDetaile() {
//   const navigate = useNavigate();
//   const [students, setStudents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [studentInputs, setStudentInputs] = useState({});
//   const [savedData, setSavedData] = useState({});

//   useEffect(() => {
//     fetchStudents();
//   }, []);

//   const fetchStudents = async () => {
//     try {
//       const response = await axios.get("/api/student");
//       setStudents(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching student data:", error.message);
//       setLoading(false);
//     }
//   };

  

//   const handleGenerateCertificate = async (student) => {
//     if (!savedData[student._id]) {
//       alert(
//         "Please save the grade and awarded date before generating the certificate."
//       );
//       return;
//     }

//     try {
//       const pdfBytes = await generateCertificate(student);
//       const file = new File([pdfBytes], `Certificate_${student.RegNo}.pdf`, {
//         type: "application/pdf",
//       });
//       saveAs(file);
//     } catch (error) {
//       console.error("Error generating or downloading certificate:", error);
//       alert(
//         "Error generating or downloading certificate. Please try again later."
//       );
//     }
//   };

//   const generateCertificate = async (student) => {
//     const existingPdfBytes = await fetch("certii2.pdf").then((res) =>
//       res.arrayBuffer()
//     );
//     const pdfDoc = await PDFDocument.load(existingPdfBytes);
//     const page = pdfDoc.getPage(0);
//     const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
//     const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

//     // Certificate of Achievement text
//     // page.drawText("CERTIFICATE", {
//     //   x: 200,
//     //   y: 500,
//     //   size: 30,
//     //   font: boldFont,
//     //   color: rgb(0, 0, 0),
//     // });

//     // page.drawText("Of Achievement", {
//     //   x: 240,
//     //   y: 470,
//     //   size: 20,
//     //   font: font,
//     //   color: rgb(0, 0, 0),
//     // });

//     // Congratulations text
//     page.drawText("Congratulations!", {
//       x: 56,
//       y: 370,
//       size: 20,
//       font: boldFont,
//       color: rgb(0.8, 0, 0), // Dark red color
//     });

//     // Course completion text
//     const courseCompletionText =
//       "The Course you have completed, was designed to meet your learning needs with professional instructors, relevant content, authorized courseware and ongoing evaluation by STTS.";
//     const wrappedText = wrapText(courseCompletionText, 70);
//     wrappedText.forEach((line, index) => {
//       page.drawText(line, {
//         x: 56,
//         y: 350 - index * 18,
//         size: 12,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//     });

//     // Student details
//     const leftDetails = [
//       { label: "Name", value: student.name.toUpperCase() },
//       { label: "Course Title", value: student.course },
//       { label: "Software Covered", value: "SHAW TECH BARIPADA" },
//       { label: "Training Centre", value: "SHAW TECH BARIPADA" },
//     ];

//     const formatDate = (dateString) => {
//       const date = new Date(dateString);
//       const day = String(date.getDate()).padStart(2, '0');
//       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
//       const year = date.getFullYear();
//       return `${day}/${month}/${year}`;
//     };

//     const rightDetails = [
//       { label: "S/o, D/o, W/o", value: student.fatherName.toUpperCase() },
//       { label: "Course Duration", value: student.courseDuration },
//       { label: "Awarded on",value:formatDate(student.Date)  },
//       { label: "Grade", value: student.grade },
//     ];

//     const drawDetails = (details, startX) => {
//       details.forEach((detail, index) => {
//         const yPosition = 270 - index * 30;

//         // Draw the fetched data
//         page.drawText(detail.value, {
//           x: startX,
//           y: yPosition,
//           size: 14,
//           font: boldFont,
//           color: rgb(0, 0, 0),
//         });

//         // Draw the dotted line
//         for (let i = 0; i < 30; i++) {
//           page.drawLine({
//             start: { x: startX + i * 5, y: yPosition - 2 },
//             end: { x: startX + i * 5 + 2, y: yPosition - 2 },
//             thickness: 1,
//             color: rgb(0, 0, 0),
//           });
//         }

//         // Draw the label
//         page.drawText(detail.label, {
//           x: startX,
//           y: yPosition - 15,
//           size: 10,
//           font: font,
//           color: rgb(0.5, 0.5, 0.5), // Gray color
//         });
//       });
//     };

//     drawDetails(leftDetails, 56);
//     drawDetails(rightDetails, 320);

//     // QR Code (keeping your existing code)
//     // ... (QR code implementation remains the same)
    
//   // const lines = additionalContent.split('\n');
//   // lines.forEach((line, index) => {
//   //   page.drawText(line, {
//   //     x: additionalContentPosition.x,
//   //     y: additionalContentPosition.y - index * additionalContentPosition.lineHeight,
//   //     size: 12,
//   //     font,
//   //     color: rgb(0, 0, 0)
//   //   });
//   // });

//   const qrCodeOptions = {
//     color: {
//       dark: '#000',
//     },
//     errorCorrectionLevel: 'H',
//     width: 200,
//     type: 'png',
//     transparent: true,
//   };

//   // const linkURL = `https://sttsonline.com//certificate.html?regNo=${student.regNo}`;
//   const linkURL = `/certificate.html?regNo=${student.RegNo}`;

//   const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//   const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//   const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
//   const { width, height } = page.getSize();
//   let qrCodeWidth = 80;
//   let qrCodeHeight = 80;

//   const qrCodeX = 65;
//   const qrCodeY = 60;

//   page.drawImage(qrCodeImage, {
//     x: qrCodeX,
//     y: qrCodeY,
//     width: qrCodeWidth,
//     height: qrCodeHeight,
//   });

// //   const pdfBytes = await pdfDoc.save();
// //   return pdfBytes;
// // };

//     const pdfBytes = await pdfDoc.save();
//     return pdfBytes;
//   };

//   // Helper function to wrap text
//   function wrapText(text, maxLength) {
//     const words = text.split(" ");
//     const lines = [];
//     let currentLine = "";

//     words.forEach((word) => {
//       if ((currentLine + word).length <= maxLength) {
//         currentLine += (currentLine ? " " : "") + word;
//       } else {
//         lines.push(currentLine);
//         currentLine = word;
//       }
//     });
//     lines.push(currentLine);

//     return lines;
//   }

 

//   const handleDeleteStudent = async (studentId) => {
//     try {
//       await axios.delete(`/api/student/${studentId}`);
//       fetchStudents();
//     } catch (error) {
//       console.error("Error deleting student:", error.message);
//     }
//   };

//   const handleAddGradeAndDate = async (studentId) => {
//     try {
//       const { grade, awardedDate } = studentInputs[studentId] || {};
//       if (grade && awardedDate) {
//         const updatedStudent = {
//           grade,
//           awardedDate,
//         };
//         await axios.put(`/api/addstudent/${studentId}`, updatedStudent);
//         setSavedData((prev) => ({ ...prev, [studentId]: true }));
//         setStudentInputs((prev) => ({
//           ...prev,
//           [studentId]: { grade: "", awardedDate: "" },
//         }));
//         fetchStudents();
//       } else {
//         alert("Please enter both grade and awarded date.");
//       }
//     } catch (error) {
//       console.error("Error updating student data:", error.message);
//       alert("Error updating student data. Please try again.");
//     }
//   };

//   const handleInputChange = (studentId, field, value) => {
//     setStudentInputs((prev) => ({
//       ...prev,
//       [studentId]: {
//         ...prev[studentId],
//         [field]: value,
//       },
//     }));
//   };

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const onChangeSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = students
//     .filter((student) =>
//       student.name.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     .slice(indexOfFirstStudent, indexOfLastStudent);

//   if (loading) return <p>Loading...</p>;

//   return (
//     <>
      

//       <div className="page-wrapper">
//         <Leftbar />

//         <div className="container responsive_all_here">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search students by name"
//               value={searchTerm}
//               onChange={onChangeSearch}
//               className="search-input"
//               style={{ color: "black", border: "black 1px solid" }}
//             />
//           </div>
//           <div className="student-list">
//             {currentStudents.map((student) => {
//               const { grade, awardedDate } = studentInputs[student._id] || {};
//               const isSaved = savedData[student._id];

//               return (
//                 <div key={student._id} className="student-card">
//                   <div className="student-info">
//                     <h3>{student.name}</h3>
//                     <p>
//                       <strong>RegNo:</strong> {student.RegNo}
//                     </p>
//                     <p>
//                       <strong>Course:</strong> {student.course}
//                     </p>
//                   </div>
//                   <div className="student-actions">
//                     <div className="action-buttons">
//                       <button
//                         className={`btn btn-generate ${
//                           !isSaved ? "disabled" : ""
//                         }`}
//                         onClick={() => handleGenerateCertificate(student)}
//                         disabled={!isSaved}
//                       >
//                         <BsPrinterFill />
//                       </button>
//                       <button
//                         className="btn btn-delete"
//                         onClick={() => handleDeleteStudent(student._id)}
//                       >
//                         <MdDeleteForever />
//                       </button>
//                     </div>
//                     <div className="grade-input">
//                       <input
//                         type="text"
//                         placeholder="Grade"
//                         value={grade || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "grade",
//                             e.target.value
//                           )
//                         }
//                         className="res-input-grade"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       />
//                       <input
//                         type="date"
//                         placeholder="Awarded on mm/dd/yyyy"
//                         value={awardedDate || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "awardedDate",
//                             e.target.value
//                           )
//                         }
//                         className="input-date"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       />
//                     </div>
//                     <button
//                       className="btn btn-save"
//                       onClick={() => handleAddGradeAndDate(student._id)}
//                       disabled={isSaved}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <Pagination
//             studentsPerPage={studentsPerPage}
//             totalStudents={
//               students.filter((student) =>
//                 student.name.toLowerCase().includes(searchTerm.toLowerCase())
//               ).length
//             }
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// const Pagination = ({
//   studentsPerPage,
//   totalStudents,
//   paginate,
//   currentPage,
// }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalStudents / studentsPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <nav className="pagination-container">
//       <ul className="pagination">
//         {pageNumbers.map((number) => (
//           <li
//             key={number}
//             className={`page-item ${currentPage === number ? "active" : ""}`}
//           >
//             <button onClick={() => paginate(number)} className="page-link">
//               {number}
//             </button>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };

// export default CertificateDetaile;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import QRCode from "qrcode";
// import Modal from "react-modal";
// import "./CertificateView.css";
// import Leftbar from "../Leftbar/Leftbar";
// import { BsPrinterFill } from "react-icons/bs";
// import { MdDeleteForever } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
// import "./certi.css";

// Modal.setAppElement("#root");

// function CertificateDetaile() {
//   const navigate = useNavigate();
//   const [students, setStudents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [studentInputs, setStudentInputs] = useState({});
//   const [savedData, setSavedData] = useState({});

//   useEffect(() => {
//     fetchStudents();
//     const savedDataFromStorage = JSON.parse(localStorage.getItem('savedData')) || {};
//     setSavedData(savedDataFromStorage);
//   }, []);

//   const fetchStudents = async () => {
//     try {
//       const response = await axios.get("/api/student");
//       setStudents(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching student data:", error.message);
//       setLoading(false);
//     }
//   };

//   const handleGenerateCertificate = async (student) => {
//     if (!savedData[student._id]) {
//       alert("Please save the grade and awarded date before generating the certificate.");
//       return;
//     }

//     try {
//       const pdfBytes = await generateCertificate(student);
//       const file = new File([pdfBytes], `Certificate_${student.RegNo}.pdf`, {
//         type: "application/pdf",
//       });
//       saveAs(file);
//     } catch (error) {
//       console.error("Error generating or downloading certificate:", error);
//       alert("Error generating or downloading certificate. Please try again later.");
//     }
//   };


//   const generateCertificate = async (student) => {
//     const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
//       res.arrayBuffer()
//     );
//     const pdfDoc = await PDFDocument.load(existingPdfBytes);
//     const page = pdfDoc.getPage(0);
//     const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
//     const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
//     page.drawText("Congratulations!", {
//       x: 56,
//       y: 400,
//       size: 24,
//       font: boldFont,
//       color: rgb(0.8, 0, 0),
//     });
  
//     const courseCompletionText =
//       "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
//     const wrappedText = wrapText(courseCompletionText, 75);
//     wrappedText.forEach((line, index) => {
//       page.drawText(line, {
//         x: 56,
//         y: 370 - index * 18,
//         size: 14,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//     });
  
//     // Calculate the y-position for RegNo based on the last line of wrapped text
//     const regNoYPosition = 390 - wrappedText.length * 18 - 30; // Adjust the -30 for spacing
  
//     // Display the Registration Number (RegNo)
//     page.drawText(`RegNo: ${student.RegNo}`, {
//       x: 420,
//       y: regNoYPosition,
//       size: 14,
//       font: boldFont,
//       color: rgb(0, 0, 0),
//     });
  
//     const leftDetails = [
//       { label: "Name", value: student.name.toUpperCase() },
//       { label: "Course Title", value: student.course },
//       { label: "Software Covered", value: student.softwareCovered },
//       { label: "Training Centre", value: student.centreName },
//     ];
  
//     const formatDate = (dateString) => {
//       const date = new Date(dateString);
//       const day = String(date.getDate()).padStart(2, "0");
//       const month = String(date.getMonth() + 1).padStart(2, "0");
//       const year = date.getFullYear();
//       return `${day}/${month}/${year}`;
//     };
  
//     const rightDetails = [
//       { label: "S/o, D/o, W/o", value: student.fatherName.toUpperCase() },
//       { label: "Course Duration", value: student.courseDuration },
//       { label: "Awarded on", value: formatDate(student.Date) },
//       { label: "Grade", value: student.grade },
//     ];
  
//     const drawDetails = (details, startX) => {
//       details.forEach((detail, index) => {
//         const yPosition = 270 - index * 30;
  
//         page.drawText(detail.value, {
//           x: startX,
//           y: yPosition,
//           size: 14,
//           font: boldFont,
//           color: rgb(0, 0, 0),
//         });
  
//         for (let i = 0; i < 60; i++) {
//           page.drawLine({
//             start: { x: startX + i * 5, y: yPosition - 2 },
//             end: { x: startX + i * 5 + 2, y: yPosition - 2 },
//             thickness: 1,
//             color: rgb(0, 0, 0),
//           });
//         }
  
//         page.drawText(detail.label, {
//           x: startX,
//           y: yPosition - 15,
//           size: 10,
//           font: font,
//           color: rgb(0.5, 0.5, 0.5),
//         });
//       });
//     };
  
//     drawDetails(leftDetails, 56);
//     drawDetails(rightDetails, 420);
  
//     const qrCodeOptions = {
//       color: {
//         dark: "#000",
//       },
//       errorCorrectionLevel: "H",
//       width: 200,
//       type: "png",
//       transparent: true,
//     };
  
//     // const linkURL = `/certificate.html?regNo=${student.RegNo}`;
//     const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.regNo}`;
//     const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//     const qrCodeImageBytes = Uint8Array.from(
//       atob(qrCodeURL.split("base64,")[1]),
//       (c) => c.charCodeAt(0)
//     );
//     const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
//     const qrCodeWidth = 80;
//     const qrCodeHeight = 80;
  
//     const qrCodeX = 65;
//     const qrCodeY = 60;
  
//     page.drawImage(qrCodeImage, {
//       x: qrCodeX,
//       y: qrCodeY,
//       width: qrCodeWidth,
//       height: qrCodeHeight,
//     });
  
//     const pdfBytes = await pdfDoc.save();
//     return pdfBytes;
//   };
  
//   // const generateCertificate = async (student) => {
//   //   const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
//   //     res.arrayBuffer()
//   //   );
//   //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
//   //   const page = pdfDoc.getPage(0);
//   //   const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
//   //   const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

//   //   page.drawText("Congratulations!", {
//   //     x: 56,
//   //     y: 400,
//   //     size: 20,
//   //     font: boldFont,
//   //     color: rgb(0.8, 0, 0),
//   //   });

//   //   const courseCompletionText =
//   //     "The Course you have completed, was designed to meet your learning needs with professional instructors, relevant content, authorized courseware and ongoing evaluation by STTS.";
//   //   const wrappedText = wrapText(courseCompletionText, 75);
//   //   wrappedText.forEach((line, index) => {
//   //     page.drawText(line, {
//   //       x: 56,
//   //       y: 350 - index * 18,
//   //       size: 14,
//   //       font: font,
//   //       color: rgb(0, 0, 0),
//   //     });
//   //   });

   
    
//   //   // Usage
   

//   //   const leftDetails = [
//   //     { label: "Name", value: student.name.toUpperCase() },
//   //     { label: "Course Title", value: student.course },
//   //     { label: "Software Covered", value: student.softwareCovered },
//   //     { label: "Training Centre", value: student.centreName },
//   //   ];

//   //   const formatDate = (dateString) => {
//   //     const date = new Date(dateString);
//   //     const day = String(date.getDate()).padStart(2, '0');
//   //     const month = String(date.getMonth() + 1).padStart(2, '0');
//   //     const year = date.getFullYear();
//   //     return `${day}/${month}/${year}`;
//   //   };

//   //   const rightDetails = [
//   //     { label: "S/o, D/o, W/o", value: student.fatherName.toUpperCase() },
//   //     { label: "Course Duration", value: student.courseDuration },
//   //     { label: "Awarded on", value: formatDate(student.Date) },
//   //     { label: "Grade", value: student.grade },
//   //   ];

//   //   const drawDetails = (details, startX) => {
//   //     details.forEach((detail, index) => {
//   //       const yPosition = 270 - index * 30;

//   //       page.drawText(detail.value, {
//   //         x: startX,
//   //         y: yPosition,
//   //         size: 14,
//   //         font: boldFont,
//   //         color: rgb(0, 0, 0),
//   //       });

//   //       for (let i = 0; i < 30; i++) {
//   //         page.drawLine({
//   //           start: { x: startX + i * 5, y: yPosition - 2 },
//   //           end: { x: startX + i * 5 + 2, y: yPosition - 2 },
//   //           thickness: 1,
//   //           color: rgb(0, 0, 0),
//   //         });
//   //       }

//   //       page.drawText(detail.label, {
//   //         x: startX,
//   //         y: yPosition - 15,
//   //         size: 10,
//   //         font: font,
//   //         color: rgb(0.5, 0.5, 0.5),
//   //       });
//   //     });
//   //   };

//   //   drawDetails(leftDetails, 56);
//   //   drawDetails(rightDetails, 320);

//   //   const qrCodeOptions = {
//   //     color: {
//   //       dark: '#000',
//   //     },
//   //     errorCorrectionLevel: 'H',
//   //     width: 200,
//   //     type: 'png',
//   //     transparent: true,
//   //   };

//   //   const linkURL = `/certificate.html?regNo=${student.RegNo}`;

//   //   const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//   //   const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
//   //   const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
//   //   const qrCodeWidth = 80;
//   //   const qrCodeHeight = 80;

//   //   const qrCodeX = 65;
//   //   const qrCodeY = 60;

//   //   page.drawImage(qrCodeImage, {
//   //     x: qrCodeX,
//   //     y: qrCodeY,
//   //     width: qrCodeWidth,
//   //     height: qrCodeHeight,
//   //   });

//   //   const pdfBytes = await pdfDoc.save();
//   //   return pdfBytes;
//   // };

//   function wrapText(text, maxLength) {
//     const words = text.split(" ");
//     const lines = [];
//     let currentLine = "";

//     words.forEach((word) => {
//       if ((currentLine + word).length <= maxLength) {
//         currentLine += (currentLine ? " " : "") + word;
//       } else {
//         lines.push(currentLine);
//         currentLine = word;
//       }
//     });
//     lines.push(currentLine);

//     return lines;
//   }

//   const handleDeleteStudent = async (studentId) => {
//     try {
//       await axios.delete(`/api/student/${studentId}`);
//       fetchStudents();
//     } catch (error) {
//       console.error("Error deleting student:", error.message);
//     }
//   };

//   const handleAddGradeAndDate = async (studentId) => {
//     try {
//       const { grade, awardedDate } = studentInputs[studentId] || {};
//       if (grade && awardedDate) {
//         const updatedStudent = {
//           grade,
//           awardedDate,
//         };
//         await axios.put(`/api/addstudent/${studentId}`, updatedStudent);
//         const newSavedData = { ...savedData, [studentId]: true };
//         setSavedData(newSavedData);
//         localStorage.setItem('savedData', JSON.stringify(newSavedData));
//         setStudentInputs((prev) => ({
//           ...prev,
//           [studentId]: { grade: "", awardedDate: "" },
//         }));
//         fetchStudents();
//       } else {
//         alert("Please enter both grade and awarded date.");
//       }
//     } catch (error) {
//       console.error("Error updating student data:", error.message);
//       alert("Error updating student data. Please try again.");
//     }
//   };

//   const handleInputChange = (studentId, field, value) => {
//     setStudentInputs((prev) => ({
//       ...prev,
//       [studentId]: {
//         ...prev[studentId],
//         [field]: value,
//       },
//     }));
//   };

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const onChangeSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = students
//     .filter((student) =>
//       student.name.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     .slice(indexOfFirstStudent, indexOfLastStudent);

//   if (loading) return <p>Loading...</p>;

//   return (
//     <>
//       <div className="page-wrapper">
//         <Leftbar />

//         <div className="container responsive_all_here">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search students by name"
//               value={searchTerm}
//               onChange={onChangeSearch}
//               className="search-input"
//               style={{ color: "black", border: "black 1px solid" }}
//             />
//           </div>
//           <div className="student-list">
//             {currentStudents.map((student) => {
//               const { grade, awardedDate } = studentInputs[student._id] || {};
//               const isSaved = savedData[student._id];

//               return (
//                 <div key={student._id} className="student-card">
//                   <div className="student-info">
//                     <h3>{student.name}</h3>
//                     <p>
//                       <strong>RegNo:</strong> {student.RegNo}
//                     </p>
//                     <p>
//                       <strong>Course:</strong> {student.course}
//                     </p>
//                   </div>
//                   <div className="student-actions">
//                     <div className="action-buttons">
//                       <button
//                         className={`btn btn-generate ${
//                           !isSaved ? "disabled" : ""
//                         }`}
//                         onClick={() => handleGenerateCertificate(student)}
//                         disabled={!isSaved}
//                       >
//                         Download
//                         {/* <BsPrinterFill /> */}
//                       </button>
//                       {/* <button
//                         className="btn btn-delete"
//                         onClick={() => handleDeleteStudent(student._id)}
//                       >
//                         <MdDeleteForever />
//                       </button> */}
//                     </div>
//                     <div className="grade-input">
//                       <select
//                         value={grade || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "grade",
//                             e.target.value
//                           )
//                         }
//                         className="res-input-grade"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       >
//                         <option value="">Select Grade</option>
//                         <option value="A+">A+</option>
//                         <option value="A">A</option>
//                         <option value="B">B</option>
//                       </select>
//                       <input
//                         type="date"
//                         placeholder="Awarded on mm/dd/yyyy"
//                         value={awardedDate || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "awardedDate",
//                             e.target.value
//                           )
//                         }
//                         className="input-date"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       />
//                     </div>
//                     <button
//                       className="btn btn-save"
//                       onClick={() => handleAddGradeAndDate(student._id)}
//                       disabled={isSaved}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <Pagination
//             studentsPerPage={studentsPerPage}
//             totalStudents={
//               students.filter((student) =>
//                 student.name.toLowerCase().includes(searchTerm.toLowerCase())
//               ).length
//             }
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// const Pagination = ({
//   studentsPerPage,
//   totalStudents,
//   paginate,
//   currentPage,
// }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalStudents / studentsPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <nav className="pagination-container">
//       <ul className="pagination">
//         {pageNumbers.map((number) => (
//           <li
//             key={number}
//             className={`page-item ${currentPage === number ? "active" : ""}`}
//           >
//             <button onClick={() => paginate(number)} className="page-link">
//               {number}
//             </button>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };

// export default CertificateDetaile;




// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { saveAs } from "file-saver";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import QRCode from "qrcode";
// import Modal from "react-modal";
// import "./CertificateView.css";
// import Leftbar from "../Leftbar/Leftbar";
// import { useNavigate } from "react-router-dom";
// import "./certi.css";

// Modal.setAppElement("#root");

// function CertificateDetaile() {
//   const navigate = useNavigate();
//   const [students, setStudents] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [studentsPerPage] = useState(5);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [studentInputs, setStudentInputs] = useState({});
//   const [savedData, setSavedData] = useState({});

//   useEffect(() => {
//     fetchStudents();
//     const savedDataFromStorage = JSON.parse(localStorage.getItem('savedData')) || {};
//     setSavedData(savedDataFromStorage);
//   }, []);

//   const fetchStudents = async () => {
//     try {
//       const response = await axios.get("/api/student");
//       setStudents(response.data);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching student data:", error.message);
//       setLoading(false);
//     }
//   };

//   const handleGenerateCertificate = async (student) => {
//     if (!savedData[student._id]) {
//       alert("Please save the grade and awarded date before generating the certificate.");
//       return;
//     }

//     try {
//       if (!student || typeof student !== 'object') {
//         throw new Error('Invalid student data');
//       }
//       const pdfBytes = await generateCertificate(student);
//       const file = new File([pdfBytes], `Certificate_${student.RegNo || 'Unknown'}.pdf`, {
//         type: "application/pdf",
//       });
//       saveAs(file);
//     } catch (error) {
//       console.error("Error generating or downloading certificate:", error);
//       alert("Error generating or downloading certificate. Please try again later.");
//     }
//   };

//   const generateCertificate = async (student) => {
//     const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
//       res.arrayBuffer()
//     );
//     const pdfDoc = await PDFDocument.load(existingPdfBytes);
//     const page = pdfDoc.getPage(0);
//     const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
//     const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
//     page.drawText("Congratulations!", {
//       x: 56,
//       y: 400,
//       size: 24,
//       font: boldFont,
//       color: rgb(0.8, 0, 0),
//     });
  
//     const courseCompletionText =
//       "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
//     const wrappedText = wrapText(courseCompletionText, 75);
//     wrappedText.forEach((line, index) => {
//       page.drawText(line, {
//         x: 56,
//         y: 370 - index * 18,
//         size: 14,
//         font: font,
//         color: rgb(0, 0, 0),
//       });
//     });
  
//     const regNoYPosition = 390 - wrappedText.length * 18 - 30;
  
//     page.drawText(`RegNo: ${student.RegNo || 'Unknown'}`, {
//       x: 420,
//       y: regNoYPosition,
//       size: 14,
//       font: boldFont,
//       color: rgb(0, 0, 0),
//     });
  
//     const leftDetails = [
//       { label: "Name", value: (student.name || "").toUpperCase() },
//       { label: "Course Title", value: student.course || "" },
//       { label: "Software Covered", value: student.softwareCovered || "" },
//       { label: "Training Centre", value: student.centreName || "" },
//     ];
  
//     const formatDate = (dateString) => {
//       if (!dateString) return "";
//       const date = new Date(dateString);
//       const day = String(date.getDate()).padStart(2, "0");
//       const month = String(date.getMonth() + 1).padStart(2, "0");
//       const year = date.getFullYear();
//       return `${day}/${month}/${year}`;
//     };
  
//     const rightDetails = [
//       { label: "S/o, D/o, W/o", value: (student.fatherName || "").toUpperCase() },
//       { label: "Course Duration", value: student.courseDuration || "" },
//       { label: "Awarded on", value: formatDate(student.Date) },
//       { label: "Grade", value: student.grade || "" },
//     ];
  
//     const drawDetails = (details, startX) => {
//       details.forEach((detail, index) => {
//         const yPosition = 270 - index * 30;
  
//         page.drawText(detail.value, {
//           x: startX,
//           y: yPosition,
//           size: 14,
//           font: boldFont,
//           color: rgb(0, 0, 0),
//         });
  
//         for (let i = 0; i < 60; i++) {
//           page.drawLine({
//             start: { x: startX + i * 5, y: yPosition - 2 },
//             end: { x: startX + i * 5 + 2, y: yPosition - 2 },
//             thickness: 1,
//             color: rgb(0, 0, 0),
//           });
//         }
  
//         page.drawText(detail.label, {
//           x: startX,
//           y: yPosition - 15,
//           size: 10,
//           font: font,
//           color: rgb(0.5, 0.5, 0.5),
//         });
//       });
//     };
  
//     drawDetails(leftDetails, 56);
//     drawDetails(rightDetails, 420);
  
//     const qrCodeOptions = {
//       color: {
//         dark: "#000",
//       },
//       errorCorrectionLevel: "H",
//       width: 200,
//       type: "png",
//       transparent: true,
//     };
  
//     const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.RegNo || ''}`;
//     const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
//     const qrCodeImageBytes = Uint8Array.from(
//       atob(qrCodeURL.split("base64,")[1]),
//       (c) => c.charCodeAt(0)
//     );
//     const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
//     const qrCodeWidth = 80;
//     const qrCodeHeight = 80;
  
//     const qrCodeX = 65;
//     const qrCodeY = 60;
  
//     page.drawImage(qrCodeImage, {
//       x: qrCodeX,
//       y: qrCodeY,
//       width: qrCodeWidth,
//       height: qrCodeHeight,
//     });
  
//     const pdfBytes = await pdfDoc.save();
//     return pdfBytes;
//   };

//   function wrapText(text, maxLength) {
//     const words = text.split(" ");
//     const lines = [];
//     let currentLine = "";

//     words.forEach((word) => {
//       if ((currentLine + word).length <= maxLength) {
//         currentLine += (currentLine ? " " : "") + word;
//       } else {
//         lines.push(currentLine);
//         currentLine = word;
//       }
//     });
//     lines.push(currentLine);

//     return lines;
//   }

//   const handleAddGradeAndDate = async (studentId) => {
//     try {
//       const { grade, awardedDate } = studentInputs[studentId] || {};
//       if (grade && awardedDate) {
//         const updatedStudent = {
//           grade,
//           awardedDate,
//         };
//         await axios.put(`/api/addstudent/${studentId}`, updatedStudent);
//         const newSavedData = { ...savedData, [studentId]: true };
//         setSavedData(newSavedData);
//         localStorage.setItem('savedData', JSON.stringify(newSavedData));
//         setStudentInputs((prev) => ({
//           ...prev,
//           [studentId]: { grade: "", awardedDate: "" },
//         }));
//         fetchStudents();
//       } else {
//         alert("Please enter both grade and awarded date.");
//       }
//     } catch (error) {
//       console.error("Error updating student data:", error.message);
//       alert("Error updating student data. Please try again.");
//     }
//   };

//   const handleInputChange = (studentId, field, value) => {
//     setStudentInputs((prev) => ({
//       ...prev,
//       [studentId]: {
//         ...prev[studentId],
//         [field]: value,
//       },
//     }));
//   };

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);

//   const onChangeSearch = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const indexOfLastStudent = currentPage * studentsPerPage;
//   const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
//   const currentStudents = students
//     .filter((student) =>
//       student.name.toLowerCase().includes(searchTerm.toLowerCase())
//     )
//     .slice(indexOfFirstStudent, indexOfLastStudent);

//   if (loading) return <p>Loading...</p>;

//   return (
//     <>
//       <div className="page-wrapper">
//         <Leftbar />

//         <div className="container responsive_all_here">
//           <div className="search-bar">
//             <input
//               type="text"
//               placeholder="Search students by name"
//               value={searchTerm}
//               onChange={onChangeSearch}
//               className="search-input"
//               style={{ color: "black", border: "black 1px solid" }}
//             />
//           </div>
//           <div className="student-list">
//             {currentStudents.map((student) => {
//               const { grade, awardedDate } = studentInputs[student._id] || {};
//               const isSaved = savedData[student._id];
//               const canSave = grade && awardedDate && !isSaved;

//               return (
//                 <div key={student._id} className="student-card">
//                   <div className="student-info">
//                     <h3>{student.name}</h3>
//                     <p>
//                       <strong>RegNo:</strong> {student.RegNo}
//                     </p>
//                     <p>
//                       <strong>Course:</strong> {student.course}
//                     </p>
//                   </div>
//                   <div className="student-actions">
//                     <div className="action-buttons">
//                       <button
//                         className={`btn btn-generate ${
//                           !isSaved ? "disabled" : ""
//                         }`}
//                         onClick={() => handleGenerateCertificate(student)}
//                         disabled={!isSaved}
//                       >
//                         Download
//                       </button>
//                     </div>
//                     <div className="grade-input">
//                       <select
//                         value={grade || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "grade",
//                             e.target.value
//                           )
//                         }
//                         className="res-input-grade"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       >
//                         <option value="">Select Grade</option>
//                         <option value="A+">A+</option>
//                         <option value="A">A</option>
//                         <option value="B">B</option>
//                       </select>
//                       <input
//                         type="date"
//                         placeholder="Awarded on mm/dd/yyyy"
//                         value={awardedDate || ""}
//                         onChange={(e) =>
//                           handleInputChange(
//                             student._id,
//                             "awardedDate",
//                             e.target.value
//                           )
//                         }
//                         className="input-date"
//                         style={{ color: "black", border: "black 1px solid" }}
//                         disabled={isSaved}
//                       />
//                     </div>
//                     <button
//                       className="btn btn-save"
//                       onClick={() => handleAddGradeAndDate(student._id)}
//                       disabled={!canSave}
//                     >
//                       Save
//                     </button>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//           <Pagination
//             studentsPerPage={studentsPerPage}
//             totalStudents={
//               students.filter((student) =>
//                 student.name.toLowerCase().includes(searchTerm.toLowerCase())
//               ).length
//             }
//             paginate={paginate}
//             currentPage={currentPage}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// const Pagination = ({
//   studentsPerPage,
//   totalStudents,
//   paginate,
//   currentPage,
// }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalStudents / studentsPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <nav className="pagination-container">
//       <ul className="pagination">
//         {pageNumbers.map((number) => (
//           <li
//             key={number}
//             className={`page-item ${currentPage === number ? "active" : ""}`}
//           >
//             <button onClick={() => paginate(number)} className="page-link">
//               {number}
//             </button>
//           </li>
//         ))}
//       </ul>
//     </nav>
//   );
// };

// export default CertificateDetaile;


import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import QRCode from "qrcode";
import Modal from "react-modal";
import "./CertificateView.css";
import Leftbar from "../Leftbar/Leftbar";
import { useNavigate } from "react-router-dom";
import "./certi.css";

Modal.setAppElement("#root");

function CertificateDetaile() {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentInputs, setStudentInputs] = useState({});
  const [savedData, setSavedData] = useState({});

  useEffect(() => {
    fetchStudents();
    const savedDataFromStorage = JSON.parse(localStorage.getItem('savedData')) || {};
    setSavedData(savedDataFromStorage);
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axios.get("/api/student");
      // Filter students with currentDue equal to zero
      const eligibleStudents = response.data.filter(student => student.currentDue === 0);
      setStudents(eligibleStudents);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching student data:", error.message);
      setLoading(false);
    }
  };

  const handleGenerateCertificate = async (student) => {
    if (!savedData[student._id]) {
      alert("Please save the grade and awarded date before generating the certificate.");
      return;
    }

    try {
      if (!student || typeof student !== 'object') {
        throw new Error('Invalid student data');
      }
      const pdfBytes = await generateCertificate(student);
      const file = new File([pdfBytes], `Certificate_${student.RegNo || 'Unknown'}.pdf`, {
        type: "application/pdf",
      });
      saveAs(file);
    } catch (error) {
      console.error("Error generating or downloading certificate:", error);
      alert("Error generating or downloading certificate. Please try again later.");
    }
  };
  // const generateCertificate = async (student) => {
  //   const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const page = pdfDoc.getPage(0);
  //   const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  //   const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
  //   page.drawText("Congratulations!", {
  //     x: 306,
  //     y: 410,
  //     size: 24,
  //     font: boldFont,
  //     color: rgb(0.8, 0, 0),
  //   });
  
  //   const courseCompletionText =
  //     "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
  //   const wrappedText = wrapText(courseCompletionText, 75);
  
  //   const initialY = 380;
  //   const secondLineOffsetX = 35;
  //   const thirdLineOffsetX = 150;
  
  //   wrappedText.forEach((line, index) => {
  //     let xCoordinate = 156;
  
  //     if (index === 1) {
  //       xCoordinate += secondLineOffsetX;
  //     } else if (index === 2) {
  //       xCoordinate += thirdLineOffsetX;
  //     }
  
  //     page.drawText(line, {
  //       x: xCoordinate,
  //       y: initialY - index * 18,
  //       size: 14,
  //       font: font,
  //       color: rgb(0, 0, 0),
  //     });
  //   });
  
  //   const regNoYPosition = 420 - wrappedText.length * 18 - 30;
  
  //   page.drawText(`Reg. No.: ${student.RegNo || 'Unknown'}`, {
  //     x: 510,
  //     y: regNoYPosition,
  //     size: 14,
  //     font: boldFont,
  //     color: rgb(0, 0, 0),
  //   });
  
  //   const leftDetails = [
  //     { label: "Name", value: (student.name || "").toUpperCase() },
  //     { label: "Course Title", value: (student.course || "").toUpperCase() },
  //     { label: "Software Covered", value: (student.softwareCovered || []).join(", ").toUpperCase() },
  //     { label: "Training Centre", value: (student.centreName || "").toUpperCase() },

  //   ];
  
  //   const formatDate = (dateString) => {
  //     if (!dateString) return "";
  //     const date = new Date(dateString);
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const year = date.getFullYear();
  //     return `${month}/${day}/${year}`;
  //   };
  
  //   const rightDetails = [
  //     { label: "S/o, D/o, W/o", value: (student.fatherName || "").toUpperCase() },
  //     { label: "Course Duration", value: `${student.courseDuration || ""} Hrs` },
  //     { label: "Awarded on", value: formatDate(student.Date) },
  //     { label: "Grade", value: student.grade || "" },
  //   ];
  //   const drawDetails = (details, startX) => {
  //     let yOffset = 0;
    
  //     details.forEach((detail, index) => {
  //       const yPosition = 320 - yOffset;
    
  //       // Wrapping text for long content
  //       const lines = wrapText(detail.value, 45);
  //       lines.forEach((line, lineIndex) => {
  //         page.drawText(line, {
  //           x: startX,
  //           y: yPosition - (lineIndex + 1) * 14, // Draw value first
  //           size: 14,
  //           font: boldFont,
  //           color: rgb(0, 0, 0),
  //         });
    
  //         // Dotted line directly below the value
  //         for (let i = 0; i < 60; i++) {
  //           page.drawLine({
  //             start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * 14 - 1 },
  //             end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * 14 - 1 },
  //             thickness: 1,
  //             color: rgb(0, 0, 0),
  //           });
  //         }
  //       });
    
  //       // Draw label below the value and above the dotted line
  //       page.drawText(detail.label, {
  //         x: startX,
  //         y: yPosition - (lines.length + 1) * 14 - 4, // Position label below the last line of value
  //         size: 10,
  //         font: font,
  //         color: rgb(0.5, 0.5, 0.5),
  //       });
    
  //       // Update yOffset for the next detail
  //       yOffset += lines.length * 14 + 20; // Adjusted to balance space between entries
  //     });
  //   };
    
  
  //   // const drawDetails = (details, startX) => {
  //   //   let yOffset = 0;
    
  //   //   details.forEach((detail, index) => {
  //   //     const yPosition = 290 - yOffset;
    
  //   //     // Draw label first, above the value
  //   //     page.drawText(detail.label, {
  //   //       x: startX,
  //   //       y: yPosition,
  //   //       size: 10,
  //   //       font: font,
  //   //       color: rgb(0.5, 0.5, 0.5),
  //   //     });
    
  //   //     // Wrapping text for long content
  //   //     const lines = wrapText(detail.value, 45);
  //   //     lines.forEach((line, lineIndex) => {
  //   //       page.drawText(line, {
  //   //         x: startX,
  //   //         y: yPosition - (lineIndex + 1) * 14, // Position value closer to the label
  //   //         size: 14,
  //   //         font: boldFont,
  //   //         color: rgb(0, 0, 0),
  //   //       });
    
  //   //       // Dotted line directly below the value
  //   //       for (let i = 0; i < 60; i++) {
  //   //         page.drawLine({
  //   //           start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * 14 - 1 },
  //   //           end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * 14 - 1 },
  //   //           thickness: 1,
  //   //           color: rgb(0, 0, 0),
  //   //         });
  //   //       }
  //   //     });
    
  //   //     // Update yOffset for the next detail
  //   //     yOffset += lines.length * 14 + 20; // Adjusted to balance space between entries
  //   //   });
  //   // };
    
    
  
  //   drawDetails(leftDetails, 56);
  //   drawDetails(rightDetails, 490);
  
  //   const qrCodeOptions = {
  //     color: {
  //       dark: "#000",
  //     },
  //     errorCorrectionLevel: "H",
  //     width: 200,
  //     type: "png",
  //     transparent: true,
  //   };
  
  //   const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.RegNo || ''}`;
  //   const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
  //   const qrCodeImageBytes = Uint8Array.from(
  //     atob(qrCodeURL.split("base64,")[1]),
  //     (c) => c.charCodeAt(0)
  //   );
  //   const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
  //   const qrCodeWidth = 80;
  //   const qrCodeHeight = 80;
  
  //   const qrCodeX = 65;
  //   const qrCodeY = 60;
  
  //   page.drawImage(qrCodeImage, {
  //     x: qrCodeX,
  //     y: qrCodeY,
  //     width: qrCodeWidth,
  //     height: qrCodeHeight,
  //   });
  
  //   const pdfBytes = await pdfDoc.save();
  //   return pdfBytes;
  // };
  
  // function wrapText(text, maxLength) {
  //   const words = text.split(" ");
  //   const lines = [];
  //   let currentLine = "";
  
  //   words.forEach((word) => {
  //     if ((currentLine + word).length <= maxLength) {
  //       currentLine += (currentLine ? " " : "") + word;
  //     } else {
  //       lines.push(currentLine);
  //       currentLine = word;
  //     }
  //   });
  //   lines.push(currentLine);
  
  //   return lines;
  // }
  
  // const generateCertificate = async (student) => {
  //   const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const page = pdfDoc.getPage(0);
  //   const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  //   const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
  //   page.drawText("Congratulations!", {
  //     x: 306,
  //     y: 410,
  //     size: 24,
  //     font: boldFont,
  //     color: rgb(0.8, 0, 0),
  //   });
  
  //   const courseCompletionText =
  //     "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
  //   const wrappedText = wrapText(courseCompletionText, 75);
  
  //   const initialY = 380;
  //   const secondLineOffsetX = 35;
  //   const thirdLineOffsetX = 150;
  
  //   wrappedText.forEach((line, index) => {
  //     let xCoordinate = 156;
  
  //     if (index === 1) {
  //       xCoordinate += secondLineOffsetX;
  //     } else if (index === 2) {
  //       xCoordinate += thirdLineOffsetX;
  //     }
  
  //     page.drawText(line, {
  //       x: xCoordinate,
  //       y: initialY - index * 18,
  //       size: 14,
  //       font: font,
  //       color: rgb(0, 0, 0),
  //     });
  //   });
  
  //   const regNoYPosition = 420 - wrappedText.length * 18 - 30;
  
  //   page.drawText(`Reg. No.: ${student.RegNo || 'Unknown'}`, {
  //     x: 510,
  //     y: regNoYPosition,
  //     size: 14,
  //     font: boldFont,
  //     color: rgb(0, 0, 0),
  //   });
  
  //   const leftDetails = [
  //     { label: "Name", value: (student.name || "").toUpperCase() },
  //     { label: "Course Title", value: (student.course || "").toUpperCase() },
  //     { label: "Software Covered", value: (student.softwareCovered || []).join(", ").toUpperCase() },
  //     { label: "Training Centre", value: (student.centreName || "").toUpperCase() },
  //   ];
  
  //   const formatDate = (dateString) => {
  //     if (!dateString) return "";
  //     const date = new Date(dateString);
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const year = date.getFullYear();
  //     return `${month}/${day}/${year}`;
  //   };
  
  //   const rightDetails = [
  //     { label: "S/o, D/o, W/o", value: (student.fatherName || "").toUpperCase() },
  //     { label: "Course Duration", value: `${student.courseDuration || ""} Hrs` },
  //     { label: "Awarded on", value: formatDate(student.Date) },
  //     { label: "Grade", value: student.grade || "" },
  //   ];

  //   const drawDetails = (details, startX) => {
  //     let yOffset = 0;
    
  //     details.forEach((detail, index) => {
  //       const yPosition = 320 - yOffset;
    
  //       // Adjust the wrapping width for the "Software Covered" field
  //       const wrapWidth = detail.label === "Software Covered" ? 35 : 45;
    
  //       // Wrapping text for long content
  //       const lines = wrapText(detail.value, wrapWidth);
    
  //       // Add extra space between lines for the "Software Covered" label
  //       const lineSpacing = detail.label === "Software Covered" ? 20 : 14;
    
  //       lines.forEach((line, lineIndex) => {
  //         // Draw the text with a slight offset to create a gap above the dotted line
  //         page.drawText(line, {
  //           x: startX,
  //           y: yPosition - (lineIndex + 1) * lineSpacing + 5,
  //           size: 12,
  //           font: boldFont,
  //           color: rgb(0, 0, 0),
  //         });
    
  //         // Dotted line below the value, now with a gap
  //         for (let i = 0; i < 60; i++) {
  //           page.drawLine({
  //             start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
  //             end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
  //             thickness: 1,
  //             color: rgb(0, 0, 0),
  //           });
  //         }
  //       });
    
  //       // Adjust the yPosition for the label only for "Software Covered"
  //       const labelYOffset = detail.label === "Software Covered" ? 6 : 0;
    
  //       // Draw label lower, closer to the dotted line
  //       page.drawText(detail.label, {
  //         x: startX,
  //         y: yPosition - (lines.length + 1) * lineSpacing - 0 + labelYOffset,
  //         size: 10,
  //         font: font,
  //         color: rgb(0.5, 0.5, 0.5),
  //       });
    
  //       // Update yOffset for the next detail
  //       yOffset += lines.length * lineSpacing + 25; // Increased spacing between entries
  //     });
  //   };
    
  //   drawDetails(leftDetails, 56);
  //   drawDetails(rightDetails, 460);
  
  //   const qrCodeOptions = {
  //     color: {
  //       dark: "#000",
  //     },
  //     errorCorrectionLevel: "H",
  //     width: 200,
  //     type: "png",
  //     transparent: true,
  //   };
  
  //   const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.RegNo || ''}`;
  //   const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
  //   const qrCodeImageBytes = Uint8Array.from(
  //     atob(qrCodeURL.split("base64,")[1]),
  //     (c) => c.charCodeAt(0)
  //   );
  //   const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
  //   const qrCodeWidth = 80;
  //   const qrCodeHeight = 80;
  
  //   const qrCodeX = 65;
  //   const qrCodeY =40;
  
  //   page.drawImage(qrCodeImage, {
  //     x: qrCodeX,
  //     y: qrCodeY,
  //     width: qrCodeWidth,
  //     height: qrCodeHeight,
  //   });
  
  //   const pdfBytes = await pdfDoc.save();
  //   return pdfBytes;
  // };
  
  // function wrapText(text, maxLength) {
  //   const words = text.split(" ");
  //   const lines = [];
  //   let currentLine = "";
  
  //   words.forEach((word) => {
  //     if ((currentLine + word).length <= maxLength) {
  //       currentLine += (currentLine ? " " : "") + word;
  //     } else {
  //       lines.push(currentLine);
  //       currentLine = word;
  //     }
  //   });
  //   lines.push(currentLine);
  
  //   return lines;
  // }





  const generateCertificate = async (student) => {
    const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const page = pdfDoc.getPage(0);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
    page.drawText("Congratulations!", {
      x: 306,
      y: 410,
      size: 24,
      font: boldFont,
      color: rgb(0.8, 0, 0),
    });
  
    const courseCompletionText =
      "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
    const wrappedText = wrapText(courseCompletionText, 75);
  
    const initialY = 380;
    const secondLineOffsetX = 35;
    const thirdLineOffsetX = 150;
  
    wrappedText.forEach((line, index) => {
      let xCoordinate = 156;
  
      if (index === 1) {
        xCoordinate += secondLineOffsetX;
      } else if (index === 2) {
        xCoordinate += thirdLineOffsetX;
      }
  
      page.drawText(line, {
        x: xCoordinate,
        y: initialY - index * 18,
        size: 14,
        font: font,
        color: rgb(0, 0, 0),
      });
    });
  
    const regNoYPosition = 420 - wrappedText.length * 18 - 30;
  
    page.drawText(`Reg. No.: ${student.RegNo || "Unknown"}`, {
      x: 510,
      y: regNoYPosition,
      size: 14,
      font: boldFont,
      color: rgb(0, 0, 0),
    });
  
    const leftDetails = [
      { label: "Name", value: (student.name || "").toUpperCase() },
      { label: "Course Title", value: (student.course || "").toUpperCase() },
      { label: "Software Covered", value: (student.softwareCovered || []).join(", ").toUpperCase() },
      { label: "Training Centre", value: (student.centreName || "").toUpperCase() },
    ];
  
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const rightDetails = [
      { label: "S/o, D/o, W/o", value: (student.fatherName || "").toUpperCase() },
      { label: "Course Duration", value: `${student.courseDuration || ""} Hrs` },
      { label: "Awarded on", value: formatDate(student.awardedDate) },
      { label: "Grade", value: student.grade || "" },
    ];
  
    const drawDetails = (details, startX) => {
      let yOffset = 0;
  
      details.forEach((detail, index) => {
        const yPosition = 320 - yOffset;
  
        const wrapWidth = detail.label === "Software Covered" ? 35 : 45;
  
        const lines = wrapText(detail.value, wrapWidth);
  
        const lineSpacing = detail.label === "Software Covered" ? 20 : 14;
  
        lines.forEach((line, lineIndex) => {
          page.drawText(line, {
            x: startX,
            y: yPosition - (lineIndex + 1) * lineSpacing + 5,
            size: 12,
            font: boldFont,
            color: rgb(0, 0, 0),
          });
  
          for (let i = 0; i < 60; i++) {
            page.drawLine({
              start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
              end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
              thickness: 1,
              color: rgb(0, 0, 0),
            });
          }
        });
  
        const labelYOffset = detail.label === "Software Covered" ? 6 : 0;
  
        page.drawText(detail.label, {
          x: startX,
          y: yPosition - (lines.length + 1) * lineSpacing - 0 + labelYOffset,
          size: 10,
          font: font,
          color: rgb(0.5, 0.5, 0.5),
        });
  
        yOffset += lines.length * lineSpacing + 25;
      });
  
      return yOffset; // Return the total height occupied by the details
    };
  
    const leftDetailsHeight = drawDetails(leftDetails, 56);
    const rightDetailsHeight = drawDetails(rightDetails, 460);
  
    const totalHeight = Math.max(leftDetailsHeight, rightDetailsHeight);
  
    const qrCodeOptions = {
      color: {
        dark: "#000",
      },
      errorCorrectionLevel: "H",
      width: 200,
      type: "png",
      transparent: true,
    };
  
    const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.RegNo || ""}`;
    const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
    const qrCodeImageBytes = Uint8Array.from(
      atob(qrCodeURL.split("base64,")[1]),
      (c) => c.charCodeAt(0)
    );
    const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
    
    // Adjust QR code size and position dynamically
    const qrCodeBaseSize = 80;
    const qrCodeMaxLines = 2;  // Max lines for software before resizing
    const softwareCoveredLines = wrapText(leftDetails.find(detail => detail.label === "Software Covered").value, 35).length;
    
    let qrCodeSize = qrCodeBaseSize;
    let qrCodeY = Math.max(40, 320 - totalHeight - 100);
  
    // If "Software Covered" exceeds 2 lines, reduce the QR code size
    if (softwareCoveredLines > qrCodeMaxLines) {
      qrCodeSize = qrCodeBaseSize - (softwareCoveredLines - qrCodeMaxLines) * 10; // Reduce size
      qrCodeY -= (softwareCoveredLines - qrCodeMaxLines) * 3;  // Move it down
    }
  
    const qrCodeX = 65;
  
    page.drawImage(qrCodeImage, {
      x: qrCodeX,
      y: qrCodeY,
      width: qrCodeSize,
      height: qrCodeSize,
    });
  
    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  };
  
  function wrapText(text, maxLength) {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";
  
    words.forEach((word) => {
      if ((currentLine + word).length <= maxLength) {
        currentLine += (currentLine ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });
    lines.push(currentLine);
  
    return lines;
  }
  
  // const generateCertificate = async (student) => {
  //   const existingPdfBytes = await fetch("Certi.pdf").then((res) =>
  //     res.arrayBuffer()
  //   );
  //   const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //   const page = pdfDoc.getPage(0);
  //   const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  //   const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  
  //   page.drawText("Congratulations!", {
  //     x: 306,
  //     y: 410,
  //     size: 24,
  //     font: boldFont,
  //     color: rgb(0.8, 0, 0),
  //   });
  
  //   const courseCompletionText =
  //     "The Course you have completed was designed to meet your learning needs with professional instructors, relevant content, authorized courseware, and ongoing evaluation by STTS.";
  //   const wrappedText = wrapText(courseCompletionText, 75);
  
  //   const initialY = 380;
  //   const secondLineOffsetX = 35;
  //   const thirdLineOffsetX = 150;
  
  //   wrappedText.forEach((line, index) => {
  //     let xCoordinate = 156;
  
  //     if (index === 1) {
  //       xCoordinate += secondLineOffsetX;
  //     } else if (index === 2) {
  //       xCoordinate += thirdLineOffsetX;
  //     }
  
  //     page.drawText(line, {
  //       x: xCoordinate,
  //       y: initialY - index * 18,
  //       size: 14,
  //       font: font,
  //       color: rgb(0, 0, 0),
  //     });
  //   });
  
  //   const regNoYPosition = 420 - wrappedText.length * 18 - 30;
  
  //   page.drawText(`Reg. No.: ${student.RegNo || 'Unknown'}`, {
  //     x: 510,
  //     y: regNoYPosition,
  //     size: 14,
  //     font: boldFont,
  //     color: rgb(0, 0, 0),
  //   });
  
  //   const leftDetails = [
  //     { label: "Name", value: (student.name || "").toUpperCase() },
  //     { label: "Course Title", value: (student.course || "").toUpperCase() },
  //     { label: "Software Covered", value: (student.softwareCovered || []).join(", ").toUpperCase() },
  //     { label: "Training Centre", value: (student.centreName || "").toUpperCase() },
  //   ];
  
  //   const formatDate = (dateString) => {
  //     if (!dateString) return "";
  //     const date = new Date(dateString);
  //     const day = String(date.getDate()).padStart(2, "0");
  //     const month = String(date.getMonth() + 1).padStart(2, "0");
  //     const year = date.getFullYear();
  //     return `${month}/${day}/${year}`;
  //   };
  
  //   const rightDetails = [
  //     { label: "S/o, D/o, W/o", value: (student.fatherName || "").toUpperCase() },
  //     { label: "Course Duration", value: `${student.courseDuration || ""} Hrs` },
  //     { label: "Awarded on", value: formatDate(student.Date) },
  //     { label: "Grade", value: student.grade || "" },
  //   ];
  //   const drawDetails = (details, startX) => {
  //     let yOffset = 0;
    
  //     details.forEach((detail, index) => {
  //       const yPosition = 320 - yOffset;
    
  //       // Adjust the wrapping width for the "Software Covered" field
  //       const wrapWidth = detail.label === "Software Covered" ? 35 : 45;
    
  //       // Wrapping text for long content
  //       const lines = wrapText(detail.value, wrapWidth);
    
  //       // Add extra space between lines for the "Software Covered" label
  //       const lineSpacing = detail.label === "Software Covered" ? 20 : 14; // Increased to 20 for more gap
    
  //       lines.forEach((line, lineIndex) => {
  //         page.drawText(line, {
  //           x: startX,
  //           y: yPosition - (lineIndex + 1) * lineSpacing,
  //           size: 14,
  //           font: boldFont,
  //           color: rgb(0, 0, 0),
  //         });
    
  //         // Dotted line directly below the value
  //         for (let i = 0; i < 60; i++) {
  //           page.drawLine({
  //             start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
  //             end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * lineSpacing - 1 },
  //             thickness: 1,
  //             color: rgb(0, 0, 0),
  //           });
  //         }
  //       });
    
  //       // Adjust the yPosition for the label only for "Software Covered"
  //       const labelYOffset = detail.label === "Software Covered" ? 6 : 0;
    
  //       // Draw label closer to the value and dotted line
  //       page.drawText(detail.label, {
  //         x: startX,
  //         y: yPosition - (lines.length + 1) * lineSpacing + 3 + labelYOffset,
  //         size: 10,
  //         font: font,
  //         color: rgb(0.5, 0.5, 0.5),
  //       });
    
  //       // Update yOffset for the next detail
  //       yOffset += lines.length * lineSpacing + 20; // Adjusted to balance space between entries
  //     });
  //   };
    
  //   drawDetails(leftDetails, 56);
  //   drawDetails(rightDetails, 460);
    
    
  //   // const drawDetails = (details, startX) => {
  //   //   let yOffset = 0;
    
  //   //   details.forEach((detail, index) => {
  //   //     const yPosition = 320 - yOffset;
    
  //   //     // Wrapping text for long content
  //   //     const lines = wrapText(detail.value, 45);
        
  //   //     // Add extra space between software covered lines
  //   //     const extraSpace = detail.label === "Software Covered" ? 10 : 0;
        
  //   //     lines.forEach((line, lineIndex) => {
  //   //       page.drawText(line, {
  //   //         x: startX,
  //   //         y: yPosition - (lineIndex + 1) * 14 - extraSpace, // Add extra space for software covered
  //   //         size: 14,
  //   //         font: boldFont,
  //   //         color: rgb(0, 0, 0),
  //   //       });
    
  //   //       // Dotted line directly below the value
  //   //       for (let i = 0; i < 60; i++) {
  //   //         page.drawLine({
  //   //           start: { x: startX + i * 5, y: yPosition - (lineIndex + 1) * 14 - 1 - extraSpace },
  //   //           end: { x: startX + i * 5 + 2, y: yPosition - (lineIndex + 1) * 14 - 1 - extraSpace },
  //   //           thickness: 1,
  //   //           color: rgb(0, 0, 0),
  //   //         });
  //   //       }
  //   //     });
    
  //   //     // Draw label closer to the value and dotted line
  //   //     page.drawText(detail.label, {
  //   //       x: startX,
  //   //       y: yPosition - (lines.length + 1) * 14 + 3 - extraSpace, // Move label up by 6 units
  //   //       size: 10,
  //   //       font: font,
  //   //       color: rgb(0.5, 0.5, 0.5),
  //   //     });
    
  //   //     // Update yOffset for the next detail
  //   //     yOffset += lines.length * 14 + 20 + extraSpace; // Adjusted to balance space between entries
  //   //   });
  //   // };
  
  //   // drawDetails(leftDetails, 56);
  //   // drawDetails(rightDetails, 490);
  
  //   const qrCodeOptions = {
  //     color: {
  //       dark: "#000",
  //     },
  //     errorCorrectionLevel: "H",
  //     width: 200,
  //     type: "png",
  //     transparent: true,
  //   };
  
  //   const linkURL = `https://sttsonline.com/certificate.html?regNo=${student.RegNo || ''}`;
  //   const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
  //   const qrCodeImageBytes = Uint8Array.from(
  //     atob(qrCodeURL.split("base64,")[1]),
  //     (c) => c.charCodeAt(0)
  //   );
  //   const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
  //   const qrCodeWidth = 80;
  //   const qrCodeHeight = 80;
  
  //   const qrCodeX = 65;
  //   const qrCodeY = 40;
  
  //   page.drawImage(qrCodeImage, {
  //     x: qrCodeX,
  //     y: qrCodeY,
  //     width: qrCodeWidth,
  //     height: qrCodeHeight,
  //   });
  
  //   const pdfBytes = await pdfDoc.save();
  //   return pdfBytes;
  // };
  
  // function wrapText(text, maxLength) {
  //   const words = text.split(" ");
  //   const lines = [];
  //   let currentLine = "";
  
  //   words.forEach((word) => {
  //     if ((currentLine + word).length <= maxLength) {
  //       currentLine += (currentLine ? " " : "") + word;
  //     } else {
  //       lines.push(currentLine);
  //       currentLine = word;
  //     }
  //   });
  //   lines.push(currentLine);
  
  //   return lines;
  // }

  const handleAddGradeAndDate = async (studentId) => {
    try {
      const { grade, awardedDate } = studentInputs[studentId] || {};
      if (grade && awardedDate) {
        const updatedStudent = {
          grade,
          awardedDate,
        };
        await axios.put(`/api/addstudent/${studentId}`, updatedStudent);
        const newSavedData = { ...savedData, [studentId]: true };
        setSavedData(newSavedData);
        localStorage.setItem('savedData', JSON.stringify(newSavedData));
        setStudentInputs((prev) => ({
          ...prev,
          [studentId]: { grade: "", awardedDate: "" },
        }));
        fetchStudents();
      } else {
        alert("Please enter both grade and awarded date.");
      }
    } catch (error) {
      console.error("Error updating student data:", error.message);
      alert("Error updating student data. Please try again.");
    }
  };

  const handleInputChange = (studentId, field, value) => {
    setStudentInputs((prev) => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [field]: value,
      },
    }));
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students
    .filter((student) =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstStudent, indexOfLastStudent);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="page-wrapper">
        <Leftbar />

        <div className="container responsive_all_here">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search students by name"
              value={searchTerm}
              onChange={onChangeSearch}
              className="search-input"
              style={{ color: "black", border: "black 1px solid" }}
            />
          </div>
          <div className="student-list">
            {currentStudents.map((student) => {
              const { grade, awardedDate } = studentInputs[student._id] || {};
              const isSaved = savedData[student._id];
              const canSave = grade && awardedDate && !isSaved;

              return (
                <div key={student._id} className="student-card">
                  <div className="student-info">
                    <h3>{student.name}</h3>
                    <p>
                      <strong>RegNo:</strong> {student.RegNo}
                    </p>
                    <p>
                      <strong>Course:</strong> {student.course}
                    </p>
                  </div>
                  <div className="student-actions">
                    <div className="action-buttons">
                      <button
                        className={`btn btn-generate ${
                          !isSaved ? "disabled" : ""
                        }`}
                        onClick={() => handleGenerateCertificate(student)}
                        disabled={!isSaved}
                      >
                        Download
                      </button>
                    </div>
                    <div className="grade-input">
                      <select
                        value={grade || ""}
                        onChange={(e) =>
                          handleInputChange(
                            student._id,
                            "grade",
                            e.target.value
                          )
                        }
                        className="res-input-grade"
                        style={{ color: "black", border: "black 1px solid" }}
                        disabled={isSaved}
                      >
                        <option value="">Select Grade</option>
                        <option value="A+">A+</option>
                        <option value="A">A</option>
                        <option value="B">B+</option>
                        <option value="B">B</option>
                      </select>
                      <input
                        type="date"
                        placeholder="Awarded on mm/dd/yyyy"
                        value={awardedDate || ""}
                        onChange={(e) =>
                          handleInputChange(
                            student._id,
                            "awardedDate",
                            e.target.value
                          )
                        }
                        className="input-date"
                        style={{ color: "black", border: "black 1px solid" }}
                        disabled={isSaved}
                      />
                    </div>
                    <button
                      className="btn btn-save"
                      onClick={() => handleAddGradeAndDate(student._id)}
                      disabled={!canSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <Pagination
            studentsPerPage={studentsPerPage}
            totalStudents={
              students.filter((student) =>
                student.name.toLowerCase().includes(searchTerm.toLowerCase())
              ).length
            }
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
}

const Pagination = ({
  studentsPerPage,
  totalStudents,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalStudents / studentsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination-container">
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default CertificateDetaile;