
import { useState } from 'react';
import Topheader from '../Components/Topheader'
import { IoLocationSharp } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";


const Contact = () => {
       
    
        const [info, setInfo] = useState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
        });
    
        const [status, setStatus] = useState('submit');

const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
};



 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameRegex = /^[^0-9]+$/;
    if (!info.name || !nameRegex.test(info.name)) {
        alert('Invalid name. Please enter a valid name without numeric values.');
        return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!info.email || !emailRegex.test(info.email)) {
            alert('Invalid email. Please enter a valid email address.');
            return;
        }

        // Validate phone number using regular expression
        const phoneRegex = /^[0-9]+$/;
        if (!info.phone || !phoneRegex.test(info.phone)) {
            alert('Invalid phone number. Please enter only numeric values.');
            return;
        }

    // If all validations pass, you can proceed with form submission
    alert('Form submitted successfully!');
           
          
              // Validate phone number
             
          
              // Validate name
             
          
              // If all validations pass, you can proceed with form submission
              
              // Your form submission logic here...
    const link=`/api/contact`
            let response = await fetch(link, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Accept: "application/json",
                    //'Content-Type': 'application/json',
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(info),
                //body: JSON.stringify(details),
            });
            //console.log(details);
            console.log(info);
            setStatus("Submit");
            setInfo({ name: "", email: "", phone: "", subject: "", message: "" });
            let result = await response.json();
            //alert(result.status);
        };
    return (
        <>
        {/* <Topheader/> */}

            <section className="page-header">
                <div className="page-header__bg_4"></div>
                {/* <div className="page-header__gradient" style={{ backgroundImage: 'url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)' }}></div> */}
                <div className="shape1 float-bob-x"><img src="./images/sttsbanner.png" alt="banner shape" /></div>
                <div className="container">
                    <div className="page-header__inner text-center">
                        <h2>Contact</h2>
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li className="home">
                                <span property="itemListElement" typeof="ListItem">
                                    <a property="item" typeof="WebPage" title="Go to Ontech." href="/" className="home">
                                        <span property="name">Home</span>
                                    </a>
                                    <meta property="position" content="1" />
                                </span>
                            </li>
                            <li className="post post-page current-item">
                                <span property="itemListElement" typeof="ListItem">
                                    <span property="name" className="post post-page current-item">Contact</span>
                                   
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div data-elementor-type="wp-page" data-elementor-id="41" class="elementor elementor-41">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-f35d0a8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f35d0a8" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1fd2384" data-id="1fd2384" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-63e015f elementor-widget elementor-widget-ontech-contact-info" data-id="63e015f" data-element_type="widget" data-widget_type="ontech-contact-info.default">
                                    <div class="elementor-widget-container">
                                        <div class="contact-page__top">
                                            <div class="title text-center">
                                            
                                            </div>

                                            <ul class="list-unstyled ml-0">

                                                <li class="contact-page__top-single" >
                                                    <div class="icon" style={{marginTop:"30px"}}>
                                                    <IoLocationSharp style={{color:"#FFB237"}}/>
 </div>
                                                    <div class="content" style={{marginLeft:"-20px",marginTop:"10px"}}>
                                                        <h2>Address</h2>
                                                        <p>
                                                        WN:10 Golapbagh, IDBI Bank,<br/> 
                                                            Building Baripada - 757001

                                                        </p>
                                                    </div>
                                                </li>

                                                <li class="contact-page__top-single" >
                                                    <div class="icon" style={{marginTop:"20px"}}>
                                                    <FiPhoneCall style={{color:"#FFB237"}}/>
 </div>
                                                    <div class="content" style={{marginLeft:"-20px",marginTop:"10px"}}>
                                                        <h2>Get in Touch</h2>
                                                        <p>+91 9692114127 / 9337219166
                                                        
                                                        </p>
                                                    </div>
                                                </li>


                                                <li class="contact-page__top-single" >
                                                    <div class="icon" style={{marginTop:"20px"}}>
                                                    <TfiEmail style={{color:"#FFB237"}}/>
 </div>
                                                    <div class="content" style={{marginLeft:"-20px",marginTop:"10px"}}>
                                                        <h2>Email Address</h2>
                                                        <p>
                                                        sttstrainings@gmail.com<br/>{" "}

                                                        </p>
                                                    </div>
                                                </li>


                                               
                                                
                                                {/* <li class="contact-page__top-single" style={{marginLeft:"70px"}}>
                                                    <div class="icon  responsive_getincontanct_icon" style={{marginLeft:"-10px",marginLeft:"30px"}} >
                                                    <FiPhoneCall style={{color:"#FFB237"}}/>

                                                         </div>
                                                    <div class="content responsive_getincontanct"  >
                                                        <h2>Get In Touch</h2>
                                                        <p> <a href="">+91 9692114127 / 9337219166</a>
                                                            </p>
                                                    </div>
                                                </li>

                                                <li class="contact-page__top-single" style={{marginTop:"-2px",marginLeft:"20px"}}>
                                                    <div class="icon" style={{marginLeft:"80px"}}>
                                                        <TfiEmail style={{color:"#FFB237"}}/> 
                                                        </div>
                                                    <div class="content responsive_getincontanct_email">
                                                        <h2>Email Address</h2>
                                                        <p><a href="">sttstrainings@gmail.com</a>
                                                            </p>
                                                    </div>
                                                </li> */}
                                                


                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="elementor-section elementor-top-section elementor-element elementor-element-f929bc0 contact-page form elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="f929bc0" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b2ea87c" data-id="b2ea87c" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-6257334 elementor-widget elementor-widget-ontech-contact-form" data-id="6257334" data-element_type="widget" data-widget_type="ontech-contact-form.default">
                                    <div class="elementor-widget-container">

                                        <div class="contact-page__bottom">
                                            <div class="contact-page__bottom-pattern" style={{backgroundImage: "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/contact-page-pattern.jpg)"}}> </div>
                                            <div class="contact-page__bottom-inner">
                                                <div class="wpcf7 no-js" id="wpcf7-f469-p41-o1" lang="en-US" dir="ltr">
                                                    <div class="screen-reader-response">
                                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                        <ul></ul>
                                                    </div>
                                                    <form onSubmit={handleSubmit} method="post" class="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init">
                                        <div style={{ display: "none" }}>

                                        </div>
                                        <div class="contact-page__form contact-form-validated">
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="contact-page__input-box">
                                                        <p><span class="wpcf7-form-control-wrap" name="name"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"
                                                            placeholder="User Name" pattern="/[a-z A-Z\s]+/" value={info.name} onChange={handleChange} type="text" name="name" required /></span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="contact-page__input-box">
                                                        <p><span class="wpcf7-form-control-wrap" name="email"><input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"
                                                            placeholder="Email Address" pattern="/[^\s@]+@[^\s@]+\.[^\s@]+/" value={info.email} onChange={handleChange} type="text" name="email" required /></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="contact-page__input-box">
                                                        <p><span class="wpcf7-form-control-wrap" name="phone">
                                                            <input size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false"
                                                                placeholder="Phone" pattern="/\d{10}/" value={info.phone} onChange={handleChange} type="text" name="phone" required />


                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                                    <div class="contact-page__input-box">

                                                        <p><span class="wpcf7-form-control-wrap">
                                                            <input
                                                                size="40"
                                                                type="text"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true"
                                                                aria-invalid="false"
                                                                placeholder="Subject"
                                                                onChange={handleChange}
                                                                name="subject"
                                                                value={info.subject} // Remove this line
                                                            />
                                                        </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                    <div class="contact-page__input-box">
                                                        <p><span class="wpcf7-form-control-wrap" name="textarea-40">


                                                            <textarea
                                                                cols="40"
                                                                rows="10"
                                                                class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                                aria-required="true"
                                                                aria-invalid="false"
                                                                placeholder="Your Message here"
                                                                onChange={handleChange}
                                                                name="message"
                                                                value={info.message}// Remove this line
                                                            >
                                                                
                                                            </textarea>

                                                        </span>
                                                        </p>
                                                    </div>
                                                    <div class="contact-page__btn">
                                                        <p><button type="submit" class="thm-btn">
                                                            <span>{status}  </span>
                                                        </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpcf7-response-output" aria-hidden="true"></div>
                                    </form>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section class="elementor-section elementor-top-section elementor-element elementor-element-4e29bbff elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="4e29bbff" data-element_type="section">
                    <div class="elementor-container elementor-column-gap-no">
                        <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-491a0fd0" data-id="491a0fd0" data-element_type="column">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-30286391 elementor-widget elementor-widget-ontech-google-maps" data-id="30286391" data-element_type="widget" data-widget_type="ontech-google-maps.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-custom-embed">
                                            <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>


        </>
    )
}

export default Contact
