import React, { useEffect } from 'react';
import './Specializations.css';

const CourseItem = ({ title, skills }) => (
  <div className="course-item">
    <h3>{title}</h3>
    <p>{skills.join(' • ')}</p>
  </div>
);

const Specialization = ({ title, courses }) => (
  <section className="specialization" id={title.replace(/\s+/g, '')}>
    <h2>{title}</h2>
    <div className="course-grid">
      {courses.map((course, index) => (
        <CourseItem key={index} title={course.title} skills={course.skills} />
      ))}
    </div>
  </section>
);

const Specializations = () => {
  const specializations = [
    {
      title: "CIVIL & ARCHITECT",
      courses: [
        {
          title: "Professional in Building Design",
          skills: ["Auto CAD", "REVIT ARCH/ Sketch Up", "VRAY / Lumion"]
        },
        {
          title: "Professional in Structural Design",
          skills: ["Auto CAD", "STAAD", "ETab"]
        },
        {
          title: "Master in Interior Design",
          skills: ["Auto CAD", "REVIT ARCH", "3DS MAX", "VRAY"]
        },
        {
          title: "Master in BIM Technology",
          skills: ["Auto CAD", "REVIT ARCH", "REVIT Structure", "STAAD"]
        },
        {
          title: "Professional in Building Design and Estimate",
          skills: ["Auto CAD", "REVIT ARCH", "QTO"]
        },
        {
          title: "Diploma in Highway & Infrastructure Design",
          skills: ["Auto CAD", "Civil 3D"]
        }
      ]
    },
    {
      title: "ELECTRICAL",
      courses: [
        {
          title: "Control System Design",
          skills: ["Auto CAD ELECTRICAL", "EPlan"]
        }
      ]
    },
    {
      title: "MECHANICAL",
      courses: [
        {
          title: "Professional in Product Design & Manufacturing",
          skills: ["Auto CAD", "Solidworks / CATIA / CREO", "CNC", "GD&T"]
        },
        {
          title: "Professional in Product Design & Analysis",
          skills: ["Auto CAD", "Solidworks / CATIA / CREO", "Ansys Workbench"]
        },
        {
          title: "Professional in Automotive Design",
          skills: ["Auto CAD", "Solidworks", "CATIA"]
        },
        {
          title: "Professional in Manufacturing",
          skills: ["Auto CAD", "CNC", "GD&T"]
        },
        {
          title: "Diploma in HVAC Design",
          skills: ["Auto CAD", "REVIT MEP"]
        }
      ]
    }
  ];

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }   
  }, []);

  return (
    <div className="specialization-container">
      {specializations.map((spec, index) => (
        <Specialization key={index} title={spec.title} courses={spec.courses} />
      ))}
    </div>
  );
};

export default Specializations;