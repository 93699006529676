// // Write your code here
// import {Link} from 'react-router-dom'
// import React, { useEffect } from "react";
// import './AppItem.css'

// const AppItem = props => {
//   const {Appitem} = props
//   const {appName, category, imageUrl, backinformation, link = '', appId, my} = Appitem;


//   const handleCardClick = () =>{
//     if(link.startsWith('http')){
//       window.open(link,'_blank')
//     }
//   }
  

//   useEffect(() => {
//     const observer = new IntersectionObserver((entries) => {
//       // loop over all the entries
//       entries.forEach((entry) => {
//         console.log(entry);
//         // use a conditional to check if the entry is intersection
//         if (entry.isIntersecting) {
//           // add show class
//           entry.target.classList.add("show");
//         } else {
//           // remove when not intersecting
//           entry.target.classList.remove("show");
//         }
//       });
//     });
    
//     const hiddenElements = document.querySelectorAll(".hidden");
//     hiddenElements.forEach((el) => observer.observe(el));    
//   })

//   return (
//     // <div className="appItemcontainer">
//     //   <img src={imageUrl} alt={appName} className="appimage" />
//     //   <p className="appname">{appName}</p>
//     // </div>

//     <>
//         <div className="card_1 hidden" onClick={handleCardClick}>
//     <div className="upper-part_1">
//         <div className="upper-part-face_1">
//         <img src={imageUrl} alt={appName} className="appimage" />
//         </div>
    
//         <div className="upper-part-back_1"> 
//           <Link to={`/Coursesdetails/${appName}`} style={{color:"white",fontSize:"17px"}}>{backinformation}..<br/>
//             <span style={{color:"#FFB237"}}>Read More</span>
        
        
//         </Link>
        
//         </div>
//     </div>
//     <div className="lower-part_1">
//         <div className="lower-part-face_1">
//         {appName}
//         </div>
//         <a ><div className="lower-part-back_1">Register</div></a>
        
//     </div>
//     </div>
//     </>


//   )
// }
// export default AppItem

import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './AppItem.css';

const AppItem = (props) => {
  const { Appitem } = props;
  const { appName, category, imageUrl, backinformation, link = '', appId, profcontent } = Appitem;

  const [activeCourse, setActiveCourse] = useState(null);

  const handleCardClick = () => {
    if (link.startsWith('http')) {
      window.open(link, '_blank');
    }
  };

  const toggleCourseDropdown = (index) => {
    setActiveCourse(activeCourse === index ? null : index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  // Render UI for "PROF" category with course dropdowns
  if (category === 'PROF') {
    return (
      <div className="prof-content">
        {profcontent && profcontent.map((content, index) => (
          <div key={index} className="prof-content-section">
            <h4 style={{marginLeft:"50px",marginBottom:"10px"}}>{content.title}</h4>
            <ul>
              {content.courses.map((course, idx) => (
                <li key={idx}>
                  <button onClick={() => toggleCourseDropdown(idx)} style={{ cursor: 'pointer' }} className='m-1'>
                    {course.title} {activeCourse === idx ? '▲' : '▼'}
                  </button>
                  {activeCourse === idx && (
                    <ul>
                      {course.skills.map((skill, idy) => (
                        <li key={idy} style={{listStyleType:"Circle"}}>{skill}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  // Render UI for other categories
  return (
    <div className="card_1 hidden" onClick={handleCardClick}>
      <div className="upper-part_1">
        <div className="upper-part-face_1">
          <img src={imageUrl} alt={appName} className="appimage" />
        </div>
        <div className="upper-part-back_1">
          <Link to={`/Coursesdetails/${appName}`} style={{ color: 'white', fontSize: '17px' }}>
            {backinformation}..<br />
            <span style={{ color: '#FFB237' }}>Read More</span>
          </Link>
        </div>
      </div>
      <div className="lower-part_1">
        <div className="lower-part-face_1">
          {appName}
        </div>
        <a>
          <div className="lower-part-back_1">Register</div>
        </a>
      </div>
    </div>
  );
};

export default AppItem;