import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import Leftbar from "../../Components/Leftbar/Leftbar";
import "../Marksheets/mycode.css";

const ViewMarksheets = () => {
  const [marksheets, setMarksheets] = useState([]);
  const [selectedMarksheet, setSelectedMarksheet] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false); // State for controlling edit modal visibility
  const [showViewModal, setShowViewModal] = useState(false); // State for controlling view modal visibility
  const [formData, setFormData] = useState({
    _id: "",
    name: "",
    regNo: "",
    course: "",
    subjects: [],
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    fetchMarksheets();
  }, []);

  const fetchMarksheets = () => {
    axios
      .get("/marksheets")
      .then((response) => {
        setMarksheets(response.data);
      })
      .catch((error) => {
        console.error("Error fetching marksheets:", error);
      });
  };

  const handleEdit = (marksheet) => {
    setFormData(marksheet);
    setShowEditModal(true); // Open the modal for editing
  };

  const handleView = (marksheet) => {
    setSelectedMarksheet(marksheet);
    setShowViewModal(true); // Open the modal for viewing
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.includes("subjects[")) {
      const index = name.match(/subjects\[(\d+)\]/)[1];
      const key = name.split(".").pop();
      const updatedSubjects = [...formData.subjects];

      // Handle clearing the marksScored input
      updatedSubjects[index] = {
        ...updatedSubjects[index],
        [key]:
          key === "marksScored"
            ? value === ""
              ? ""
              : parseInt(value, 10)
            : value,
      };

      // Validate marks scored
      if (key === "marksScored") {
        const marks = parseInt(value, 10);
        if (value === "" || (marks >= 0 && marks <= 100)) {
          setFormData({ ...formData, subjects: updatedSubjects });
        }
      } else {
        setFormData({ ...formData, subjects: updatedSubjects });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUpdate = () => {
    axios
      .put(`/marksheet/${formData._id}`, formData)
      .then((response) => {
        fetchMarksheets(); // Refresh marksheets after update
        setShowEditModal(false); // Close the modal after update
      })
      .catch((error) => {
        console.error("Error updating marksheet:", error);
      });
  };
 
  
  // Utility function to format date to dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const handleOverlayTemplate = async () => {
    if (selectedMarksheet) {
      const { subjects } = selectedMarksheet;
  
      // Check if the number of subjects is 6 or more
      if (subjects.length < 6) {
        alert("The number of subjects must be 6 or more to generate the PDF.");
        return; // Exit the function if the condition is not met
      }
  
      try {
        // Fetch the existing PDF
        const existingPdfBytes = await fetch("/Gradecard2.pdf").then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
        // Register fontkit
        pdfDoc.registerFontkit(fontkit);
  
        // Fetch and load the Nunito fonts
        const nunitoRegularBytes = await fetch("/Nunito-Regular.ttf").then(
          (res) => res.arrayBuffer()
        );
        const nunitoBoldBytes = await fetch("/Nunito-Bold.ttf").then((res) =>
          res.arrayBuffer()
        );
        const nunitoRegularFont = await pdfDoc.embedFont(nunitoRegularBytes);
        const nunitoBoldFont = await pdfDoc.embedFont(nunitoBoldBytes);
  
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
  
        const { name, regNo, course, date } = selectedMarksheet;
        const { width, height } = firstPage.getSize();
        const fontSize = 12;
        const boldFontSize = 14;
  
        // Function to draw text in bold using Nunito Bold font
        const drawBoldText = (text, x, y) => {
          firstPage.drawText(text, {
            x,
            y,
            size: boldFontSize,
            font: nunitoBoldFont,
            color: rgb(0, 0, 0),
            lineHeight: 18,
          });
        };
  
        // Function to draw text using Nunito Regular font
        const drawText = (text, x, y, size) => {
          firstPage.drawText(text, {
            x,
            y,
            size,
            font: nunitoRegularFont,
            color: rgb(0, 0, 0),
          });
        };
  
        // Overlaying details
        const leftColumnX = 50;
        const rightColumnX = width / 2 + 50;
        const rowSpacing = 30;
  
        // First row: Name and Registration No
        drawBoldText(`Name: ${name.toUpperCase()}`, leftColumnX, height - 190);
        drawBoldText(`Registration No: ${regNo}`, rightColumnX, height - 190);
  
        // Second row: Course and Date
        drawBoldText(`Course: ${course}`, leftColumnX, height - 190 - rowSpacing);
        drawBoldText(`Date: ${formatDate(date)}`, rightColumnX, height - 190 - rowSpacing);
  
        // Table settings
        const tableWidth = width - 100;
        const tableX = (width - tableWidth) / 2;
        let startY = height - 300;
        const columnWidths = [
          tableWidth * 0.5, // Increased width for subject column
          tableWidth * 0.2,
          tableWidth * 0.3,
        ];
        const rowHeight = 25;
  
        // Function to draw a cell with border and centered text using Nunito fonts
        const drawCell = (text, x, y, width, isBold = false) => {
          firstPage.drawRectangle({
            x,
            y: y - rowHeight,
            width,
            height: rowHeight,
            borderColor: rgb(0, 0, 0),
            borderWidth: 1,
          });
          const textWidth =
            (isBold ? boldFontSize : fontSize) * text.length * 0.6;
          const textX = x + (width - textWidth) / 2;
          if (isBold) {
            drawBoldText(text, textX, y - rowHeight + 8);
          } else {
            drawText(text, textX, y - rowHeight + 8, fontSize);
          }
        };
  
        // Draw table headers
        drawCell("Subjects", tableX, startY, columnWidths[0], true);
        drawCell(
          "Max Marks",
          tableX + columnWidths[0],
          startY,
          columnWidths[1],
          true
        );
        drawCell(
          "Secured Marks",
          tableX + columnWidths[0] + columnWidths[1],
          startY,
          columnWidths[2],
          true
        );
  
        startY -= rowHeight;
  
        // Filter out subjects with 0 or undefined marks
        const validSubjects = subjects.filter(subject => subject && subject.marksScored > 0);
  
        // Draw subject rows only for valid subjects
        validSubjects.forEach((subject) => {
          const { subjectName, marksScored } = subject;
          drawCell(subjectName, tableX, startY, columnWidths[0]);
          drawCell("100", tableX + columnWidths[0], startY, columnWidths[1]);
          drawCell(
            marksScored.toString(),
            tableX + columnWidths[0] + columnWidths[1],
            startY,
            columnWidths[2]
          );
          startY -= rowHeight;
        });
  
        // Calculate and display total marks, percentage, and grade only if there are valid subjects
        if (validSubjects.length > 0) {
          const totalSecuredMarks = validSubjects.reduce(
            (total, subject) => total + subject.marksScored,
            0
          );
          const totalMarks = validSubjects.length * 100;
          const percentage = ((totalSecuredMarks / totalMarks) * 100).toFixed(2);
  
          const determineGrade = (percentage) => {
            if (percentage >= 90) return "A+";
            if (percentage >= 80) return "A";
            if (percentage >= 70) return "B+";
            if (percentage >= 60) return "B";
  
            return "F";
          };
  
          const grade = determineGrade(parseFloat(percentage));
  
          const tablexx = 300;
          drawBoldText(
            `Total Secured Marks: ${totalSecuredMarks}`,
            tablexx,
            startY - 30
          );
  
          const tablexxx = 100;
          drawBoldText(
            `The student has achieved ${percentage}% marks, earning a grade of ${grade}.`,
            tablexxx,
            startY - 80
          );
        } else {
          const tablexx = 300;
          drawBoldText(
            `No valid subjects to display.`,
            tablexx,
            startY - 30
          );
        }
  
        const pdfBytes = await pdfDoc.save();
  
        // Download the overlaid PDF
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${regNo}_marksheet.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error overlaying template:", error);
      }
    }
  };
  
  // const handleOverlayTemplate = async () => {
  //   if (selectedMarksheet) {
  //     const { subjects } = selectedMarksheet;
  
  //     // Check if the number of subjects is 6 or more
  //     if (subjects.length < 6) {
  //       alert("The number of subjects must be 6 or more to generate the PDF.");
  //       return; // Exit the function if the condition is not met
  //     }
  
  //     try {
  //       // Fetch the existing PDF
  //       const existingPdfBytes = await fetch("/Gradecard2.pdf").then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);
  
  //       // Register fontkit
  //       pdfDoc.registerFontkit(fontkit);
  
  //       // Fetch and load the Nunito fonts
  //       const nunitoRegularBytes = await fetch("/Nunito-Regular.ttf").then(
  //         (res) => res.arrayBuffer()
  //       );
  //       const nunitoBoldBytes = await fetch("/Nunito-Bold.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const nunitoRegularFont = await pdfDoc.embedFont(nunitoRegularBytes);
  //       const nunitoBoldFont = await pdfDoc.embedFont(nunitoBoldBytes);
  
  //       const pages = pdfDoc.getPages();
  //       const firstPage = pages[0];
  
  //       const { name, regNo, course, date } = selectedMarksheet;
  //       const { width, height } = firstPage.getSize();
  //       const fontSize = 12;
  //       const boldFontSize = 14;
  
  //       // Function to draw text in bold using Nunito Bold font
  //       const drawBoldText = (text, x, y) => {
  //         firstPage.drawText(text, {
  //           x,
  //           y,
  //           size: boldFontSize,
  //           font: nunitoBoldFont,
  //           color: rgb(0, 0, 0),
  //           lineHeight: 18,
  //         });
  //       };
  
  //       // Function to draw text using Nunito Regular font
  //       const drawText = (text, x, y, size) => {
  //         firstPage.drawText(text, {
  //           x,
  //           y,
  //           size,
  //           font: nunitoRegularFont,
  //           color: rgb(0, 0, 0),
  //         });
  //       };
  
  //       // Overlaying details
  //       const leftColumnX = 50;
  //       const rightColumnX = width / 2 + 50;
  //       const rowSpacing = 30;
  
  //       // First row: Name and Registration No
  //       drawBoldText(`Name: ${name.toUpperCase()}`, leftColumnX, height - 190);
  //       drawBoldText(`Registration No: ${regNo}`, rightColumnX, height - 190);
  
  //       // Second row: Course and Date
  //       drawBoldText(`Course: ${course}`, leftColumnX, height - 190 - rowSpacing);
  //       drawBoldText(`Date: ${date}`, rightColumnX, height - 190 - rowSpacing);
  
  //       // Table settings
  //       const tableWidth = width - 100;
  //       const tableX = (width - tableWidth) / 2;
  //       let startY = height - 300;
  //       const columnWidths = [
  //         tableWidth * 0.4,
  //         tableWidth * 0.3,
  //         tableWidth * 0.3,
  //       ];
  //       const rowHeight = 25;
  
  //       // Function to draw a cell with border and centered text using Nunito fonts
  //       const drawCell = (text, x, y, width, isBold = false) => {
  //         firstPage.drawRectangle({
  //           x,
  //           y: y - rowHeight,
  //           width,
  //           height: rowHeight,
  //           borderColor: rgb(0, 0, 0),
  //           borderWidth: 1,
  //         });
  //         const textWidth =
  //           (isBold ? boldFontSize : fontSize) * text.length * 0.6;
  //         const textX = x + (width - textWidth) / 2;
  //         if (isBold) {
  //           drawBoldText(text, textX, y - rowHeight + 8);
  //         } else {
  //           drawText(text, textX, y - rowHeight + 8, fontSize);
  //         }
  //       };
  
  //       // Draw table headers
  //       drawCell("Subjects", tableX, startY, columnWidths[0], true);
  //       drawCell(
  //         "Max Marks",
  //         tableX + columnWidths[0],
  //         startY,
  //         columnWidths[1],
  //         true
  //       );
  //       drawCell(
  //         "Secured Marks",
  //         tableX + columnWidths[0] + columnWidths[1],
  //         startY,
  //         columnWidths[2],
  //         true
  //       );
  
  //       startY -= rowHeight;
  
  //       // Filter out subjects with 0 or undefined marks
  //       const validSubjects = subjects.filter(subject => subject && subject.marksScored > 0);
  
  //       // Draw subject rows only for valid subjects
  //       validSubjects.forEach((subject) => {
  //         const { subjectName, marksScored } = subject;
  //         drawCell(subjectName, tableX, startY, columnWidths[0]);
  //         drawCell("100", tableX + columnWidths[0], startY, columnWidths[1]);
  //         drawCell(
  //           marksScored.toString(),
  //           tableX + columnWidths[0] + columnWidths[1],
  //           startY,
  //           columnWidths[2]
  //         );
  //         startY -= rowHeight;
  //       });
  
  //       // Calculate and display total marks, percentage, and grade only if there are valid subjects
  //       if (validSubjects.length > 0) {
  //         const totalSecuredMarks = validSubjects.reduce(
  //           (total, subject) => total + subject.marksScored,
  //           0
  //         );
  //         const totalMarks = validSubjects.length * 100;
  //         const percentage = ((totalSecuredMarks / totalMarks) * 100).toFixed(2);
  
  //         const determineGrade = (percentage) => {
  //           if (percentage >= 90) return "A+";
  //           if (percentage >= 80) return "A";
  //           if (percentage >= 70) return "B+";
  //           if (percentage >= 60) return "B";
         
  //           return "F";
  //         };
  
  //         const grade = determineGrade(parseFloat(percentage));
  
  //         const tablexx = 300;
  //         drawBoldText(
  //           `Total Secured Marks: ${totalSecuredMarks}`,
  //           tablexx,
  //           startY - 30
  //         );
  
  //         const tablexxx = 100;
  //         drawBoldText(
  //           `The student has achieved ${percentage}% marks, earning a grade of ${grade}.`,
  //           tablexxx,
  //           startY - 60
  //         );
  //       } else {
  //         const tablexx = 300;
  //         drawBoldText(
  //           `No valid subjects to display.`,
  //           tablexx,
  //           startY - 30
  //         );
  //       }
  
  //       const pdfBytes = await pdfDoc.save();
  
  //       // Download the overlaid PDF
  //       const blob = new Blob([pdfBytes], { type: "application/pdf" });
  //       const url = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `${regNo}_marksheet.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error("Error overlaying template:", error);
  //     }
  //   }
  // };
  
// const handleOverlayTemplate = async () => {
//   if (selectedMarksheet) {
//     const { subjects } = selectedMarksheet;

//     // Check if the number of subjects is 6 or more
//     if (subjects.length < 6) {
//       alert("The number of subjects must be 6 or more to generate the PDF.");
//       return; // Exit the function if the condition is not met
//     }

//     try {
//       // Fetch the existing PDF
//       const existingPdfBytes = await fetch("/Gradecard2.pdf").then((res) =>
//         res.arrayBuffer()
//       );
//       const pdfDoc = await PDFDocument.load(existingPdfBytes);

//       // Register fontkit
//       pdfDoc.registerFontkit(fontkit);

//       // Fetch and load the Nunito fonts
//       const nunitoRegularBytes = await fetch("/Nunito-Regular.ttf").then(
//         (res) => res.arrayBuffer()
//       );
//       const nunitoBoldBytes = await fetch("/Nunito-Bold.ttf").then((res) =>
//         res.arrayBuffer()
//       );
//       const nunitoRegularFont = await pdfDoc.embedFont(nunitoRegularBytes);
//       const nunitoBoldFont = await pdfDoc.embedFont(nunitoBoldBytes);

//       const pages = pdfDoc.getPages();
//       const firstPage = pages[0];

//       const { name, regNo, course } = selectedMarksheet;
//       const { width, height } = firstPage.getSize();
//       const fontSize = 12;
//       const boldFontSize = 14;

//       // Function to draw text in bold using Nunito Bold font
//       const drawBoldText = (text, x, y) => {
//         firstPage.drawText(text, {
//           x,
//           y,
//           size: boldFontSize,
//           font: nunitoBoldFont,
//           color: rgb(0, 0, 0),
//           lineHeight: 18,
//         });
//       };

//       // Function to draw text using Nunito Regular font
//       const drawText = (text, x, y, size) => {
//         firstPage.drawText(text, {
//           x,
//           y,
//           size,
//           font: nunitoRegularFont,
//           color: rgb(0, 0, 0),
//         });
//       };

//       // Overlaying details
//       drawBoldText(`Name: ${name.toUpperCase()}`, 50, height - 170);
//       drawBoldText(`Registration No: ${regNo}`, 50, height - 190);
//       drawBoldText(`Course: ${course}`, 50, height - 210);

//       // Table settings
//       const tableWidth = width - 100;
//       const tableX = (width - tableWidth) / 2;
//       let startY = height - 300;
//       const columnWidths = [
//         tableWidth * 0.4,
//         tableWidth * 0.3,
//         tableWidth * 0.3,
//       ];
//       const rowHeight = 25;

//       // Function to draw a cell with border and centered text using Nunito fonts
//       const drawCell = (text, x, y, width, isBold = false) => {
//         firstPage.drawRectangle({
//           x,
//           y: y - rowHeight,
//           width,
//           height: rowHeight,
//           borderColor: rgb(0, 0, 0),
//           borderWidth: 1,
//         });
//         const textWidth =
//           (isBold ? boldFontSize : fontSize) * text.length * 0.6;
//         const textX = x + (width - textWidth) / 2;
//         if (isBold) {
//           drawBoldText(text, textX, y - rowHeight + 8);
//         } else {
//           drawText(text, textX, y - rowHeight + 8, fontSize);
//         }
//       };

//       // Draw table headers
//       drawCell("Subjects", tableX, startY, columnWidths[0], true);
//       drawCell(
//         "Max Marks",
//         tableX + columnWidths[0],
//         startY,
//         columnWidths[1],
//         true
//       );
//       drawCell(
//         "Secured Marks",
//         tableX + columnWidths[0] + columnWidths[1],
//         startY,
//         columnWidths[2],
//         true
//       );

//       startY -= rowHeight;

//       // Filter out subjects with 0 or undefined marks
//       const validSubjects = subjects.filter(subject => subject && subject.marksScored > 0);

//       // Draw subject rows only for valid subjects
//       validSubjects.forEach((subject) => {
//         const { subjectName, marksScored } = subject;
//         drawCell(subjectName, tableX, startY, columnWidths[0]);
//         drawCell("100", tableX + columnWidths[0], startY, columnWidths[1]);
//         drawCell(
//           marksScored.toString(),
//           tableX + columnWidths[0] + columnWidths[1],
//           startY,
//           columnWidths[2]
//         );
//         startY -= rowHeight;
//       });

//       // Calculate and display total marks, percentage, and grade only if there are valid subjects
//       if (validSubjects.length > 0) {
//         const totalSecuredMarks = validSubjects.reduce(
//           (total, subject) => total + subject.marksScored,
//           0
//         );
//         const totalMarks = validSubjects.length * 100;
//         const percentage = ((totalSecuredMarks / totalMarks) * 100).toFixed(2);

//         const determineGrade = (percentage) => {
//           if (percentage >= 90) return "A+";
//           if (percentage >= 80) return "A";
//           if (percentage >= 70) return "B+";
//           if (percentage >= 60) return "B";
//           if (percentage >= 35) return "C";
//           return "F";
//         };

//         const grade = determineGrade(parseFloat(percentage));

//         const tablexx = 300;
//         drawBoldText(
//           `Total Secured Marks: ${totalSecuredMarks}`,
//           tablexx,
//           startY - 30
//         );

//         const tablexxx = 100;
//         drawBoldText(
//           `The student has achieved ${percentage}% marks, earning a grade of ${grade}.`,
//           tablexxx,
//           startY - 60
//         );
//       } else {
//         const tablexx = 300;
//         drawBoldText(
//           `No valid subjects to display.`,
//           tablexx,
//           startY - 30
//         );
//       }

//       const pdfBytes = await pdfDoc.save();

//       // Download the overlaid PDF
//       const blob = new Blob([pdfBytes], { type: "application/pdf" });
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = `${regNo}_marksheet.pdf`;
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//       URL.revokeObjectURL(url);
//     } catch (error) {
//       console.error("Error overlaying template:", error);
//     }
//   }
// };
  

  // Import fontkit

  // const handleOverlayTemplate = async () => {
  //   if (selectedMarksheet) {
  //     try {
  //       // Fetch the existing PDF
  //       const existingPdfBytes = await fetch('/gradecard.pdf').then(res => res.arrayBuffer());
  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);

  //       // Register fontkit
  //       pdfDoc.registerFontkit(fontkit);

  //       // Fetch and load the Nunito fonts
  //       const nunitoRegularBytes = await fetch('/Nunito-Regular.ttf').then(res => res.arrayBuffer());
  //       const nunitoBoldBytes = await fetch('/Nunito-Bold.ttf').then(res => res.arrayBuffer());
  //       const nunitoRegularFont = await pdfDoc.embedFont(nunitoRegularBytes);
  //       const nunitoBoldFont = await pdfDoc.embedFont(nunitoBoldBytes);

  //       const pages = pdfDoc.getPages();
  //       const firstPage = pages[0];

  //       const { name, regNo, course, subjects } = selectedMarksheet;
  //       const { width, height } = firstPage.getSize();
  //       const fontSize = 14;
  //       const boldFontSize = 16;

  //       // Function to draw text in bold using Nunito Bold font
  //       const drawBoldText = (text, x, y) => {
  //         firstPage.drawText(text, {
  //           x, y,
  //           size: boldFontSize,
  //           font: nunitoBoldFont,
  //           color: rgb(0, 0, 0),
  //           lineHeight: 18
  //         });
  //       };

  //       // Function to draw text using Nunito Regular font
  //       const drawText = (text, x, y, size) => {
  //         firstPage.drawText(text, {
  //           x, y,
  //           size,
  //           font: nunitoRegularFont,
  //           color: rgb(0, 0, 0)
  //         });
  //       };

  //       // Overlaying details
  //       drawBoldText(`Name: ${name.toUpperCase()}`, 50, height - 190);
  //       drawBoldText(`Registration No: ${regNo}`, 50, height - 210);
  //       drawBoldText(`Course: ${course}`, 50, height - 230);

  //       // Table settings
  //       const tableWidth = width - 100; // Increase table width
  //       const tableX = (width - tableWidth) / 2; // Center the table
  //       let startY = height - 300;
  //       const columnWidths = [tableWidth * 0.4, tableWidth * 0.3, tableWidth * 0.3];
  //       const rowHeight = 30; // Increase row height

  //       // Function to draw a cell with border and centered text using Nunito fonts
  //       const drawCell = (text, x, y, width, isBold = false) => {
  //         firstPage.drawRectangle({
  //           x, y: y - rowHeight,
  //           width, height: rowHeight,
  //           borderColor: rgb(0, 0, 0),
  //           borderWidth: 1,
  //         });
  //         const textWidth = (isBold ? boldFontSize : fontSize) * text.length * 0.6; // Estimate text width
  //         const textX = x + (width - textWidth) / 2; // Center text horizontally
  //         if (isBold) {
  //           drawBoldText(text, textX, y - rowHeight + 8);
  //         } else {
  //           drawText(text, textX, y - rowHeight + 8, fontSize);
  //         }
  //       };

  //       // Draw table headers
  //       drawCell('Subjects', tableX, startY, columnWidths[0], true);
  //       drawCell('Max Marks', tableX + columnWidths[0], startY, columnWidths[1], true);
  //       drawCell('Secured Marks', tableX + columnWidths[0] + columnWidths[1], startY, columnWidths[2], true);

  //       startY -= rowHeight;

  //       // Draw subject rows
  //       subjects.forEach((subject, index) => {
  //         const { subjectName, marksScored } = subject;
  //         drawCell(subjectName, tableX, startY, columnWidths[0]);
  //         drawCell('800', tableX + columnWidths[0], startY, columnWidths[1]);
  //         drawCell(marksScored.toString(), tableX + columnWidths[0] + columnWidths[1], startY, columnWidths[2]);
  //         startY -= rowHeight;
  //       });

  //       // Calculate and draw total secured marks
  //       const tablexx = 300;
  //       const totalSecuredMarks = subjects.reduce((total, subject) => total + subject.marksScored, 0);
  //       drawBoldText(`Total Secured Marks: ${totalSecuredMarks}`, tablexx, startY - 30);

  //       const pdfBytes = await pdfDoc.save();

  //       // Download the overlaid PDF
  //       const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  //       const url = URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = `${regNo}_marksheet.pdf`;
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error('Error overlaying template:', error);
  //     }
  //   }
  // };

    const handleCloseEditModal = () => {
      setShowEditModal(false);
    };

    const handleCloseViewModal = () => {
      setShowViewModal(false);
    };

  // const responive_view_container = {
  //   padding: '30px',
  //   backgroundColor: '#fff',
  //   borderradius: '10px',
  //   maxWidth: '1200px',
  //   margin: '40px auto',
  //   fontFamily: 'Arial, sans-serif',
  //   marginLeft:'300px',
  // }

  const getResponsiveViewContainer = () => {
    const baseStyles = {
      backgroundColor: "#fff",
      borderRadius: "10px",
      fontFamily: "Arial, sans-serif",
    };

    const largeScreenStyles = {
      maxWidth: "1200px",
      marginLeft: "300px",
      marginTop: "-300px",
    };

    const mediumScreenStyles = {
      maxWidth: "900px",
      marginLeft: "100px",
      marginTop: "40px",
    };

    const smallScreenStyles = {
      maxWidth: "320px",
    };

    const width = window.innerWidth;

    if (windowWidth >= 1200) {
      return { ...baseStyles, ...largeScreenStyles };
    } else if (windowWidth >= 768) {
      return { ...baseStyles, ...mediumScreenStyles };
    } else {
      return { ...baseStyles, ...smallScreenStyles };
    }
  };

  return (
    <>
      {/* <section className="page-header">
        <div className="page-header__bg_1"
        >
          
        </div>
        
        <div className="shape1 float-bob-x">
          <img src=" ./images/sttsbanner.png" alt="banner shape" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Admin</h2>

            <ul className="thm-breadcrumb list-unstyled ml-0">
             
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Ontech."
                    href="/"
                    className="home"
                  >
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">
                 Admin
                  </span>
                  <meta property="url" content="" />
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </div>
        
      </section> */}
      <Leftbar />

      <div style={getResponsiveViewContainer()}>
        <div style={{ marginBottom: "40px" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginBottom: "20px",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #e0e0e0",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "16px",
                    color: "#000",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    border: "1px solid #e0e0e0",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "16px",
                    color: "#000",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Reg No
                </th>
                <th
                  style={{
                    border: "1px solid #e0e0e0",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "16px",
                    color: "#000",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Course Title
                </th>
                <th
                  style={{
                    border: "1px solid #e0e0e0",
                    padding: "12px 15px",
                    textAlign: "left",
                    fontSize: "16px",
                    color: "#000",
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {marksheets.map((marksheet, idx) => (
                <tr key={idx}>
                  <td
                    style={{
                      border: "1px solid #e0e0e0",
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "#555",
                    }}
                  >
                    <span className="resposive_s">Name:{"  "}</span>
                    {marksheet.name}
                  </td>
                  <td
                    style={{
                      border: "1px solid #e0e0e0",
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "#555",
                    }}
                  >
                    <span className="resposive_s">RegNo:{"  "}</span>
                    {marksheet.regNo}
                  </td>
                  <td
                    style={{
                      border: "1px solid #e0e0e0",
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "16px",
                      color: "#555",
                    }}
                  >
                    <span className="resposive_s">MarkSheets:{"  "}</span>
                    {marksheet.course}
                  </td>
                  <td
                    style={{
                      border: "1px solid #e0e0e0",
                      padding: "12px 15px",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    <button
                      onClick={() => handleEdit(marksheet)}
                      style={{
                        backgroundColor: "#fd7e14",
                        color: "#fff",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleView(marksheet)}
                      style={{
                        backgroundColor: "#fd7e14",
                        color: "#fff",
                        border: "none",
                        padding: "8px 16px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginRight: "10px",
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Edit Modal */}
        <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Marksheet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  style={{ color: "black" }}
                />
              </Form.Group>
              <Form.Group controlId="formRegNo">
                <Form.Label>Registration No</Form.Label>
                <Form.Control
                  type="text"
                  name="regNo"
                  value={formData.regNo}
                  onChange={handleInputChange}
                  style={{ color: "black" }}
                />
              </Form.Group>
              <Form.Group controlId="formCourse">
                <Form.Label>Course</Form.Label>
                <Form.Control
                  type="text"
                  name="course"
                  value={formData.course}
                  onChange={handleInputChange}
                  style={{ color: "black" }}
                />
              </Form.Group>
              {formData.subjects.map((subject, index) => (
                <div key={index}>
                  <Form.Group controlId={`formSubject${index}`}>
                    <Form.Label>Subject {index + 1}</Form.Label>
                    <Form.Control
                      type="text"
                      name={`subjects[${index}].subjectName`}
                      value={subject.subjectName}
                      onChange={handleInputChange}
                      style={{ color: "black" }}
                    />
                  </Form.Group>
                  <Form.Group controlId={`formMarks${index}`}>
                    <Form.Label>Marks Scored</Form.Label>
                    <Form.Control
                      type="number"
                      name={`subjects[${index}].marksScored`}
                      value={subject.marksScored}
                      onChange={handleInputChange}
                      min="0"
                      max="100"
                      style={{ color: "black" }}
                    />
                  </Form.Group>
                </div>
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* View Modal */}
        <Modal show={showViewModal} onHide={handleCloseViewModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>View Marksheet Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedMarksheet && (
              <div>
                <p>
                  <strong>Name:</strong> {selectedMarksheet.name}
                </p>
                <p>
                  <strong>Registration No:</strong> {selectedMarksheet.regNo}
                </p>
                <p>
                  <strong>Course:</strong> {selectedMarksheet.course}
                </p>
                <ul>
                  {selectedMarksheet.subjects.map((subject, index) => (
                    <li key={index}>
                      {subject.subjectName}: {subject.marksScored}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseViewModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleOverlayTemplate}>
              Generate PDF
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ViewMarksheets;
