// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './ApplicantList.css'; // Assuming you create a CSS file for styling

// const ApplicantList = () => {
//   const [applications, setApplications] = useState([]);

//   useEffect(() => {
//     const fetchApplications = async () => {
//       try {
//         const response = await axios.get('http://localhost:8081/applications');
//         if (Array.isArray(response.data)) {
//           setApplications(response.data);
//         } else {
//           console.error('Unexpected response format: ', response.data);
//         }
//       } catch (error) {
//         console.error('Error fetching applications', error);
//       }
//     };

//     fetchApplications();
//   }, []);

//   const handleDownloadResume = async (resumeFilename) => {
//     try {
//       const response = await axios.get(`http://localhost:8081/uploads/${resumeFilename}`, {
//         responseType: 'blob', // Ensure response type is blob (binary data)
//       });

//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', resumeFilename);
//       document.body.appendChild(link);
//       link.click();

//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(link);
//     } catch (error) {
//       console.error('Error downloading resume', error);
//     }
//   };

//   const handleDeleteApplication = async (id) => {
//     try {
//       await axios.delete(`http://localhost:8081/api/jobapplications/${id}`);
//       setApplications(applications.filter(application => application._id !== id));
//     } catch (error) {
//       console.error('Error deleting application', error);
//     }
//   };

//   return (
//     <div className="applicant-list-container">
//       <h2>Applicant List</h2>
//       <table className="applicant-table">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Email</th>
//             <th>Phone</th>
//             <th>Resume</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {Array.isArray(applications) && applications.length > 0 ? (
//             applications.map((application) => (
//               <tr key={application._id}>
//                 <td>{application.name}</td>
//                 <td>{application.email}</td>
//                 <td>{application.phone}</td>
//                 <td>
//                   <button className="resume-download" onClick={() => handleDownloadResume(application.resume)}>
//                     Download Resume
//                   </button>
//                 </td>
//                 <td>
//                   <button className="delete-button" onClick={() => handleDeleteApplication(application._id)}>
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))
//           ) : (
//             <tr>
//               <td colSpan="5">No applications found</td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default ApplicantList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ApplicantList.css"; // Assuming you create a CSS file for styling
import Leftbar from "../Leftbar/Leftbar";

const ApplicantList = () => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get("/applications");
        if (Array.isArray(response.data)) {
          setApplications(response.data);
        } else {
          console.error("Unexpected response format: ", response.data);
        }
      } catch (error) {
        console.error("Error fetching applications", error);
      }
    };

    fetchApplications();
  }, []);

  const handleDownloadResume = async (resumeFilename) => {
    try {
      // Assuming resumeFilename is the path stored in application.resume
      const response = await axios.get(
        `/download/${encodeURIComponent(resumeFilename)}`,
        {
          responseType: "blob", // Ensure response type is blob (binary data)
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", resumeFilename); // Use the filename here if needed
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading resume", error);
    }
  };

  // const handleDownloadResume = async (resumeFilename) => {
  //   try {
  //     const response = await axios.get(/uploads1/${resumeFilename}, {
  //       responseType: 'blob', // Ensure response type is blob (binary data)
  //     });

  //     const blob = new Blob([response.data], { type: 'application/pdf' });

  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', resumeFilename);
  //     document.body.appendChild(link);
  //     link.click();

  //     window.URL.revokeObjectURL(url);
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading resume', error);
  //   }
  // };

  const handleDeleteApplication = async (id) => {
    try {
      await axios.delete(`/api/jobapplications/${id}`);
      setApplications(
        applications.filter((application) => application._id !== id)
      );
    } catch (error) {
      console.error("Error deleting application", error);
    }
  };

  return (
    <>
      <Leftbar />
      <div style={{ width: "893px" }}>
        <table className="applicant-table table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Resume</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(applications) && applications.length > 0 ? (
              applications.map((application) => (
                <tr key={application._id}>
                  <td>
                    <span className="resposive_s">Name:{"  "}</span>
                    {application.name}
                  </td>
                  <td>
                    <span className="resposive_s">Email:{"  "}</span>
                    {application.email}
                  </td>
                  <td>
                    <span className="resposive_s">Phone:{"  "}</span>
                    {application.phone}
                  </td>
                  <td>
                    <button
                      className="resume-download"
                      onClick={() => handleDownloadResume(application.resume)}
                    >
                      Download Resume
                    </button>
                  </td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteApplication(application._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No applications found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ApplicantList;
