import React from 'react'
import '../Dashboards/Dashboard.css'
import { useNavigate } from 'react-router-dom';
import Leftbar from '../../Components/Leftbar/Leftbar'



const Dashboard = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <section className="page-header">
        <div className="page-header__bg_1"
        >
          
        </div>
        {/* <div
          className="page-header__gradient"
          style={{
            backgroundImage:
              "url(https://bracketweb.com/ontechwp/wp-content/uploads/2023/07/page-header-gradient-bg.png)",
          }}
        ></div> */}
        <div className="shape1 float-bob-x">
          <img src=" ./images/sttsbanner.png" alt="banner shape" />
        </div>
        <div className="container">
          <div className="page-header__inner text-center">
            <h2>Admin</h2>

            <ul className="thm-breadcrumb list-unstyled ml-0">
              {/* Breadcrumb NavXT 7.2.0 */}
              <li className="home">
                <span property="itemListElement" typeof="ListItem">
                  <a
                    property="item"
                    typeof="WebPage"
                    title="Go to Ontech."
                    href="/"
                    className="home"
                  >
                    <span property="name">Home</span>
                  </a>
                  <meta property="position" content="1" />
                </span>
              </li>
              <li className="post post-page current-item">
                <span property="itemListElement" typeof="ListItem">
                  <span property="name" className="post post-page current-item">
                 Admin
                  </span>
                  <meta property="url" content="" />
                  <meta property="position" content="2" />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Leftbar />
      <div className="dashboard">
      <div className="cookieCard">
          <p className="cookieDescription">Student Register</p>
          <button className="acceptButton" onClick={() => handleNavigate('/stud')}>
            View Details
          </button>
        </div>
        
        <div className="cookieCard">
          <p className="cookieDescription">Total Certificates</p>
          <button className="acceptButton" onClick={() => handleNavigate('/certificateview')}>
            View Details
          </button>
        </div>
        <div className="cookieCard1">
          <p className="cookieDescription">Total Marksheets</p>
          <button className="acceptButton" onClick={() => handleNavigate('/view')}>
            View Details
          </button>
        </div>
      </div>
    </>
  );
};

export default Dashboard