


import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Leftbar from "../Leftbar/Leftbar";

function Student() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    collegeName: "",
    fatherName: "",
    course: "",
    centreName: "",
    grade: "",
    courseDuration: "",
    courseFee: "",
    amountReceived: "",
    currentDue: "",
    Date: "",
    softwareCovered: [],
    RegNo: "",
    mobileNo: "",
    gender: "",
    PaymentBy: "",
    Address: "",
  });

  const [receiptId, setReceiptId] = useState("Stts100");
  const [studentCount, setStudentCount] = useState(0);

  const courseIdentifiers = {
    DCA: "DCA",
    "ADVANCE PGDCA": "APG",
    PGDCA: "PG",
    MECHANICAL: "MCAD",
    ELECTRICAL: "ECAD",
    "CIVIL & ARCH": "ACAD",
    "IT & CS": "IT",
    TALLY: "TY",
    PHOTOSHOP: "PS",
    "MS OFFICE": "MSO",
    "ADVANCE EXCEL": "AE",
    "BASIC COMPUTER": "BC",
   "DIPLOMA MECH CAD":"DMCAD",
    "PROFESSIONAL MECH CAD":"PMCAD",
    "DIPLOMA CIVIL CAD":"DACD",
    "PROFESSIONAL CIVIL CAD":"PACAD",
    "DIPLOMA ELECTRICAL CAD":"DECAD",
   "PROFESSIONAL ELECTRCIAL CAD":"PECAD",
   "INTERIOR DESIGNING":"IAD"
    
  };
  const softwareOptions = {
    "ADVANCE EXCEL": [
      "Microsoft Excel"
    ],
    "ADVANCE PGDCA": [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "MS Office",
     "Internet",
     "Paint",
  "Notepad",
  "WordPad",
  "C",
  "C++",
  "HTML",
  "TALLY",
 ],
    "BASIC COMPUTER": [
      "Paint",
"Notepad",
"WordPad",
"MS Office",
"C",
"C++",
"HTML",
"TALLY",
"Ms DOs & Windows",
"Internet"
    ],
   "CIVIL & ARCH": [
    "Auto CAD",
    "Revit Architecture",
    "Revit Structure",
    "Sketch up",
    "3Ds Max",
    "Vray",
    "Enscape",
    "Lumion",
    "Tekla",
    "Quantity Take Off",
    "Staad Pro",
    "ETAB",
    "PRIMAVERA"
  ],
    DCA: [
      "Paint",
  "Notepad",
  "WordPad",
  "MS Office",
  "C",
  "C++",
  "HTML",
  "TALLY",
  "Ms DOs & Windows",
  "Internet",   "Software Hacking and IT Security",
  "ER Basics",
  
  "E-Business",
      
    ],
    "DIPLOMA CIVIL CAD":[
      "Auto CAD",
      "Revit Architecture",
      "Revit Structure",
      "Sketch up",
      "3Ds Max",
      "Vray",
      "Enscape",
      "Lumion",
      "Tekla",
      "Quantity Take Off",
      "Staad Pro",
      "ETAB",
      "PRIMAVERA"
    ],
    "DIPLOMA ELECTRICAL CAD":[
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
    "DIPLOMA MECH CAD":[
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    ELECTRICAL: [
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
   "INTERIOR DESIGNING":[
    "Auto CAD",
    "Revit Architecture",
    "Revit Structure",
    "Sketch up",
    "3Ds Max",
    "Vray",
    "Enscape",
    "Lumion",
    "Tekla",
    "Quantity Take Off",
    "Staad Pro",
    "ETAB",
    "PRIMAVERA"
  ],
    "IT & CS": [
      "C",
      "C++",
      "Java",
      "Python",
      "Power BI"
    ],
    
    MECHANICAL: [
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    
    
    
    
    "MS OFFICE": [
      "Microsoft Word",
      "Microsoft Excel",
      "Microsoft PowerPoint"
    ],
    PGDCA:  [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "MS Office",
     "Internet",
     "Paint",
  "Notepad",
  "WordPad",
  "C",
  "C++",
  "HTML",
  "TALLY",
 ],
    PHOTOSHOP: [
      "Basics of Photoshop",
      "Image Editing",
      "Layer Management",
      "Working with Paths"
    ],
    "PROFESSIONAL CIVIL CAD":[
      "Auto CAD",
      "Revit Architecture",
      "Revit Structure",
      "Sketch up",
      "3Ds Max",
      "Vray",
      "Enscape",
      "Lumion",
      "Tekla",
      "Quantity Take Off",
      "Staad Pro",
      "ETAB",
      "PRIMAVERA"
    ],
    "PROFESSIONAL ELECTRCIAL CAD":[
      "Auto CAD Electrical",
      "Eplan",
      "Matlab",
      "PLC",
      "SCADA",
      "Revit MEP"
    ],
    "PROFESSIONAL MECH CAD":[
      "Auto CAD",
      "Solidworks",
      "Catia",
      "NX CAD",
      "Autodesk Inventor",
      "Ansys Workbench",
      "CNC",
      "GD & T",
      "Revit MEP",
      "Art CAM"
    ],
    TALLY: [
      "Company Creation",
      "Accounting Features",
      "Inventory Management"
    ],
    
    
    
   
  };
  
  // const softwareOptions = {
  //   MECHANICAL: [
  //     "Auto CAD", "Solidworks", "Catia", "NX CAD", "Autodesk Inventor", "Ansys Workbench",
  //     "CNC", "GD & T", "Revit MEP", "Art CAM"
  //   ],
  //   CIVIL: [
  //     "Auto CAD", "Revit Architecture", "Revit Structure", "Sketch up", "3Ds Max", "Vray",
  //     "Enscape", "Lumion", "Tekla", "Quantity Take Off", "Staad Pro", "ETAB", "PRIMAVERA"
  //   ],
  //   ELECTRICAL: [
  //     "Auto CAD Electrical", "Eplan", "Matlab", "PLC", "SCADA", "Revit MEP"
  //   ],
  //   "ADVANCE EXCEL": ["Microsoft Excel"],
  //   "IT and CS": ["C", "C++", "Java", "Python", "Power BI"],
  //   "BASIC COMPUTER": ["Paint", "Notepad", "WordPad", "Computer Fundamental"]
  // };

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await axios.get("/api/student-count");
        setStudentCount(response.data.count);
      } catch (error) {
        console.error("Error fetching student count:", error);
      }
    };

    fetchStudentCount();
  }, []);

  useEffect(() => {
    if (formData.course) {
      const courseIdentifier = courseIdentifiers[formData.course] || "XXX";
      const incrementedNumber = studentCount + 301; // Start from 301

      setFormData((prevData) => ({
        ...prevData,
        RegNo: `STTS/${courseIdentifier}/24-${incrementedNumber}`,
      }));
    }
  }, [formData.course, studentCount]);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    let newFormData = { ...formData };

    if (type === "checkbox") {
      const updatedSoftwareCovered = checked
        ? [...newFormData.softwareCovered, value]
        : newFormData.softwareCovered.filter((software) => software !== value);

      newFormData.softwareCovered = updatedSoftwareCovered;
    } else {
      newFormData[id] = value;
    }

    const courseFee = parseFloat(newFormData.courseFee) || 0;
    const amountReceived = parseFloat(newFormData.amountReceived) || 0;

    if (amountReceived > courseFee) {
      newFormData.amountReceived = courseFee.toFixed(2);
    }

    newFormData.currentDue = Math.max(0, (courseFee - amountReceived).toFixed(2));

    setFormData(newFormData);
  };

  const incrementReceiptId = (currentId) => {
    const numPart = parseInt(currentId.slice(4), 10);
    return `Stts${(numPart + 1).toString().padStart(3, "0")}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const courseFee = parseFloat(formData.courseFee) || 0;
    const amountReceived = parseFloat(formData.amountReceived) || 0;

    if (amountReceived > courseFee) {
      toast.error("Amount Received cannot exceed Course Fee.");
      return;
    }

    try {
      await axios.post("/api/addstudent", {
        ...formData,
        receiptId: receiptId,
      });

      const newReceiptId = incrementReceiptId(receiptId);
      setReceiptId(newReceiptId);

      setFormData({
        name: "",
        email: "",
        collegeName: "",
        fatherName: "",
        course: "",
        centreName: "",
        grade: "",
        courseDuration: "",
        courseFee: "",
        amountReceived: "",
        currentDue: "",
        Date: "",
        softwareCovered: [],
        RegNo: "",
        mobileNo: "",
        gender: "",
        PaymentBy: "",
        Address: "",
        receiptId: "",
      });

      toast.success("Student added successfully!");
    } catch (error) {
      console.error(
        "Error adding data:",
        error.response ? error.response.data : error.message
      );
      toast.error("Error adding student data. Please try again.");
    }
  };
  return (
    <>
      <Leftbar />
      <div className="form-container">
        <h2>Add Student Data</h2>
        <ToastContainer />
        <form onSubmit={handleSubmit} className="student-form" style={{marginLeft:'-40px'}}>
  <div className="form-grid">
    <div className="input-group">
      <label htmlFor="name">Name:</label>
      <input type="text" id="name" value={formData.name} onChange={handleChange} required style={{color:'black'}} />
    </div>
    <div className="input-group">
      <label htmlFor="email">Email:</label>
      <input type="email" id="email" value={formData.email} onChange={handleChange} required style={{color:'black'}} />
    </div>
    <div className="input-group">
      <label htmlFor="fatherName">Father's Name:</label>
      <input type="text" id="fatherName" value={formData.fatherName} onChange={handleChange} required style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="gender">Gender:</label>
      <select id="gender" value={formData.gender} onChange={handleChange} required style={{color:'black'}}>
        <option value="">Select Gender</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>
    </div>
    <div className="input-group">
      <label htmlFor="mobileNo">Mobile No:</label>
      <input type="text" id="mobileNo" value={formData.mobileNo} onChange={handleChange} required  style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="Address">Address:</label>
      <input type="text" id="Address" value={formData.Address} onChange={handleChange} required style={{color:'black'}} />
    </div>
    <div className="input-group">
      <label htmlFor="collegeName">College Name:</label>
      <input type="text" id="collegeName" value={formData.collegeName} onChange={handleChange} required style={{color:'black'}} />
    </div>
    <div className="input-group">
      <label htmlFor="course">Course Title:</label>
      <select id="course" value={formData.course} onChange={handleChange} required style={{color:'black'}}>
        <option value="">Select Course</option>
        {Object.keys(softwareOptions).map((course) => (
          <option key={course} value={course}>{course}</option>
        ))}
      </select>
    </div>
    <div className="input-group">
      <label htmlFor="RegNo">Registration No:</label>
      <input type="text" id="RegNo" value={formData.RegNo} readOnly style={{color:'black'}} />
    </div>
    <div className="input-group">
      <label htmlFor="centreName">Centre Name:</label>
      <input type="text" id="centreName" value={formData.centreName} onChange={handleChange} required  style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="courseDuration">Course Duration:</label>
      <input type="text" id="courseDuration" value={formData.courseDuration} onChange={handleChange} required  style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="courseFee">Course Fee:</label>
      <input type="number" id="courseFee" value={formData.courseFee} onChange={handleChange} required  style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="amountReceived">Amount Received:</label>
      <input type="number" id="amountReceived" value={formData.amountReceived} onChange={handleChange} required style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="currentDue">Current Due:</label>
      <input type="text" id="currentDue" value={formData.currentDue} readOnly style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="Date">Date:</label>
      <input type="date" id="Date" value={formData.Date} onChange={handleChange} required style={{color:'black'}}/>
    </div>
    <div className="input-group">
      <label htmlFor="PaymentBy">Payment By:</label>
      <select id="PaymentBy" value={formData.PaymentBy} onChange={handleChange} required style={{color:'black'}}>
        <option value="">Select Payment Method</option>
        <option value="UPI">UPI</option>
        <option value="Bank Transfer">Bank Transfer</option>
        <option value="Cash">Cash</option>
      </select>
    </div>
    <div className="input-group software-covered">
      <label>Software Covered:</label>
      <div className="software-list">
        {softwareOptions[formData.course]?.map((software, index) => (
          <div className="checkbox-item" key={software}>
            <input
              type="checkbox"
              id={software}
              value={software}
              checked={formData.softwareCovered.includes(software)}
              onChange={handleChange}
              required={index === 0 && formData.softwareCovered.length === 0}
            />
            <label htmlFor={software}>{software}</label>
          </div>
        ))}
      </div>
    </div>
    <div className="submit-group">
      <button type="submit">Submit</button>
    </div>
  </div>
</form>

</div>

<style jsx>{`
  
  .input-group {
     flex: 1 1 calc(25% - 20px); /* Three fields per row */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  .input-group label {
    font-weight: bold;
    margin-bottom: 5px;
    text-align:left;
  }
  .input-group input,
  .input-group select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%; /* Make inputs take the full width of their container */
  }
  .input-group input[type="number"] {
    width: 100%;
  }
  .software-covered {
    margin-top: -10px;
    width: 100%;
  }
  .software-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  .checkbox-item {
    display: flex;
    align-items: center;
    width: calc(20% - 10px); /* 5 items per row */
    font-size: 14px;
  }
  .checkbox-item input[type="checkbox"] {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  .checkbox-item label {
    cursor: pointer;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .submit-group {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .submit-group button {
    padding: 10px 20px;
    background-color: #FFB237;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  .submit-group button:hover {
    background-color: #FFB237;
  }
  @media (max-width: 1200px) {
    .checkbox-item {
      width: calc(25% - 10px); /* 4 items per row on smaller screens */
    }
  }
  @media (max-width: 768px) {
    .checkbox-item {
      width: calc(33.33% - 10px); /* 3 items per row on mobile */
    }
  }
`}</style>




</>
)
}

export default Student;
