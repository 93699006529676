


// // import React, { useState } from "react";
// // import axios from "axios";
// // import "./Marksheet.css";
// // import Leftbar from "../../Components/Leftbar/Leftbar";

// // const AddMarksheetForm = () => {
// //   const initialFormData = {
// //     name: "",
// //     regNo: "",
// //     course: "",
// //     centre: "STTS, BARIPADA",
// //     date: "",
// //     subjects: [
// //       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
// //     ],
// //   };

// //   const [formData, setFormData] = useState(initialFormData);
// //   const [suggestions, setSuggestions] = useState([]);

// //   // Fetch suggestions based on registration number
// //   const fetchSuggestions = async (query) => {
// //     if (query.length > 0) {
// //       try {
// //         const response = await axios.get("/api/suggestions", {
// //           params: { query },
// //         });
// //         setSuggestions(response.data);
// //       } catch (error) {
// //         console.error("Error fetching suggestions:", error);
// //       }
// //     } else {
// //       setSuggestions([]);
// //     }
// //   };

// //   // Fetch user info based on registration number
// //   const fetchUserInfo = async (regNo) => {
// //     try {
// //       const response = await axios.get("/api/user-info", {
// //         params: { regNo },
// //       });
// //       if (response.data) {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: response.data.name,
// //           course: response.data.course,
// //           regNo: regNo,
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching user info:", error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //     if (name === "regNo") {
// //       fetchSuggestions(value);
// //     }
// //   };

// //   const handleSubjectChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedSubjects = [...formData.subjects];
// //     if (
// //       name === "marksScored" &&
// //       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
// //     ) {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     } else if (name !== "marksScored") {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     }
// //     setFormData({ ...formData, subjects: updatedSubjects });
// //   };

// //   const handleSuggestionClick = (regNo) => {
// //     fetchUserInfo(regNo);
// //     setSuggestions([]);
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       await axios.post("/add-marksheet", formData);
// //       setFormData(initialFormData);
// //     } catch (error) {
// //       console.error("There was an error!", error);
// //     }
// //   };

// //   return (
// //     <>
// //       <Leftbar />
// //       <div className="add-marksheet-form responsive_add-marksheet">
// //         <h2>Add Marksheet</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="regNo">Registration No:</label>
// //               <input
// //                 type="text"
// //                 id="regNo"
// //                 name="regNo"
// //                 value={formData.regNo}
// //                 onChange={handleChange}
// //                 required
// //                 style={{ color: "black" }}
// //               />
// //               {suggestions.length > 0 && (
// //                 <ul>
// //                   {suggestions.map((suggestion, index) => (
// //                     <li
// //                       key={index}
// //                       onClick={() => handleSuggestionClick(suggestion.regNo)}
// //                     >
// //                       {suggestion.regNo}
// //                     </li>
// //                   ))}
// //                 </ul>
// //               )}
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="name">Name:</label>
// //               <input
// //                 type="text"
// //                 id="name"
// //                 name="name"
// //                 value={formData.name}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="course">Course:</label>
// //               <input
// //                 type="text"
// //                 id="course"
// //                 name="course"
// //                 value={formData.course}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="centre">Centre:</label>
// //               <input
// //                 type="text"
// //                 id="centre"
// //                 name="centre"
// //                 value={formData.centre}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-group">
// //             <label htmlFor="date">Date:</label>
// //             <input
// //               type="date"
// //               id="date"
// //               name="date"
// //               value={formData.date}
// //               onChange={handleChange}
// //               style={{ color: "black" }}
// //             />
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>Sr No</th>
// //                   <th>Subject Name</th>
// //                   <th>Max Marks</th>
// //                   <th>Marks Scored</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {formData.subjects.map((subject, index) => (
// //                   <tr key={index} style={{ width: "auto" }}>
// //                     <td>
// //                       <span className="resposive_s">S.no:{"  "}</span>
// //                       {subject.srNo}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="text"
// //                         name="subjectName"
// //                         value={subject.subjectName}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                     <td>
// //                       <span className="resposive_s">MaxMarks:{"  "}</span>
// //                       {subject.maxMarks}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="number"
// //                         name="marksScored"
// //                         value={subject.marksScored}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         max="100"
// //                         min="0"
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <div style={{ display: "flex" }}>
// //             <div className="button-container">
// //               <button type="submit">Add Marksheet</button>
// //             </div>
// //             <div
// //               className="button-container-1"
// //               style={{ marginTop: "29px", marginLeft: "10px" }}
// //             >
// //               <a href="/view">
// //                 <button type="button">View Marksheets</button>
// //               </a>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // };

// // export default AddMarksheetForm;   main


























// // import React, { useState } from "react";
// // import axios from "axios";
// // import "./Marksheet.css";
// // import Leftbar from "../../Components/Leftbar/Leftbar";

// // const AddMarksheetForm = () => {
// //   const initialFormData = {
// //     name: "",
// //     regNo: "",
// //     course: "",
// //     centre: "STTS, BARIPADA",
// //     date: "",
// //     subjects: [
// //       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
// //     ],
// //   };

// //   const [formData, setFormData] = useState(initialFormData);
// //   const [suggestions, setSuggestions] = useState([]);

// //   // Fetch suggestions based on registration number
// //   const fetchSuggestions = async (query) => {
// //     if (query.length > 0) {
// //       try {
// //         const response = await axios.get("/api/suggestions", {
// //           params: { query },
// //         });
// //         setSuggestions(response.data);
// //       } catch (error) {
// //         console.error("Error fetching suggestions:", error);
// //       }
// //     } else {
// //       setSuggestions([]);
// //     }
// //   };

// //   // Fetch user info based on registration number
// //   const fetchUserInfo = async (regNo) => {
// //     try {
// //       const response = await axios.get("/api/user-info", {
// //         params: { regNo },
// //       });
// //       if (response.data) {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: response.data.name,
// //           course: response.data.course,
// //           regNo: regNo,
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching user info:", error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //     if (name === "regNo") {
// //       fetchSuggestions(value);
// //       fetchUserInfo(value); // Fetch user info when regNo changes
// //     }
// //   };

// //   const handleSubjectChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedSubjects = [...formData.subjects];
// //     if (
// //       name === "marksScored" &&
// //       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
// //     ) {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     } else if (name !== "marksScored") {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     }
// //     setFormData({ ...formData, subjects: updatedSubjects });
// //   };

// //   const handleSuggestionClick = (regNo) => {
// //     fetchUserInfo(regNo);
// //     setSuggestions([]);
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       await axios.post("/add-marksheet", formData);
// //       setFormData(initialFormData);
// //     } catch (error) {
// //       console.error("There was an error!", error);
// //     }
// //   };

// //   return (
// //     <>
// //       <Leftbar />
// //       <div className="add-marksheet-form responsive_add-marksheet">
// //         <h2>Add Marksheet</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="regNo">Registration No:</label>
// //               <input
// //                 type="text"
// //                 id="regNo"
// //                 name="regNo"
// //                 value={formData.regNo}
// //                 onChange={handleChange}
// //                 required
// //                 style={{ color: "black" }}
// //               />
// //               {suggestions.length > 0 && (
// //                 <ul>
// //                   {suggestions.map((suggestion, index) => (
// //                     <li
// //                       key={index}
// //                       onClick={() => handleSuggestionClick(suggestion.regNo)}
// //                     >
// //                       {suggestion.regNo}
// //                     </li>
// //                   ))}
// //                 </ul>
// //               )}
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="name">Name:</label>
// //               <input
// //                 type="text"
// //                 id="name"
// //                 name="name"
// //                 value={formData.name}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="course">Course:</label>
// //               <input
// //                 type="text"
// //                 id="course"
// //                 name="course"
// //                 value={formData.course}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="centre">Centre:</label>
// //               <input
// //                 type="text"
// //                 id="centre"
// //                 name="centre"
// //                 value={formData.centre}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-group">
// //             <label htmlFor="date">Date:</label>
// //             <input
// //               type="date"
// //               id="date"
// //               name="date"
// //               value={formData.date}
// //               onChange={handleChange}
// //               style={{ color: "black" }}
// //             />
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>Sr No</th>
// //                   <th>Subject Name</th>
// //                   <th>Max Marks</th>
// //                   <th>Marks Scored</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {formData.subjects.map((subject, index) => (
// //                   <tr key={index} style={{ width: "auto" }}>
// //                     <td>
// //                       <span className="resposive_s">S.no:{"  "}</span>
// //                       {subject.srNo}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="text"
// //                         name="subjectName"
// //                         value={subject.subjectName}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                     <td>
// //                       <span className="resposive_s">MaxMarks:{"  "}</span>
// //                       {subject.maxMarks}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="number"
// //                         name="marksScored"
// //                         value={subject.marksScored}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         max="100"
// //                         min="0"
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <div style={{ display: "flex" }}>
// //             <div className="button-container">
// //               <button type="submit">Add Marksheet</button>
// //             </div>
// //             <div
// //               className="button-container-1"
// //               style={{ marginTop: "29px", marginLeft: "10px" }}
// //             >
// //               <a href="/view">
// //                 <button type="button">View Marksheets</button>
// //               </a>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // };

// // export default AddMarksheetForm;




// // import React, { useState } from "react";
// // import axios from "axios";
// // import "./Marksheet.css";
// // import Leftbar from "../../Components/Leftbar/Leftbar";

// // const AddMarksheetForm = () => {
// //   const initialFormData = {
// //     name: "",
// //     regNo: "",
// //     course: "",
// //     centre: "STTS, BARIPADA",
// //     date: "",
// //     subjects: [
// //       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
// //     ],
// //   };

// //   const [formData, setFormData] = useState(initialFormData);
// //   const [suggestions, setSuggestions] = useState([]);

// //   // Fetch suggestions based on registration number
// //   const fetchSuggestions = async (query) => {
// //     if (query.length > 0) {
// //       try {
// //         const response = await axios.get("/api/suggestions", {
// //           params: { query },
// //         });
// //         setSuggestions(response.data);
// //       } catch (error) {
// //         console.error("Error fetching suggestions:", error);
// //       }
// //     } else {
// //       setSuggestions([]);
// //     }
// //   };

// //   // Fetch user info based on registration number
// //   const fetchUserInfo = async (regNo) => {
// //     try {
// //       const response = await axios.get("/api/user-info", {
// //         params: { regNo },
// //       });
// //       if (response.data) {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: response.data.name,
// //           course: response.data.course,
// //           regNo: regNo,
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching user info:", error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //     if (name === "regNo") {
// //       fetchSuggestions(value);
// //       fetchUserInfo(value); // Fetch user info when regNo changes
// //     }
// //   };

// //   const handleSubjectChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedSubjects = [...formData.subjects];
// //     if (
// //       name === "marksScored" &&
// //       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
// //     ) {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     } else if (name !== "marksScored") {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     }
// //     setFormData({ ...formData, subjects: updatedSubjects });
// //   };

// //   const handleSuggestionClick = (regNo) => {
// //     fetchUserInfo(regNo);
// //     setSuggestions([]);
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       await axios.post("/add-marksheet", formData);
// //       setFormData(initialFormData);
// //     } catch (error) {
// //       console.error("There was an error!", error);
// //     }
// //   };

// //   return (
// //     <>
// //       <Leftbar />
// //       <div className="add-marksheet-form responsive_add-marksheet">
// //         <h2>Add Marksheet</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="regNo">Registration No:</label>
// //               <input
// //                 type="text"
// //                 id="regNo"
// //                 name="regNo"
// //                 value={formData.regNo}
// //                 onChange={handleChange}
// //                 required
// //                 style={{ color: "black" }}
// //               />
// //               {suggestions.length > 0 && (
// //                 <ul>
// //                   {suggestions.map((suggestion, index) => (
// //                     <li
// //                       key={index}
// //                       onClick={() => handleSuggestionClick(suggestion.regNo)}
// //                     >
// //                       {suggestion.regNo}
// //                     </li>
// //                   ))}
// //                 </ul>
// //               )}
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="name">Name:</label>
// //               <input
// //                 type="text"
// //                 id="name"
// //                 name="name"
// //                 value={formData.name}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="course">Course:</label>
// //               <input
// //                 type="text"
// //                 id="course"
// //                 name="course"
// //                 value={formData.course}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="centre">Centre:</label>
// //               <input
// //                 type="text"
// //                 id="centre"
// //                 name="centre"
// //                 value={formData.centre}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-group">
// //             <label htmlFor="date">Date:</label>
// //             <input
// //               type="date"
// //               id="date"
// //               name="date"
// //               value={formData.date}
// //               onChange={handleChange}
// //               style={{ color: "black" }}
// //             />
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>Sr No</th>
// //                   <th>Subject Name</th>
// //                   <th>Max Marks</th>
// //                   <th>Marks Scored</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {formData.subjects.map((subject, index) => (
// //                   <tr key={index} style={{ width: "auto" }}>
// //                     <td>
// //                       <span className="resposive_s">S.no:{"  "}</span>
// //                       {subject.srNo}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="text"
// //                         name="subjectName"
// //                         value={subject.subjectName}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                     <td>
// //                       <span className="resposive_s">MaxMarks:{"  "}</span>
// //                       {subject.maxMarks}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="number"
// //                         name="marksScored"
// //                         value={subject.marksScored}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         max="100"
// //                         min="0"
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <div style={{ display: "flex" }}>
// //             <div className="button-container">
// //               <button type="submit">Add Marksheet</button>
// //             </div>
// //             <div
// //               className="button-container-1"
// //               style={{ marginTop: "29px", marginLeft: "10px" }}
// //             >
// //               <a href="/view">
// //                 <button type="button">View Marksheets</button>
// //               </a>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // };

// // export default AddMarksheetForm;


// //----------------------------------------------------
// // import React, { useState } from "react";
// // import axios from "axios";
// // import "./Marksheet.css";
// // import Leftbar from "../../Components/Leftbar/Leftbar";

// // const AddMarksheetForm = () => {
// //   const initialFormData = {
// //     name: "",
// //     regNo: "",
// //     course: "",
// //     centre: "STTS, BARIPADA",
// //     date: "",
// //     subjects: [
// //       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
// //     ],
// //   };

// //   const [formData, setFormData] = useState(initialFormData);
// //   const [suggestions, setSuggestions] = useState([]);

// //   // Fetch student info based on registration number from the student database
// //   const fetchStudentInfo = async (regNo) => {
// //     try {
// //       const response = await axios.get("/api/student-info", {
// //         params: { regNo },
// //       });
// //       if (response.data) {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: response.data.name,
// //           course: response.data.course,
// //           regNo: regNo,
// //         }));
// //       } else {
// //         // Clear name and course if regNo is not found
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: "",
// //           course: "",
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching student info:", error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //     if (name === "regNo") {
// //       fetchStudentInfo(value);
// //     }
// //   };

// //   const handleSubjectChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedSubjects = [...formData.subjects];
// //     if (
// //       name === "marksScored" &&
// //       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
// //     ) {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     } else if (name !== "marksScored") {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     }
// //     setFormData({ ...formData, subjects: updatedSubjects });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       await axios.post("/add-marksheet", formData);
// //       setFormData(initialFormData);
// //     } catch (error) {
// //       console.error("There was an error!", error);
// //     }
// //   };

// //   return (
// //     <>
// //       <Leftbar />
// //       <div className="add-marksheet-form responsive_add-marksheet">
// //         <h2>Add Marksheet</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="regNo">Registration No:</label>
// //               <input
// //                 type="text"
// //                 id="regNo"
// //                 name="regNo"
// //                 value={formData.regNo}
// //                 onChange={handleChange}
// //                 required
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="name">Name:</label>
// //               <input
// //                 type="text"
// //                 id="name"
// //                 name="name"
// //                 value={formData.name}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
           
// //           </div>
// //           <div className="form-row">
// //           <div className="form-group">
// //               <label htmlFor="course">Course:</label>
// //               <input
// //                 type="text"
// //                 id="course"
// //                 name="course"
// //                 value={formData.course}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="centre">Centre:</label>
// //               <input
// //                 type="text"
// //                 id="centre"
// //                 name="centre"
// //                 value={formData.centre}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           <div className="form-group">
// //             <label htmlFor="date">Date:</label>
// //             <input
// //               type="date"
// //               id="date"
// //               name="date"
// //               value={formData.date}
// //               onChange={handleChange}
// //               style={{ color: "black" }}
// //             />
// //           </div>
           
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>Sr No</th>
// //                   <th>Subject Name</th>
// //                   <th>Max Marks</th>
// //                   <th>Marks Scored</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {formData.subjects.map((subject, index) => (
// //                   <tr key={index} style={{ width: "auto" }}>
// //                     <td>
// //                       <span className="resposive_s">S.no:{"  "}</span>
// //                       {subject.srNo}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="text"
// //                         name="subjectName"
// //                         value={subject.subjectName}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                     <td>
// //                       <span className="resposive_s">MaxMarks:{"  "}</span>
// //                       {subject.maxMarks}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="number"
// //                         name="marksScored"
// //                         value={subject.marksScored}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         max="100"
// //                         min="0"
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <div style={{ display: "flex" }}>
// //             <div className="button-container">
// //               <button type="submit">Add Marksheet</button>
// //             </div>
// //             <div
// //               className="button-container-1"
// //               style={{ marginTop: "29px", marginLeft: "10px" }}
// //             >
// //               <a href="/view">
// //                 <button type="button">View Marksheets</button>
// //               </a>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // };

// // export default AddMarksheetForm;


// // import React, { useState } from "react";
// // import axios from "axios";
// // import "./Marksheet.css";
// // import Leftbar from "../../Components/Leftbar/Leftbar";

// // const AddMarksheetForm = () => {
// //   const initialFormData = {
// //     name: "",
// //     regNo: "",
// //     course: "",
// //     centre: "STTS, BARIPADA",
// //     date: "",
// //     subjects: [
// //       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
// //       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
// //     ],
// //   };

// //   const [formData, setFormData] = useState(initialFormData);

// //   const fetchStudentInfo = async (regNo) => {
// //     try {
// //       const response = await axios.get("/api/student-info", {
// //         params: { regNo },
// //       });
// //       if (response.data) {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: response.data.name,
// //           course: response.data.course,
// //           regNo: regNo,
// //         }));
// //       } else {
// //         setFormData((prevData) => ({
// //           ...prevData,
// //           name: "",
// //           course: "",
// //         }));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching student info:", error);
// //     }
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({ ...formData, [name]: value });

// //     if (name === "regNo") {
// //       fetchStudentInfo(value);
// //     }
// //   };

// //   const handleSubjectChange = (e, index) => {
// //     const { name, value } = e.target;
// //     const updatedSubjects = [...formData.subjects];
// //     if (
// //       name === "marksScored" &&
// //       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
// //     ) {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     } else if (name !== "marksScored") {
// //       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
// //     }
// //     setFormData({ ...formData, subjects: updatedSubjects });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     // Count the non-empty subject fields
// //     const filledFieldsCount = formData.subjects.filter(
// //       (subject) => subject.subjectName && subject.marksScored
// //     ).length;

// //     // Check if at least 6 fields are filled
// //     if (filledFieldsCount < 6) {
// //       alert("Please fill at least 6 subjects.");
// //       return;
// //     }

// //     try {
// //       await axios.post("/add-marksheet", formData);
// //       setFormData(initialFormData);
// //     } catch (error) {
// //       console.error("There was an error!", error);
// //     }
// //   };

// //   return (
// //     <>
// //       <Leftbar />
// //       <div className="add-marksheet-form responsive_add-marksheet">
// //         <h2>Add Marksheet</h2>
// //         <form onSubmit={handleSubmit}>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="regNo">Registration No:</label>
// //               <input
// //                 type="text"
// //                 id="regNo"
// //                 name="regNo"
// //                 value={formData.regNo}
// //                 onChange={handleChange}
// //                 required
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="name">Name:</label>
// //               <input
// //                 type="text"
// //                 id="name"
// //                 name="name"
// //                 value={formData.name}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group">
// //               <label htmlFor="course">Course:</label>
// //               <input
// //                 type="text"
// //                 id="course"
// //                 name="course"
// //                 value={formData.course}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="centre">Centre:</label>
// //               <input
// //                 type="text"
// //                 id="centre"
// //                 name="centre"
// //                 value={formData.centre}
// //                 disabled
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //             <div className="form-group">
// //               <label htmlFor="date">Date:</label>
// //               <input
// //                 type="date"
// //                 id="date"
// //                 name="date"
// //                 value={formData.date}
// //                 onChange={handleChange}
// //                 style={{ color: "black" }}
// //               />
// //             </div>
// //           </div>
// //           <div className="table-container">
// //             <table>
// //               <thead>
// //                 <tr>
// //                   <th>Sr No</th>
// //                   <th>Subject Name</th>
// //                   <th>Max Marks</th>
// //                   <th>Marks Scored</th>
// //                 </tr>
// //               </thead>
// //               <tbody>
// //                 {formData.subjects.map((subject, index) => (
// //                   <tr key={index} style={{ width: "auto" }}>
// //                     <td>
// //                       <span className="resposive_s">S.no:{"  "}</span>
// //                       {subject.srNo}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="text"
// //                         name="subjectName"
// //                         value={subject.subjectName}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                     <td>
// //                       <span className="resposive_s">MaxMarks:{"  "}</span>
// //                       {subject.maxMarks}
// //                     </td>
// //                     <td>
// //                       <input
// //                         type="number"
// //                         name="marksScored"
// //                         value={subject.marksScored}
// //                         onChange={(e) => handleSubjectChange(e, index)}
// //                         max="100"
// //                         min="0"
// //                         style={{ color: "black" }}
// //                       />
// //                     </td>
// //                   </tr>
// //                 ))}
// //               </tbody>
// //             </table>
// //           </div>
// //           <div style={{ display: "flex" }}>
// //             <div className="button-container">
// //               <button type="submit">Add Marksheet</button>
// //             </div>
// //             <div
// //               className="button-container-1"
// //               style={{ marginTop: "29px", marginLeft: "10px" }}
// //             >
// //               <a href="/view">
// //                 <button type="button">View Marksheets</button>
// //               </a>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </>
// //   );
// // };

// // export default AddMarksheetForm;


// // ----------------------


// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./Marksheet.css";
// import Leftbar from "../../Components/Leftbar/Leftbar";

// const AddMarksheetForm = () => {
//   const initialFormData = {
//     name: "",
//     regNo: "",
//     course: "",
//     centre: "STTS, BARIPADA",
//     date: "",
//     subjects: [
//       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
//     ],
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

//   const fetchSuggestions = async (query, field) => {
//     if (query.length > 0) {
//       try {
//         const response = await axios.get("/api/suggestions", {
//           params: { query },
//         });
//         setSuggestions((prev) => ({ ...prev, [field]: response.data }));
//       } catch (error) {
//         console.error("Error fetching suggestions:", error);
//       }
//     } else {
//       setSuggestions((prev) => ({ ...prev, [field]: [] }));
//     }
//   };

//   const fetchStudentInfo = async (field, value) => {
//     try {
//       const response = await axios.get("/api/student-info", {
//         params: { [field]: value },
//       });
//       if (response.data) {
//         setFormData((prevData) => ({
//           ...prevData,
//           name: response.data.name,
//           course: response.data.course,
//           regNo: response.data.RegNo,
//         }));
//       } else {
//         setFormData((prevData) => ({
//           ...prevData,
//           name: "",
//           course: "",
//           regNo: "",
//         }));
//       }
//     } catch (error) {
//       console.error("Error fetching student info:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "regNo" && value) {
//       fetchStudentInfo("regNo", value);
//     } else if (name === "name" && value) {
//       fetchSuggestions(value, "name");
//     }
//   };

//   const handleSubjectChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSubjects = [...formData.subjects];
//     if (
//       name === "marksScored" &&
//       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
//     ) {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     } else if (name !== "marksScored") {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     }
//     setFormData({ ...formData, subjects: updatedSubjects });
//   };

//   const handleSuggestionClick = async (suggestion) => {
//     // Fetch student info based on the selected suggestion
//     await fetchStudentInfo("name", suggestion);

//     // Clear the suggestions after selection
//     setSuggestions((prev) => ({ ...prev, name: [] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const filledFieldsCount = formData.subjects.filter(
//       (subject) => subject.subjectName && subject.marksScored
//     ).length;

//     if (filledFieldsCount < 6) {
//       alert("Please fill at least 6 subjects.");
//       return;
//     }

//     try {
//       await axios.post("/add-marksheet", formData);
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   return (
//     <>
//       <Leftbar />
//       <div className="add-marksheet-form responsive_add-marksheet">
//         <h2>Add Marksheet</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="regNo">Registration No:</label>
//               <input
//                 type="text"
//                 id="regNo"
//                 name="regNo"
//                 placeholder="STTS/"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               {suggestions.regNo.length > 0 && (
//                 <ul>
//                   {suggestions.regNo.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.RegNo)}
//                     >
//                       {suggestion.RegNo}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//             <div className="form-group">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//               {suggestions.name.length > 0 && (
//                 <ul>
//                   {suggestions.name.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.name)}
//                     >
//                       {suggestion.name}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="course">Course:</label>
//               <input
//                 type="text"
//                 id="course"
//                 name="course"
//                 value={formData.course}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="centre">Centre:</label>
//               <input
//                 type="text"
//                 id="centre"
//                 name="centre"
//                 value={formData.centre}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="date">Date:</label>
//               <input
//                 type="date"
//                 id="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>Subject Name</th>
//                   <th>Max Marks</th>
//                   <th>Marks Scored</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {formData.subjects.map((subject, index) => (
//                   <tr key={index} style={{ width: "auto" }}>
//                     <td>
//                       <span className="resposive_s">S.no:{"  "}</span>
//                       {subject.srNo}
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         name="subjectName"
//                         value={subject.subjectName}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                     <td>
//                       <span className="resposive_s">MaxMarks:{"  "}</span>
//                       {subject.maxMarks}
//                     </td>
//                     <td>
//                       <input
//                         type="number"
//                         name="marksScored"
//                         value={subject.marksScored}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         max="100"
//                         min="0"
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div style={{ display: "flex" }}>
//             <div className="button-container">
//               <button type="submit">Add Marksheet</button>
//             </div>
//             <div
//               className="button-container-1"
//               style={{ marginTop: "29px", marginLeft: "10px" }}
//             >
//               <a href="/view">
//                 <button type="button">View Marksheets</button>
//               </a>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddMarksheetForm;

































// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./Marksheet.css";
// import Leftbar from "../../Components/Leftbar/Leftbar";

// const AddMarksheetForm = () => {
//   const initialFormData = {
//     name: "",
//     regNo: "",
//     course: "",
//     centre: "STTS, BARIPADA",
//     date: "",
//     subjects: [
//       { srNo: "1", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "2", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "3", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "4", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "5", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "6", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "7", subjectName: "", maxMarks: "100", marksScored: "" },
//       { srNo: "8", subjectName: "", maxMarks: "100", marksScored: "" },
//     ],
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

//   const fetchSuggestions = async (query, field) => {
//     if (query.length > 0) {
//       try {
//         const response = await axios.get("api/suggestions", {
//           params: { query },
//         });
//         setSuggestions((prev) => ({ ...prev, [field]: response.data }));
//       } catch (error) {
//         console.error("Error fetching suggestions:", error);
//       }
//     } else {
//       setSuggestions((prev) => ({ ...prev, [field]: [] }));
//     }
//   };

//   const fetchStudentInfo = async (field, value) => {
//     try {
//       const response = await axios.get("api/student-info", {
//         params: { [field]: value },
//       });
//       if (response.data) {
//         setFormData((prevData) => ({
//           ...prevData,
//           name: response.data.name,
//           course: response.data.course,
//           regNo: response.data.RegNo,
//         }));
//       } else {
//         setFormData((prevData) => ({
//           ...prevData,
//           name: "",
//           course: "",
//           regNo: "",
//         }));
//       }
//     } catch (error) {
//       console.error("Error fetching student info:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "regNo" && value) {
//       fetchStudentInfo("regNo", value);
//     } else if (name === "name" && value) {
//       fetchStudentInfo("name", value);
//       fetchSuggestions(value, "name");
//     }
//   };

//   const handleSubjectChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSubjects = [...formData.subjects];
//     if (
//       name === "marksScored" &&
//       (value === "" || (/^\d{1,3}$/.test(value) && value <= 100))
//     ) {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     } else if (name !== "marksScored") {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     }
//     setFormData({ ...formData, subjects: updatedSubjects });
//   };

//   const handleSuggestionClick = async (suggestion) => {
//     await fetchStudentInfo("name", suggestion);
//     setSuggestions((prev) => ({ ...prev, name: [] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const filledFieldsCount = formData.subjects.filter(
//       (subject) => subject.subjectName && subject.marksScored
//     ).length;

//     if (filledFieldsCount < 6) {
//       alert("Please fill at least 6 subjects.");
//       return;
//     }

//     try {
//       await axios.post("add-marksheet", formData);
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   return (
//     <>
//       <Leftbar />
//       <div className="add-marksheet-form responsive_add-marksheet">
//         <h2>Add Marksheet</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="regNo">Registration No:</label>
//               <input
//                 type="text"
//                 id="regNo"
//                 name="regNo"
//                 placeholder="STTS/"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               {suggestions.regNo.length > 0 && (
//                 <ul>
//                   {suggestions.regNo.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.RegNo)}
//                     >
//                       {suggestion.RegNo}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//             <div className="form-group">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//               {suggestions.name.length > 0 && (
//                 <ul>
//                   {suggestions.name.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.name)}
//                     >
//                       {suggestion.name}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="course">Course:</label>
//               <input
//                 type="text"
//                 id="course"
//                 name="course"
//                 value={formData.course}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="centre">Centre:</label>
//               <input
//                 type="text"
//                 id="centre"
//                 name="centre"
//                 value={formData.centre}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="date">Date:</label>
//               <input
//                 type="date"
//                 id="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>Subject Name</th>
//                   <th>Max Marks</th>
//                   <th>Marks Scored</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {formData.subjects.map((subject, index) => (
//                   <tr key={index} style={{ width: "auto" }}>
//                     <td>
//                       <span className="resposive_s">S.no:{"  "}</span>
//                       {subject.srNo}
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         name="subjectName"
//                         value={subject.subjectName}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                     <td>
//                       <span className="resposive_s">MaxMarks:{"  "}</span>
//                       {subject.maxMarks}
//                     </td>
//                     <td>
//                       <input
//                         type="number"
//                         name="marksScored"
//                         value={subject.marksScored}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         max="100"
//                         min="0"
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div style={{ display: "flex" }}>
//             <div className="button-container">
//               <button type="submit">Add Marksheet</button>
//             </div>
//             <div
//               className="button-container-1"
//               style={{ marginTop: "29px", marginLeft: "10px" }}
//             >
//               <a href="/view">
//                 <button type="button">View Marksheets</button>
//               </a>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddMarksheetForm;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./Marksheet.css";
// import Leftbar from "../../Components/Leftbar/Leftbar";

// const AddMarksheetForm = () => {
//   const initialFormData = {
//     name: "",
//     regNo: "",
//     course: "",
//     centre: "STTS, BARIPADA",
//     date: "",
//     subjects: [],
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [subjectOptions, setSubjectOptions] = useState([]);
//   const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

//   const courseSubjectMap = {
//     DCA: [
//       "Basic Computer Skills",
//       "MS Office Applications",
//       "Software Hacking and IT Security",
//       "ERP Basics",
//       "Internet Basics",
//       "E-Business",
//     ],
//     PGDCA: [
//       "Computer Fundamental",
//       "Ms-Dom & Windows",
//       "Ms-office",
//       "C,C++Programming",
//       "Internet",
//       "HTML",
//       "Practical",
//     ],
//     // Add other courses and subjects here
//   };

//   const fetchSuggestions = async (query, field) => {
//     if (query.length > 0) {
//       try {
//         const response = await axios.get("/api/suggestions", {
//           params: { query },
//         });
//         setSuggestions((prev) => ({ ...prev, [field]: response.data }));
//       } catch (error) {
//         console.error("Error fetching suggestions:", error);
//       }
//     } else {
//       setSuggestions((prev) => ({ ...prev, [field]: [] }));
//     }
//   };

//   const fetchStudentInfo = async (field, value) => {
//     try {
//       const response = await axios.get("/api/student-info", {
//         params: { [field]: value },
//       });
//       if (response.data) {
//         const { name, course, regNo, Date } = response.data;
//         console.log(Date)
//         const subjects = courseSubjectMap[course] || [];
//         setFormData({
//           name,
//           course,
//           regNo,
//           Date,
//           subjects: subjects.map((subject, index) => ({
//             srNo: (index + 1).toString(),
//             subjectName: "",
//             maxMarks: "100",
//             marksScored: "",
//           })),
//         });
//         setSubjectOptions(subjects);
//       } else {
//         setFormData(initialFormData);
//         setSubjectOptions([]);
//       }
//     } catch (error) {
//       console.error("Error fetching student info:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "regNo" && value) {
//       fetchStudentInfo("regNo", value);
//     } else if (name === "name" && value) {
//       fetchStudentInfo("name", value);
//       fetchSuggestions(value, "name");
//     }
//   };

//   const handleSubjectChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSubjects = [...formData.subjects];

//     if (name === "marksScored" && value <= 100) {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     } else if (name !== "marksScored") {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     }

//     setFormData({ ...formData, subjects: updatedSubjects });
//   };

//   const handleSuggestionClick = async (suggestion) => {
//     await fetchStudentInfo("name", suggestion);
//     setSuggestions((prev) => ({ ...prev, name: [] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const filledFieldsCount = formData.subjects.filter(
//       (subject) => subject.subjectName && subject.marksScored
//     ).length;

//     if (filledFieldsCount < 6) {
//       alert("Please fill at least 6 subjects.");
//       return;
//     }

//     try {
//       await axios.post("/add-marksheet", formData);
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   const renderSubjectDropdown = (index) => {
//     const selectedSubjects = formData.subjects.map((sub) => sub.subjectName);
//     return (
//       <select
//         name="subjectName"
//         value={formData.subjects[index].subjectName}
//         onChange={(e) => handleSubjectChange(e, index)}
//         style={{ color: "black" }}
//       >
//         <option value="">Select Subject</option>
//         {subjectOptions.map((subject, i) => (
//           <option key={i} value={subject} disabled={selectedSubjects.includes(subject)}>
//             {subject}
//           </option>
//         ))}
//       </select>
//     );
//   };

//   return (
//     <>
//       <Leftbar />
//       <div className="add-marksheet-form responsive_add-marksheet">
//         <h2>Add Marksheet</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="regNo">Registration No:</label>
//               <input
//                 type="text"
//                 id="regNo"
//                 name="regNo"
//                 placeholder="STTS/"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               {suggestions.regNo.length > 0 && (
//                 <ul>
//                   {suggestions.regNo.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.RegNo)}
//                     >
//                       {suggestion.RegNo}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//             <div className="form-group">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//               {suggestions.name.length > 0 && (
//                 <ul>
//                   {suggestions.name.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.name)}
//                     >
//                       {suggestion.name}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="course">Course:</label>
//               <input
//                 type="text"
//                 id="course"
//                 name="course"
//                 value={formData.course}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="centre">Centre:</label>
//               <input
//                 type="text"
//                 id="centre"
//                 name="centre"
//                 value={formData.centre}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="date">Date:</label>
//               <input
//                 type="date"
//                 id="date"
//                 name="date"
//                 value={formData.Date}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>Subject Name</th>
//                   <th>Max Marks</th>
//                   <th>Marks Scored</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {formData.subjects.map((subject, index) => (
//                   <tr key={index} style={{ width: "auto" }}>
//                     <td>
//                       <span className="responsive_s">S.no:{"  "}</span>
//                       {subject.srNo}
//                     </td>
//                     <td>{renderSubjectDropdown(index)}</td>
//                     <td>
//                       <span className="responsive_s">MaxMarks:{"  "}</span>
//                       {subject.maxMarks}
//                     </td>
//                     <td>
//                       <input
//                         type="number"
//                         name="marksScored"
//                         value={subject.marksScored}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         max="100"
//                         min="0"
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div style={{ display: "flex" }}>
//             <div className="button-container">
//               <button type="submit">Add Marksheet</button>
//             </div>
//             <div className="button-container-1">
//               <button type="submit">Download Marksheet</button>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddMarksheetForm;


// import React, { useState } from "react";
// import axios from "axios";
// import "./Marksheet.css";
// import Leftbar from "../../Components/Leftbar/Leftbar";

// const AddMarksheetForm = () => {
//   const initialFormData = {
//     name: "",
//     regNo: "",
//     course: "",
//     centre: "STTS, BARIPADA",
//     date: "",
//     subjects: [],
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [subjectOptions, setSubjectOptions] = useState([]);
//   const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

//   const courseSubjectMap = {
//     DCA: [
//       "Basic Computer Skills",
//       "MS Office Applications",
//       "Software Hacking and IT Security",
//       "ERP Basics",
//       "Internet Basics",
//       "E-Business",
//     ],
//     PGDCA: [
//       "Computer Fundamental",
//       "Ms-Dom & Windows",
//       "Ms-office",
//       "C,C++Programming",
//       "Internet",
//       "HTML",
//       "Practical",
//     ],
//  "ADVANCE PGDCA":[
//   "Computer Fundemental",
// 'Ms-Dom & window',
// "Ms-office",
// "C,C++Programming",
// "Internet",
// "Tally 9.0",
// "HTML",
// "Practical",
//  ]
//     // Add other courses and subjects here
//   };

//   const fetchSuggestions = async (query, field) => {
//     if (query.length > 0) {
//       try {
//         const response = await axios.get("/api/suggestions", {
//           params: { query },
//         });
//         setSuggestions((prev) => ({ ...prev, [field]: response.data }));
//       } catch (error) {
//         console.error("Error fetching suggestions:", error);
//       }
//     } else {
//       setSuggestions((prev) => ({ ...prev, [field]: [] }));
//     }
//   };

//   const fetchStudentInfo = async (field, value) => {
//     try {
//       const response = await axios.get("/api/student-info", {
//         params: { [field]: value },
//       });
//       if (response.data) {
//         const { name, course, regNo, date } = response.data;
//         const subjects = courseSubjectMap[course] || [];
//         setFormData({
//           name,
//           course,
//           regNo,
//           date: new Date(date).toISOString().split('T')[0], // Convert to YYYY-MM-DD format
//           subjects: subjects.map((subject, index) => ({
//             srNo: (index + 1).toString(),
//             subjectName: "",
//             maxMarks: "100",
//             marksScored: "",
//           })),
//         });
//         setSubjectOptions(subjects);
//       } else {
//         setFormData(initialFormData);
//         setSubjectOptions([]);
//       }
//     } catch (error) {
//       console.error("Error fetching student info:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "regNo" && value) {
//       fetchStudentInfo("regNo", value);
//     } else if (name === "name" && value) {
//       fetchStudentInfo("name", value);
//       fetchSuggestions(value, "name");
//     }
//   };

//   const handleSubjectChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSubjects = [...formData.subjects];

//     if (name === "marksScored" && value <= 100) {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     } else if (name !== "marksScored") {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     }

//     setFormData({ ...formData, subjects: updatedSubjects });
//   };

//   const handleSuggestionClick = async (suggestion) => {
//     await fetchStudentInfo("name", suggestion);
//     setSuggestions((prev) => ({ ...prev, name: [] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const filledFieldsCount = formData.subjects.filter(
//       (subject) => subject.subjectName && subject.marksScored
//     ).length;

//     if (filledFieldsCount < 6) {
//       alert("Please fill at least 6 subjects.");
//       return;
//     }

//     try {
//       await axios.post("/add-marksheet", formData);
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   const renderSubjectDropdown = (index) => {
//     const selectedSubjects = formData.subjects.map((sub) => sub.subjectName);
//     return (
//       <select
//         name="subjectName"
//         value={formData.subjects[index].subjectName}
//         onChange={(e) => handleSubjectChange(e, index)}
//         style={{ color: "black" }}
//       >
//         <option value="">Select Subject</option>
//         {subjectOptions.map((subject, i) => (
//           <option key={i} value={subject} disabled={selectedSubjects.includes(subject)}>
//             {subject}
//           </option>
//         ))}
//       </select>
//     );
//   };

//   return (
//     <>
//       <Leftbar />
//       <div className="add-marksheet-form responsive_add-marksheet">
//         <h2>Add Marksheet</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="regNo">Registration No:</label>
//               <input
//                 type="text"
//                 id="regNo"
//                 name="regNo"
//                 placeholder="STTS/"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               {suggestions.regNo.length > 0 && (
//                 <ul>
//                   {suggestions.regNo.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.RegNo)}
//                     >
//                       {suggestion.RegNo}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//             <div className="form-group">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//               {suggestions.name.length > 0 && (
//                 <ul>
//                   {suggestions.name.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.name)}
//                     >
//                       {suggestion.name}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="course">Course:</label>
//               <input
//                 type="text"
//                 id="course"
//                 name="course"
//                 value={formData.course}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="centre">Centre:</label>
//               <input
//                 type="text"
//                 id="centre"
//                 name="centre"
//                 value={formData.centre}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="date">Date:</label>
//               <input
//                 type="date"
//                 id="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>Subject Name</th>
//                   <th>Max Marks</th>
//                   <th>Marks Scored</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {formData.subjects.map((subject, index) => (
//                   <tr key={index} style={{ width: "auto" }}>
//                     <td>
//                       <span className="responsive_s">{"  "}</span>
//                       {subject.srNo}
//                     </td>
//                     <td>{renderSubjectDropdown(index)}</td>
//                     <td>
//                       <span className="responsive_s">MaxMarks:{"  "}</span>
//                       {subject.maxMarks}
//                     </td>
//                     <td>
//                       <input
//                         type="number"
//                         name="marksScored"
//                         value={subject.marksScored}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         max="100"
//                         min="0"
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div style={{ display: "flex" }}>
//             <div className="button-container">
//               <button type="submit">Add Marksheet</button>
//             </div>
//             <div
//               className="button-container-1"
//               style={{ marginTop: "29px", marginLeft: "10px" }}
//             >
//               <a href="/view">
//                 <button type="button">View Marksheets</button>
//               </a>
//             </div>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddMarksheetForm;


// import React, { useState } from "react";
// import axios from "axios";
// import "./Marksheet.css";
// import Leftbar from "../../Components/Leftbar/Leftbar";

// const AddMarksheetForm = () => {
//   const initialFormData = {
//     name: "",
//     regNo: "",
//     course: "",
//     centre: "STTS, BARIPADA",
//     date: "",
//     subjects: [],
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [subjectOptions, setSubjectOptions] = useState([]);
//   const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

//   const courseSubjectMap = {
//     DCA: [
//       "Basic Computer Skills",
//       "MS Office Applications",
//       "Software Hacking and IT Security",
//       "ERP Basics",
//       "Internet Basics",
//       "E-Business",
//     ],
//     PGDCA: [
//       "Computer Fundamental",
//       "Ms-Dom & Windows",
//       "Ms-office",
//       "C,C++Programming",
//       "Internet",
//       "HTML",
//       "Practical",
//     ],
//    " ADVANCE PGDCA": [
//       "Computer Fundamental",
//       "Ms-Dom & Windows",
//       "Ms-office",
//       "C,C++Programming",
//       "Internet",
//       "Tally 9.0",
//       "HTML",
//       "Practical",
//     ],
//     // Add other courses and subjects here
//   };

//   const fetchSuggestions = async (query, field) => {
//     if (query.length > 0) {
//       try {
//         const response = await axios.get("/api/suggestions", {
//           params: { query },
//         });
//         setSuggestions((prev) => ({ ...prev, [field]: response.data }));
//       } catch (error) {
//         console.error("Error fetching suggestions:", error);
//       }
//     } else {
//       setSuggestions((prev) => ({ ...prev, [field]: [] }));
//     }
//   };

//   const fetchStudentInfo = async (field, value) => {
//     try {
//       const response = await axios.get("/api/student-info", {
//         params: { [field]: value },
//       });
//       if (response.data) {
//         const { name, course, regNo, date } = response.data;
//         console.log("hello",response.data)
//         const subjects = courseSubjectMap[course] || [];
//         setFormData({
//           name,
//           course,
//           regNo,
//           date: new Date(date).toISOString().split('T')[0], // Convert to YYYY-MM-DD format
//           subjects: subjects.map((subject, index) => ({
//             srNo: (index + 1).toString(),
//             subjectName: "",
//             maxMarks: "100",
//             marksScored: "",
//           })),
//         });
//         setSubjectOptions(subjects);
//       } else {
//         setFormData(initialFormData);
//         setSubjectOptions([]);
//       }
//     } catch (error) {
//       console.error("Error fetching student info:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });

//     if (name === "regNo" && value) {
//       fetchStudentInfo("regNo", value);
//     } else if (name === "name" && value) {
//       fetchStudentInfo("name", value);
//       fetchSuggestions(value, "name");
//     }
//   };

//   const handleSubjectChange = (e, index) => {
//     const { name, value } = e.target;
//     const updatedSubjects = [...formData.subjects];

//     if (name === "marksScored" && value <= 100) {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     } else if (name !== "marksScored") {
//       updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
//     }

//     setFormData({ ...formData, subjects: updatedSubjects });
//   };

//   const handleSuggestionClick = async (suggestion) => {
//     await fetchStudentInfo("name", suggestion);
//     setSuggestions((prev) => ({ ...prev, name: [] }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     const filledFieldsCount = formData.subjects.filter(
//       (subject) => subject.subjectName && subject.marksScored
//     ).length;

//     if (filledFieldsCount < 6) {
//       alert("Please fill at least 6 subjects.");
//       return;
//     }

//     // Log formData to see if all fields are included
//     console.log("Submitting data:", formData);

//     try {
//       await axios.post("/add-marksheet", formData);
//       setFormData(initialFormData);
//     } catch (error) {
//       console.error("There was an error!", error);
//     }
//   };

//   const renderSubjectDropdown = (index) => {
//     const selectedSubjects = formData.subjects.map((sub) => sub.subjectName);
//     return (
//       <select
//         name="subjectName"
//         value={formData.subjects[index].subjectName}
//         onChange={(e) => handleSubjectChange(e, index)}
//         style={{ color: "black" }}
//       >
//         <option value="">Select Subject</option>
//         {subjectOptions.map((subject, i) => (
//           <option key={i} value={subject} disabled={selectedSubjects.includes(subject)}>
//             {subject}
//           </option>
//         ))}
//       </select>
//     );
//   };

//   return (
//     <>
//       <Leftbar />
//       <div className="add-marksheet-form responsive_add-marksheet">
//         <h2>Add Marksheet</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="regNo">Registration No:</label>
//               <input
//                 type="text"
//                 id="regNo"
//                 name="regNo"
//                 placeholder="STTS/"
//                 value={formData.regNo}
//                 onChange={handleChange}
//                 required
//                 style={{ color: "black" }}
//               />
//               {suggestions.regNo.length > 0 && (
//                 <ul>
//                   {suggestions.regNo.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.RegNo)}
//                     >
//                       {suggestion.RegNo}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//             <div className="form-group">
//               <label htmlFor="name">Name:</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//               {suggestions.name.length > 0 && (
//                 <ul>
//                   {suggestions.name.map((suggestion, index) => (
//                     <li
//                       key={index}
//                       onClick={() => handleSuggestionClick(suggestion.name)}
//                     >
//                       {suggestion.name}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group">
//               <label htmlFor="course">Course:</label>
//               <input
//                 type="text"
//                 id="course"
//                 name="course"
//                 value={formData.course}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="centre">Centre:</label>
//               <input
//                 type="text"
//                 id="centre"
//                 name="centre"
//                 value={formData.centre}
//                 disabled
//                 style={{ color: "black" }}
//               />
//             </div>
//             <div className="form-group">
//               <label htmlFor="date">Date:</label>
//               <input
//                 type="date"
//                 id="date"
//                 name="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 style={{ color: "black" }}
//               />
//             </div>
//           </div>
//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Sr No</th>
//                   <th>Subject Name</th>
//                   <th>Max Marks</th>
//                   <th>Marks Scored</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {formData.subjects.map((subject, index) => (
//                   <tr key={index} style={{ width: "auto" }}>
//                     <td>
//                       <span className="responsive_s">{"  "}</span>
//                       {subject.srNo}
//                     </td>
//                     <td>{renderSubjectDropdown(index)}</td>
//                     <td>
//                       <span className="responsive_s">MaxMarks:{"  "}</span>
//                       {subject.maxMarks}
//                     </td>
//                     <td>
//                       <input
//                         type="number"
//                         name="marksScored"
//                         value={subject.marksScored}
//                         onChange={(e) => handleSubjectChange(e, index)}
//                         max="100"
//                         min="0"
//                         style={{ color: "black" }}
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//           <div style={{ marginTop: "20px" }}>
//             <button type="submit">Submit</button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddMarksheetForm;


import React, { useState } from "react";
import axios from "axios";
import "./Marksheet.css";
import Leftbar from "../../Components/Leftbar/Leftbar";

const AddMarksheetForm = () => {
  const initialFormData = {
    name: "",
    regNo: "",
    course: "",
    centre: "STTS, BARIPADA",
    date: "",
    subjects: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [suggestions, setSuggestions] = useState({ name: [], regNo: [] });

  const courseSubjectMap = {
    DCA: [
      "Basic Computer Skills",
      "MS Office Applications",
      "Software Hacking and IT Security",
      "ER Basics",
      "Internet Basics",
      "E-Business",
    ],
    "PGDCA": [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "Ms-office",
      "C,C++Programming",
      "Internet",
      "HTML",
      "Practical",
      "Tally9.0"
    ],
   "ADVANCE PGDCA": [
      "Computer Fundamental",
      "Ms-DOS & Windows",
      "Ms-office",
      "C,C++Programming",
      "Internet",
      "HTML",
      "Practical",
      "Tally9.0"
    ],
    
    // Add other courses and subjects here
  };

  const fetchSuggestions = async (query, field) => {
    if (query.length > 0) {
      try {
        const response = await axios.get("/api/suggestions", {
          params: { query },
        });
        setSuggestions((prev) => ({ ...prev, [field]: response.data }));
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions((prev) => ({ ...prev, [field]: [] }));
    }
  };
  const fetchStudentInfo = async (field, value) => {
    try {
        const response = await axios.get("/api/student-info", {
            params: { [field]: value },
        });

        console.log("API Response:", response.data); // Debugging output

        if (response.data) {
            const { name, course, RegNo } = response.data; // Ensure RegNo is properly destructured
            const subjects = courseSubjectMap[course] || [];

            setFormData(prevData => {
                const updatedFormData = {
                    ...prevData,
                    name: name || prevData.name,        // Always update the name
                    course: course || prevData.course,  // Always update the course
                    regNo: RegNo || prevData.regNo,     // Ensure RegNo is correctly updated here
                    date: prevData.date || "",          // Keep date if already set
                    centre: "STTS, BARIPADA",           // Set centre
                    subjects: subjects.map((subject, index) => ({
                        srNo: (index + 1).toString(),
                        subjectName: "",
                        maxMarks: "100",
                        marksScored: "",
                    })),
                };

                console.log("Updated FormData:", updatedFormData); // Debugging output
                return updatedFormData;
            });

            setSubjectOptions(subjects);
        } else {
            setFormData(prevData => ({
                ...prevData,
                course: "",
                date: "",
                subjects: [],
            }));
            setSubjectOptions([]);
        }
    } catch (error) {
        console.error("Error fetching student info:", error);
    }
};


  // const fetchStudentInfo = async (field, value) => {
  //   try {
  //     const response = await axios.get("/api/student-info", {
  //       params: { [field]: value },
  //     });
  //     if (response.data) {
  //       const { name, course, regNo } = response.data;
  //       const subjects = courseSubjectMap[course] || [];
  //       setFormData(prevData => ({
  //         ...prevData,
  //         name,
  //         course,
  //         regNo: regNo || prevData.regNo,
  //         date ,
  //         centre: "STTS, BARIPADA",
  //         subjects: subjects.map((subject, index) => ({
  //           srNo: (index + 1).toString(),
  //           subjectName: "",
  //           maxMarks: "100",
  //           marksScored: "",
  //         })),
  //       }));
  //       setSubjectOptions(subjects);
  //     } else {
  //       setFormData(prevData => ({
  //         ...prevData,
  //         course: "",
  //         date: "",
  //         subjects: [],
  //       }));
  //       setSubjectOptions([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching student info:", error);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));

    if (name === "regNo" && value) {
      fetchStudentInfo("regNo", value);
    } else if (name === "name" && value) {
      fetchStudentInfo("name", value);
      fetchSuggestions(value, "name");
    }
  };

  const handleSubjectChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSubjects = [...formData.subjects];

    if (name === "marksScored" && value <= 100) {
      updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
    } else if (name !== "marksScored") {
      updatedSubjects[index] = { ...updatedSubjects[index], [name]: value };
    }

    setFormData({ ...formData, subjects: updatedSubjects });
  };

  const handleSuggestionClick = async (suggestion) => {
    await fetchStudentInfo("name", suggestion);
    setSuggestions((prev) => ({ ...prev, name: [] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Submitting data:", formData);

    const filledFieldsCount = formData.subjects.filter(
      (subject) => subject.subjectName && subject.marksScored
    ).length;

    if (filledFieldsCount < 6) {
      alert("Please fill at least 6 subjects.");
      return;
    }

    try {
      await axios.post("/add-marksheet", formData);
      setFormData(initialFormData);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const renderSubjectDropdown = (index) => {
    const selectedSubjects = formData.subjects.map((sub) => sub.subjectName);
    return (
      <select
        name="subjectName"
        value={formData.subjects[index].subjectName}
        onChange={(e) => handleSubjectChange(e, index)}
        style={{ color: "black" }}
      >
        <option value="">Select Subject</option>
        {subjectOptions.map((subject, i) => (
          <option key={i} value={subject} disabled={selectedSubjects.includes(subject)}>
            {subject}
          </option>
        ))}
      </select>
    );
  };

  return (
    <>
      <Leftbar />
      <div className="add-marksheet-form responsive_add-marksheet">
        <h2>Add Marksheet</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="regNo">Registration No:</label>
              <input
                type="text"
                id="regNo"
                name="regNo"
                placeholder="STTS/"
                value={formData.regNo || ""}
                onChange={handleChange}
                required
                style={{ color: "black" }}
              />
              {suggestions.regNo.length > 0 && (
                <ul>
                  {suggestions.regNo.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion.RegNo)}
                    >
                      {suggestion.RegNo}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                style={{ color: "black" }}
              />
              {suggestions.name.length > 0 && (
                <ul>
                  {suggestions.name.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion.name)}
                    >
                      {suggestion.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="course">Course Title:</label>
              <input
                type="text"
                id="course"
                name="course"
                value={formData.course}
                disabled
                style={{ color: "black" }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="centre">Centre:</label>
              <input
                type="text"
                id="centre"
                name="centre"
                value={formData.centre}
                disabled
                style={{ color: "black" }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                style={{ color: "black" }}
                required
              />
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Subject Name</th>
                  <th>Max Marks</th>
                  <th>Marks Scored</th>
                </tr>
              </thead>
              <tbody>
                {formData.subjects.map((subject, index) => (
                  <tr key={index} style={{ width: "auto" }}>
                    <td>
                      <span className="responsive_s">{"  "}</span>
                      {subject.srNo}
                    </td>
                    <td>{renderSubjectDropdown(index)}</td>
                    <td>
                      <span className="responsive_s">MaxMarks:{"  "}</span>
                      {subject.maxMarks}
                    </td>
                    <td>
                      <input
                        type="number"
                        name="marksScored"
                        value={subject.marksScored}
                        onChange={(e) => handleSubjectChange(e, index)}
                        max="100"
                        min="0"
                        style={{ color: "black" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex" }}>
            <div className="button-container">
              <button type="submit">Add Marksheet</button>
            </div>
            <div
              className="button-container-1"
              style={{ marginTop: "29px", marginLeft: "10px" }}
            >
              <a href="/view">
                <button type="button">View Marksheets</button>
              </a>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddMarksheetForm;